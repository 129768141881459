import React from 'react';
import {
    IColumn,
    Checkbox, IDropdownOption, DropdownMenuItemType
} from "@fluentui/react";
import {DIRECTIMPORTCOLUMNS} from '../../Constants';
import CurrencyFormat from "react-currency-format";
import {IDirectImportItem} from "../../models/Extension";


export interface IDirectImportItemColumn {
    basicColumn: IColumn;
}
//NOTE: DO NOT USE KEYS THAT ARE COMMON ACROSS COLUMN SETS. Otherwise you get rendering issues on the DetailsList => (directImportDescription vs. description)
export const DIRECT_IMPORT_COLUMN_PROPERTIES: {[key: string]: IDirectImportItemColumn } = {
    'name': {
        basicColumn: {
            key: 'directImportname',
            name: 'Name',
            fieldName: 'name',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
    },
    'description': {
        basicColumn: {
            key: 'directImportdescription',
            name: 'Description',
            fieldName: 'description',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'cost': {
        basicColumn: {
            key: 'directImportCost',
            name: 'Cost',
            fieldName: 'cost',
            minWidth: 50,
            maxWidth: 100
        },
    },
    'price': {
        basicColumn: {
            key: 'directImportPrice',
            name: 'Price',
            fieldName: 'price',
            minWidth: 50,
        },
    },
    'manufacturer': {
        basicColumn: {
            key: 'directImportManufacturer',
            name: 'Manufacturer',
            fieldName: 'manufacturer',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    },
    'manufacturerPartNumber': {
        basicColumn: {
            key: 'directImportManufacturerPartNumber',
            name: 'Manufacturer Part Number',
            fieldName: 'manufacturerPartNumber',
            minWidth: 235,
            isRowHeader: true,
            isResizable: true,
        },
    },
    'vendorPartNumber': {
        basicColumn: {
            key: 'directImportVendorPartNumber',
            name: 'Vendor Part Number',
            fieldName: 'vendorPartNumber',
            minWidth: 150,
            isRowHeader: true,
        }
    },
    'vendor': {
        basicColumn: {
            key: 'directImportVendor',
            name: 'Vendor',
            fieldName: 'vendor',
            minWidth: 300,
            isRowHeader: true,
        }
    },
    'quantity': {
        basicColumn: {
            key: 'directImportQuantity',
            name: 'Quantity',
            fieldName: 'quantity',
            minWidth: 100,
            maxWidth: 100,
        },
    },
    'orderQuantity': {
        basicColumn: {
            key: 'directImportOrderQuantity',
            name: 'Order Quantity',
            fieldName: 'orderQuantity',
            minWidth: 100,
            maxWidth: 100,
        },
    },
    'source': {
        basicColumn: {
            key: 'directImportSource',
            name: 'Source',
            fieldName: 'source',
            minWidth: 200
        },
    },
    'active': {
        basicColumn: {
            key: 'directImportActive',
            name: 'Active',
            fieldName: 'active',
            minWidth: 100
        },
    },
    'status': {
        basicColumn: {
            key: 'directImportStatus',
            name: 'Status',
            fieldName: 'status',
            minWidth: 200
        },
    },
    'productClass': {
        basicColumn: {
            key: 'directImportProductClass',
            name: 'Product Class',
            fieldName: 'productClass',
            minWidth: 200
        },
    },
    'businessUnit': {
        basicColumn: {
            key: 'directImportBusinessUnit',
            name: 'Business Unit',
            fieldName: 'businessUnit',
            minWidth: 200
        },
    },
    'company': {
        basicColumn: {
            key: 'directImportCompany',
            name: 'Company',
            fieldName: 'company',
            minWidth: 200
        },
    },
    'margin': {
        basicColumn: {
            key: 'directImportMargin',
            name: 'Margin',
            fieldName: 'margin',
            minWidth: 200
        },
    },
    'isAcProduct': {
        basicColumn: {
            key: 'directImportIsAcProduct',
            name: 'In Catalog',
            fieldName: 'isAcProduct',
            minWidth: 200
        },
    },
    'salesOrder': {
        basicColumn: {
            key: 'directImportSalesOrder',
            name: 'Sales Order',
            fieldName: 'salesOrder',
            minWidth: 200
        },
    },
    'opportunity': {
        basicColumn: {
            key: 'directImportOpportunity',
            name: 'Opportunity',
            fieldName: 'opportunity',
            minWidth: 200
        },
    },
    'location': {
        basicColumn: {
            key: 'directImportLocation',
            name: 'Location',
            fieldName: 'location',
            minWidth: 200
        },
    },
    'purchaseDate': {
        basicColumn: {
            key: 'directImportPurchaseDate',
            name: 'Purchase Date',
            fieldName: 'purchaseDate',
            minWidth: 200
        },
    },
}

export const DEMO_DIRECT_IMPORT_ITEMS: IDirectImportItem[] = [
    {
        manufacturerPartNumber: 'mfp1',
        cost: 12.99,
        manufacturer: 'MFG Solutions',
        name: 'Item 1',
        quantity: 1,
        vendorPartNumber: 'VNDR-01',
        active: true,
        inStock: true,
        onHand: 150,
        description: 'Detailed information about MFG Solutions Item 1',
        acProduct: 'AcProductId1',
        opportunity: 'Opportunity 1',
        salesOrder: 'Sales Order 1',
        price: 36.99,
        location: 'MyCom Location 1',
        company: 'Customer Company',
        orderQuantity: 1,
        productClass: 'NonInventory',
        purchaseDate: '12/25/2023',
        vendor: 'DistiCo',
        margin: 50,
        businessUnit: 'Sales',
        status: 'Open'
    },
    {
        manufacturerPartNumber: 'mfp2',
        cost: 27.99,
        manufacturer: 'MFG Solutions',
        name: 'Item 2',
        quantity: 100,
        vendorPartNumber: 'VNDR-02',
        active: true,
        inStock: true,
        onHand: 3,
        description: 'Detailed information about MFG Solutions Item 1',
        acProduct: 'AcProductId2',
        opportunity: 'Opportunity 1',
        salesOrder: 'Sales Order 1',
        price: 54.99,
        location: 'MyCom Location 1',
        company: 'Customer Company',
        orderQuantity: 1,
        productClass: 'NonInventory',
        purchaseDate: '12/25/2023',
        vendor: 'DistiCo',
        margin: 50,
        businessUnit: 'Sales',
        status: 'Open'
    },
]

export const getDirectImportColumnOptions = () : IDropdownOption[] => {
    const options: IDropdownOption[] = [
        {key: 'Standard Fields', text: 'Standard Fields', itemType: DropdownMenuItemType.Header},
    ];

    options.push(...DIRECTIMPORTCOLUMNS.map(a => {
            return ({
                    key: DIRECT_IMPORT_COLUMN_PROPERTIES[a]?.basicColumn?.fieldName ?? "",
                    text: DIRECT_IMPORT_COLUMN_PROPERTIES[a]?.basicColumn?.name ?? ""}
            )
        }).filter(a => a.text != '')
    )

    return options;
}
export const renderDirectImportColumns = (): IColumn[] => {
    const columns: IColumn[] = [];
    for (const field of DIRECTIMPORTCOLUMNS) {
        const tempCol: IColumn = DIRECT_IMPORT_COLUMN_PROPERTIES[field]?.basicColumn ?? {
            key: `${field}`,
            name: `${field} - missing`,
            fieldName: `${field}`,
            minWidth: 100,
            maxWidth: 100,
        };
        if(field === "active"){
            tempCol.onRender = (product: IDirectImportItem) => 
                <Checkbox label={'Active'}
                          name={'Active'}
                          checked={product.active === true}
                          disabled={true}
                />

        }        
        else if(field === "cost"){
            tempCol.onRender = (product: IDirectImportItem) => product
                ?<div className={'ac-grid-field'}>
                    <CurrencyFormat
                        value={product.cost ?? 0}
                        displayType="text"
                        thousandSeparator=","
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>
                : null;
        }
        else if(field === "price"){
            tempCol.onRender = (product: IDirectImportItem) => product
                ?<div className={'ac-grid-field'}>
                    <CurrencyFormat
                        value={product.price ?? 0}
                        displayType="text"
                        thousandSeparator=","
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>
                : null;
        }
        else if (field ===  'isAcProduct'){
            tempCol.onRender = (product: IDirectImportItem) => product
                ? <div className={'ac-grid-field'}>
            <Checkbox
                checked={product.acProduct != undefined && product.acProduct != ''}
                disabled={true}
            />
        </div> : null;
        }
        else if (field === 'purchaseDate'){
            tempCol.onRender = (product: IDirectImportItem) => product.purchaseDate
                ? 
                    <div className={'ac-grid-field'}>{new Date(product.purchaseDate).toLocaleString()}</div> : null
        }
        columns.push(tempCol);
    }
    return columns;
}
