import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {useGetWorkspaceQuery, usePutWorkspaceMutation} from "../../store/Api";
import {Checkbox, ContextualMenu, Icon, IContextualMenuItem} from "@fluentui/react";


const WorkspaceTaxableHeader: React.FC = () => {
    const selectedWorkspace = useSelector((state: RootState) => state.settings.selectedWorkspaceId);
    if (selectedWorkspace == null) return <>Is Taxable</>
    return <WorkspaceTaxableEntry workspace={selectedWorkspace} />
}

interface IWorkspaceTaxableEntryProps {
    workspace: string;
}

const WorkspaceTaxableEntry: React.FC<IWorkspaceTaxableEntryProps> = (props) => {
    const workspace = useGetWorkspaceQuery(props.workspace);
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const headerRef = React.useRef<HTMLSpanElement>(null);
    const [patchWorkspace] = usePutWorkspaceMutation();

    const update = (v: boolean | undefined) => {
        if (workspace.data) {
            patchWorkspace({
                ...workspace.data,
                id: props.workspace,
                defaultTaxable: v,
                documentIsCurrent: false
            });
            setShowMenu(false);
        }
    }
    const getDefaultTaxableHeader = () => {
        if(workspace.data?.defaultTaxable === true) {
            return '(✓)'
        }
        return ''

    }

    const menuItems: IContextualMenuItem[] = [
        {
            onRender: () =>
                <div style={{margin: '1em'}}>
                    <Checkbox checked={workspace.data?.defaultTaxable === true}
                              label={'Taxable'}
                        onChange={(e,d) => {
                            if (d != undefined) {
                                update(d)
                            }
                        }}
                    />
                </div>,
            key: 'isTaxable',
        }
    ]
    return (<>
        <div
            onClick={() => {
                setShowMenu(!showMenu);
            }}
            style={{
                cursor: 'pointer',
                width: '400px',
            }}
        >
            <span ref={headerRef}>
                Taxable {getDefaultTaxableHeader()}
                <ContextualMenu items={menuItems} hidden={!showMenu} target={headerRef}/>
                <Icon iconName={'Edit'} style={{marginLeft: '0.5em'}}/>
            </span>
        </div>
    </>);
}

export default WorkspaceTaxableHeader;