import React, {useEffect, useLayoutEffect, useState} from "react";
import {
    CommandBar,
    DetailsList,
    IColumn, ICommandBarItemProps,
    IDetailsHeaderProps,
    IRenderFunction,
    Selection,
    SelectionMode
} from "@fluentui/react";
import {IWorkspaceItem, IWorkspaceItemDetails} from "../../models/Workspace";
import Sticky from "react-stickynode"
import {renderProcurementSelectorColumns} from "../../logic/Columns/ProcurementItemColumns";
import {cloneDeep} from "lodash";

interface IWorkspaceProcurementTableProps {
    selectedItems: IWorkspaceItem[];
    onSelectedItemsChanged?: (items: IWorkspaceItem[]) => void;
    generateProcurementProducts: (items: IWorkspaceItem[]) => void;
    onValidate: (isValid: boolean, message?: string) => void
    onChange: (item:IWorkspaceItem[]) => void;
}

const WorkspaceProcurementTable: React.FC<IWorkspaceProcurementTableProps> = (props) => {
    const [activeProducts, setActiveProducts] = useState<IWorkspaceItem[]>([]);
    const [columns, setColumns] = useState<IColumn[]>([])
    const [selected, setSelected] = useState<IWorkspaceItem>()
    useEffect(()=> {
        if(props.selectedItems && props.selectedItems.length > 0){
            setActiveProducts(props.selectedItems)
        }
    }, [props.selectedItems])
    useEffect(() => {
        if(activeProducts){
            props.generateProcurementProducts(activeProducts)
            validate(activeProducts)
        }
    }, [activeProducts]);

    function validate(items: IWorkspaceItem[]){
        let valid = true;
        let validationMessage = undefined
        items?.forEach((item) => {
            if(!item.details.source || item.details.source == '' || item.details.source == 'LowestInStock'){
                validationMessage = "Source selection required"
                valid = false
            }
        })
        props.onValidate(valid, validationMessage)
    }

    function detailsUpdater(product: IWorkspaceItem, details: IWorkspaceItemDetails) {
        const changingProducts = cloneDeep(activeProducts)
        const specificProduct = changingProducts?.filter((a) => a.id === product.id)?.[0];
        const productIndex = changingProducts?.findIndex((a) => a.id === product.id);
        if(productIndex != -1) {
            if (specificProduct?.details && changingProducts && changingProducts.length > 0) {
                specificProduct.details = details
                setActiveProducts(changingProducts)
                props.onChange(changingProducts)
            }
        }
    }

    function splitItem(product: IWorkspaceItem) {
        const changingProducts = cloneDeep(activeProducts)
        const specificProduct = changingProducts?.filter((a) => a.id === product.id)?.[0];

        if(!specificProduct?.details) return;
        let newItemQuantity = 1;
        if(specificProduct.details.quantity && specificProduct.details.quantity > 1){
            const currentQuantity = specificProduct.details.quantity;
            const remainder = currentQuantity % 2
            const halfsies = Math.trunc(currentQuantity / 2)
            specificProduct.details.quantity = halfsies + remainder
            newItemQuantity = halfsies
        }
        const newProduct = cloneDeep(specificProduct)
        newProduct.id = newProduct?.id + '-split'
        if(newProduct?.details){
            newProduct.details.quantity = newItemQuantity
        }
        const productIndex = changingProducts?.findIndex((a) => a.id === product.id);
        if(productIndex != -1) {
            changingProducts.splice((productIndex+1), 0, newProduct)
            setActiveProducts(changingProducts)
        }
    }

    function removeItem(product: IWorkspaceItem){
        const changingProducts = cloneDeep(activeProducts)
        const productIndex = changingProducts?.findIndex((a) => a.id === product.id);
        if(productIndex != -1) {
            changingProducts.splice(productIndex, 1)
            setActiveProducts(changingProducts)
        }
    }

    const commands: ICommandBarItemProps[] = [
        {
            key: 'split',
            text: 'Split',
            disabled: !selected,
            iconProps: { iconName: 'Split' },
            onClick: () => {
                if(selected) {
                    splitItem(selected)
                }
            }
        },
        {
            key: 'remove',
            text: 'Remove',
            disabled: !selected,
            iconProps: { iconName: 'Delete' },
            onClick: () => {
                if(selected) {
                    removeItem(selected)
                }
            }
        }
    ]

    useLayoutEffect(() => {
        const columns = renderProcurementSelectorColumns(detailsUpdater);
        setColumns(columns)
    }, [activeProducts]);

    const selection = new Selection({
        onSelectionChanged: () => {
            const selected = selection.getSelection() as IWorkspaceItem[];
            setSelected(selected[0])
            if(props.onSelectedItemsChanged) {
                props.onSelectedItemsChanged(selected);
            }
        }
    })

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        return (<Sticky enabled={true} top={48} innerZ={10}>{defaultRender!({...props})}</Sticky>);
    };

    return (<div>
            <CommandBar items={commands}></CommandBar>
            <DetailsList
                key={'procurement-items-details'}
                items={activeProducts ?? []}
                columns={columns}
                selectionMode={SelectionMode.single}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                onRenderDetailsHeader={onRenderDetailsHeader}
            />
        </div>
    )
};

export default WorkspaceProcurementTable;