import {BaseDAL} from "./BaseDAL";
import {
    IRealTimeExtensionResponse, 
    IExtensionLink,
    IImportExtensionMappingSampleResponse,
    IImportExtensionMappingSampleRequest, 
    IProductReference,
} from "../models/Extension";

export class ExtensionsDAL extends BaseDAL {
    public postRunRealTimeExtension(
        id: string,
        products?: IProductReference[],
        signal?: AbortSignal,
    ): Promise<IRealTimeExtensionResponse | null> {
        const url = `${this.baseUrl}/api/extensions/realtime/${id}/run?`;
        const body = {
            products: products,
        };
        return this.post<IRealTimeExtensionResponse>(url, JSON.stringify(body), signal);
    }
    public getExtensionLink(
        extensionId: string,
        id: string,
    ) {
        const url = `${this.baseUrl}/api/extensions/links/${extensionId}/${id}?`;
        return this.get<IExtensionLink>(url);
    }

    public getImportSample(
        extensionId: string,
        body: IImportExtensionMappingSampleRequest
    ) {
        const url = `${this.baseUrl}/api/extensions/available/${extensionId}/getSample?`;
        return this.post<IImportExtensionMappingSampleResponse>(url, JSON.stringify(body));
    }

}
