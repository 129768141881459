import {IActionResponse} from "./BaseDAL";
import {IGetEmbeddedSessionKeyRequest, IResolveEmbeddedSessionRequest} from "../models/ApiKey";
import {AnonDAL} from "./AnonDAL";

export class EmbeddedLoginDAL extends AnonDAL {
    public postCreateEmbeddedSession(requestBody: IGetEmbeddedSessionKeyRequest) {
        const url = `${this.baseUrl}/api/keyauth/embeddedLogin`;
        return this.post<IActionResponse>(url, JSON.stringify(requestBody));
    }
    
    public postValidateExistingSession(requestBody: IResolveEmbeddedSessionRequest){
        const url = `${this.baseUrl}/api/keyauth/embeddedLogin/existingSession`
        return this.post<IActionResponse>(url, JSON.stringify(requestBody));
    }
    
    public getFrameableHosts(){
        const url = `${this.baseUrl}/api/keyauth/frameableHosts`
        return this.get<string[]>(url)
    }
}
