import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Icon} from "@fluentui/react";
import {useHistory} from "react-router-dom";
import {setSessionAuth} from "../store/authSlice";
import {
    setPunchoutWorkspace,
    setExternalQuoteId,
    setExternalQuotePartitionId,
    setPunchoutMode,
    setSelectedWorkspaceId, setSourceInstance
} from "../store/settingsSlice";
import {RootState} from "../store/rootReducer";
const EmbeddedPunchoutPage: React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const workspaceid = query.get('workspace');
    const quoteId = query.get('quoteId');
    const quotePartitionId = query.get('quotePartitionId');
    const sourceInstance = query.get('sourceInstance');
    const punchoutMode = query.get('punchoutMode');    
    const sessionKey = query.get('sessionKey');
    const auth = useSelector((state: RootState) => state.auth);
    
    useEffect(() => {
        if(sessionKey) {
            console.log('setting session key')
            dispatch(setSessionAuth(sessionKey))
        }
    }, [sessionKey]);

    setTimeout(() => {

        console.log('hit the punchout page')
        if(workspaceid) {
            dispatch(setPunchoutWorkspace(workspaceid));
            dispatch(setSelectedWorkspaceId(workspaceid));
        }
        if(quoteId) {
            dispatch(setExternalQuoteId(quoteId));
        }
        if(quotePartitionId) {
            dispatch(setExternalQuotePartitionId(quotePartitionId));
        }
        if(punchoutMode) {
            dispatch(setPunchoutMode(punchoutMode));
        }
        if(sourceInstance) {
            dispatch(setSourceInstance(sourceInstance));
        }
        history.push('/punchout/search');
    }, 500)
    if (!sessionKey) {
        return <div style={{textAlign:'center'}}>
            <Icon iconName='Error' style={{fontSize:150, marginTop: '1em', color: 'red'}} />
            <h1>Session ID Not Found</h1>
            <p>
                Your session ID was either not found or expired. Please try again.
            </p>
            <p>
                If you continue to run into this error, please contact support.
            </p>
        </div>
    }


    return (<div style={{textAlign: 'center'}}>Loading</div>);
}

export default EmbeddedPunchoutPage;