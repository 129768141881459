import React from 'react';
import {IWorkspaceFileImportResponse, IWorkspaceFileImportResponseRecord} from "../../models/Workspace";
import TableRowSearchResults from "./TableRowSearchResults";
import {
    createTheme,
    DetailsList,
    DetailsRow,
    IColumn,
    IDetailsRowProps,
    SelectionMode, ThemeProvider
} from "@fluentui/react";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {IProductReference} from "../../models/Extension";

interface ImportTableProps {
    data?: IWorkspaceFileImportResponse;
    onSelectedChange: (ids: IProductReference[]) => void;
    sideBySide?: boolean;
    catalog?: string;
}

const ImportTable: React.FC<ImportTableProps> = (props) => {
    const [selected, setSelected] = React.useState<{[key: number]: IProductReference | undefined}>({});

    const renderRow = (row: IDetailsRowProps | undefined) => {
        if (!row) return null;
        const item = row.item as IWorkspaceFileImportResponseRecord;
        return <>
            <DetailsRow {...row} item={normalize(item)} />
            {item.values
                ? <TableRowSearchResults
                    catalog={props.catalog}
                    values={item.values}
                    manufacturerPartNumber={item.manufacturerPartNumber}
                    productName={item.productName}
                    onSelectedChange={(ref) => {updateSelected(ref, row?.itemIndex || 0)}}
                />
                : <div>Row does not have values</div>
            }
        </>
    }

    const headers: IColumn[] = props.data?.headers?.map((h) => ({
        name: h,
        fieldName: h,
        key: h,
        minWidth: 50,
        maxWidth: 100,
        isMultiline: true
    })) ?? [];

    const normalize = (item: IWorkspaceFileImportResponseRecord) => {
        const val: {[key:string]: string} = {};
        if (item.values == null) return val;
        for (let i = 0; i < item.values.length; i++) {
            if (props.data?.headers != null) val[props.data.headers[i]] = item.values[i];
            else val[`Column ${i}`] = item.values[i];
        }
        return val;
    }

    const updateSelected = (ref: IProductReference | undefined, index: number) => {
        setSelected((prev) => {
            prev[index] = ref
            const filtered: IProductReference[] = [];
            for(const s of Object.values(prev)) {
                if (s) filtered.push({...s});
            }
            props.onSelectedChange(filtered);
            return prev
        });
    }

    const sideBySideColumns: IColumn[] = [
        ColumnGenerator('Column', '0', 100, 200),
        ColumnGenerator('Value', '1', 100, 200, undefined, true),
    ]

    const transparentTheme = createTheme({
        palette: {
            white: '#e2e9cb',                 // used for default background color
            neutralLighter: '#cfdcb5',        // used for hover background color
            neutralLight: '#ddd',          // used for selected background color
            neutralQuaternaryAlt: '#0'   // used for selected hover background color
        }
    });

    if (props.sideBySide) return <>
        <table style={{width: '95%', borderCollapse: 'collapse'}}>
            <thead>
                <tr>
                    <th style={{width: '35%', textAlign: 'center', backgroundColor: '#789c2f', color: '#efefef'}}>Data from File</th>
                    <th style={{width: '65%', textAlign: 'center', backgroundColor: '#577222', color: '#efefef'}}>Possible Matches</th>
                </tr>
            </thead>
            <tbody>
            {props.data?.rows.map((row, index) => {
                const normalized = normalize(row);
                return <tr key={index} style={{borderCollapse: 'collapse'}}>
                    <td valign='top' style={{borderCollapse: 'collapse'}}>
                        <ThemeProvider theme={transparentTheme}>
                            <DetailsList
                                theme={transparentTheme}
                                items={Object.entries(normalized)}
                                selectionMode={SelectionMode.none}
                                columns={sideBySideColumns}
                                />
                        </ThemeProvider>
                    </td>
                    <td valign='top'>
                        {row.values
                            ? <TableRowSearchResults
                                catalog={props.catalog}
                                values={row.values}
                                manufacturerPartNumber={row.manufacturerPartNumber}
                                productName={row.productName}
                                onSelectedChange={(ref) => {updateSelected(ref, index)}}
                            />
                            : <div>Row does not have values</div>
                        }
                    </td>
                </tr>
            })}
            </tbody>
        </table>
    </>

    return <>
        <DetailsList
            items={props.data?.rows ?? []}
            onRenderRow={renderRow}
            columns={headers}
            styles={{root: {width: '100%'}}}
            selectionMode={SelectionMode.none}
        />
    </>
}

export default ImportTable;