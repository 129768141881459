import {IAdditionalData} from "../../models/Extension";
import React, {useEffect} from "react";
import {ComboBox} from "@fluentui/react";
import {useGetExtensionInstallOptionsQuery} from "../../store/Api";

interface IExtensionInstallOptionProps {
    initialValue: string;
    optionsField: IAdditionalData;
    extensionId: string;
    onChange: (field: string, value: string) => void;
    readOnly?: boolean;
}
const ExtensionInstallOption: React.FC<IExtensionInstallOptionProps> = (props) => {
    const fieldOptions = useGetExtensionInstallOptionsQuery({extensionId: props.extensionId, field: props.optionsField.field}, {skip:(!props.extensionId || !props.optionsField.field)})
    const [selectedOption, setSelectedOption] = React.useState<string>()
    
    useEffect(() => {
        if(!selectedOption && fieldOptions?.data){
            const previousDefault = props.initialValue;
            if(previousDefault){
                setSelectedOption(previousDefault)
                props.onChange(props.optionsField.field, previousDefault)
            }
            else {
                const defaultOption = fieldOptions?.data?.options?.filter((a) => a.default)
                if (defaultOption && defaultOption.length > 0) {
                    setSelectedOption(defaultOption[0].value)
                    props.onChange(props.optionsField.field, defaultOption[0].value)
                }
            }
        }
        }, [fieldOptions.data]
    )

    return(<ComboBox
        label={props.optionsField.description}
        options={fieldOptions.data?.options?.map(c => ({
            key: c.value,
            text: c.display
        })) ?? []}
        autoComplete={"on"}
        errorMessage={!props.optionsField.optional && !selectedOption ? "Field is required" : ""}
        disabled={fieldOptions.isFetching || props.readOnly}
        required={!props.optionsField.optional}
        placeholder={"Select Option"}
        selectedKey={selectedOption}
        onChange={(e, d) => {
            setSelectedOption(d?.key?.toString() ?? "")
            props.onChange(props.optionsField.field, d?.key.toString() ?? '')
        }}/>);
}
export default ExtensionInstallOption;