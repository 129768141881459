import { IFavorite, IProduct, IVendor } from "../models/Search";
import { INormalizedProduct } from "../models/NormalizedProduct";
import {
    IProductReference,
    IRealTimeExtensionResponse, IRealTimeExtensionResponseItem
} from "../models/Extension";
import { IWorkspaceItem, IWorkspaceItemDetails } from "../models/Workspace";

export function NormalizeResult(product: IProduct, favorite?: IFavorite, desiredQuantity?: number, excludeFromBestPriceVendors?: string[]): INormalizedProduct {
    const normalized: INormalizedProduct = {...product};
    normalized.stock = false;
    if (normalized.vendors != null) {
        const vendors = Object.values(normalized.vendors);
        if (vendors.length === 1) {
            normalized.cost = vendors[0].cost;
        } else if (vendors.length > 1) {
            // normalized.lowestCost = vendors.reduce((min, v) => v.cost < min ? v.cost : min, vendors[0].cost);
            vendors.forEach((v) => {
                if (v.cost !== undefined && v.cost !== null) {
                    if (
                        normalized.lowestCost === undefined ||
                        (normalized.lowestCost > v.cost)
                    ) {
                        normalized.lowestCost = v.cost;
                    }
                    if (
                        normalized.highestCost === undefined ||
                        (normalized.highestCost < v.cost)
                    ) {
                        normalized.highestCost = v.cost;
                    }
                }
            });
            if (normalized.lowestCost === normalized.highestCost) {
                normalized.cost = normalized.lowestCost;
                normalized.lowestCost = undefined;
                normalized.highestCost = undefined;
            }
            // normalized.highestCost = vendors.reduce((min, v) => v.cost > min ? v.cost : min, vendors[0].cost);
        }
        if (vendors.some(checkStock)) {
            normalized.stock = true;
        }
    }

    let [bestPrice, bestBasePrice, bestVendor, onHand] = calculateBestPrice(normalized, desiredQuantity ?? 1, true, true, excludeFromBestPriceVendors);
    if (bestPrice == null) [bestPrice, bestBasePrice, bestVendor, onHand] = calculateBestPrice(normalized, desiredQuantity ?? 1, true, false, excludeFromBestPriceVendors);
    if (bestPrice == null) [bestPrice, bestBasePrice, bestVendor, onHand] = calculateBestPrice(normalized, desiredQuantity ?? 1,false, false, excludeFromBestPriceVendors);
    normalized.bestPrice = bestPrice;
    normalized.bestBasePrice = bestBasePrice;
    normalized.bestVendor = bestVendor;
    normalized.bestVendorOnHand = onHand;
    
     if (favorite) {
        normalized.favorite = favorite;
     }
    return normalized;
}

const calculateBestPrice = (product: INormalizedProduct, desiredQuantity: number, inStockOnly: boolean, quantityCheck: boolean, excludeVendors?: string[]): [number | undefined, number | undefined, string | undefined, number | undefined] => {
    if (product.vendors == null) return [undefined, undefined, undefined, undefined];
    let bestPrice = Number.MAX_VALUE;
    let bestBasePrice = Number.MAX_VALUE;
    let bestVendor = '';
    let onHand = undefined;

    for (const vendor of product.vendors) {
        if (excludeVendors?.includes(vendor.vendorName)) continue;
        if (quantityCheck && desiredQuantity && vendor.onHand && vendor.onHand < desiredQuantity) continue;
        if (inStockOnly && !vendor.inStock && vendor.onHand === 0) continue;
        if (desiredQuantity && vendor.priceBreaks) {
            for (const pb of vendor.priceBreaks) {
                if (pb.quantity <= desiredQuantity && pb.price < bestPrice) {
                    bestPrice = pb.price;
                    bestVendor = vendor.vendorName;
                    bestBasePrice = vendor.cost ?? 0;
                    onHand = vendor.onHand ?? undefined;
                }
            }
        }
        if (vendor.cost && vendor.cost < bestPrice) {
            bestPrice = vendor.cost;
            bestVendor = vendor.vendorName;
            bestBasePrice = vendor.cost;
            onHand = vendor.onHand ?? undefined;
        }
    }
    if (bestPrice === Number.MAX_VALUE) return [undefined, undefined, undefined, undefined]
    return [bestPrice, bestBasePrice, bestVendor, onHand];
}

function checkStock(vendor: IVendor) {
    return vendor.inStock;
}

export const generateShortDescription = (product: INormalizedProduct):string | undefined => {
    let formFactor: string | undefined;
    let memory: string | undefined;
    let procType: string | undefined;
    let procModel: string | undefined;
    let procFreq: string | undefined;
    let procFamily: string | undefined;
    let rackHeight: string | undefined;
    let taa: string | undefined;
    let storageCap: string | undefined;
    let storageType: string | undefined;
    let screenSize: string | undefined;
    let screenRes: string | undefined;
    let screenType: string | undefined;
    let os: string | undefined;
    let gpu: string | undefined;
    if (product.facets == null) {
        return;
    }

    for (const facet of Object.keys(product.facets)) {
        switch (facet.toLowerCase()) {
            case 'form factor':
                formFactor = product.facets[facet];
                break;
            case 'standard memory':
            case 'memory size':
            case 'internal memory':
                memory = product.facets[facet];
                break;
            case 'processor type':
                procType = product.facets[facet];
                break;
            case 'processor model':
                procModel = product.facets[facet];
                break;
            case 'rack height':
            case 'compatible rack unit':
                rackHeight = product.facets[facet];
                break;
            case 'taa compliant':
                if (product.facets[facet] === 'Yes') {
                    taa = 'TAA Compliant';
                }
                break;
            case 'storage capacity':
            case 'total storage capacity':
                storageCap = product.facets[facet];
                break;
            case 'screen size':
            case 'display diagonal':
                screenSize = product.facets[facet];
                break;
            case 'display screen type':
                screenType = product.facets[facet];
                break;
            case 'processor frequency':
                procFreq = product.facets[facet];
                break;
            case 'screen resolution':
            case 'maximum resolution':
            case 'display resolution':
                screenRes = product.facets[facet];
                break;
            case 'processor family':
                procFamily = product.facets[facet];
                break;
            case 'operating system installed':
                os = product.facets[facet];
                break;
            case 'storage media':
                storageType = product.facets[facet];
                break;
            case 'discrete graphics adapter model':
                if (product.facets[facet] !== 'Not available') {
                    gpu = product.facets[facet];
                }
        }
    }
    const details = []
    if (formFactor) {
        details.push(formFactor);
    }
    if (memory) {
        details.push(`${memory} RAM`);
    }
    if(procType || procModel || procFreq) {
        details.push(`${procType ?? ''} ${procModel ?? ''} ${procFreq ?? ''}`)
    } else if (procFamily) {
        details.push(`${procFamily}`);
    }
    if (rackHeight) {
        details.push(rackHeight);
    }
    if (storageCap || storageType) {
        details.push(`${storageCap} ${storageType}`);
    }
    if (screenSize || screenRes || screenType) {
        details.push(`${screenSize ?? ''} ${screenRes ?? ''} ${screenType ?? ''}`);
    }
    if (gpu) {
        details.push(gpu);
    }
    if (os) {
        details.push(os);
    }
    if (taa) {
        details.push(taa);
    }

    if(details.length === 0) {
        return;
    }
    return details.join(', ');
}


export function calculatePrice(product: INormalizedProduct, details: IWorkspaceItemDetails, defaultMarkup?: number, defaultListDiscount?: number) {
    if (details.price != undefined) return details.price;
    if (details.markup != undefined || defaultMarkup != undefined) {
        const markup = (details.markup ?? defaultMarkup ?? 0) + 100;
        return (Math.round((calculateCost(product, details.source, details.discountedCost, details.overrideCost) ?? 0) * markup) / 100).toFixed(2);
    }
    return 0;
}

export function calculateCost(product: INormalizedProduct, source?: string, discountedCost?: number, overrideCost?: number) {
    if (overrideCost != undefined) return overrideCost;
    if (discountedCost != undefined) return discountedCost;
    if(product.vendors == null) return 0;
    if (source === 'LowestPrice') {
        const lowestCost = product.vendors.reduce((prev, current) => {
            return (prev.cost == null || (current.cost != null && prev.cost < current.cost)) ? prev : current;
        });
        return lowestCost.cost;
    } else if (source === 'LowestInStock' || source === undefined) {
        let lowestCost = 0;
        for (const vendor of product.vendors) {
            if (vendor.inStock || (vendor.onHand != null && vendor.onHand > 0)) {
                if (vendor.cost != null && (lowestCost === 0 || vendor.cost < lowestCost)) {
                    lowestCost = vendor.cost;
                }
            }
        }
        return lowestCost;
    }
    const vendor = product.vendors.find((v) => v.vendorName === source);
    if (vendor) {
        if (vendor.cost !== undefined) {
            return vendor.cost;
        }
    }
    return 0;
}

export const updateVendorFromRealtime = (vendor: IVendor, result:IRealTimeExtensionResponseItem, pRef: IProductReference) => {
    if (result.cost !== undefined) {
        vendor.cost = result.cost;
    }
    if (result.onHand !== undefined) {
        vendor.onHand = result.onHand;
    }
    if (result.warehouses !== undefined) {
        vendor.warehouses = result.warehouses;
    }
    if (result.inStockDate !== undefined) {
        vendor.inStockDate = result.inStockDate;
    }
    if (result.priceBreaks !== undefined) {
        vendor.priceBreaks = result.priceBreaks?.map(pb => ({
            ...pb,
            selected: pRef?.quantity === pb.quantity
        }));
    }
    vendor.quantityCost = result.quantityCost;
    return vendor;
}

export const createVendorFromRealtime = (product: IProduct, result: IRealTimeExtensionResponseItem, vendorName: string, pRef: IProductReference) => {
    if(product.vendors == null) product.vendors = [];
    product.vendors.push({
        vendorName: vendorName,
        vendorPartNumber: result.vendorPartNumber,
        cost: result.cost,
        onHand: result.onHand,
        inStock: result.onHand ? result.onHand > 0 : false,
        status: result.error,
        warehouses: result.warehouses,
        priceBreaks: result.priceBreaks?.map(pb => ({
            ...pb,
            selected: pRef?.quantity === pb.quantity
        })),
        quantityCost: result.quantityCost,
    });
}

export const updateProductFromRealtime = (product: INormalizedProduct, result: IRealTimeExtensionResponse, vendorName: string, pRef: IProductReference) => {
    if(product.vendors != null) {
        let updated = false;
        for (const vendor of product.vendors) {
            if (vendor.vendorName !== vendorName) continue;
            const rVendor =
                result.items.find((v) => v.referenceId === product.id && v.vendorPartNumber === vendor.vendorPartNumber)
                ?? result.items.find((v) => v.vendorPartNumber === vendor.vendorPartNumber);
            if (rVendor) {
                updateVendorFromRealtime(vendor, rVendor, pRef);
                updated = true;
            } 
        }
        if (!updated) {
            const newVendor = result.items.find((v) => v.referenceId === product.id);
            if (newVendor) {
                createVendorFromRealtime(product, newVendor, vendorName, pRef);
            }
        }
    }
}

export const updateWorkspaceItemFromRealtime = (product: IWorkspaceItem, result: IRealTimeExtensionResponse, vendorName: string) => {
    if (product.details.source !== vendorName) return;
    let vpn: string | undefined;
    if (product.normalized?.vendors) {
        for (const vendor of product.normalized.vendors) {
            if (vendor.vendorName === vendorName) {
                vpn = vendor.vendorPartNumber;
                break;
            }
        }
    } else if (product.product?.vendors) {
        for (const vendor of product.product.vendors) {
            if (vendor.vendorName === vendorName) {
                vpn = vendor.vendorPartNumber;
                break;
            }
        }
    }
    if (vpn === undefined) return;
    const rVendor = result.items.find((v) => v.referenceId === product.productId && v.vendorPartNumber === vpn)
        ?? result.items.find((v) => v.vendorPartNumber === vpn);
    if (rVendor == null) return;
    
    const pb = rVendor.priceBreaks?.find(p => p.selected || p.quantity === product.details.quantity)?.price
        ?? rVendor.quantityCost;
    product.details.discountedCost = pb;
}