import React from "react";
import "./ResultTable.css";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {
    IColumn,
    IDetailsHeaderProps,
    IRenderFunction,
    PrimaryButton,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {renderSearchColumns} from '../../logic/Columns/ProductColumns';
import {useGetColumnsQuery, useGetFavoritesQuery, useGetSearchResultsQuery} from "../../store/Api";
import {SEARCHCOLUMNS} from "../../Constants";
import {NormalizeResult} from "../../logic/Product";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import Sticky from "react-stickynode";
import { cloneDeep } from "lodash";

interface IResultTableProps {
    runningRealtime?: boolean;
    refreshingVendors?: string[];
    onDetailsSelection: (p: INormalizedProduct) => void;
}

const ResultTable: React.FC<IResultTableProps> = (props) => {
    const catalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const criteria = useSelector((state: RootState) => state.search.criteria);
    const products = useGetSearchResultsQuery({catalog: catalog?.name, criteria});
    const favorites = useGetFavoritesQuery();
    const getColumns = useGetColumnsQuery();
    const sortBestPrice = useSelector((state: RootState) => state.search.sortBestPrice);
    const desiredQuantity = useSelector((state: RootState) => state.search.desiredQuantity);
    const [normalized, setNormalized] = React.useState<INormalizedProduct[]>([]);
    const [sorted, setSorted] = React.useState<INormalizedProduct[]>([]);
    const bestPriceExcludeGlobal = useSelector((state: RootState) => state.settings.selectedOrganization?.excludeFromBestPrice);
    const bestPriceExcludeSearch = useSelector((state: RootState) => state.search.bestPriceExclusion);

    React.useEffect(() => {
        const bpExclude = [...(bestPriceExcludeGlobal ?? []), ...(bestPriceExcludeSearch ?? [])]
        const norm = products.data?.products.map(p => NormalizeResult(p, favorites.data ? favorites.data[p.id] : undefined, desiredQuantity, bpExclude));
        setNormalized(norm ?? []);
    }, [products.data, bestPriceExcludeSearch, bestPriceExcludeGlobal, favorites.data, desiredQuantity]);

    React.useEffect(() => {
        if (sortBestPrice === undefined) {
            setSorted(normalized);
            return;
        }
        setSorted(cloneDeep(normalized).sort((a, b) => {
            // Define categories for quantity comparison
            const getQuantityCategory = (product: INormalizedProduct) => {
                if (desiredQuantity === undefined || desiredQuantity === 1) return 0; // no desired quantity (default to sufficient)
                if (product.bestVendorOnHand === undefined || product.bestVendorOnHand === 0) return 2; // out of stock
                if (product.bestVendorOnHand >= desiredQuantity) return 0; // sufficient
                return 1; // insufficient
            };
    
            const aCategory = getQuantityCategory(a);
            const bCategory = getQuantityCategory(b);
    
            // If products are in different categories, sort by category
            if (aCategory !== bCategory) {
                return aCategory - bCategory;
            }
    
            // If products are in the same category, sort by price
            if (a.bestPrice === undefined && b.bestPrice === undefined) {
                return 0;
            }
            if (a.bestPrice === undefined) {
                return 1;
            }
            if (b.bestPrice === undefined) {
                return -1;
            }
            return sortBestPrice === 'ASC'
                ? a.bestPrice - b.bestPrice
                : b.bestPrice - a.bestPrice;
        }));
    }, [normalized, sortBestPrice, desiredQuantity]);


    const columns = getColumns.data
        ? renderSearchColumns(getColumns.data.searchColumns, props.runningRealtime ?? false, false, props.refreshingVendors)
        : renderSearchColumns(SEARCHCOLUMNS, props.runningRealtime ?? false, false, props.refreshingVendors);
        
    const detailsButton: IColumn = {
        key: 'details',
        name: '',
        fieldName: 'details',
        minWidth: 100,
        maxWidth: 100,
        onRender:(product: INormalizedProduct) => <PrimaryButton
            text='Details'
            onClick={() => {
                props.onDetailsSelection(product)
            }}
        />
    }
    columns.push(detailsButton);

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        return (<Sticky enabled={true} top={48} innerZ={10}>{defaultRender!({...props})}</Sticky>);
    };

    return (
        <ShimmeredDetailsList
                items={sorted}
                enableShimmer={products.isFetching || favorites.isFetching} 
                columns={columns} 
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={onRenderDetailsHeader}
            />
    );
};

export default ResultTable;
