import React from 'react';
import {IProductBase} from "../../models/Search";
import {CommandBar, DetailsList, IColumn, SelectionMode} from "@fluentui/react";
import {cloneDeep} from "lodash";
import {ButtonColumnGenerator, CheckboxColumnGenerator, TextBoxColumnGenerator} from "../../logic/Columns/ColumnGenerator";

interface IEditorVendorTableProps {
    product: IProductBase;
    onChange: (product: IProductBase) => void;
}

const EditorVendorTable: React.FC<IEditorVendorTableProps> = (props) => {
    const columns: IColumn[] = [
        TextBoxColumnGenerator('Vendor Name', 'vendorName', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].vendorName = v;
            props.onChange(newProduct);
        }),
        TextBoxColumnGenerator('Name', 'name', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].name = v;
            props.onChange(newProduct);
        }),
        TextBoxColumnGenerator('Vendor Part Number', 'vendorPartNumber', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].vendorPartNumber = v;
            props.onChange(newProduct);
        }),
        TextBoxColumnGenerator('Start Quantity', 'startQuantity', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].startQuantity = parseInt(v);
            props.onChange(newProduct);
        }, 'number'),
        TextBoxColumnGenerator('End Quantity', 'endQuantity', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].endQuantity = parseInt(v);
            props.onChange(newProduct);
        }, 'number'),
        TextBoxColumnGenerator('Status', 'status', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].status = v;
            props.onChange(newProduct);
        }),
        TextBoxColumnGenerator('Cost', 'cost', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].cost = parseFloat(v);
            props.onChange(newProduct);
        }, 'number'),
        CheckboxColumnGenerator('In Stock', 'inStock', (v, i) => {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors[i].inStock = v;
            props.onChange(newProduct);
        }),
        ButtonColumnGenerator((item, i) =>  {
            const newProduct = cloneDeep(props.product);
            if(newProduct.vendors) newProduct.vendors.splice(i, 1);
            props.onChange(newProduct);
        }, 'Delete', '', false, 'Delete')
    ]

    const commands = [
        {
            key: 'add',
            text: 'Add',
            iconProps: {iconName: 'Add'},
            onClick: () => {
                const newProduct = cloneDeep(props.product);
                if(newProduct.vendors == null) newProduct.vendors = []
                
                newProduct.vendors.push({
                    vendorName: '',
                    name: '',
                    vendorPartNumber: '',
                    cost: undefined,
                    startQuantity: undefined,
                    endQuantity: undefined,
                    status: undefined,
                    inStock: false,
                });
                props.onChange(newProduct);
            }
        }
    ]
    return <>
        <CommandBar items={commands} />
        <DetailsList items={props.product.vendors ?? []} columns={columns} selectionMode={SelectionMode.none} />
    </>
}

export default EditorVendorTable;
