import React from 'react'
import {TextField} from "@fluentui/react";
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import {IProcurementItem} from "../../models/Order";

interface IQuantityEntryProps {
    item: IProcurementItem;
    onChange?: (qty: number) => void;
    disabled?: boolean;
    label?: string;
}

const ProcurementQuantityEntry: React.FC<IQuantityEntryProps> = (props) => {
    const [tempValue, setTempValue] = React.useState<string | undefined>(props.item?.quantity?.toString() ?? "1");
    const [warn, setWarn] = React.useState<boolean>(false);

    React.useEffect(() => {
        const stock = props.item?.stock;
        if (props.item?.quantity && stock && stock < props.item?.quantity) {
            setWarn(true);
        } else {
            setWarn(false);
        }
    }, [props.item?.quantity, props.item?.source])

    React.useEffect(() => {
        setTempValue(props.item?.quantity?.toString() ?? '1');
    }, [props.item?.quantity]);

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, v: string | undefined) => {
        setTempValue(v);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.currentTarget.value === '' && props.onChange) {
            setTempValue('1');
            props.onChange(1);
            return;
        }
        setTempValue(e.currentTarget.value ?? '1');
        const val = parseFloat(e.currentTarget.value ?? '1');
        try {
            if(!props.onChange) return
            if (!isNaN(val)) {
                props.onChange(val);
            } else {
                props.onChange(1);
            }
        }
        catch(e){
            console.log('Issue with property mapping', e)
        }
    }
    
    return (<>
        <TooltipHost
            content="Quantity exeeds stock"
            hidden={!warn}
        >
            <TextField
                iconProps={warn ? {iconName: 'WarningSolid', style:{color: 'red'}} : undefined}
                value={tempValue}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={props.disabled}
                width={20}
                label={props.label}
            />
            </TooltipHost>
        </>);
}

export default ProcurementQuantityEntry;