import React from 'react';
import {IVendor} from "../../models/Search";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardPreview,
    DocumentCardType,
    IDocumentCardPreviewImage,
    Stack
} from "@fluentui/react";
import CurrencyFormat from 'react-currency-format';

interface IVendorCardsProps {
    vendors: IVendor[];
    refreshing?: boolean;
    product: INormalizedProduct;
}

const VendorCards: React.FC<IVendorCardsProps> = (props) => {
    return (<>
        <Stack tokens={{childrenGap: '1em'}}>
            {props.vendors.map((vendor) => {
                const inStock = vendor.inStock || (vendor.onHand && vendor.onHand > 0);
                const previewProps: IDocumentCardPreviewImage[] = [
                    {
                        previewIconProps: {
                            iconName: inStock ? 'Checkmark' : 'Blocked',
                            styles: {
                                root: {
                                    fontSize: '3em',
                                    color: inStock ? 'white' : '#D0342C',
                                    padding: '0.5em'
                                    // backgroundColor: inStock ? '#789c2f' : 'red'
                                }
                            }
                        }
                    }
                ];
                return (<DocumentCard type={DocumentCardType.compact} key={vendor.vendorName}>
                    <DocumentCardPreview
                        previewImages={previewProps}
                        styles={{previewIcon: {backgroundColor: inStock ? '#789c2f' : 'lightgrey'}}}
                    />
                    <DocumentCardDetails>
                        <div style={{padding: '1em'}}>

                            <h4 style={{margin: 0}}>
                                {vendor.vendorName} - {inStock ? 'In Stock' : 'Out of Stock'} {vendor.onHand && `(${vendor.onHand})`}
                            </h4>
                            <CurrencyFormat
                                value={vendor.cost}
                                displayType="text"
                                thousandSeparator=","
                                prefix="$"
                                fixedDecimalScale={true}
                            />
                            <p style={{padding: 0, margin: 0}}>Part Number: {vendor.vendorPartNumber}</p>
                            {vendor.name && <p style={{padding: 0, margin: 0}}>Name: {vendor.name}</p>}
                        </div>
                    </DocumentCardDetails>
                </DocumentCard>);
            })}
        </Stack>
    </>);
}

    export default VendorCards;