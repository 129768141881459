import {Checkbox, DefaultButton, IColumn, PrimaryButton, Spinner, SpinnerSize, TextField} from "@fluentui/react";
import React, {ReactElement} from "react";
import CAN from "../../permissions/ability"
import CurrencyFormat from "react-currency-format";

export const ColumnGenerator = (friendlyName: string, field: string, minWidth = 100, maxWidth?: number, onRender?: (item:any) => ReactElement, wrap = false, align: 'left'|'center'|'right' = 'left'): IColumn => {
    return {
        key: `generatedColumn+${field}`,
        name: friendlyName,
        minWidth,
        maxWidth,
        fieldName: field,
        onRender: onRender ?? ((item) => <div style={{textAlign: align}}>{item[field]}</div>),
        isMultiline: wrap,
    };
}

export const CurrencyColumnGenerator = (friendlyName: string, field: string, minWidth = 100, maxWidth = 100): IColumn => ({
    key: `generatedColumn+${field}`,
    name: friendlyName,
    minWidth,
    maxWidth,
    fieldName: field,
    onRender: (item) => (
        item[field] != null
            ? <div style={{textAlign: 'right'}}>
                <CurrencyFormat
                    value={item[field]}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    fixedDecimalScale={true}
                    decimalScale={2}
                />
            </div>
            : <></>
    )
})

export const DisableableButtonColumnGenerator = (onClick: (item:any, i: number) => void, columnName = 'Details', buttonText  = 'Details', primary = true, checkEnabled: (value: any) => boolean, icon: string | undefined = undefined, checkLoading?: (value: any) => boolean): IColumn => {
    return {
        key: `Actions_Button_${columnName}`,
        name: columnName,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item, i) => (<>
            {(checkLoading && checkLoading(item)) ? <Spinner size={SpinnerSize.small} label={'Loading'} /> : 
            <DefaultButton
                disabled={!checkEnabled(item)}
                primary={primary}
                text={buttonText}
                onClick={() => onClick(item, i ?? 0)}
                iconProps={icon ? {iconName: icon} : undefined}
            />}</>
        ),
    }
}

export const ButtonColumnGenerator = (onClick: (item:any, i: number) => void, columnName = 'Details', buttonText  = 'Details', primary = true, icon: string | undefined = undefined): IColumn => {
    return {
        key: `Actions_Button_${columnName}`,
        name: columnName,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item, i) => (
            <DefaultButton
                primary={primary}
                text={buttonText}
                onClick={() => onClick(item, i ?? 0)}
                iconProps={icon ? {iconName: icon} : undefined}
            />
        ),
    }
}

export const ConditionalButtonColumnGenerator = (onClick: (item:any) => void, columnName = 'Details', buttonText  = 'Details', requiredClaim = ""): IColumn => {
    const hasRequiredClaim = CAN(requiredClaim);
    return {
        key: `Actions_${columnName}`,
        name: columnName,
        minWidth: 100,
        maxWidth: 100,
        onRender: (item) => (
            hasRequiredClaim ? <PrimaryButton text={buttonText} onClick={() => onClick(item)} /> : <></>
        ),
    }
}

export const TextBoxColumnGenerator = (name: string, field: string, onChange: (value:string, i: number) => void, type = 'text', minWidth = 150, maxWidth = 150): IColumn => ({
    key: name,
    name,
    minWidth,
    maxWidth,
    onRender: (item, i) => (<TextField
        value={item[field]}
        type={type}
        onChange={(e, d) => {
            if (i === undefined) return;
            onChange(d ?? '', i);
        }}
    />),
});

export const CheckboxColumnGenerator = (name: string, field: string, onChange: (value:boolean, i: number) => void, minWidth = 100, maxWidth = 100): IColumn => ({
    key: name,
    name,
    minWidth,
    maxWidth,
    onRender: (item, i) => (<Checkbox
        checked={item[field]}
        onChange={(e, d) => {
            if (i === undefined) return;
            onChange(d ?? false, i);
        }}
        />),
});