import React, {useEffect, useState} from "react";
import {IProcurementItem} from "../../models/Order";
import {
    Checkbox,
    DefaultButton,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    Pivot,
    PivotItem, PrimaryButton,
    Stack,
    Text, TextField
} from "@fluentui/react";
import showdown from "showdown"
import {cloneDeep} from "lodash";
import {useLazyGetCompanyQuery, usePutProcurementProductMutation} from "../../store/Api";
import ProcurementQuantityEntry from "./ProcurementQuantityEntry";
import ProcurementRequirements from "./ProcurementRequirements";
import WorkspaceCompanyDetails from "../Workspace/WorkspaceCompanyDetails";
import {ICompany} from "../../models/Company";

interface IProcurementItemDetailsModalProps {
    product: IProcurementItem;
    open: boolean;
    onClose: () => void;
}

const ProcurementItemDetailsModal: React.FC<IProcurementItemDetailsModalProps> = (props) => {
    const [updateProduct, isUpdating] = usePutProcurementProductMutation();
    const [notes, setNotes] = useState<string>();
    const [requirementNotes, setRequirementNotes] = useState<string>();
    const [updatedProduct, setUpdatedProduct] = useState<IProcurementItem>();
    const [isChanged, setIsChanged] = useState<boolean>();
    const [warn, setWarn] = useState<boolean>();
    const [endUserDetails, setEndUserDetails] = useState<ICompany>();
    const [internalNotes, setInternalNotes] = useState<string>();
    const [getCompany] = useLazyGetCompanyQuery();
    useEffect(() => {
        if(props?.product?.requirementNotes){
            const converter = new showdown.Converter( {tables: true});
            setRequirementNotes(converter.makeHtml(props.product.requirementNotes))
        }
        if(props.product){
            setInternalNotes(props.product.internalNotes)
            setUpdatedProduct(props.product)
            setWarn(warnOnItem(props.product))

            if(!props.product.endUserDetails && props.product.company){
                getCompany(props.product.company).unwrap().then(a => setEndUserDetails(a));
            }
            else {
                const company: ICompany = {
                    companyName: props.product?.endUserDetails?.name ?? "",
                    contact: {
                        firstName: props.product?.endUserDetails?.contact1 ?? "",
                        lastName: props.product?.endUserDetails?.contact2 ?? "",
                        emailAddress: props.product?.endUserDetails?.contactEmail ?? "",
                        phoneNumber: props.product?.endUserDetails?.contactPhone ?? ""
                    },
                    address: {
                        address1: props.product?.endUserDetails?.address1 ?? "",
                        address2: props.product?.endUserDetails?.address2 ?? "",
                        address3: props.product?.endUserDetails?.address3 ?? "",
                        city: props.product?.endUserDetails?.city ?? "",
                        stateTerritory: props.product?.endUserDetails?.stateProvince ?? "",
                        postalCodeZip: props.product?.endUserDetails?.postalCode ?? "",
                        country: props.product?.endUserDetails?.country ?? "",
                        website: props.product?.endUserDetails?.website ?? ""
                    }
                }
                setEndUserDetails(company)
            }
        }
        

    }, [props.product])
    const warnOnItem = (item :IProcurementItem) => {
        const unfinishedQuestions = item.procurementRequirements?.
        filter(a => a.required && (!a.selectedValues || a.selectedValues.length === 0 ))

        return (unfinishedQuestions && unfinishedQuestions.length > 0);
    }
    
    return (
        <div key={`item-procurement-${props.product.id}`}>
            <Panel
                className={"detail-panel"}
                isOpen={props.open}
                onDismiss={() => props.onClose()}
                type={PanelType.large}
                isFooterAtBottom={true}
                styles={{
                    commands: {
                        background: "white"
                    },
                    footer: {
                        background: "white"
                    },
                    navigation: {
                        flex: '1 1 auto',
                    },
                }}
                onRenderHeader={() => {
                    return (
                        <div style={{flexGrow: 1, paddingLeft: '25px', background: "white"}}>
                            <Stack horizontal disableShrink>
                                <Stack.Item align='center' style={{paddingLeft: '1em'}}>
                                    <Text variant={'xLarge'} block>
                                        {props?.product?.name}
                                    </Text>
                                    <Text variant={'small'} block>
                                        Manufacturer Part Number: {props?.product?.manufacturerPartNumber}
                                    </Text>
                                    <Text variant={'small'} block>
                                        {props.product.source} Part Number: {props?.product?.vendorPartNumber}
                                    </Text>
                                </Stack.Item>
                            </Stack>
                        </div>
                    )
                }}
                onRenderFooterContent={() => (
                    <div style={{background: "white"}}>
                        <PrimaryButton
                            text={"Save and Close"}
                            iconProps={{iconName: 'cancel'}}
                            disabled={isUpdating.isLoading || !isChanged}
                            onClick={() => {
                                const id = updatedProduct?.id
                                if(id) {
                                    updateProduct({procurementProductId: id, request: updatedProduct})
                                }
                                props.onClose()
                            }}
                            style={{marginLeft: '1em'}}
                        />
                        <DefaultButton
                            text={"Close"}
                            iconProps={{iconName: 'cancel'}}
                            onClick={() => {
                                console.log('clicked close')
                                props.onClose()
                            }}
                            style={{marginLeft: '1em'}}
                        />
                    </div>
                )}
            >
                {props.product
                    ? (

                        <div>
                            {warn &&
                            <MessageBar messageBarType={MessageBarType.blocked}>Please complete required order information</MessageBar>}
                            <Pivot overflowBehavior='menu' styles={{itemContainer: {marginTop: '1em'}}}>
                                <PivotItem headerText={'Basic Details'}>
                                    {updatedProduct && 
                                    <div>
                                        <div className={'ac-details-field'}>
                                        <Checkbox label={"Dropship"} checked={updatedProduct?.dropship} onChange={ (event, value) =>{
                                        const changingProduct = cloneDeep(updatedProduct)
                                        if(changingProduct) {
                                            changingProduct.dropship = value;
                                            setIsChanged(true)
                                            setUpdatedProduct(changingProduct)
                                        }}
                                        }/>
                                    </div>
                                    <div className={'ac-details-field'}>
                                        <ProcurementQuantityEntry 
                                            item={updatedProduct} 
                                            label={'Order Quantity'} 
                                            onChange={(quanity) => {
                                            const changingProduct = cloneDeep(updatedProduct)
                                            if(changingProduct) {
                                                changingProduct.quantity = quanity;
                                                setIsChanged(true)
                                                setUpdatedProduct(changingProduct)
                                            }}
                                        }/>
                                    </div>
                                </div>
                                }
                                </PivotItem>
                                {requirementNotes && <PivotItem headerText='Requirements Notes'>
                                    <div dangerouslySetInnerHTML={{ __html: requirementNotes}}/>
                                </PivotItem>}
                                <PivotItem headerText='Item Notes'>
                                    <div>
                                        <TextField
                                            label={'Notes'}
                                            multiline={true}
                                            rows={5}
                                            value={notes ?? ""}
                                            onChange={(e,d) => {
                                                const changingProduct = cloneDeep(updatedProduct)
                                                if(changingProduct) {
                                                    changingProduct.notes = d;
                                                    setIsChanged(true)
                                                    setUpdatedProduct(changingProduct)
                                                    setNotes(d);
                                                }

                                            }}
                                        />
                                        <TextField
                                            label={'Internal Notes'}
                                            multiline={true}
                                            rows={5}
                                            value={internalNotes ?? ""}
                                            onChange={(e,d) => {
                                                const changingProduct = cloneDeep(updatedProduct)
                                                if(changingProduct) {
                                                    changingProduct.internalNotes = d;
                                                    setIsChanged(true)
                                                    setUpdatedProduct(changingProduct)
                                                    setInternalNotes(d);
                                                }

                                            }}
                                        />
                                    </div>
                                </PivotItem>
                                {props.product.procurementRequirements && props.product.procurementRequirements.length > 0 && 
                                    <PivotItem headerText='Vendor Specific Item Options' itemIcon={(warn ? 'Warning' : undefined)}>
                                        <ProcurementRequirements 
                                            onChange={(requirements) => {
                                                setIsChanged(true)
                                                const product = cloneDeep(updatedProduct)
                                                if(product) {
                                                    product.procurementRequirements = requirements
                                                    setUpdatedProduct(product)
                                                }
                                            }} 
                                            requirements={props.product.procurementRequirements}
                                            source={props.product.source}
                                            showCategoryToggles={true}
                                        />

                                </PivotItem>}
                                <PivotItem headerText={'Item End-User Details'}>
                                    <WorkspaceCompanyDetails 
                                        currentCompany={endUserDetails}
                                    />
                                </PivotItem>
                            </Pivot>
                        </div>
                    )
                    : null
                }
            </Panel>
        </div>
    )
}
export default ProcurementItemDetailsModal