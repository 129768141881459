import React, {useEffect} from "react";
import {IInstalledExtension} from "../../models/Extension";
import {DefaultButton, IColumn, SelectionMode, ShimmeredDetailsList} from "@fluentui/react";
import Header from "../Common/Header";
import Segment from "../Common/Segment";
import {ButtonColumnGenerator, ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import InstalledExtensionsTableValidationButton from "./InstalledExtensionTableValidationButton";


interface IInstalledExtensionsTableProps {
    onDetailsSelect: (extension: IInstalledExtension) => void;
    onAvailableOpen: () => void;
    onDisableToggle: (extension: IInstalledExtension) => void;
    onDelete: (extension: IInstalledExtension) => void;
    installedExtensions: IInstalledExtension[];
    isLoading: boolean;
}

const InstalledExtensionsTable: React.FC<IInstalledExtensionsTableProps> = (props) => {
    const [installedExtensions, setInstalledExtensions] = React.useState<IInstalledExtension[] | undefined>(props.installedExtensions);
    const [loading, setLoading] = React.useState<boolean>(props.isLoading);
    useEffect(() => {
        if(props.installedExtensions) {
            setInstalledExtensions(props.installedExtensions)
        }
        setLoading(false);
    }, [props.installedExtensions]);
    useEffect(() => {
        setLoading(props.isLoading)
    }, [props.isLoading])
    
    if (installedExtensions?.length === 0 && !loading) {
        return (
            <div className="main-content">
                <Segment>
                    <Header size={"h3"} icon={'Puzzle'} showIconCircle={false}>
                        <Header key={'no-installed-extensions-header'} size={"h1"}>
                            It looks like you don't have any extensions installed.
                        </Header>
                        <DefaultButton primary text='Install' onClick={() => props.onAvailableOpen()} /><br /><br />
                        Click install to browse available extensions.
                    </Header>
                </Segment>
            </div>
        );
    }

    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name', 200, undefined, (ext) => <span>{ext.nickname ?? "No nickname"} - {ext.name}</span>),
        {
            key: 'validate',
            name: '',
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IInstalledExtension) => {
                if (!item.hasValidation) return null;
                if(item.extensionType == 'Folio') return <InstalledExtensionsTableValidationButton folio={item.source} />
                return <InstalledExtensionsTableValidationButton extension={item} />
            }
        },
        ColumnGenerator('', 'enabled', 100, 100, (extension) => {
            if(extension.extensionType == 'Folio') return <></>;
            return(<DefaultButton
                text={extension.enabled ? 'Disable' : 'Enable'}
                primary={!extension.enabled}
                onClick={() => props.onDisableToggle(extension)}
            />)
        }),
        ColumnGenerator('', 'delete', 100, 100, (extension) => {
            if(extension.extensionType == 'Folio') return <></>;
            return(<DefaultButton
                text='Delete'
                className="btn-warning"
                onClick={() => props.onDelete(extension)}
                />)
        }),
        ButtonColumnGenerator(props.onDetailsSelect, '', 'Details'),
    ];

    return <ShimmeredDetailsList items={installedExtensions ?? []} enableShimmer={loading} columns={columns} selectionMode={SelectionMode.none} />
};

export default InstalledExtensionsTable;
