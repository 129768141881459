import React from "react";
import {cloneDeep} from "lodash";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IconButton,
    SelectionMode,
    TextField
} from "@fluentui/react";
import AttributeDivider from "../Common/AttributeDivider";

interface IProductLinesTableProps {
    title?: string;
    productLines: string[];
    editMode?: boolean;
    onUpdate?: (productLines: string[]) => void;
}

export interface IAttributeKV {
    key: string;
    value: string | number | undefined;
}

export const ProductLinesTable: React.FC<IProductLinesTableProps> = (props) => {
    const updateProductLine = (i: number, value: string) => {
        if (!props.onUpdate) return;
        const newProductLines = cloneDeep(props.productLines);
        newProductLines[i] = value;
        props.onUpdate(newProductLines);
    }

    const addProductLine = () => {
        if (!props.onUpdate) return;
        const newProductLine = cloneDeep(props.productLines);
        //Have to push an empty space, otherwise DetailsList will not render TextField control
        newProductLine.push(' ');
        props.onUpdate(newProductLine);
    }

    const deleteProductLine = (i: number | undefined) => {
        if (!props.onUpdate) return;
        if (i === undefined) return;
        const newProductLines = cloneDeep(props.productLines);
        newProductLines.splice(i, 1);
        props.onUpdate(newProductLines);
    }

    if (props.productLines.length === 0 && !props.editMode) return null;

    const columns: IColumn[] = [
        {
            key: 'value',
            minWidth: 100,
            name: 'Value',
            flexGrow: 1,
            onRender: (item: string, i) => {
                console.log(item);
                return props.editMode ?
                    <TextField
                        value={item.trim()}
                        placeholder={"Enter a value for Product Line"}
                        onChange={(e, d) => {
                            if (i !== undefined && d) updateProductLine(i, d);
                        }}
                    />
                    : <>{item}</>
            }
        },
        {
            key: 'actions',
            minWidth: 50,
            maxWidth: 50,
            name: 'Actions',
            onRender: (item: IAttributeKV, i) => props.editMode ? <IconButton
                iconProps={{iconName: 'Delete'}}
                onClick={() => deleteProductLine(i)}
            /> : <></>
        }
    ]

    return <div style={{paddingTop:"30px"}}>
        <div className={"ac-attribute-segment"}>
        {props.title && <AttributeDivider title={props.title}></AttributeDivider>}
            <DetailsList
                className={"ac-attribute-container"}
                selectionMode={SelectionMode.none}
                items={props.productLines}
                columns={columns}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                isHeaderVisible={false}
            />
            <div>
                {props.editMode && <IconButton
                    iconProps={{iconName: 'Add'}}
                    onClick={() => addProductLine()}
                />}
            </div>
        </div>
    </div>
};
