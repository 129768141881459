import { Panel, Stack, TextField } from '@fluentui/react';
import React from 'react';
import ChatMessage from './ChatMessage';
import './AI.css';
import { useAddUserChatMessageMutation, useCreateUserChatMutation } from '../../store/Api';
import { IsMutationSuccess } from '../../logic/MutationTypeChecker';
import ChatSelection from './ChatSelection';
import ChatMessages from './ChatMessages';

interface IAIPanelProps {
    isOpen: boolean;
    onClose: () => void;
}

const AIPanel: React.FC<IAIPanelProps> = (props) => {
    const [message, setMessage] = React.useState<string>('');
    const [startChat] = useCreateUserChatMutation();
    const [addChatMessage] = useAddUserChatMessageMutation();
    const [selectedChat, setSelectedChat] = React.useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = React.useState<boolean>(false);

    const submitMessage = async () => {
        setSubmitting(true);
        if (!selectedChat) {
            const result = await startChat({request: {message: message}});
                if (IsMutationSuccess(result)) {
                    setSelectedChat(result.data?.id ?? '');
                    setMessage('');
                    setSubmitting(false);
                }
        } else {
            const result = await addChatMessage({id: selectedChat, request: {message: message}});
            if (IsMutationSuccess(result)) {
                setMessage('');
                setSubmitting(false);
            }
        }
    }

    return (
        <Panel
            isOpen={props.isOpen}
            headerText='Adaptive Intelligence'
            onDismiss={props.onClose}
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <Stack horizontal tokens={{childrenGap: '1em'}}>
                    <TextField
                        value={message}
                        onChange={(_, newValue) => setMessage(newValue ?? '')}
                        placeholder='Type your message here...'
                        styles={{
                            root: {
                                width: '100%'
                            }
                        }}
                        onKeyDown={(e) => {
                            if(e.key === 'Enter'){
                                submitMessage();
                            }
                        }}
                        disabled={submitting}
                    />
                </Stack>

            )}
            >
                <Stack tokens={{childrenGap: '1em'}} style={{marginTop: '1em'}}>
                    <ChatSelection selected={selectedChat} onSelect={setSelectedChat} />
                    <ChatMessage
                        message='Hello, how can I help you today?'
                        messageSource='SYSTEM'
                    />
                    {selectedChat && <ChatMessages
                        chatId={selectedChat}
                        />}
                </Stack>
        </Panel>
    );
}

export default AIPanel;