import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';
import CompanyManagementDisplay from '../../components/Contacts/CompanyManagementDisplay';
import ContactsManagementDisplay from '../../components/Contacts/ContactsManagementDisplay';

const ContactManagementPage: React.FC = () => {
    return (
        <div>
            <h1>Contact Management</h1>
            <Pivot styles={{itemContainer:{paddingTop: '1em'}}}>
                <PivotItem headerText='Companies'>
                    <CompanyManagementDisplay />
                </PivotItem>
                <PivotItem headerText='Contacts'>
                    <ContactsManagementDisplay />
                </PivotItem>
            </Pivot>
        </div>
    );
}

export default ContactManagementPage;