import {IProductColumnBase} from "./ProductColumns";
import React from "react";
import {IColumn} from "@fluentui/react";
import CurrencyFormat from "react-currency-format";
import {IFreightOption} from "../../models/Extension";

export const FREIGHT_ESTIMATE_COLUMNS_PROPERTIES: {[key: string]: IProductColumnBase } = {
    'carrierIdentifier': {
        basicColumn: {
            key: 'carrierIdentifier',
            name: 'Carrier Identifier',
            fieldName: 'carrierIdentifier',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'carrierName': {
        basicColumn: {
            key: 'carrierName',
            name: 'Carrier Name',
            fieldName: 'carrierName',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'estimatedDays': {
        basicColumn: {
            key: 'estimatedDays',
            name: 'Estimated Days',
            fieldName: 'estimatedDays',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'freightAmount': {
        basicColumn: {
            key: 'freightAmount',
            name: 'Freight Amount',
            fieldName: 'freightAmount',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'shipFromLocation': {
        basicColumn: {
            key: 'shipFromLocation',
            name: 'Ship From Location',
            fieldName: 'shipFromLocation',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },

}
export const FREIGHT_ESTIMATE_COLUMNS: string[] = ["carrierIdentifier", "carrierName", "estimatedDays", "freightAmount", "shipFromLocation"]
export const renderFreightEstimateColumns = (sorter: (column: IColumn) => void) : IColumn[] => {
    const freightColumns: IColumn[] = []
    
    
    for (const field of FREIGHT_ESTIMATE_COLUMNS) {
        const freightTempColumn: IColumn = FREIGHT_ESTIMATE_COLUMNS_PROPERTIES[field].basicColumn;

        if(field === "freightAmount"){
            freightTempColumn.onRender = (freightRecord: IFreightOption) =>
                freightRecord ? <CurrencyFormat
                    value={freightRecord.freightAmount ?? 0}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                /> : <></>
        }

        freightTempColumn.onColumnClick = (ev, column) => { sorter(column)}
        freightColumns.push(freightTempColumn);
    }
    return freightColumns;
}