import {IconButton, IDropdownProps} from "@fluentui/react";
import React from "react";

interface IRequirementDropdownCustomLabel {
    dropdown?: IDropdownProps,
    toggleNa: () => void,
    saveResponse: () => void,
    isNa: boolean,
    isSaveable: boolean
    isRequired?: boolean
    hidden?: boolean
    applyToAll?: () => void
    canApplyToAll?: boolean;
}
const RequirementDropdownCustomLabel: React.FC<IRequirementDropdownCustomLabel> = props => {
    return (
        <div>
        {
            props.dropdown &&
                <div className={'icon-button-label'}>
                    {props.isRequired && <div style={{display: 'inline-flex', color: 'red', paddingRight: '5px' }}> * </div>}
                    <div style={{display: 'inline-flex', textDecoration: props.isNa ? 'line-through' : '' }}>{props.dropdown.label}</div>
                    {!props.hidden && <>
                    <IconButton
                        iconProps={{iconName: props.isNa ? 'Edit' : 'DependencyRemove'}}
                        text={'N/A'}
                        title="Toggle Not Applicable"
                        ariaLabel="Toggle Not Applicable"
                        onClick={() => props.toggleNa()}
                    />
                    <IconButton
                        disabled={!props.isSaveable}
                        iconProps={{iconName: 'SaveTemplate'}}
                        title='Use This Answer For All Procurement Items'
                        ariaLabel="Info"
                        onClick={() => {
                            props.saveResponse()
                        }}
                    />
                    {props.canApplyToAll && props.applyToAll &&
                        <IconButton
                            iconProps={{iconName: 'CheckList'}}
                            title='Apply To All'
                            ariaLabel='Apply To All'
                            onClick={() => {
                                if(props.applyToAll) {
                                    props.applyToAll()
                                }
                            }}
                        />
                    }</>}
                </div>
        }
        </div>
    );
};

export default RequirementDropdownCustomLabel