import React, {useEffect} from 'react';
import {IUnmatchedExtensionProduct} from "../../models/Extension";
import {
    DefaultButton,
    DetailsList,
    IColumn,
    Modal,
    PrimaryButton,
    SelectionMode,
    Separator,
    Stack
} from "@fluentui/react";
import {MODAL_STYLE} from "../../Constants";
import {ButtonColumnGenerator, ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {useGetCatalogsQuery, usePostCreateExtensionProductFromUnmatchedMutation} from "../../store/Api";

interface IUnmatchedProductDetailsProps {
    extensionId: string;
    selectedProduct: IUnmatchedExtensionProduct;
    onClose: () => void;
}

const UnmatchedProductDetails: React.FC<IUnmatchedProductDetailsProps> = (props) => {
    const [creating, setCreating] = React.useState(false);
    const catalogs = useGetCatalogsQuery();
    const [createProduct] = usePostCreateExtensionProductFromUnmatchedMutation();
    const [catalogButton, setCatalogButton] = React.useState<JSX.Element | undefined>(undefined);

    useEffect(() => {
        if (catalogs.data == null) return;
        if (catalogs.data.length === 0) return;
        else if (catalogs.data.length === 1) setCatalogButton(
            <PrimaryButton
                text={props.selectedProduct.active == false ? 'Product Inactive' : 'Create'}
                disabled={props.selectedProduct.active == false || creating}
                onClick={() => {
                    setCreating(true);
                    createProduct({
                        extensionId: props.extensionId,
                        matchId: props.selectedProduct.id,
                        catalog: catalogs.data ? catalogs.data[0].name : '',
                    }).then(props.onClose);
                }}
                />);
        else if (catalogs.data.length > 1) setCatalogButton( // TODO: Add catalog selection
            <PrimaryButton
                text={props.selectedProduct.active == false ? 'Product Inactive' : 'Create'}
                disabled={props.selectedProduct.active == false || creating}
                onClick={() => {
                    setCreating(true);
                    createProduct({
                        extensionId: props.extensionId,
                        matchId: props.selectedProduct.id,
                        catalog: catalogs.data ? catalogs.data[0].name : '',
                    }).then(props.onClose);
                }}
            />);
    }, [catalogs.data])

    const pmColumns: IColumn[] = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('MFP', 'manufacturerPartNumber', 150, 150),
        ColumnGenerator('Manufacturer', 'manufacturer', 200, 200),
        ButtonColumnGenerator((item) => {
            alert(item)
        }, 'Confirm Match', 'Confirm')
    ]

    const detailsColumns: IColumn[] = [
        ColumnGenerator('Field', 'field', 100, 100),
        {
            ...ColumnGenerator('Value', 'value'),
            isMultiline: true
        }
    ]

    const data: { field: string, value?: string }[] = [
        {field: 'ID', value: props.selectedProduct.sourceId},
        {field: 'Name', value: props.selectedProduct.name},
        {field: 'MFP', value: props.selectedProduct.manufacturerPartNumber},
        {field: 'Manufacturer', value: props.selectedProduct.manufacturer},
        {field: 'Active', value: props.selectedProduct.active ? 'Yes' : 'No'},
        {field: 'Status', value: props.selectedProduct.status},
        {field: 'Cost', value: props.selectedProduct.cost?.toString()},
        {field: 'On Hand', value: props.selectedProduct.onHand?.toString()},
        {field: 'In Stock', value: props.selectedProduct.inStock ? 'Yes' : 'No'},
        {field: 'Description', value: props.selectedProduct.description},
        {field: 'Category', value: props.selectedProduct.category},
        {field: 'Sub Category', value: props.selectedProduct.subCategory},
    ];

    return (<Modal
            isOpen={true}
            onDismiss={props.onClose}
            containerClassName={MODAL_STYLE.container}
            styles={{main: {width: '60%', minWidth: '400px'}}}
        >
            <div className={MODAL_STYLE.header}>
                <h2>Matches for {props.selectedProduct.name}</h2>
            </div>
            <div className={MODAL_STYLE.body}>
                <Separator>Source Item</Separator>
                <DetailsList
                    isHeaderVisible={false}
                    compact={true}
                    columns={detailsColumns}
                    selectionMode={SelectionMode.none}
                    items={data}
                />
                {(props.selectedProduct.possibleMatches && props.selectedProduct.possibleMatches.length > 0) && <>
                    <Separator>Possible Matches</Separator>
                    <DetailsList
                        items={props.selectedProduct.possibleMatches ?? []}
                        columns={pmColumns}
                        selectionMode={SelectionMode.none}
                    />
                </>}
            </div>
            <div className={MODAL_STYLE.body}>
                <Stack tokens={{childrenGap: '1em'}} horizontal horizontalAlign='end'>
                    {catalogButton}
                    <DefaultButton text='Cancel' onClick={props.onClose}/>
                </Stack>
            </div>
        </Modal>
    )
}

export default UnmatchedProductDetails;