import React from "react";
import {IAddress} from "../../models/Company";
import {cloneDeep} from "lodash";
import DataField from "../Common/DataField";
interface IWorkspaceCompanyAddressDetailsProps {
    canEdit?: boolean
    currentAddress?: IAddress
    updateCompanyAddressDetails?: (company: IAddress) => void
}

const WorkspaceCompanyAddressDetails: React.FC<IWorkspaceCompanyAddressDetailsProps> = props => {
    const generateAddressFields = () => {
        if(props?.currentAddress){
            return (<>
                <DataField
                    hideIfEmpty={!props.canEdit}
                    readOnly={!props.canEdit}
                    placeholder={'Address 1'}
                    value={props?.currentAddress.address1 ?? ""}
                    onChange={(e, value) => {
                        if (value != undefined) updateCompanyAddress("address1", value)
                    }}
                />
                <DataField
                    hideIfEmpty={!props.canEdit}
                    readOnly={!props.canEdit}
                    placeholder={'Address 2'}
                    value={props?.currentAddress.address2 ?? ""}
                    onChange={(e, value) => {
                        if (value != undefined) updateCompanyAddress("address2", value)
                    }}
                />
                <DataField
                    hideIfEmpty={!props.canEdit}
                    readOnly={!props.canEdit}
                    placeholder={'Address 3'}
                    value={props?.currentAddress.address3 ?? ""}
                    onChange={(e, value) => {
                        if (value != undefined) updateCompanyAddress("address3", value)
                    }}
                />
                <DataField
                    hideIfEmpty={!props.canEdit}
                    readOnly={!props.canEdit}
                    placeholder={'City'}
                    value={props?.currentAddress.city ?? ""}
                    onChange={(e, value) => {
                        if (value != undefined) updateCompanyAddress("city", value)
                    }}
                />
                <DataField
                    hideIfEmpty={!props.canEdit}
                    readOnly={!props.canEdit}
                    placeholder={'State'}
                    value={props?.currentAddress.stateTerritory ?? ""}
                    onChange={(e, value) => {
                        if (value != undefined) updateCompanyAddress("stateTerritory", value)
                    }}
                />
                <DataField
                    hideIfEmpty={!props.canEdit}
                    readOnly={!props.canEdit}
                    placeholder={'Zip Code'}
                    value={props?.currentAddress.postalCodeZip ?? ""}
                    onChange={(e, value) => {
                        if (value != undefined) updateCompanyAddress("postalCodeZip", value)
                    }}
                />
                <DataField
                    hideIfEmpty={!props.canEdit}
                    readOnly={!props.canEdit}
                    placeholder={'Country'}
                    value={props?.currentAddress.country ?? ""}
                    onChange={(e, value) => {
                        if (value != undefined) updateCompanyAddress("country", value)
                    }}
                />
            </>)
        }
    }
    const updateCompanyAddress = (field: string, value: string) => {
        const address = cloneDeep(props.currentAddress);
        if(props.updateCompanyAddressDetails && address) {
            switch (field) {
                case "address1": {
                    address.address1 = value
                    break;
                }
                case "address2": {
                    address.address2 = value
                    break;

                }
                case "address3": {
                    address.address3 = value
                    break;

                }
                case "city": {
                    address.city = value
                    break;

                }
                case "stateTerritory": {
                    address.stateTerritory = value
                    break;

                }
                case "postalCodeZip": {
                    address.postalCodeZip = value
                    break;
                }
                case "country": {
                    address.country = value
                    break;
                }
            }
            props.updateCompanyAddressDetails(address)
        }
    }
    
    return(<>{generateAddressFields()}</>)
}
export default WorkspaceCompanyAddressDetails;