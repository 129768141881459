import React, {useEffect} from 'react';
import {ISubOrganization} from "../../models/Settings";
import {Label, Panel, PrimaryButton, TextField} from "@fluentui/react";
import {useAddSubOrganizationMutation, useUpdateSubOrganizationMutation} from "../../store/Api";
import CountrySelection from "../CountrySelection/CountrySelection";
import EditableImage from "../DetailsModal/EditableImage";

interface ISubOrganizationModal {
    isOpen: boolean;
    onClose: () => void;
    subOrganization?: ISubOrganization;
}

const SubOrganizationModal: React.FC<ISubOrganizationModal> = (props) => {
    const [name, setName] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [logo, setLogo] = React.useState('');
    const [addSubOrganization] = useAddSubOrganizationMutation();
    const [updateSubOrganization] = useUpdateSubOrganizationMutation();

    useEffect(() => {
        setName(props.subOrganization?.name ?? '');
        setCountry(props.subOrganization?.country ?? '');
        setLogo(props.subOrganization?.logo ?? '');
    }, [props.subOrganization]);

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.onClose}
            headerText={props.subOrganization ? 'Edit Sub Organization' : 'Add Sub Organization'}
            isFooterAtBottom={true}
            onRenderFooter={() => <>
                <PrimaryButton
                    onClick={() => {
                        if (props.subOrganization) {
                            updateSubOrganization({
                                id: props.subOrganization.id,
                                body: {
                                    name,
                                    country,
                                    logo,
                                }
                            }).then(() => {props.onClose()});
                        } else {
                            addSubOrganization({
                                name,
                                country,
                                logo,
                            }).then(() => { props.onClose() });
                        }
                    }}
                    text="Save"
                    />
            </>}
            >
            <TextField
                label="Name"
                value={name}
                onChange={(e, v) => {setName(v ?? '');}}
            />
            <CountrySelection
                value={country}
                onChange={(v) => {setCountry(v);}}
                />
            <div>
                <Label>Logo</Label>
                <EditableImage
                    image={{
                        url: logo ?? '',
                        type: 'Custom',
                        name: name,
                        id: 'logo',
                        isCustom: true,
                    }}
                    editMode={true}
                    onChange={(id, url) => setLogo(url)}
                />
            </div>
        </Panel>
    );
}

export default SubOrganizationModal;