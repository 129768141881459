import {
    getTheme,
    IColumn, 
    mergeStyleSets,
    Panel,
    PanelType,
    PrimaryButton,
    SearchBox,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import React, {useCallback, useState} from "react";
import {IWorkspace} from "../../models/Workspace";
import {
    useGetSavedSearchesQuery,
} from "../../store/Api";

import {ISavedSearch} from "../../models/Search";
import {debounce} from "lodash";
import SaveSearchPanel from "./SaveSearchPanel";
import {updateSelectedSaveSearchId} from "../../store/searchSlice";
import {useDispatch, useSelector} from "react-redux";
import SavedSearchDeleteModal from "./DeleteSavedSearchModal";
import {RootState} from "../../store/rootReducer";

interface ISavedSearchSelectorPanel {
    displayModal: boolean;
    onSelect: (selectedSearchId: string) => void;
    closer: () => void;
    onClear: () => void;
}
const SavedSearchSelectorPanel: React.FC<ISavedSearchSelectorPanel> = props => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState<string>("")
    const [momentarySearchText, setMomentarySearchText] = useState<string>("")
    const [deleteSavedSearchId, setDeleteSavedSearchId] = useState<string>()
    const [deleteSavedSearchModalOpen, setDeleteSavedSearchModalOpen] = React.useState(false);
    const [savedSearchDetailsModalOpen, setSavedSearchDetailsModalOpen] = useState(false);
    const [selectedSavedSearch, setSelectedSavedSearch] = useState<ISavedSearch | undefined>();
    const globalSelectedSavedSearchId = useSelector((state: RootState) => state.search.selectedSaveSearchId);
    const savedSearches = useGetSavedSearchesQuery({searchTerm: searchText})
    
    const theme = getTheme();
    const initialColumns: IColumn[] = [
        {
            key: 'SelectButton',
            name: '',
            fieldName: 'selectSavedSearch',
            onRender: (key: ISavedSearch) => <PrimaryButton
                onClick={() => {
                    props.onSelect(key.id ?? "")
                    props.closer()
                }
                }>Apply</PrimaryButton>,
            minWidth: 80,
            maxWidth: 80
        },
        {
            key: 'EditButton',
            name: '',
            fieldName: 'editSavedSearch',
            onRender: (key: ISavedSearch) => <PrimaryButton
                onClick={() => {
                    setSavedSearchDetailsModalOpen(true)
                    setSelectedSavedSearch({...key})
                }
                }>Edit</PrimaryButton>,
            minWidth: 80,
            maxWidth: 80
        },
        {
            key: 'Name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 300,
            isRowHeader: true,
        },
        {
            key: 'Shared',
            name: 'Is Shared',
            fieldName: 'shared',
            onRender: (key: ISavedSearch) => <div style={{textAlign: "center"}}>{key.shared ? "✓" : "" }</div>,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'Delete',
            name: '',
            fieldName: 'delete',
            minWidth: 80,
            maxWidth: 80,
            ariaLabel: 'Delete Saved Search',
            onRender: (a: IWorkspace) =>
                <PrimaryButton styles={{root: { backgroundColor: theme.palette.red, color: theme.palette.white }, rootFocused: { backgroundColor: theme.palette.redDark, color: theme.palette.white }, rootHovered: { backgroundColor: theme.palette.redDark, color: theme.palette.white },}}
                               iconProps={{iconName: 'Delete'}}
                               onClick={() => {{
                                   setDeleteSavedSearchId(a.id);
                                   setDeleteSavedSearchModalOpen(true)
                               }
                               }}>
                </PrimaryButton>,

        },
    ]
    const [columns] = useState<IColumn[]>(initialColumns)


    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        navigation: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        footerInner: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        content: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        commands: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
    });
    
    
    const handleSearchText = (searchText: string) => {
        setMomentarySearchText(searchText)
        debouncedSetSearchedKeywords(searchText)
    }

    const debouncedSetSearchedKeywords = useCallback(
        debounce(async (val:string | undefined) => {
            if(val &&  val.length > 0) {
                setSearchText(val)
            }
        }, 500, {leading: false}),
        []
    );
    return (
        <div>
            <Panel
                onOuterClick={() => {console.log("swallowing modal close event")}}
                isOpen={props.displayModal}
                onDismiss={props.closer}
                className={"saved-search-selector-panel"}
                type={PanelType.custom}
                customWidth={"auto"}
                isFooterAtBottom={true}
                title={"Search Saved Searches"}
                styles={{
                    root: {
                        zIndex: 10,
                        marginTop: '48px',
                    },
                    content:contentStyles.content,
                    commands:contentStyles.commands,
                    scrollableContent: contentStyles.scrollableContent,
                    navigation: contentStyles.navigation,
                    footerInner: contentStyles.footerInner,
                }}
            >   {savedSearchDetailsModalOpen && 
                <SaveSearchPanel
                    closer={() => {
                        setSavedSearchDetailsModalOpen(false)}
                    }
                    onUpdate={(id) => {
                        dispatch(updateSelectedSaveSearchId(id))}
                    }
                    criteria={selectedSavedSearch?.criteria}
                    savedSearch={selectedSavedSearch}
                />
                }
                <h1>Find Saved Search</h1>
                <br/>
                <SearchBox
                    placeholder={"Saved Search Name"}
                    value={momentarySearchText}
                    onChange={(e, d) => {
                        handleSearchText(d ?? '');
                    }}
                />
                <br/>
                <SavedSearchDeleteModal
                    displayModal={deleteSavedSearchModalOpen}
                    closer={() => {
                        setDeleteSavedSearchModalOpen(false)
                    }}
                    selectedSavedSearch={deleteSavedSearchId}
                    onDeleteSavedSearch={() => {
                        if(deleteSavedSearchId && globalSelectedSavedSearchId && globalSelectedSavedSearchId === deleteSavedSearchId) {
                            props.onClear()
                        }
                    }}
                />
                <ShimmeredDetailsList
                    items={savedSearches.data?? []}
                    enableShimmer={savedSearches.isFetching}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                />

            </Panel>
        </div>

    );
}
export default SavedSearchSelectorPanel;