import React from 'react';

interface ICardProps {
    title: string;
    loading?: boolean;
    width?: number;
    height?: number;
}

const Card: React.FC<ICardProps> = (props) => {
    const appliedWidth = props.width ? (props.width + 'px') : 'auto';
    const appliedHeight = props.height ? (props.height + 'px') : '25em'
    return (
        <div className='cardRoot' style={{width: appliedWidth, minWidth:'300px'}}>
            <div className='cardBase'>
                <div className='cardTitle' style={{textOverflow: 'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>
                    {props.title}
                </div>
                <div className='cardContent' style={{height:appliedHeight}}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Card;