import {
    DefaultButton,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
} from "@fluentui/react";
import React, {useEffect, useState} from "react";
import {
    useLazyGetWorkspaceQuery, 
    useProcureWorkspaceItemsMutation,
} from "../../store/Api";
import {cloneDeep} from "lodash";
import {IWorkspaceItem} from "../../models/Workspace";
import WorkspaceProcurementTable from "./WorkspaceProcurementTable";
import {IProcurementItem} from "../../models/Order";
import {IVendor} from "../../models/Search";
import OrderModal from "../Procurement/OrderModal";

interface IWorkspaceProcurementModalProps {
    selectedWorkspace: string
    selectedItems?: IWorkspaceItem[];
    displayModal: boolean;
    closer: () => void;
}
const WorkspaceProcurementModal: React.FC<IWorkspaceProcurementModalProps> = props => {
    const [procure] = useProcureWorkspaceItemsMutation();
    const [selectedCompany, setSelectedCompany] = useState<string | undefined>()
    const [getWorkspace] = useLazyGetWorkspaceQuery();
    const [activeItems, setActiveItems] = useState<IWorkspaceItem[]>([]);
    const [procurementItems, setProcurementItems] = useState<IProcurementItem[]>()
    const [validationMessage, setValidationMessage] = useState<string>()
    const [isValid, setIsValid] = useState<boolean>();
    const [canQuickOrder, setCanQuickOrder] = useState<boolean>()
    const [quickOrderVendor, setQuickOrderVendor] = useState<string>()
    const [displayOrderModal, setDisplayOrderModal] = useState<boolean>()
    
    useEffect(() => {
        getWorkspace(props.selectedWorkspace).then((w) => {
            if(w.data?.companyId) {
                setSelectedCompany(w.data.companyId);
            }
        })
    }, [props.selectedWorkspace])
    useEffect(() => {
        if(props.selectedItems){
            const items = props.selectedItems.filter(a => !a.adhoc)
            for(let i = 0; i< items.length; i++) {
                const updatingItem = cloneDeep(items[i])
                updatingItem.details.source = getUnsetItemSource(updatingItem)
                items[i] = updatingItem
            }
            setActiveItems(items)
        }
        else {
            setActiveItems([])
        }
    }, [props.selectedItems]);
    
    const getUnsetItemSource = (item: IWorkspaceItem): string | undefined => {
        const source = item.details.source
        if(item.normalized?.vendors == null) return source;
        if ((source === 'LowestInStock' || source === undefined) && item.normalized?.vendors) {
            let lowestCostVendor: IVendor | undefined = undefined;
            for (const vendor of item.normalized.vendors) {
                if ((vendor.inStock || (vendor.onHand != null && vendor.onHand > 0))
                    && (lowestCostVendor?.cost === undefined || lowestCostVendor?.cost === null || vendor.cost != null &&
                        (lowestCostVendor?.cost === 0 || vendor.cost < lowestCostVendor?.cost))
                ) {
                    lowestCostVendor = vendor;
                }
            }
            return lowestCostVendor?.vendorName ?? source
        }
        else if(source === 'LowestPrice'){
            const lowestCostVendor = item.normalized?.vendors.reduce((prev, current) => {
                return (prev.cost == null || (current.cost != null && prev.cost < current.cost)) ? prev : current;
            });

            return lowestCostVendor?.vendorName ?? source
        }
        return source;
    }
    
    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        navigation: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        footerInner: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        content: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        commands: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
    });

    const close = () => {
        props.closer()
        setValidationMessage('')
        setIsValid(undefined)
        setProcurementItems(undefined)
    }
    
    const createProcurementItems = (items: IWorkspaceItem[], ) =>{
        const transformedItems: IProcurementItem[] = items.map(a =>{ 
            const vendor = a.normalized?.vendors?.filter(v => v.vendorName === a.details.source)?.[0]
            return (
            {
                id: undefined,
                username: a.username, 
                productId: a.productId, 
                workspace: props.selectedWorkspace ?? "",
                workspaceName: "", //We'll fill this in on the backend
                quantity: a.details.quantity ?? 1,
                stock: vendor?.onHand,
                name: a.normalized?.name ?? "", 
                cost: vendor?.cost ?? 0, 
                manufacturerPartNumber: a.normalized?.manufacturerPartNumber ?? "", 
                manufacturer: a.normalized?.manufacturer ?? "", 
                vendorPartNumber: vendor?.vendorPartNumber ?? "",
                source: a.details?.source ?? "",
                company: selectedCompany ?? "",
                dropship: a.details?.dropship
            })})
        setProcurementItems(transformedItems);
       
        const listOfVendors: string[] = []
        
        for (const item of transformedItems){
            if(!listOfVendors.includes(item.source)){
                listOfVendors.push(item.source)
            }
        }
        
        if(listOfVendors.length === 1){
            setCanQuickOrder(true)
            setQuickOrderVendor(listOfVendors[0])
        }
    }
    
    return (
        <div>
            <Panel
                onOuterClick={() => {console.log("swallowing modal close event")}}
                isOpen={props.displayModal}
                onDismiss={close}
                className={"workspace-procurement-panel"}
                isFooterAtBottom={true}
                type={PanelType.large}
                title={"Procure"}
                styles={{
                    root: {
                        zIndex: 10,
                        marginTop: '48px'
                    },
                    content:contentStyles.content,
                    commands:contentStyles.commands,
                    scrollableContent: contentStyles.scrollableContent,
                    navigation: contentStyles.navigation,
                    footerInner: contentStyles.footerInner,
                }}
                onRenderFooter={() => (
                    <div>
                        <DefaultButton 
                            disabled={!isValid} 
                            primary
                            style={{width: '50%', marginLeft: '1em'}} onClick={() => {
                                procure({workspaceId: props.selectedWorkspace, procurementItems: procurementItems ?? []})
                            close()
                            }} 
                            text={"Procure Items"}
                        />
                        <DefaultButton
                            disabled={!canQuickOrder} 
                            style={{width: '40%', marginLeft: '2em'}} 
                            onClick={() => {
                                setDisplayOrderModal(true)}} 
                            text={"Quick Order"}
                        />
                    </div>
                )}
            >
                <h1>Procure Items</h1>
                {validationMessage && <MessageBar messageBarType={MessageBarType.blocked}>{validationMessage}</MessageBar>}
                <WorkspaceProcurementTable
                    selectedItems={activeItems} 
                    generateProcurementProducts={createProcurementItems} 
                    onChange={setActiveItems}
                    onValidate={(isValid, message) => {
                        setIsValid(isValid);
                        setValidationMessage(message)
                    }}
                />
                <OrderModal
                    selectedCompany={selectedCompany}
                    displayModal={displayOrderModal == true} 
                    closer={() => {
                    setDisplayOrderModal(false)
                    close();}} 
                    onChange={() => console.log('quick order placed')} 
                    vendor={quickOrderVendor ?? ""} 
                    selectedItems={procurementItems}/>
            </Panel>

        </div>
    );
}
export default WorkspaceProcurementModal;