import React, {useEffect, useState} from "react";
import {
    useAddUpdateTaxCodeMutation, 
    useGetTaxCodesQuery
} from "../../store/Api";
import {
    IsFetchBaseQueryError,
    IsMutationError,
    IsMutationSuccess,
    IsSerializedError
} from "../../logic/MutationTypeChecker";
import {
    Checkbox,
    DefaultButton,
    FontWeights,
    getTheme,
    IButtonStyles,
    IconButton,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import {ITaxCode} from "../../models/TaxCode";

interface IEditTaxCodeModalProps {
    taxCode?: ITaxCode;
    isOpen: boolean;
    onClose: () => void;
}

const EditTaxCodeModal: React.FC<IEditTaxCodeModalProps> = (props) => {
    const getTaxCodes = useGetTaxCodesQuery();
    const [saveTaxCode] = useAddUpdateTaxCodeMutation();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [source, setSource] = useState('');
    const [sourceId, setSourceId] = useState('');
    const [taxCodeId, setTaxCodeId] = useState('');
    const [active, setActive] = useState(true);
    const [taxRate, setTaxRate] = useState(0);
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState<string | undefined>()
    const [savable, setSavable] = useState(true);
    const [duplicateName, setDuplicateName] = useState(false);

    useEffect(() => {
        setName(props.taxCode?.name ?? '');
        setDescription(props.taxCode?.description ?? '');
        setActive(props.taxCode?.active ?? true);
        setTaxRate(props.taxCode?.rate ?? 0.00);
        setTaxCodeId(props.taxCode?.id ?? '');
        setSourceId(props.taxCode?.sourceId ?? '');
        setSource(props.taxCode?.source ?? '');
    }, [props.taxCode]);
    
    const clearValues = () => {
        setName('');
        setDescription('');
        setActive(true);
        setTaxRate(0.00);
        setTaxCodeId('');
        setSourceId('');
        setSource('');
    }

    useEffect(() => {
        if (getTaxCodes.data) {
            if(getTaxCodes.data.length == 0) {
                setSavable(true)
                return
            }
            if (getTaxCodes.data.findIndex(i => i.name.toLowerCase() === name.toLowerCase()) !== -1 && props.taxCode == undefined) {
                setSavable(false);
                setDuplicateName(true);
            } else {
                setSavable(true);
                setDuplicateName(false);
            }
        } else {
            setSavable(false);
        }
    }, [getTaxCodes.data, name, props.taxCode]);

    const save = () => {
        setSaving(true);
        saveTaxCode({name: name, description: description, rate: taxRate, active: active, sourceId: sourceId, id: taxCodeId, source: source})
            .then((result) => {
                if (IsMutationSuccess(result)) {
                    if (!result.data.success) setSaveError(result.data.message ?? 'Error saving Tax Code');
                    setSaving(false);
                    clearValues();
                    props.onClose();
                } else if (IsMutationError(result)) {
                    if (IsFetchBaseQueryError(result)) {
                        setSaveError(`Network error while communicating with API: ${result.error}`)
                    } else if (IsSerializedError(result)) {
                        setSaveError(`Response error while communicating with API: ${result.error}`)
                    }
                }
                setSaving(false);
            });
    }

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: '4px solid #789c2f',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
    });
    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };
    
    const parseRate = (rateString: string | undefined) => {
        if(!rateString) return 0;
        
        if(!isNaN(parseFloat(rateString))){
            return parseFloat(rateString)
        }
        return 0;
    }

    return (
        <Modal
            isOpen={props.isOpen}
            onDismiss={props.onClose}
        >
            <div className={contentStyles.header}>
                <span>Add New Tax Code</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{iconName: 'Cancel'}}
                    ariaLabel="Close popup modal"
                    onClick={() => props.onClose()}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack tokens={{childrenGap: '1em'}}>
                    {saveError && <MessageBar messageBarType={MessageBarType.error}>{saveError}</MessageBar> }
                    <TextField
                        label='Name'
                        value={name}
                        onChange={(e, d) => setName(d ?? '')}
                        disabled={props.taxCode != undefined}
                        errorMessage={duplicateName ? 'Tax Code name must be unique' : undefined}
                    />
                    <TextField
                        label='Description'
                        value={description}
                        onChange={(e, d) => setDescription(d ?? '')}
                    />
                    <TextField
                        label='Rate'
                        defaultValue={taxRate.toPrecision(3)}
                        onChange={(e, d) => setTaxRate(parseRate(d))}
                        suffix={'%'}
                    />
                    <Checkbox
                        label='Active'
                        checked={active}
                        onChange={(e, d) => setActive(d ?? false)}
                    />
                </Stack>
            </div>
            <div className={contentStyles.body}>
                <Stack horizontal tokens={{childrenGap: '1em'}} horizontalAlign='end'>
                    <PrimaryButton
                        text='Save'
                        disabled={saving || !savable}
                        onClick={() => save()}
                    />
                    <DefaultButton
                        onClick={() => props.onClose()}
                        text='Close'
                    />
                </Stack>
            </div>
        </Modal>
    );
}

export default EditTaxCodeModal;
