import React from 'react';
import {
    IColumn,
    Checkbox, PrimaryButton, IconButton, Icon, IDropdownOption, DropdownMenuItemType,
} from "@fluentui/react";
import {IWorkspaceItem, IWorkspaceItemDetails} from "../../models/Workspace";
import {
    ORDERITEMCOLUMNS,
    PREPAREORDERITEMCOLUMNS,
    DEFAULTPROCUREMENTCOLUMNS,
    EXPORTABLEPROCUREMENTCOLUMNS
} from '../../Constants';
import QuantityEntry from "../../components/ProductTable/QuantityEntry";
import ProcurementVendorSourceSelection from "../../components/Workspace/ProcurementVendorSourceSelection";
import {IProcurementItem} from "../../models/Order";
import ProcurementQuantityEntry from "../../components/Procurement/ProcurementQuantityEntry";
import CurrencyFormat from "react-currency-format";
import {cloneDeep} from "lodash";
import {IProductColumnBase} from "./ProductColumns";


export interface IProcurementProductColumn extends IProductColumnBase{
    refreshingVendors?: boolean;
    onPrepareOrderHeaderRender?: () => React.ReactElement;
    onPrepareOrderRender?: (product: IProcurementItem) => React.ReactElement;
}

export const PROCUREMENT_ITEM_COLUMN_PROPERTIES: {[key: string]: IProcurementProductColumn } = {
    //WS & Search
    'VENDOR_COLUMN': {
        basicColumn: {
            key: 'vendor',
            name: 'Vendor',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            isPadded: true,
            isMultiline: true
        },
    },
    'name': {
        basicColumn: {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'image': {
        basicColumn: {
            key: 'image',
            name: '',
            fieldName: 'imageDisplay',
            minWidth: 50,
            maxWidth: 50,
        },
    },
    'availability': {
        basicColumn: {
            key: 'availability',
            name: 'Availability',
            fieldName: 'stockLabel',
            minWidth: 200,
            maxWidth: 150,
            isRowHeader: true,
        }
    },
    'cost': {
        basicColumn: {
            key: 'cost',
            name: 'Cost',
            fieldName: 'cost',
            minWidth: 150,
            isMultiline: true,
        },
    },
    'internalPartNumber': {
        basicColumn: {
            key: 'internalPartNumber',
            name: 'Internal PN',
            fieldName: 'internalPartNumber',
            minWidth: 100,
            maxWidth: 150,
        }
    },
    'manufacturer': {
        basicColumn: {
            key: 'manufacturer',
            name: 'Manufacturer',
            fieldName: 'manufacturer',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    },
    'mfp': {
        basicColumn: {
            key: 'mfp',
            name: 'MFP',
            fieldName: 'manufacturerPartNumber',
            minWidth: 150,
            isRowHeader: true,
            isResizable: true,
        },
    },
    'manufacturerPartNumber': {
        basicColumn: {
            key: 'manufacturerPartNumber',
            name: 'Manufacturer Part Number',
            fieldName: 'manufacturerPartNumber',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
        },
    },
    'vpn': {
        basicColumn: {
            key: 'vpn',
            name: 'Vendor Part Number',
            fieldName: 'vendorPartNumber',
            minWidth: 300,
            isRowHeader: true,
        }
    },
    'vendorPartNumber': {
        basicColumn: {
            key: 'vendorPartNumber',
            name: 'Vendor Part Number',
            fieldName: 'vendorPartNumber',
            minWidth: 300,
            isRowHeader: true,
        }
    },
    'details': {
        basicColumn: {
            key: 'details',
            name: '',
            fieldName: 'details',
            minWidth: 125,
            maxWidth: 150,
        },
    },
    'quantity': {
        basicColumn: {
            key: 'quantity',
            name: 'Quantity',
            fieldName: 'quantity',
            minWidth: 100,
            maxWidth: 100,
        },
    },
    'prepareQuantity': {
        basicColumn: {
            key: 'quantity',
            name: 'Quantity',
            fieldName: 'quantity',
            minWidth: 100,
            maxWidth: 100,
        },
    },
    'actions': {
        basicColumn: {
            key: 'actions',
            name: '',
            fieldName: 'actions',
            minWidth: 200,
        },
    },
    'price': {
        basicColumn: {
            key: 'price',
            name: 'Price',
            fieldName: 'price',
            minWidth: 125
        },
    },
    'source': {
        basicColumn: {
            key: 'source',
            name: 'Source',
            fieldName: 'source',
            minWidth: 200
        },
    },
    'userName': {
        basicColumn: {
            key: 'userName',
            name: 'User Name',
            fieldName: 'userName',
            minWidth: 200
        },
    },
    'workspace': {
        basicColumn: {
            key: 'workspace',
            name: 'Workspace',
            fieldName: 'workspace',
            minWidth: 200
        },
    },
    'workspaceName': {
        basicColumn: {
            key: 'workspaceName',
            name: 'Workspace Name',
            fieldName: 'workspaceName',
            minWidth: 200
        },
    },
    'stock': {
        basicColumn: {
            key: 'stock',
            name: 'On Hand',
            fieldName: 'stock',
            minWidth: 150,
            isMultiline: true
        },
    },
    'requirementNotes': {
        basicColumn: {
            key: 'requirementNotes',
            name: 'Requirement Notes',
            fieldName: 'requirementNotes',
            minWidth: 300,
            isMultiline: true
        },
    },
    'internalNotes': {
        basicColumn: {
            key: 'internalNotes',
            name: 'Internal Notes',
            fieldName: 'internalNotes',
            minWidth: 300,
            isMultiline: true
        },
    },
    'notes': {
        basicColumn: {
            key: 'notes',
            name: 'Notes',
            fieldName: 'notes',
            minWidth: 300,
            isMultiline: true
        },
    },
    'externalItemId': {
        basicColumn: {
            key: 'externalItemId',
            name: 'External Item Id',
            fieldName: 'externalItemId',
            minWidth: 200
        },
    },
    'requestedCarrier': {
        basicColumn: {
            key: 'requestedCarrier',
            name: 'Requested Carrier',
            fieldName: 'requestedCarrier',
            minWidth: 200
        },
    },
    'orderedQuantity': {
        basicColumn: {
            key: 'orderedQuantity',
            name: 'Ordered Quantity',
            fieldName: 'orderedQuantity',
            minWidth: 200
        },
    },
    'backOrderedQuantity': {
        basicColumn: {
            key: 'backOrderedQuantity',
            name: 'Back Ordered Quantity',
            fieldName: 'backOrderedQuantity',
            minWidth: 200
        },
    },
    'confirmedQuantity': {
        basicColumn: {
            key: 'confirmedQuantity',
            name: 'Confirmed Quantity',
            fieldName: 'confirmedQuantity',
            minWidth: 200
        },
    },
    'confirmedCost': {
        basicColumn: {
            key: 'confirmedCost',
            name: 'Confirmed Cost',
            fieldName: 'confirmedCost',
            minWidth: 200,
        },
    },
    'orderLineNumber': {
        basicColumn: {
            key: 'orderLineNumber',
            name: 'Order Line Number',
            fieldName: 'orderLineNumber',
            minWidth: 200
        },
    },
    'id': {
        basicColumn: {
            key: 'id',
            name: 'ID',
            fieldName: 'id',
            minWidth: 200
        },
    },
    'productId': {
        basicColumn: {
            key: 'productId',
            name: 'Product ID (Catalog)',
            fieldName: 'productId',
            minWidth: 200
        },
    },
    'company': {
        basicColumn: {
            key: 'company',
            name: 'Company ID',
            fieldName: 'company',
            minWidth: 200
        },
    },
    'order': {
        basicColumn: {
            key: 'order',
            name: 'Order ID',
            fieldName: 'order',
            minWidth: 200
        },
    },
    
}

export const DEMO_PROCUREMENT_ITEMS: IProcurementItem[] = [
    {
        id: "id1",
        productId: "productId1",
        name: "Demo Product 1",
        workspace: "workspaceId1",
        workspaceName: "Workspace1",
        quantity: 1,
        cost: 15.99,
        manufacturer: 'manufacturer1',
        manufacturerPartNumber: 'mfp1',
        vendorPartNumber: 'vpn1',
        source: 'vendor',
        company: 'company1'
    },
    {
        id: "id2",
        productId: "productId2",
        name: "Demo Product 2",
        workspace: "workspaceId2",
        workspaceName: "Workspace2",
        quantity: 5,
        cost: 173.22,
        manufacturer: 'manufacturer2',
        manufacturerPartNumber: 'mfp2',
        vendorPartNumber: 'vpn2',
        source: 'vendor',
        company: 'company1'
    },]


export const getProcurementItemColumnOptions = () : IDropdownOption[] => {
    const options: IDropdownOption[] = [
        {key: 'Standard Fields', text: 'Standard Fields', itemType: DropdownMenuItemType.Header},
    ];
    
    options.push(...EXPORTABLEPROCUREMENTCOLUMNS.map(a => {
        return ({
        key: PROCUREMENT_ITEM_COLUMN_PROPERTIES[a]?.basicColumn?.key ?? "", 
        text: PROCUREMENT_ITEM_COLUMN_PROPERTIES[a]?.basicColumn?.name ?? ""}
        )
    }).filter(a => a.text != '')
    )
    
    return options;
}


const warnOnItem = (item :IProcurementItem) => {
    const unfinishedQuestions = item.procurementRequirements?.
    filter(a => a.required && (!a.selectedValues || a.selectedValues.length === 0 ))
    
    return (unfinishedQuestions && unfinishedQuestions.length > 0);
}

export const renderProcurementSelectorColumns = (onDetailsChange: (product: IWorkspaceItem, details: IWorkspaceItemDetails) => void, sorter?: (column: IColumn) => void): IColumn[] => {
    const columns: IColumn[] = [];
    for (const field of DEFAULTPROCUREMENTCOLUMNS) {
        const tempCol: IColumn = PROCUREMENT_ITEM_COLUMN_PROPERTIES[field].basicColumn;
        tempCol.isResizable = false;
        tempCol.onColumnClick = (ev, column) => {
            if(sorter){
                sorter(column)
            }}
        if(field === "image"){
            tempCol.onRender = (product: IWorkspaceItem) => product.normalized?.image
                ? <img 
                    src={product.normalized.image} 
                    style={{ maxHeight: '50px', maxWidth: '50px', margin: '0 auto' }} 
                    alt={product.normalized.name} 
                />
                : <></>;
            tempCol.onColumnClick = undefined
        }
        if(field === "quantity"){
            tempCol.onRender = (product: IWorkspaceItem) => product.normalized
                ? <QuantityEntry
                    item={product}
                    onChange={(v) => onDetailsChange(product, { ...product.details, quantity: v })}
                />
                : null;
            tempCol.onColumnClick = undefined
        }
        else if(field === "name"){
            tempCol.onRender = (product: IWorkspaceItem) => product.normalized
                ? <div>{product.normalized.name}</div>
                : null;
        }
        else if(field === "manufacturer"){
            tempCol.onRender = (product: IWorkspaceItem) => product.normalized
                ? <div>{product.normalized.manufacturer}</div>
                : null;
        }
        else if(field === "manufacturerPartNumber"){
            tempCol.onRender = (product: IWorkspaceItem) => product.normalized
                ? <div>{product.normalized.manufacturerPartNumber}</div>
                : null;
        }
        else if(field === "source"){
            tempCol.onRender = (product: IWorkspaceItem) => <ProcurementVendorSourceSelection
                selectedSource={product.details.source}
                item={product} 
                onChange={(v) => {
                    onDetailsChange(product, { ...product.details, source: v })
                }}/>
            tempCol.onColumnClick = undefined
        }


        columns.push(tempCol);
    }
    return columns;
}

export const renderPrepareOrderColumns = (onDetailsClick?: (product: IProcurementItem) => void, sorter?: (column: IColumn) => void): IColumn[] => {
    const prepareOrderColumns: IColumn[] = [];
    for (const field of PREPAREORDERITEMCOLUMNS) {
        const prepareOrderTempCol: IColumn = PROCUREMENT_ITEM_COLUMN_PROPERTIES[field].basicColumn;

        prepareOrderTempCol.onColumnClick = (ev, column) => {if(sorter){sorter(column)}}
        if(field === "quantity"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => product
                ? <ProcurementQuantityEntry
                    item={product}
                    disabled={true}
                />
                : null;
        }
        else if(field === "manufacturer"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.manufacturer}</div>
                : null;
        }
        else if(field === "name"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.name}</div>
                : null;
        }
        else if(field === "mfp"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.manufacturerPartNumber}</div>
                : null;
        }
        else if(field === "vpn"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.vendorPartNumber}</div>
                : null;
        }
        else if(field === "stock"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => {
                const sameStock = !!(product.previousStock && product.previousStock == product.stock);
                const stockUp = !!(product.previousStock && product.previousStock < (product.stock ?? 0));
                const stockDown = !!(product.previousStock && product.previousStock > (product.stock ?? 0));

                return (product
                ? <>

                    <div className={'ac-grid-field'}>
                        {product.stock}
                        {sameStock &&
                            <Icon iconName={'CheckMark'} color={'green'}/>
                        }
                        {stockUp &&
                            <Icon iconName={'stockUp'} color={'green'}/>
                        }
                        {stockUp &&
                            <Icon iconName={'stockDown'} color={'red'}/>
                        }
                    </div>
                    {(stockUp || stockDown) && 
                        <>
                            <div className={'ac-grid-field'}>Previous: {product.previousStock}</div>
                        </>
                    }
                </>
                : null)}
        } else if (field === "workspace"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => product
                ? <div><IconButton iconProps={{iconName: 'OpenInNewWindow', title: `${product.workspaceName}`}} onClick={() => {
                    if(window){
                        window.open(`/workspace/${product.workspace}`, '_blank')?.focus()
                    }}}/><div className={'ac-grid-field'}>{product.workspaceName}</div></div>
                : null;
        }
        else if(field === "userName"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.username}</div>
                : null;
        }
        
        else if(field === "cost"){
            prepareOrderTempCol.onRender = (product: IProcurementItem) => {
                
                const sameCost = product.previousCost && product.previousCost == product.cost;
                const costUp = product.previousCost && product.previousCost < product.cost;
                const costDown = product.previousCost && product.previousCost > product.cost;

                return(product
                ? <>
                    <div className={'ac-grid-field'}>

                        <CurrencyFormat
                            value={product.cost ?? 0}
                            displayType="text"
                            thousandSeparator=","
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                        {sameCost &&
                            <Icon iconName={'CheckMark'} color={'green'}/>
                        }
                        {costUp &&
                            <Icon iconName={'stockUp'} color={'red'}/>
                        }
                        {costDown &&
                            <Icon iconName={'stockDown'} color={'green'}/>
                        }
                    </div>
                    {(costUp || costDown) &&
                        <div className={'ac-grid-field'}>
                            Previous:
                            <CurrencyFormat
                                value={product.previousCost ?? 0}
                                displayType="text"
                                thousandSeparator=","
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        </div>
                    }
            </>
                : null)}
        }
        else if(field === "details"){
            prepareOrderTempCol.onColumnClick = undefined
            prepareOrderTempCol.onRender = (product: IProcurementItem) =><>
                <div className={'ac-item-icon'}>
                    {warnOnItem(product) &&<Icon iconName={'Warning'} style={{color:'red'}}></Icon>}
                </div>
                <PrimaryButton text='Details' onClick={() => {
                    if(onDetailsClick){
                        onDetailsClick(product)
                    }}}
                />
            </>
        }


        prepareOrderColumns.push(prepareOrderTempCol);
    }
    return prepareOrderColumns;
}
export const renderOrderItemColumns = (onDetailsChange?: (product: IProcurementItem) => void, disabled?: boolean, sorter?: (column: IColumn) => void): IColumn[] => {
    const columns: IColumn[] = [];
    for (const field of ORDERITEMCOLUMNS) {
        const tempCol: IColumn = PROCUREMENT_ITEM_COLUMN_PROPERTIES[field].basicColumn;
        if(field === "quantity"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <ProcurementQuantityEntry
                    disabled={disabled}
                    item={product}
                    onChange={(v) => { 
                        if(onDetailsChange){
                            const tempProd = cloneDeep(product)
                            tempProd.quantity = v; 
                            onDetailsChange(tempProd)
                        }}}
                />
                : null;
        }
        else if(field === "manufacturer"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.manufacturer}</div>
                : null;
        }
        else if(field === "name"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.name}</div>
                : null;
        }
        else if(field === "mfp"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.manufacturerPartNumber}</div>
                : null;
        }
        else if(field === "vpn"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.vendorPartNumber}</div>
                : null;
        }
        else if(field === "stock"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.stock}</div>
                : null;
        }
        else if(field === "workspace"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <div><IconButton iconProps={{iconName: 'OpenInNewWindow', title: `${product.workspaceName}`}} onClick={() => {
                    if(window){
                        window.open(`/workspace/${product.workspace}`, '_blank')?.focus()
                    }}}/><div className={'ac-grid-field'}>{product.workspaceName}</div></div>
                : null;
        }
        else if(field === "userName"){
            tempCol.onRender = (product: IProcurementItem) => product
                ? <div className={'ac-grid-field'}>{product.username}</div>
                : null;
        }

        else if(field === "cost"){
            tempCol.onRender = (product: IProcurementItem) => product
                ?<div className={'ac-grid-field'}>
                    <CurrencyFormat
                        value={product.cost ?? 0}
                        displayType="text"
                        thousandSeparator=","
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></div>
                : null;
        }
        else if(field === "dropship"){
            tempCol.onRender = (product: IProcurementItem) =>
                <div className={'ac-grid-field'}>
                    <Checkbox
                        disabled={disabled}
                        name={'Dropship'}
                        checked={product.dropship}
                        onChange={(e, i) => {if(product?.quantity && onDetailsChange){
                            const prod = cloneDeep(product)
                            prod.dropship = i
                            onDetailsChange(prod)
                        }}}
                    />
                </div>
        }
        else if(field === "details"){
            tempCol.onRender = (product: IProcurementItem) =>
                <div className={'ac-item-icon'}>
                    {warnOnItem(product) &&<Icon iconName={'Warning'} style={{color:'red'}}></Icon>}
                </div>
        }
        
        tempCol.onColumnClick = (ev, column) => {if(sorter){sorter(column)}}


        columns.push(tempCol);
    }
    return columns;
}