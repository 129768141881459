import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {
    DefaultButton,
    Dropdown,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    Panel,
    Stack,
    TextField
} from "@fluentui/react";
import {IUserCreateRequest, IUserRoleRecord} from "../../models/Settings";
import {SettingsDAL} from "../../dal/SettingsDAL";
interface IUserDetailsDialog {
    user?: IUserRoleRecord;
    isOpen: boolean;
    onUpdate: (user: IUserRoleRecord) => void;
    onDelete: (username: string) => void;
    onClose: () => void;
}
import CAN, {USER_MODIFY} from "../../permissions/ability"


const UserDetailsDialog: React.FC<IUserDetailsDialog> = (props) => {
    const auth = useSelector((state: RootState) => state.auth);
    const loggedInUsername = auth?.user?.preferred_username as string
    const selectedOrg = useSelector((state: RootState) => state.settings.selectedOrganization?.id);
    const [username, setUsername] = useState<string | undefined>();
    const [firstName, setFirstName] = useState<string | undefined>();
    const [lastName, setLastName] = useState<string | undefined>();
    const [role, setRole] = useState<string | undefined>();
    const [mfa, setMfa] = useState<string | undefined>();
    const [savable, setSavable] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const canModifySelf = CAN(USER_MODIFY) || username == loggedInUsername;

    useEffect(() => {
        setMfa(props.user?.mfaMode ?? 'none')
        setUsername(props.user?.username);
        setRole(props.user?.role);
    }, [props.user])

    useEffect(() => {
        if (username === '' || username === undefined || role === '' || role === undefined || mfa === undefined)
            setSavable(false);
        else
            setSavable(true);
    }, [username, role, mfa]);

    const roleOptions: IDropdownOption[] = [
        { key: 'admin', text: 'Administrator' },
        { key: 'standard', text: 'Standard User' },
        { key: 'basic', text: 'Basic User' }
    ]
    
    const saveUser = async () => {
        if (username === '' || username === undefined || role === '' || role === undefined || mfa === '' || mfa === undefined)
            return;
        const dal = new SettingsDAL(auth, selectedOrg);
        const user: IUserCreateRequest = {username, role, firstName, lastName, mfaMode: mfa};
        const result = await dal.saveUser(user);
        if (result?.success) {
            props.onUpdate(user);
            setUsername(undefined);
            setRole(undefined);
            setMfa(undefined);
        } else {
            setError(result?.message ?? 'Error saving user')
        }
    }
    const deleteUser = async () => {
        if (username == undefined)
            return;
        if(username == loggedInUsername){
            setError('Cannot delete logged in user');
            return;
        }
        const dal = new SettingsDAL(auth, selectedOrg);
        const result = await dal.deleteUser(username);
        if (result?.success) {
            props.onDelete(username)
            setUsername(undefined);
            setRole(undefined);
        } else {
            setError(result?.message ?? 'Error deleting user');
        }
    }
    const canModify = CAN(USER_MODIFY)
    return (

        <Panel
            onDismiss={() => props.onClose()}
            isOpen={props.isOpen}
            headerText='User Details'
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <>
                    <Stack horizontal tokens={{childrenGap: '1em'}}>
                        <DefaultButton text='Save' style={{width:'100%'}} disabled={!savable} onClick={() => saveUser()} />
                        {props.user != undefined && canModify && (<DefaultButton text='Remove' className='btn-warning' style={{width:'100%'}} onClick={() => deleteUser()} />)}
                    </Stack>
                </>
            )}
            >
            {error &&
                <MessageBar
                    messageBarType={MessageBarType.error}
                >{error}</MessageBar>
            }
            <TextField
                label='Username (Email Address)'
                value={username}
                onChange={(e, d) => setUsername(d)}
                placeholder='Enter the user email address'
                disabled={props.user !== undefined}
                />
            <TextField
                label='First Name'
                value={firstName}
                onChange={(e,d) => setFirstName(d)}
                disabled={props.user !== undefined && !canModifySelf}
                />
            <TextField
                label='Last Name'
                value={lastName}
                onChange={(e,d) => setLastName(d)}
                disabled={props.user !== undefined && !canModifySelf}
                />
            <Dropdown
                options={roleOptions}
                selectedKey={role}
                label='User Role'
                onChange={(e, d) => setRole(d?.key.toString())}
                placeholder='Set the user role'
                disabled={!CAN(USER_MODIFY)}
                />
        </Panel>
    );
}

export default UserDetailsDialog;