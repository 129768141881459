import React from 'react';
import {useGetUnmatchedExtensionProductsQuery} from "../../store/Api";
import {
    CommandBar, DefaultButton,
    IColumn, ICommandBarItemProps,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {IUnmatchedExtensionProduct} from "../../models/Extension";
import UnmatchedProductDetails from "./UnmatchedProductDetails";

interface IUnmatchedProductsTableProps {
    extensionId: string;
}

const UnmatchedProductsTable: React.FC<IUnmatchedProductsTableProps> = (props) => {
    const [page, setPage] = React.useState(1);
    const unmatched = useGetUnmatchedExtensionProductsQuery({extensionId: props.extensionId, page});
    const [selectedProduct, setSelectedProduct] = React.useState<IUnmatchedExtensionProduct | undefined>(undefined);
    const [pageCount, setPageCount] = React.useState(1);

    React.useEffect(() => {
        if (unmatched.data?.total == null) return;
        let pages = Math.ceil(unmatched.data.total / 100);
        if (pages > 10) pages = 10;
        setPageCount(pages);
    }, [unmatched.data?.total]);

    const columns: IColumn[] = [
        ColumnGenerator('Source ID', 'sourceId', 100, 150),
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('MFP', 'manufacturerPartNumber', 150, 150),
        ColumnGenerator('Manufacturer', 'manufacturer', 200, 200),
        ColumnGenerator('Active', 'active', 100, 100, (item) => <>{item.active ? 'Yes' : 'No'}</>),
        ColumnGenerator('Matches', 'matches', 100, 100, (item: IUnmatchedExtensionProduct) => (
            <DefaultButton
                text={item.possibleMatches && item.possibleMatches?.length > 0 ? `Matches (${item.possibleMatches.length})` : 'No Matches'}
                primary={item.possibleMatches && item.possibleMatches?.length > 0}
                onClick={() => setSelectedProduct(item)}
            />
        ))
    ]

    const commands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {unmatched.refetch()}
        }
    ]

    const rightCommands: ICommandBarItemProps[] = [
        {
            key: 'page',
            text: `Page: ${page}`,
            iconProps: { iconName: 'Page' },
            subMenuProps: {
                items: Array.from(Array(pageCount).keys()).map((i) => ({
                    key: `page-${i}`,
                    text: `Page ${i + 1}`,
                    onClick: () => setPage(i + 1)
                }))
            }
        },
        {
            key: 'count',
            text: unmatched.data?.total ? `Count: ${unmatched.data.total}` : '',
        }
    ]

    return (<>
        <CommandBar items={commands} farItems={rightCommands} />
        <ShimmeredDetailsList
            items={unmatched.data?.items ?? []}
            columns={columns}
            enableShimmer={unmatched.isLoading || unmatched.isFetching}
            selectionMode={SelectionMode.none}
        />
        {selectedProduct && <UnmatchedProductDetails
            selectedProduct={selectedProduct}
            onClose={() => setSelectedProduct(undefined)}
            extensionId={props.extensionId}
        />}
    </>);
}

export default UnmatchedProductsTable;