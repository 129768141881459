import React from "react";
import './LoginCard.css'

interface IAuthenticating{
  message?: string
}
const Authenticating: React.FC<IAuthenticating> = (props) => {
  return <div>
    <div id="LoginContainer">
      <div id="LoginCard">
        <div style={{textAlign: 'center'}}>
          <img src="mainLogo.png" className='loginLogo' alt='Adaptive Catalog'/>
        </div>
        <div style={{textAlign:"center"}}>{props.message ?? 'Logging In'}</div>
      </div>
    </div>
  </div>;
};

export default Authenticating;
