import React from 'react';
import {Stack} from "@fluentui/react/lib/Stack";
import {TextField} from "@fluentui/react";

interface IPriceFiltersProps {
    highPrice?: number;
    lowPrice?: number;
    onChange: (lowPrice?: number, highPrice?: number) => void;
}

const PriceFilters: React.FC<IPriceFiltersProps> = (props) => {

    return (
        <Stack tokens={{childrenGap: '0.5em'}} horizontal>
            <TextField
                placeholder={'Low'}
                
                defaultValue={props?.lowPrice?.toFixed(2)}
                onChange={(e, p) => {
                    props.onChange(parseFloat(p ?? "0"), props.highPrice)
                }
                }
            />
            <TextField
                placeholder={'High'}
                defaultValue={props?.highPrice?.toFixed(2)}
                onChange={(e, p) => {
                    props.onChange(props.lowPrice, parseFloat(p ?? "0"));
                }}
            />
        </Stack>
    )
}

export default PriceFilters;