import React, {useEffect, useState} from 'react';

interface IPaginatorProps {
    page: number,
    pageSize: number,
    resultCount: number,
    onChange: (target:number) => void;
}

const Paginator: React.FC<IPaginatorProps> = (props) => {
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [endPage, setEndPage] = useState<number>(0)
    useEffect(() => {
        let pageCount = Math.trunc(props.resultCount / props.pageSize)
        const remainder = props.resultCount % props.pageSize
        if(remainder > 0){
            pageCount++
        }
        if (pageCount > 10) pageCount = 10;
        setEndPage(pageCount)
        
        if (props.page === 0) {
            setCurrentPage(1)
        }
        else{
            setCurrentPage(props.page)
        }
    }, [props.pageSize, props.page, props.resultCount]);
    
    const onClickFirstPage = () => {
        if (currentPage == 1) {
            return false
        }
        props.onChange(1)
    }
    const onClickPreviousPage = () => {
        if (currentPage == 1) {
            return false
        }
        props.onChange(currentPage - 1)
    }
    const onClickPage = (page : number) => {
        props.onChange(page)
    }
    const onClickNextPage = () => {
        if (currentPage === endPage) {
            return false
        }
        props.onChange(props.page + 1)
    }
    const onClickLastPage = () => {
        if (currentPage === endPage) {
            return false
        }
        props.onChange(endPage ?? 0)
    }
    const isPageActive = (page : number) => {
        return currentPage === page
    }

        const pages = []
        for (let i = 1; i <= endPage; i++) {
            pages.push(
                <div key={'paginator-' + i} onClick={ () => { onClickPage(i) } } className={ 'ac-pagination-menu ac-pagination-item' + (isPageActive(i) ? ' ac-pagination-item-active': '')}>{ i }</div>
            )
        }
        return (
            <div style={{margin:'1em auto', alignItems:"center", display:"table"}}>
            <div style={{display:"inline-flex"}} className={'ac-pagination-menu'}>
                <div onClick={ () => onClickFirstPage() } className={'ac-pagination-menu ac-pagination-item'}>First</div>
                <div onClick={ () => onClickPreviousPage() } className={'ac-pagination-menu ac-pagination-item'}>«</div>
                { pages }
                <div onClick={() => {
                    if (props.page !== endPage) {
                        onClickNextPage()
                    }}}
                className={'ac-pagination-menu ac-pagination-item'}>»</div>
                <div onClick={ () => onClickLastPage() } className={'ac-pagination-menu ac-pagination-item'} >Last</div>
            </div>
            </div>
        )
}

export default Paginator;
