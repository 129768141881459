import React, {useEffect, useRef, useState} from "react";
import {
    IconButton,
    Image,
    ImageFit,
    MessageBar,
    MessageBarType,
    Shimmer,
    ShimmerElementsGroup,
    ShimmerElementType
} from "@fluentui/react";
import {useSetImageMutation, useUploadImageMutation} from "../../store/Api";
import {PLACEHOLDER_IMAGE} from "../../Constants";
import {IProductImage} from "../../models/Search";

interface IEditableImageProps {
    image: IProductImage;
    onChange?: (id: string, url: string) => void;
    editMode?: boolean;
    sizeSquare?: number;
    onClear?: () => void;
}

const EditableImage: React.FC<IEditableImageProps> = (props) => {
    const [saveProductImage] = useSetImageMutation();
    const [uploadImage] = useUploadImageMutation();
    const [updatedProductImageUrl, setProductImageUrl] = useState<string>();
    const effectiveHeight = props.sizeSquare ?? 150;
    const effectiveWidth = props.sizeSquare ?? 150;
    const [saving, setSaving] = useState(false);
    const [imageSaveSuccess, setImageSaveSuccess] = useState(false);
    const [imageSaveMessage, setImageSaveMessage] = useState<string>();
    const productImageUrl = () => {
        if(saving) {
            return ""
        }
        if(updatedProductImageUrl) {
            return updatedProductImageUrl;
        }
        return props.image.url && props.image.url != '' ? props.image.url : PLACEHOLDER_IMAGE
    }
    
    useEffect(() => {
        if(props.image) {
            setProductImageUrl(props.image.url)
        }
    }, [props.image]);
    
    const inputFile = useRef<HTMLInputElement | null>(null);
    const onButtonClick = () => {
        if (props.editMode) {
            inputFile?.current?.click();
        }
    };

    const getCustomElements = (): JSX.Element => {
        return (
            <div>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.line, width: effectiveWidth, height: effectiveHeight},
                    ]}
                />
            </div>
        );
    };

    const onChangeFile = (event : any) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                const file = reader.result;
                if (file && typeof file !== "string" && file.byteLength < 20971520) {
                    setSaving(true);
                    setImageSaveMessage('')
                    setImageSaveSuccess(false)
                    if (props.image.type === 'Custom') {
                        uploadImage({imageId: props.image.id, file: file, imageType: props.image.type})
                            .unwrap()
                            .then((result) => {
                                if(result.success) {
                                    setProductImageUrl(result.url);
                                    if (props.onChange) {
                                        props.onChange(props.image.id, result.url)
                                    }
                                    setImageSaveSuccess(true)
                                    setImageSaveMessage("Image saved successfully")
                                }
                                else{
                                    setImageSaveSuccess(false)
                                    setImageSaveMessage("Unable to save image. \r\n" + result.error)

                                }
                                setSaving(false);
                            });
                    } else {
                        saveProductImage({imageId: props.image.id, file: file, imageType: props.image.type})
                            .unwrap()
                            .then((result) => {
                                if(result.success) {
                                    setProductImageUrl(result.url);
                                    if (props.onChange) {
                                        props.onChange(props.image.id, result.url)
                                    }
                                    setImageSaveSuccess(true)
                                    setImageSaveMessage("Image saved successfully")
                                }
                                else{
                                    setImageSaveSuccess(false)
                                    setImageSaveMessage("Unable to save image. \r\n" + result.error)

                                }
                                setSaving(false);
                            });
                    }
                }
            };
            reader.readAsArrayBuffer(file);
        }
    }
    return(
        <div>

            <input type='file' id='file' ref={inputFile} accept=".tga,.gif,.pbm,.bmp,.png,.qoi,.tiff,.jpeg,.jpg,.webp"
                   style={{display: 'none'}} onChange={onChangeFile}/>
            <div style={{width: effectiveWidth}}>
                <Shimmer isDataLoaded={!saving} customElementsGroup={getCustomElements()}>
                    <Image imageFit={ImageFit.contain} src={productImageUrl()} height={effectiveHeight}
                           width={effectiveWidth}
                           className={props.editMode ? 'product-image-edit' : 'product-image'}
                           onClick={onButtonClick}
                           title={props.editMode ? "Select a new image < 20MB" : "Product Image"}/>
                    {props.onClear && props.image.url != '' && props.image.url != PLACEHOLDER_IMAGE && <IconButton
                        iconProps={{ iconName: 'Cancel' }}
                        styles={{ root: { position: 'absolute', top: 10, right: 10 } }}
                        onClick={() => {
                            if (props.onClear) {
                                props.onClear();
                            }
                        }}
                    />}
                </Shimmer>

                {imageSaveMessage &&
                    <MessageBar messageBarType={imageSaveSuccess ? MessageBarType.success : MessageBarType.error} dismissIconProps={{iconName: 'cancel'}} onDismiss={() => {setImageSaveMessage(undefined)}}>
                        {imageSaveMessage}
                    </MessageBar>}
            </div>
        </div>
    )
}

    export default EditableImage;