import React from 'react';
import { IContact } from '../../models/Company';
import {DefaultButton, MessageBar, MessageBarType, Panel, PrimaryButton, Stack, TextField} from '@fluentui/react';
import StorefrontDropdown from '../Storefront/StorefrontDropdown';
import CompanyDropdown from './CompanyDropdown';
import { useSaveContactMutation } from '../../store/Api';

interface IContactDetailsModal {
    isOpen: boolean;
    onDismiss: () => void;
    contact?: IContact;
}

const ContactDetailsModal: React.FC<IContactDetailsModal> = (props) => {
    const [saveContact] = useSaveContactMutation();
    const [saving, setSaving] = React.useState<boolean>(false);
    const [firstName, setFirstName] = React.useState<string | undefined>(props.contact?.firstName);
    const [lastName, setLastName] = React.useState<string | undefined>(props.contact?.lastName);
    const [email, setEmail] = React.useState<string | undefined>(props.contact?.emailAddress);
    const [phone, setPhone] = React.useState<string | undefined>(props.contact?.phoneNumber);
    const [company, setCompany] = React.useState<string | undefined>(props.contact?.company);
    const [storefront, setStorefront] = React.useState<string | undefined>(props.contact?.storefront);
    const [externalId, setExternalId] = React.useState<string | undefined>(props.contact?.externalId);
    const [externalIdType, setExternalIdType] = React.useState<string | undefined>(props.contact?.externalIdType);
    const [companyName, setCompanyName] = React.useState<string | undefined>(props.contact?.company);
    const [companyError, setCompanyError] = React.useState<string | undefined>();
    
    React.useEffect(() => {
        setFirstName(props.contact?.firstName);
        setLastName(props.contact?.lastName);
        setEmail(props.contact?.emailAddress);
        setPhone(props.contact?.phoneNumber);
        setCompany(props.contact?.company);
        setStorefront(props.contact?.storefront);
        setExternalId(props.contact?.externalId);
        setExternalIdType(props.contact?.externalIdType);
    }, [props.contact])

    const save = () => {
        if(company === undefined){
            setCompanyError("Company is required")
        }
        else {
            setSaving(true);
            saveContact({
                id: props.contact?.id,
                firstName,
                lastName,
                emailAddress: email ?? '',
                phoneNumber: phone,
                company,
                storefront,
                externalId,
                externalIdType,
                companyName: companyName
            }).then(() => {
                setSaving(false);
                props.onDismiss();
            });
        }
    }

    const footer = () => <Stack horizontal tokens={{ childrenGap: 10 }}>
                <PrimaryButton text='Save' onClick={save} disabled={saving} />
                <DefaultButton text='Cancel' onClick={props.onDismiss} />
            </Stack>

    return (
        <Panel
            headerText={props.contact ? props.contact?.firstName + ' ' + props.contact?.lastName : 'New Contact'}
            isOpen={props.isOpen}
            onDismiss={props.onDismiss}
            closeButtonAriaLabel='Close'
            isFooterAtBottom={true}
            onRenderFooterContent={footer}
            >
                {companyError && <MessageBar messageBarType={MessageBarType.error}>{companyError}</MessageBar>}
                <TextField label='First Name' value={firstName} onChange={(e, v) => setFirstName(v)} />
                <TextField label='Last Name' value={lastName} onChange={(e, v) => setLastName(v)} />
                <TextField label='Email' value={email} onChange={(e, v) => setEmail(v)} />
                <TextField label='Phone' value={phone} onChange={(e, v) => setPhone(v)} />
                <CompanyDropdown selected={company} required={true} onChange={(option) => {
                    setCompany(option?.key as string);
                    setCompanyName(option?.text);
                }} />
                <StorefrontDropdown useId selected={storefront} onChange={(selected) => setStorefront(selected)} inheritedStorefrontId={props.contact?.companyStorefront} />
                <TextField label='External ID' value={externalId} onChange={(e, v) => setExternalId(v)} />
                <TextField label='External ID Type' value={externalIdType} onChange={(e, v) => setExternalIdType(v)} />
            </Panel>
    );
}

export default ContactDetailsModal;