import React, {useState} from "react";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    Icon, Selection,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {ITaxCode} from "../../models/TaxCode";
import {useDeleteTaxCodeMutation, useGetTaxCodesQuery} from "../../store/Api";
import {ColumnGenerator, ConditionalButtonColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import CAN, {TAX_CODE_MODIFY} from "../../permissions/ability"
import EditTaxCodeModal from "../../components/TaxCodeModals/EditTaxCodeModal";
import DeleteTaxCodeModal from "../../components/TaxCodeModals/DeleteTaxCodeModal";


const TaxCodeManagement: React.FC = () => {
    const getTaxCodes = useGetTaxCodesQuery();
    const [selectedTaxCode, setSelectedTaxCode] = useState<ITaxCode | undefined>();
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedTaxCodes, setSelectedTaxCodes] = useState<ITaxCode[] | undefined>()
    const commands: ICommandBarItemProps[] = [
        {
            key: 'AddTaxCode',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setModalOpen(true);
            }
        }
    ]

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'DeleteSelectedTaxCodes',
            text: selectedTaxCodes ? `Delete Selected ${selectedTaxCodes.length} Tax Codes` : 'No Tax Codes Selected',
            disabled: !selectedTaxCodes || selectedTaxCodes?.length === 0,
            iconProps: { iconName: 'Delete' },
            onClick: () => {
                setDeleteModalOpen(true);
            }
        }
    ]

    const selection = new Selection({
        onSelectionChanged: () => {
            const selected = selection.getSelection() as ITaxCode[];
            setSelectedTaxCodes([...selected])
        },
    })

    const columns: IColumn[] = [
        ColumnGenerator('Name','name', 100, 300),
        ColumnGenerator('Rate','rate', 100, 300),
        ColumnGenerator('Description', 'description', 100),
        ColumnGenerator('Active', 'active', 50, 50, (c: ITaxCode) => c.active
             ? <span style={{fontSize:'2em', fontWeight: 'bold'}}><Icon iconName='CheckMark' /></span> : <></>
        ),
        ConditionalButtonColumnGenerator((c: ITaxCode) => {setSelectedTaxCode(c); setModalOpen(true);}, 'Edit', 'Edit', TAX_CODE_MODIFY)
    ]

    return (
        <div className="main-content">
            <h1>Tax Code Management</h1>
            {CAN(TAX_CODE_MODIFY) && (
            <CommandBar items={commands} farItems={farCommands} />
            )}
            <ShimmeredDetailsList items={getTaxCodes.data ?? []} enableShimmer={getTaxCodes.isFetching} columns={columns} selectionMode={SelectionMode.multiple} selection={selection} />
            <DeleteTaxCodeModal displayModal={deleteModalOpen} closer={() => setDeleteModalOpen(false)} selectedTaxCodes={selectedTaxCodes}></DeleteTaxCodeModal>
            <EditTaxCodeModal
                taxCode={selectedTaxCode}
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedTaxCode(undefined)
                }}
            />
        </div>
    );
}

export default TaxCodeManagement;
