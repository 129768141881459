import React, {useState} from "react";
import {useSelector} from "react-redux";
import {IApiKey} from "../models/ApiKey";
import {RootState} from "../store/rootReducer";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    PrimaryButton,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import NewApiKeyDialog from "../components/ApiKeyManagement/NewApiKeyDialog";
import ApiKeyDetailsDialog from "../components/ApiKeyManagement/ApiKeyDetailsDialog";
import {useGetApiKeysQuery, useGetOrganizationsQuery} from "../store/Api";
import CAN, {API_KEYS_SELF} from "../permissions/ability";

const ApiKeyManagementPage: React.FC = () => {
    const organization = useSelector((state: RootState) => state.settings.selectedOrganization?.id)
    const getOrganizations = useGetOrganizationsQuery();
    const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);
    const [detailsKey, setDetailsKey] = useState<IApiKey | undefined>(undefined);
    const apiKeysApi = useGetApiKeysQuery();
    let org = organization;
    if (org == null && getOrganizations.data !== undefined && getOrganizations.data.length > 0)
        org = getOrganizations.data[0].id;
    

    const columns: IColumn[] = [
        {
            key: 'Name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            isRowHeader: true,
        },
        {
            key: 'Enabled',
            name: 'Enabled',
            fieldName: 'enabled',
            onRender: (key: IApiKey) => key.enabled ? "✓" : "",
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'Type',
            name: 'Key Type',
            fieldName: 'keyType',
            minWidth: 100,
            maxWidth: 200,
        },
        {
            key: 'Details',
            name: '',
            minWidth: 100,
            onRender: (key: IApiKey) => <PrimaryButton
                text='Details'
                onClick={() => {
                    setDetailsKey(key);
                }}
                />
        }
    ]

    const commands: ICommandBarItemProps[] = [
        {
            key: 'new',
            text: 'New',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setNewDialogOpen(true);
            }
        }
    ]

    return (
        <div className='main-content'>
            <h1>API Key Management</h1>
            <p>Organization: {org}</p>
            {CAN(API_KEYS_SELF) && (
            <CommandBar items={commands} />
            )}
            <ShimmeredDetailsList items={apiKeysApi.data ?? []} enableShimmer={apiKeysApi.isFetching} columns={columns} selectionMode={SelectionMode.none} />
            <NewApiKeyDialog
                onClose={() => {setNewDialogOpen(false);}}
                isOpen={newDialogOpen}
                />
            <ApiKeyDetailsDialog onClose={() => {setDetailsKey(undefined)}} apiKey={detailsKey} />
        </div>
    );
}

export default ApiKeyManagementPage;