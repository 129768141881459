import React, {useCallback, useEffect, useState} from "react";
import {useLazyGetQuicksearchQuery} from "../../store/Api";
import {debounce} from "lodash";
import Autosuggest from 'react-autosuggest';
import SearchFilter from "../Common/SearchFilter";
import {IQuickSearchResponse} from "../../models/Search";

interface IQuickSearchProps {
    catalogName : string | undefined;
    criteriaKeywords : string | undefined;
    onKeywordChange: (keyword: string, searchNow: boolean) => void;
    onManufacturerSelected: (manufacturer: string) => void;
    onCategorySelected: (segment: string, category: string) => void;
    onSubCategorySelected: (segment: string, category: string, subCategory: string) => void;
    onTagSelected: (tag: string) => void;
    onUserTagSelected: (tag: string) => void;
    onProductLineSelected: (productLine: string) => void;
    onProductSelected?: (id: string) => void;
    onSearch: () => void;
}

interface ISuggestionSection {
    title: string;
    suggestions: ISuggestion[];
}

interface ISuggestion {
    segment?: string;
    category?: string;
    subCategory?: string;
    value: string;
    productId?: string;
    label: string;
    color?: string;
    isTag?: boolean;
}


const QuickSearch: React.FC<IQuickSearchProps> = (props) => {
    const [keywords, setKeywords] = useState<string | undefined>();
    const [query] = useLazyGetQuicksearchQuery();
    const [suggestionsNormalized, setSuggestionsNormalized] = useState<ISuggestionSection[]>([]);

    React.useEffect(() => {
        if (props.criteriaKeywords !== keywords) setKeywords(props.criteriaKeywords);
    }, [props.criteriaKeywords]);

    const debouncedSetSearchedKeywords = useCallback(
        debounce((val:string | undefined) => {
            setSuggestionsNormalized([]);
            if (val && val != '') load(val);
        }, 200, {leading: false}),
    []
    );

    const load = async (value: string) => {
        const result = await query({catalog: props.catalogName, value: value});
        if (result.data) {
            normalizeSuggestions(result.data);
        }
    }

    const normalizeSuggestions = (suggestions: IQuickSearchResponse) => {
        const manufacturers = suggestions.manufacturers?.map((m) => ({value: m, label: m}));
        const productNames = Object.entries(suggestions.productNamesWithIds).map((m) => ({value: m[1], label: m[0]}));
        const productLines = suggestions.productLines?.map((m) => ({value: m, label: m}));
        const tags = suggestions.tags?.map((m) => ({value: m, label: m, isTag: true, color: "#9BD3AB"}));
        const myTags = suggestions.userTags?.map((m) => ({value: m, label: m, isTag: true, color: "#F9D585"}))
        const categories: ISuggestion[] = [];
        if (suggestions.categories) {
            for (const [segment, cats] of Object.entries(suggestions.categories)) {
                for(const category of cats) {
                    categories.push({segment, category, value: category, label: `${segment} > ${category}`});
                }
            }
        }
        const subCategories: ISuggestion[] = [];
        if (suggestions.subCategories) {
            for (const [segment, cats] of Object.entries(suggestions.subCategories)) {
                for(const [category, subCats] of Object.entries(cats)) {
                    for(const subCategory of subCats) {
                        subCategories.push({segment, category, subCategory, value: subCategory, label: `${segment} > ${category} > ${subCategory}`});
                    }
                }
            }
        }
        const normalized: ISuggestionSection[] = [];
        if (manufacturers && manufacturers.length > 0) normalized.push({title: 'Manufacturers', suggestions: manufacturers});
        if (productNames && productNames.length > 0) normalized.push({title: 'Products', suggestions: productNames});
        if (categories && categories.length > 0) normalized.push({title: 'Categories', suggestions: categories});
        if (subCategories && subCategories.length > 0) normalized.push({title: 'Sub Categories', suggestions: subCategories});
        if (productLines && productLines.length > 0) normalized.push({title: 'Product Lines', suggestions: productLines});
        if (tags && tags.length > 0) normalized.push({title: 'Tags', suggestions: tags});
        if (myTags && myTags.length > 0) normalized.push({title: 'My Tags', suggestions: myTags});

        setSuggestionsNormalized(normalized);

    }


    useEffect(() => {
        props.onKeywordChange(keywords || '', false);
    }, [keywords]);

    const onChange = (e:any, { newValue }:any) => {
        setKeywords(newValue);
    }

    const onSelect = (e:React.FormEvent<any>, suggestion: Autosuggest.SuggestionSelectedEventData<ISuggestion>) => {
        if(suggestion.sectionIndex == null) return;
        const section = suggestionsNormalized[suggestion.sectionIndex];
        switch (section.title) {
            case 'Manufacturers':
                props.onManufacturerSelected(suggestion.suggestion.value);
                setKeywords('');
                break;
            case 'Products':
                if (props.onProductSelected && suggestion.suggestion.value) {
                    console.log(['QUICKSEARCH SELECTED', suggestion.suggestion.value]);
                    props.onProductSelected(suggestion.suggestion.value)
                    setKeywords('');
                } else {
                    props.onKeywordChange(suggestion.suggestion.value, true);
                    setKeywords(suggestion.suggestion.value);
                }
                break;
            case 'Categories':
                if (suggestion.suggestion.segment && suggestion.suggestion.category)
                    props.onCategorySelected(suggestion.suggestion.segment, suggestion.suggestion.category);
                setKeywords('');
                break;
            case 'Sub Categories':
                if (suggestion.suggestion.segment && suggestion.suggestion.category && suggestion.suggestion.subCategory)
                    props.onSubCategorySelected(suggestion.suggestion.segment, suggestion.suggestion.category, suggestion.suggestion.subCategory);
                setKeywords('');
                break;
            case 'Product Lines':
                props.onProductLineSelected(suggestion.suggestion.value);
                setKeywords('');
                break;
            case 'Tags':
                props.onTagSelected(suggestion.suggestion.value)
                setKeywords('');
                break;
            case 'My Tags':
                props.onUserTagSelected(suggestion.suggestion.value)
                setKeywords('');
                break;

        }
    }

    return (<>
            <Autosuggest
                suggestions={props.criteriaKeywords !== keywords ? suggestionsNormalized : []}
                multiSection={true}
                onSuggestionsFetchRequested={({value}) => debouncedSetSearchedKeywords(value)}
                onSuggestionsClearRequested={() => setSuggestionsNormalized([])}
                getSuggestionValue={(suggestion: ISuggestion) => suggestion.value}
                getSectionSuggestions={(section: ISuggestionSection) => section.suggestions}
                renderSuggestion={(suggestion: ISuggestion) => suggestion.isTag? <SearchFilter protected={true} color={suggestion.color}>{suggestion.label}</SearchFilter> : <span>{suggestion.label}</span>}
                renderSectionTitle={(section: ISuggestionSection) => <strong>{section.title}</strong>}
                onSuggestionSelected={onSelect}
                inputProps={{
                    placeholder: 'Search...',
                    value: keywords ?? props.criteriaKeywords ?? '',
                    onChange,
                    style: {
                        width: '100%',
                        boxSizing: 'border-box',
                        height: 'auto'
                    },
                    onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                            props.onSearch();
                        }
                    }
                }}
            />
    </>
    )
} 
export default QuickSearch;