import React, {FormEvent, useEffect, useState} from "react";
import { IAttribute, IMetadataAttributeOptions } from "../../models/Search";
import {
    FocusZone,
    FocusZoneDirection,
    getFocusStyle,
    getTheme,
    Icon,
    ITheme,
    List,
    mergeStyleSets
} from "@fluentui/react";
import {Dropdown, IDropdownOption} from "@fluentui/react/lib/Dropdown";

interface IFacetSelectionProps {
    facet: string;
    facets: IMetadataAttributeOptions;
    attributeType: string;
    hidden?: boolean;
    mustFilter?: IAttribute[];
    boostFilter?: IAttribute[];
    excludeFilter?: IAttribute[];
    onAddMust: (attribute: IAttribute) => void;
    onAddBoost: (attribute: IAttribute) => void;
    onAddExclude: (attribute: IAttribute) => void;
    onRemoveCriteria: (attribute: IAttribute) => void;
}

export const FacetSelection: React.FC<IFacetSelectionProps> = (props) => {
    
    const [mustFilter, setMustFilter] = useState<IAttribute[]>();
    const [boostFilter, setBoostFilter] = useState<IAttribute[]>();
    const [excludeFilter, setExcludeFilter] = useState<IAttribute[]>();
    
    const options : IDropdownOption[] = [
        {
            key: "MUST",
            text: "Must Have",
            data: {icon: 'HeartFill', iconColor: 'red'},
        },
        {
            key: "BOOST",
            text: "Nice to Have",
            data: {icon: 'Like', iconColor: 'blue'},
        },
        {
            key: "EXCLUDE",
            text: "Must Not Have",
            data: {icon: 'StatusCircleBlock', iconColor: 'red'},
        },
        {
            key: "NONE",
            text: "Clear Selection",
            data: {icon: 'CircleRing'},
        },
    ];
    useEffect(() => {
        setMustFilter(props.mustFilter);
        setBoostFilter(props.boostFilter);
        setExcludeFilter(props.excludeFilter);
    }, [props.boostFilter, props.excludeFilter, props.mustFilter]);
    
    
    const getFilterValue = (value : string, name : string) => {
        const tryMust = mustFilter?.filter(a => a.name == name && a.value == value);
        if(tryMust && tryMust.length == 1) {
            return "MUST"
        }
        const tryExcludes = excludeFilter?.filter(a => a.name == name && a.value == value);
        if(tryExcludes && tryExcludes.length == 1) {
            return "EXCLUDE"
        }
        const tryBoost = boostFilter?.filter(a => a.name == name && a.value == value);
        if(tryBoost && tryBoost.length == 1) {
            return "BOOST"
        }
        
        return "NONE";
    }
    
    function handleChange(event: FormEvent<HTMLDivElement>, option?: IDropdownOption, value?: string) {
        if(option && value) {
            const facet: IAttribute = {
                type: props.attributeType,
                name: props.facet,
                value: value,
            };
            if (facet?.name) {
                switch (option.key) {
                    case "MUST":
                        props.onAddMust(facet);
                        break;
                    case "BOOST":
                        props.onAddBoost(facet);
                        break;
                    case "EXCLUDE":
                        props.onAddExclude(facet);
                        break;
                    default:
                        props.onRemoveCriteria(facet);
                        break;
                }
            }
        }
    }
    const theme: ITheme = getTheme();
    const { palette, semanticColors, fonts } = theme;
    const classNames = mergeStyleSets({
        itemCell: [
            getFocusStyle(theme, { inset: -1 }),
            {
                minHeight: 30,
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                selectors: {
                    '&:hover': { background: palette.neutralLight },
                },
            },
        ],
        itemImage: {
            flexShrink: 0,
        },
        itemContent: {
            marginLeft: 10,
            overflow: 'hidden',
            flexGrow: 1,
        },
        itemName: [
            fonts.xLarge,
            {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        ],
        itemIndex: {
            fontSize: fonts.small.fontSize,
            backgroundColor: 'blue',
            marginBottom: 10,
        },
        chevron: {
            alignSelf: 'center',
            marginLeft: 10,
            color: palette.neutralTertiary,
            fontSize: fonts.large.fontSize,
            flexShrink: 0,
        },
    });
    const items = Object.entries(props.facets).map((facetValue) => {
        const facet: IAttribute = {
            type: props.attributeType,
            name: props.facet,
            value: facetValue[0],
        };
        return facet;
    });
    const iconStyles = (color? : string) => { 
        return {
            marginRight: '8px',
            color: color ?? 'black'}
        };
    const onRenderOption = (option?: IDropdownOption): JSX.Element => {
        return (
            <div style={{width:'120px'}}>
                {option?.data && option?.data.icon && (
                    <div style={{display:'inline-flex'}}><Icon style={iconStyles(option.data.iconColor)} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />{option.text}</div>
                )}
            </div>
        );
    };

    const contentStyles = mergeStyleSets({
        title: {
            borderWidth: 'unset',
            borderStyle: 'unset',
            borderColor: 'unset',
            borderRadius: 'unset',
            backgroundColor: 'unset',
            width: 'fit-content',
        },
        dropDownItem: {
            backgroundColor: 'red'
        }
    });
    
    const optionElement = (option: string) => {
        let trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"CircleRing"}/></span>;
        switch (option) {
            case 'BOOST':
                trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"Like"} style={{color:'blue'}}/></span>
                break;
            case 'EXCLUDE':
                trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"StatusCircleBlock"} style={{color:'red'}}/></span>
                break;
            case 'MUST':
                trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"HeartFill"} style={{color:'red'}}/></span>;
                break;
        }
        return trigger;
    }
    
    const caretDown = () => {
        return <></>
    }

    const onRenderCell = (item?: IAttribute): JSX.Element => {
        if(item) {
            const currentOption = getFilterValue(item.value, item.name);
            return (<div className={classNames.itemCell} data-is-focusable={true}>
                <div className={classNames.itemContent}>
                    <div className={classNames.itemName} style={{fontSize: 12}}>{item?.value}</div>
                </div>
                <Dropdown
                    styles={contentStyles}
                    selectedKey={currentOption}
                    id={item?.value}
                    className='dropdown'
                    options={options}
                    onRenderTitle={() => optionElement(currentOption)}
                    onRenderCaretDown={() => caretDown()}
                    onRenderOption={onRenderOption}
/*
                    onRenderItem={onRenderItem}
*/
                    onChange={(a, b) => handleChange(a, b, item.value)}
                />
            </div>);
        }
        return <></>;

    };
    return <>
        <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={items ?? []} onRenderCell={onRenderCell} >
                
            </List>
        </FocusZone>
    </>;
};
