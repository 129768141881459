import React from 'react';
import {Checkbox, TextField} from "@fluentui/react";
import CatalogDropdown from "./CatalogDropdown";

interface ISearchSerttingsSelectionProps {
    desiredQuantity?: number;
    onDesiredQuantityChange: (desiredQuantity: number | undefined) => void;
    allKeywords: boolean;
    onAllKeywords: (allKeywords: boolean) => void;
}

const SearchSettingsSelection: React.FC<ISearchSerttingsSelectionProps> = (props) => {
    const [desiredQuantity, setDesiredQuantity] = React.useState<string | undefined>();
    const [allKeywords, setAllKeywords] = React.useState<boolean>(false);

    React.useEffect(() => {
        setDesiredQuantity(props.desiredQuantity?.toString());
        setAllKeywords(props.allKeywords)
    }, [props.desiredQuantity, props.allKeywords]);

    const changeQuantity = (value: string) => {
        if (value === '') {
            setDesiredQuantity('');
            props.onDesiredQuantityChange(undefined);
        } else {
            const parsed = parseInt(value);
            if (isNaN(parsed)) {
                setDesiredQuantity('');
                props.onDesiredQuantityChange(undefined);
            } else {
                props.onDesiredQuantityChange(parseInt(value));
            }
        }

    }

    const blurQuantity = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value;
        changeQuantity(value);
    }

    const quantityKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            const value = (event.target as HTMLInputElement).value;
            changeQuantity(value);
        }
    }
    
    const changeKeywordSelection = (change: boolean) => {
        setAllKeywords(change);
        props.onAllKeywords(change);
    }

    return (<>
        <CatalogDropdown showLabel/>
        <TextField
            label={'Desired Quantity'}
            value={desiredQuantity}
            onChange={(e,d) => setDesiredQuantity(d)}
            onBlur={blurQuantity}
            onKeyDown={quantityKeyPress}
            />
        <div style={{paddingTop: '1em'}}>
            <Checkbox
                label='Match All Keywords'
                checked={allKeywords}
                onChange={(e,d) => changeKeywordSelection(d ?? false)}
            />
        </div>
    </>);
}

export default SearchSettingsSelection;