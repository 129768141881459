import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {Dropdown, IDropdownOption, MessageBar, MessageBarType, Panel, PrimaryButton, TextField} from "@fluentui/react";
import CAN, {API_KEYS_SELF, API_KEYS_ADMIN} from "../../permissions/ability";
import {useAddApiKeyMutation, useGetApiKeyTypesQuery} from "../../store/Api";

interface INewApiKeyDialogProps {
    onClose: () => void;
    isOpen: boolean;
}

const NewApiKeyDialog: React.FC<INewApiKeyDialogProps> = (props) => {
    const canSetKeyType = CAN(API_KEYS_ADMIN);
    const canSetKey = CAN(API_KEYS_SELF);
    const auth = useSelector((state: RootState) => state.auth);

    const [saveEnabled, setSaveEnabled] = useState(false)
    const [saving, setSaving] = useState(false);
    const [name, setName] = useState('');
    const [type, setType] = useState<IDropdownOption | undefined>();
    const [resultKey, setResultKey] = useState<string | undefined>(undefined)
    const [error, setError] = useState<string | undefined>(undefined);
    const [addApiKey] = useAddApiKeyMutation();
    const typeOptions = useGetApiKeyTypesQuery();

    useEffect(() => {
        const typeKey = type === undefined && !canSetKeyType ? 'punchout' : type?.key?.toString();

        setSaveEnabled(!saving && error === undefined && typeKey !== undefined && name !== undefined && name !== '' && resultKey === undefined);
    }, [name, type, resultKey, saving, error]);

    const save = async () => {
        const typeKey = type === undefined && !canSetKeyType ? 'punchout' : type?.key?.toString();
        if (name !== undefined && name !== '' && typeKey !== undefined) {
            setSaving(true);
            await addApiKey({ request:{keyType: typeKey, name: name}, username: auth?.user?.username ?? "" }).then((result: any) => {
                if (result == null || (!result.data.success && !result.data.error))
                    setError('Error generating key');
                else if (result.error)
                    setError(result.data.error)
                else {
                    setResultKey(result.data.key)
                }
                setSaving(false);
            });

        }
    }

    const close = () => {
        setSaving(false);
        setName('');
        setType(undefined);
        setResultKey(undefined);
        setError(undefined);
        props.onClose();
    }
    return (
        <>
        {canSetKey && (

        <Panel
            isOpen={props.isOpen}
            headerText='Generate a new API key'
            onRenderFooterContent={() => (
                <PrimaryButton text='Save' disabled={!saveEnabled} onClick={() => {save()}} />
            )}
            isFooterAtBottom={true}
            onDismiss={() => {close()}}
        >
            <TextField
                label='Key Name'
                value={name}
                onChange={(e, d) => {setName(d ?? '')}}
                placeholder='Enter a descriptive name'
            />
            <Dropdown
                options={typeOptions.data?.keyTypes ?
                    Object.entries(typeOptions.data.keyTypes).map(([key, value]) => ({key: key, text: value}))
                    : []
                }
                selectedKey={ type ? type.key : undefined }
                label='Key Type'
                onChange={(e, d) => {setType(d)}}
                placeholder= 'Select an API key type'
                defaultSelectedKey={canSetKeyType ? undefined : 'punchout'}
                disabled={!canSetKeyType || typeOptions.isLoading}
            />
            <div style={{paddingTop: '2em', display: resultKey == null ? 'none' : 'inherit'}}>
                <MessageBar messageBarType={MessageBarType.info}>
                    Make sure you copy this somewhere secure. It will only be shown once.
                </MessageBar>
                <TextField label='API Key' value={resultKey} readOnly={true} />
            </div>
        </Panel>
        )}
        </>
    )
}

export default NewApiKeyDialog;