import React from "react";
import InstalledExtensionsTable from "../components/InstalledExtensions/InstalledExtensionsTable";
import AvailableExtensionsPanel from "../components/ExtensionInstallation/AvailableExtensionsPanel";
import {IExtension, IInstalledExtension} from "../models/Extension";
import {CommandBar, DefaultButton, Dialog, DialogFooter, ICommandBarItemProps} from "@fluentui/react";
import {
    useDeleteInstalledExtensionMutation,
    useDisableInstalledExtensionMutation,
    useEnableInstalledExtensionMutation, 
    useGetInstalledExtensionsQuery
} from "../store/Api";
import CAN, {ORG_EXTENSION_MODIFY} from "../permissions/ability";
import ExtensionDetailsPanel from "../components/ExtensionInstallation/ExtensionDetailsPanel";
import ExtensionFolioDetailsPanel from "../components/ExtensionFolios/ExtensionFolioDetailsPanel";

const ExtensionManagement: React.FC = () => {
    const [isNew, setIsNew] = React.useState(false);
    const [panelOpen, setPanelOpen] = React.useState(false);
    const [folioPanelOpen, setFolioPanelOpen] = React.useState(false);
    const [detailsExtension, setDetailsExtension] = React.useState<IInstalledExtension | undefined>(undefined)
    const [availablePanelOpen, setAvailablePanelOpen] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState<IInstalledExtension | undefined>(undefined);
    const [selectedExtension, setSelectedExtension] = React.useState<IExtension | undefined>();
    const [disableExtension] = useDisableInstalledExtensionMutation();
    const [enableExtension] = useEnableInstalledExtensionMutation();
    const [deleteExtension] = useDeleteInstalledExtensionMutation();
    const getInstalled = useGetInstalledExtensionsQuery();

    const openDetails = async (installed: IInstalledExtension) => {
        if(installed.extensionType == 'Folio'){
            setFolioPanelOpen(true)
        }
        else{
            setPanelOpen(true);
        }
        setDetailsExtension(installed);
    }

    const toggleInstalled = async (installed: IInstalledExtension) => {
        if (installed.enabled) {
            await disableExtension(installed.id);
        } else {
            await enableExtension(installed.id);
        }
    };

    const confirmDeleteInstalled = async (installed: IInstalledExtension) => {
        await deleteExtension(installed.id);
    };

    const commands: ICommandBarItemProps[] = [
        {
            key: 'install',
            text: 'Install',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setAvailablePanelOpen(true);
            }
        }
    ]

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconOnly: true,
            iconProps: { iconName: 'Refresh' },
            onClick: () => {
                getInstalled.refetch();
            }
        },
    ]

    return (
        CAN(ORG_EXTENSION_MODIFY) ?
        <div className="main-content">
            <h1>Extension Management</h1>
            <CommandBar items={commands} farItems={farCommands} />
            <InstalledExtensionsTable
                onDelete={(extension) => setConfirmDelete(extension)}
                installedExtensions={getInstalled.data?.filter(a => !a.isSubExtension) ?? []}
                isLoading={getInstalled.isFetching || getInstalled.isLoading}
                onAvailableOpen={() => setAvailablePanelOpen(true)}
                onDetailsSelect={(e) => openDetails(e)}
                onDisableToggle={(e) => toggleInstalled(e)}
            />
            <ExtensionDetailsPanel
                installedExtension={detailsExtension}
                extensionId={selectedExtension?.id ?? ""}
                isOpen={panelOpen}
                onClose={async() => {
                    setSelectedExtension(undefined);
                    setDetailsExtension(undefined);
                    setPanelOpen(false);
                    setIsNew(false)
                    getInstalled.refetch();
                }}
                isNew={isNew}
            />
            <ExtensionFolioDetailsPanel 
                isOpen={folioPanelOpen} 
                onClose={() => {
                    setSelectedExtension(undefined);
                    setDetailsExtension(undefined);
                    setFolioPanelOpen(false);
                    setIsNew(false)
                    getInstalled.refetch();
                }}
                installedExtension={detailsExtension}
                extensionId={selectedExtension?.id ?? ""}
                isNew={false}/>
            <AvailableExtensionsPanel
                isOpen={availablePanelOpen}
                onClose={() => {
                    setAvailablePanelOpen(false);
                    setSelectedExtension(undefined);
                }}
                onSelect={(e) => {
                    setAvailablePanelOpen(false);
                    if(e.category == "folio") {
                        setFolioPanelOpen(true);
                    }
                    else{
                        setPanelOpen(true);
                    }
                    setSelectedExtension(e);
                    setIsNew(true);
                }}
            />
            <Dialog
                hidden={confirmDelete == undefined}
                modalProps={{
                    isBlocking: true,
                    onDismiss:() => {
                        setConfirmDelete(undefined)
                    }
                }}
                dialogContentProps={{
                    title: 'Confirm Uninstall',
                    subText: `Are you sure you want to uninstall ${confirmDelete?.nickname ?? confirmDelete?.name}? This action cannot be undone.`,
                }}
                >
                <DialogFooter>
                    <DefaultButton
                        primary
                        text='Confirm'
                        onClick={() => {
                            if (confirmDelete)
                                confirmDeleteInstalled(confirmDelete).then(() => { setConfirmDelete(undefined) });
                        }}
                        />
                    <DefaultButton
                        text='Cancel'
                        onClick={() => {
                            setConfirmDelete(undefined)
                        }}
                        />
                </DialogFooter>
            </Dialog>
        </div> : <></>
    );
}

export default ExtensionManagement;
