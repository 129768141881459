import React from 'react';
import { useGetContactsQuery, useGetInstalledExtensionsQuery } from '../../store/Api';
import { CommandBar, DefaultButton, IColumn, ICommandBarItemProps, ShimmeredDetailsList, Stack, StackItem, TextField } from '@fluentui/react';
import { ButtonColumnGenerator, ColumnGenerator } from '../../logic/Columns/ColumnGenerator';
import StorefrontLabel from '../Storefront/StorefrontLabel';
import { IContact } from '../../models/Company';
import ContactDetailsModal from './ContactDetailsModal';
import { EXTENSION_TYPE_CUSTOMERIMPORT, IInstalledExtension } from '../../models/Extension';
import ContactImportPanel from './ContactImportPanel';

const ContactsManagementDisplay: React.FC = () => {
    const [query, setQuery] = React.useState('');
    const [tempQuery, setTempQuery] = React.useState('');
    const [page, setPage] = React.useState(1);
    const contacts = useGetContactsQuery({query: {query, page, pageSize: 100}, includeCompanyDetails: true}, {skip: (!query || query === '')})
    const extensions = useGetInstalledExtensionsQuery();
    const [selected, setSelected] = React.useState<IContact | undefined>(undefined);
    const [newContact, setNewContact] = React.useState(false);
    const [selectedImportExtension, setSelectedImportExtension] = React.useState<IInstalledExtension | undefined>(undefined);

    if (extensions.isLoading) {
        return <div>Loading...</div>
    }

    const columns: IColumn[] = [
        ColumnGenerator('Email', 'emailAddress', 200),
        ColumnGenerator('First Name', 'firstName', 100, 200),
        ColumnGenerator('Last Name', 'lastName', 100, 200),
        ColumnGenerator('Phone', 'phoneNumber', 100, 200),
        ColumnGenerator('Company', 'companyName', 100, 200),
        ColumnGenerator('Storefront', 'storefront', 100, 200, (item) => <StorefrontLabel storefrontId={item.storefront} fallbackStorefrontId={item.companyStorefront} />),
        ButtonColumnGenerator((item: IContact) => setSelected(item), 'Details'),
    ]

    const commands: ICommandBarItemProps[] = [{
        key: 'new',
        text: 'Add New Contact',
        iconProps: { iconName: 'Add' },
        onClick: () => {setNewContact(true);},
    }
    ]

    const importExtensions = extensions.data?.filter(e => e.extensionType === EXTENSION_TYPE_CUSTOMERIMPORT);

    if (importExtensions && importExtensions.length > 0) {
        commands.push({
            key: 'import',
            text: 'Import',
            iconProps: { iconName: 'Import' },
            subMenuProps: {
                items: importExtensions.map(e => ({
                    key: e.id,
                    text: e.name,
                    onClick: () => setSelectedImportExtension(e),
                }))
            }
        })
    }

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            iconOnly: true,
            onClick: () => {contacts.refetch()},
        }
    ]

    return (
        <div>
            <CommandBar
                items={commands}
                farItems={farCommands}
            />
            <Stack horizontal tokens={{childrenGap: '1em'}} grow>
                <StackItem grow>
                    <TextField
                        value={tempQuery}
                        onChange={(e, newValue) => setTempQuery(newValue ?? '')}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setQuery(tempQuery);
                                setPage(1);
                            }
                        }}
                    />
                </StackItem>
                <DefaultButton
                    primary
                    text='Search'
                    onClick={() => {
                        setQuery(tempQuery);
                        setPage(1);
                    }}
                />
            </Stack>
            <ShimmeredDetailsList
                enableShimmer={contacts.isFetching}
                items={contacts.data?.items ?? []}
                columns={columns}
            />
            <ContactDetailsModal
                isOpen={selected !== undefined || newContact}
                onDismiss={() => {
                    setSelected(undefined);
                    setNewContact(false);
                }}
                contact={selected}
            />
            {selectedImportExtension && <ContactImportPanel
                extension={selectedImportExtension}
                onImport={() => contacts.refetch()}
                onClose={() => setSelectedImportExtension(undefined)}
            />}
        </div>
    );
}

export default ContactsManagementDisplay;