import { TextField } from '@fluentui/react';
import React from 'react';

interface INumberFieldProps {
    value?: number;
    onChange: (value?: number) => void;
    label?: string;
    suffix?: string;
    prefix?: string;
    disabled?: boolean;
    placeholder?: string;
}

const NumberField: React.FC<INumberFieldProps> = (props) => {
    const [tempNumber, setTempNumber] = React.useState<string | undefined>(props.value?.toString());

    React.useEffect(() => {
        setTempNumber(props.value?.toString());
    }, [props.value]);

    const onBlur = () => {
        if (tempNumber === undefined || tempNumber === '') {
            props.onChange(undefined);
            return;
        }
        const number = parseInt(tempNumber);
        if (!isNaN(number)) {
            props.onChange(number);
        } else {
            setTempNumber(props.value?.toString());
        }
    }

    const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setTempNumber(newValue ?? '');
    }

    return <TextField
        disabled={props.disabled}
        label={props.label}
        value={tempNumber}
        onChange={onChange}
        onBlur={onBlur}
        suffix={props.suffix}
        prefix={props.prefix}
        placeholder={props.placeholder}
    />
}

export default NumberField;