import React from 'react';
import {ComboBox, Dropdown, IComboBoxOption, IDropdownOption} from "@fluentui/react";
import {clone} from "lodash";

interface ISchedulerProps {
    schedule: ISchedule;
    onChange: (schedule: ISchedule) => void;
}

export interface ISchedule {
    days: number[];
    hour: number;
    minute: number;
}

export function ConvertScheduleToCron(schedule: ISchedule): string {
    return `${schedule.minute} ${schedule.hour} * * ${schedule.days.sort().join(',')}`;
}

export function ConvertCronToSchedule(cron: string): ISchedule {
    const sections = cron.split(' ');
    const schedule: ISchedule = {
        days: [0,1,2,3,4,5,6],
        hour: 0,
        minute: 0,
    }
    if (sections[0] && parseInt(sections[0])) {
        schedule.minute = parseInt(sections[0]);
    }
    if (sections[1] && parseInt(sections[1])) {
        schedule.hour = parseInt(sections[1]);
    }
    if (sections[4]) {
        schedule.days = sections[4].split(',').map(d => parseInt(d));
    }
    return schedule;
}

const Scheduler: React.FC<ISchedulerProps> = (props) => {
    const days: IComboBoxOption[] = [
        {key: 0, text: 'Sunday'},
        {key: 1, text: 'Monday'},
        {key: 2, text: 'Tuesday'},
        {key: 3, text: 'Wednesday'},
        {key: 4, text: 'Thursday'},
        {key: 5, text: 'Friday'},
        {key: 6, text: 'Saturday'},
    ]

    const hour: IDropdownOption[] = [
        {key: 0, text: '00:00'},
        {key: 1, text: '01:00'},
        {key: 2, text: '02:00'},
        {key: 3, text: '03:00'},
        {key: 4, text: '04:00'},
        {key: 5, text: '05:00'},
        {key: 6, text: '06:00'},
        {key: 7, text: '07:00'},
        {key: 8, text: '08:00'},
        {key: 9, text: '09:00'},
        {key: 10, text: '10:00'},
        {key: 11, text: '11:00'},
        {key: 12, text: '12:00'},
        {key: 13, text: '13:00'},
        {key: 14, text: '14:00'},
        {key: 15, text: '15:00'},
        {key: 16, text: '16:00'},
        {key: 17, text: '17:00'},
        {key: 18, text: '18:00'},
        {key: 19, text: '19:00'},
        {key: 20, text: '20:00'},
        {key: 21, text: '21:00'},
        {key: 22, text: '22:00'},
        {key: 23, text: '23:00'},
    ];

    const minute: IDropdownOption[] = [
        {key: 0, text: ':00'},
        {key: 5, text: ':05'},
        {key: 10, text: ':10'},
        {key: 15, text: ':15'},
        {key: 20, text: ':20'},
        {key: 25, text: ':25'},
        {key: 30, text: ':30'},
        {key: 35, text: ':35'},
        {key: 40, text: ':40'},
        {key: 45, text: ':45'},
        {key: 50, text: ':50'},
        {key: 55, text: ':55'},
    ]

    return <>
        <ComboBox
            options={days}
            multiSelect
            multiSelectDelimiter=', '
            label='Days of Week'
            onChange={(e,d) => {
                const newSchedule = clone(props.schedule);
                if (d?.selected)
                    newSchedule.days = [...newSchedule.days, parseInt(d.key.toString())]
                else
                    newSchedule.days = newSchedule.days.filter(day => day.toString() !== d?.key.toString())
                props.onChange(newSchedule);
                // newSchedule.days = d;
            }}
            selectedKey={props.schedule.days}
        />
        <Dropdown
            options={hour}
            selectedKey={props.schedule.hour}
            label='Hour (UTC)'
            onChange={(e,d) => {
                props.onChange({...props.schedule, hour:parseInt( d?.key.toString() ?? '1' )})
            }}
            />
        <Dropdown
            options={minute}
            selectedKey={props.schedule.minute}
            label='Minute'
            onChange={(e,d) => {
                props.onChange({...props.schedule, minute:parseInt( d?.key.toString() ?? '0' )})
            }}
        />
    </>
}

export default Scheduler;