import { Dropdown, IDropdownOption } from '@fluentui/react';
import React from 'react';
import { CURRENCIES } from '../../models/Settings';

interface ICurrencySelectionProps {
    value?: string;
    onChange: (currency: string) => void;
    disabled?: boolean;
    allowInherit?: boolean;
}

const CurrencySelection: React.FC<ICurrencySelectionProps> = (props) => {
    const options: IDropdownOption[] = [
        ...(props.allowInherit ? [{key: '', text: 'Inherit'}] : []),
        ...CURRENCIES.map((currency) => ({key: currency, text: currency}))
    ]
    const change = (e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            props.onChange(option.key as string);
        }
    }

    return (
        <Dropdown
            label='Currency'
            selectedKey={props.value ?? ''}
            disabled={props.disabled}
            onChange={change}
            options={options}
        />
    );
}

export default CurrencySelection;