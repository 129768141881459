import React from 'react';
import { IRealTimeExtensionResponseItemPriceBreak } from '../../models/Extension';
import { DetailsList, IColumn } from '@fluentui/react';
import CurrencyFormat from "react-currency-format";
import { ColumnGenerator } from '../../logic/Columns/ColumnGenerator';

interface IPriceBreakTableProps {
    priceBreaks: IRealTimeExtensionResponseItemPriceBreak[];
    baseCost?: number;
    list?: boolean;
}

const PriceBreaksTable: React.FC<IPriceBreakTableProps> = (props) => {
    const columns: IColumn[] = [
        ColumnGenerator(
            'Quantity',
            'quantity',
            100,
            undefined,
            (item: IRealTimeExtensionResponseItemPriceBreak) =>
                <span style={{fontWeight: item.selected ? 'bold' : 'normal'}}>
                    {item.quantity}
                </span>
        ),
        ColumnGenerator(
            'Price',
            'price',
            100,
            undefined,
            (item: IRealTimeExtensionResponseItemPriceBreak) => (
                <CurrencyFormat
                    style={{fontWeight: item.selected ? 'bold' : 'normal'}}
                    value={item.price}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            )
        ),
    ]

    const sortedBreaks: IRealTimeExtensionResponseItemPriceBreak[] = [
            {quantity: 1, price: props.baseCost ?? 0},
            ...props.priceBreaks,
        ]
        .sort((a, b) => a.quantity - b.quantity)
        .filter((pb, index, self) => {
            return index === self.findIndex((t) => (
                t.price === pb.price
            )) || pb.selected;
        });

    if (props.list) {
        return (<table className={'pb-list'} style={{textAlign: 'right'}}>
            {sortedBreaks.map((pb) => (
                <tr style={{fontWeight: pb.selected ? 'bold' : 'normal'}} key={pb.quantity}>
                    <td>{pb.quantity}</td>
                    <td><CurrencyFormat
                        value={pb.price}
                        displayType="text"
                        thousandSeparator=","
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale={true}
                    /></td>
                </tr>
            ))}
        </table>)
    }

    return (
        <div>
            <DetailsList
                items={sortedBreaks}
                columns={columns}
                selectionMode={0}
            />
        </div>
    );
}

export default PriceBreaksTable;