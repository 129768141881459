import {IProcurementRequirementAnswer, IPurchaseRequirement} from "../../models/Order";
import React, {useEffect, useState} from "react";
import {
    Checkbox,
    Dropdown,
    IDropdownOption,
    TextField
} from "@fluentui/react";
import {cloneDeep} from "lodash";
import RequirementCustomLabel from "./RequirementCustomLabel";
import RequirementDropdownCustomLabel from "./RequirementDropdownCustomLabel";
import {usePutProcurementRequirementAnswerMutation} from "../../store/Api";

interface IProcurementRequirements {
    requirements: IPurchaseRequirement[]
    onChange?: (updatedRequirements: IPurchaseRequirement[]) => void
    source: string;
    showCategoryToggles?: boolean;
    initialCategoryToggles?: Map<string, boolean>;
    onVisCheck?: (isVisible?: boolean) => void;
    readonly?: boolean;
    onApplyToAll?: (requirement: IPurchaseRequirement) => void;
}

const ProcurementRequirements: React.FC<IProcurementRequirements> = props => {
    const [updateAnswer] = usePutProcurementRequirementAnswerMutation()
    const [currentRequirements, setCurrentRequirements] = useState<IPurchaseRequirement[]>([])
    const [requirementCategoryToggles, setRequirementCategoryToggles] = useState<Map<string, boolean> | undefined>(undefined)
    
    useEffect(() => {
        setCurrentRequirements(props.requirements)
    }, [props.requirements]);

    useEffect(() => {
        if(props.initialCategoryToggles && props.initialCategoryToggles.size > 0){
            setRequirementCategoryToggles(props.initialCategoryToggles)
        }
        else if(currentRequirements && (!requirementCategoryToggles || requirementCategoryToggles?.size == 0)) {
            const categoryToggles = new Map<string, boolean>()
            currentRequirements?.map((r) => {
                if(r.category != undefined && r.category != ''){
                    const predefinedToggle = (r.selectedValues?.[0] != undefined && r.selectedValues?.[0] != '');
                    
                    if(categoryToggles.get(r.category)) {
                        return
                    }
                    categoryToggles.set(r.category, predefinedToggle)
                }
            })
            setRequirementCategoryToggles(categoryToggles)
        }
    }, [currentRequirements, props.initialCategoryToggles]);
    
    
    const updatePurchaseRequirement = (initialPr: IPurchaseRequirement, value?: string) => {
        const procurementRequirement = cloneDeep(initialPr)
        procurementRequirement.selectedValues = [value ?? ""]
        const updatedRequirements = cloneDeep(currentRequirements)
        if (updatedRequirements) {
            const actualIndex = updatedRequirements.findIndex(a=> a.id == initialPr.id)
            updatedRequirements[actualIndex] = procurementRequirement
            setCurrentRequirements(updatedRequirements)
            if(props.onChange) {
                props.onChange(updatedRequirements)
            }

        }
    }
    
    const setProcurementRequirementGlobalAnswer = (value: string, source: string, id: string) =>{
        const answer: IProcurementRequirementAnswer = {value: value, identifier: id}
        console.log('setting answer:', answer)
        updateAnswer({source: source, request: answer})
    }
    
    const getCategorizedProcurementRequirements = () => {
        const toggleElements: JSX.Element[] = []
        const requirementElements: JSX.Element[] = []
        if(requirementCategoryToggles) {
            const keys = requirementCategoryToggles.keys()
            for(const key of keys){
                if(key === 'Freight') continue;
                const filteredRequirements = currentRequirements.filter(a => a.category == key)
                const check = <Checkbox key={`toggle ${key}`}
                    label={`Show ${key}`}
                    checked={requirementCategoryToggles.get(key)}
                    onChange={(e,v) => {
                        const currentToggles = cloneDeep(requirementCategoryToggles)
                        currentToggles.set(key, v ?? false)
                        setRequirementCategoryToggles(currentToggles)}}
                />
                if(requirementCategoryToggles.get(key)) {
                    
                    const header = <h4 key={`requirementtoggleheader ${key}`}>{key}</h4>
                    const body: JSX.Element[] = getProcurementRequirements(filteredRequirements)
                    if(props.onVisCheck) {
                        props.onVisCheck(body && body.length > 0);
                    }
                    const combined = <div className={"filtered-field-container"} style={{maxWidth: '810px'}}>{header}{body}</div>
                    requirementElements.push(combined)
                }
                if(props.showCategoryToggles){
                    toggleElements.push(check)
                }
            }
        }
        
        return [toggleElements, requirementElements]
    }
    const getProcurementRequirements = (currentRequirements: IPurchaseRequirement[]) => {
        if(!currentRequirements){return([<></>])}
        return currentRequirements?.map((pr, i) => {
            const isNa = currentRequirements?.[i]?.selectedValues?.[0] === 'N/A'
            const requirement = currentRequirements?.[i]
            const currentValue = requirement?.selectedValues?.[0]
            const previousValue = props?.requirements?.filter(a => a.id == requirement?.id)?.[0].selectedValues?.[0]
            if (pr.inputType === 'Input') {
                return (
                    <div key={`${pr.id ?? pr.label}-${i}-pr-input`} style={{maxWidth: '800px'}}>
                        <TextField required={pr.required}
                                   disabled={isNa || props.readonly}
                                   key={`procurement-${pr.label}`}
                                   label={pr.label}
                                   description={pr.description}
                                   width={'80%'}
                                   onRenderLabel={(a, b) => {
                                       return(<RequirementCustomLabel
                                           isRequired={pr.required}
                                           textField={a}
                                           toggleNa={() =>{
                                               updatePurchaseRequirement(pr, isNa ? "" : "N/A")
                                           }}
                                           saveResponse={() => {
                                               const requirement = currentRequirements?.[i]
                                               if(requirement && currentValue && props.source){
                                                   setProcurementRequirementGlobalAnswer(currentValue, props.source, requirement.id)
                                               }}
                                           }
                                           canApplyToAll={props.onApplyToAll != null}
                                           applyToAll={() => {
                                               const requirement = currentRequirements?.[i]
                                               if(!requirement) return
                                               if(!props.onApplyToAll) return;
                                               console.log('applying to all...', requirement)
                                               props.onApplyToAll(requirement)
                                           }}
                                           isNa={isNa}
                                           isSaveable={currentValue != previousValue}
                                           hidden={props.readonly == true}
                                       ></RequirementCustomLabel>)
                                   }}
                                   value={currentRequirements?.[i]?.selectedValues?.[0]}
                                   onChange={(a, b) => {
                                       updatePurchaseRequirement(pr, b)
                                   }}>
                        </TextField>
                    </div>
                )
            } else if (pr.inputType === 'Dropdown') {
                const roleOptions: IDropdownOption[] = [{key: 'N/A', text: 'Not Applicable'}]
                pr?.inputValues?.map(iv => roleOptions.push({key: iv, text: iv}))
                return (
                    <div key={`${pr.id}-pr-dropdown`} style={{maxWidth: '800px'}}>
                        <Dropdown
                            dropdownWidth={"auto"}
                            required={pr.required}
                            key={`procurement-${pr.label}`}
                            onRenderLabel={(a, b) => {
                                return(<RequirementDropdownCustomLabel
                                    isRequired={pr.required}
                                    dropdown={a}
                                    toggleNa={() =>{
                                        updatePurchaseRequirement(pr, isNa ? "" : "N/A")
                                    }}
                                    isNa={isNa}
                                    saveResponse={() => {
                                        if(requirement && currentValue && props.source){
                                            setProcurementRequirementGlobalAnswer(currentValue, props.source, requirement.id)}}
                                    }
                                    canApplyToAll={props.onApplyToAll != null}
                                    applyToAll={() => {
                                        const requirement = currentRequirements?.[i]
                                        if(!requirement) return
                                        if(!props.onApplyToAll) return;
                                        console.log('applying to all...', requirement)
                                        props.onApplyToAll(requirement)
                                    }}
                                    isSaveable={currentValue != previousValue}
                                    hidden={props.readonly == true}
                                ></RequirementDropdownCustomLabel>)
                            }}
                            disabled={isNa || props.readonly}
                            options={roleOptions}
                            label={pr?.label ?? ""}
                            selectedKey={currentRequirements?.[i]?.selectedValues?.[0]}
                            onChange={(a, b) => {
                                updatePurchaseRequirement(pr, b?.key.toString() ?? "")
                            }}>

                        </Dropdown>
                        <div className={"requirementdesciption"}>{pr.description}</div>
                    </div>
                )
            } else if (pr.inputType === 'TextOnly') {
                return (<div key={`procurement-${props.source}-${pr.label}`}>{pr.label}</div>)
            } else {
                return <></>
            }
        })
    }
    
    return(<div>{getCategorizedProcurementRequirements()}
        {getProcurementRequirements(currentRequirements.filter(a => a.category == undefined || a.category == ''))}</div>)
}
export default ProcurementRequirements;