import {
    Checkbox,
    DefaultButton,
    DetailsList,
    Dialog,
    DialogFooter,
    DialogType, IColumn,
    Icon,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    SelectionMode,
    Spinner,
    SpinnerSize,
    TextField,
} from "@fluentui/react";
import React, {useEffect, useRef, useState} from "react";
import {
    useCreateOrderMutation,
    useGetExtensionIdQuery,
    useGetFreightOptionsMutation,
    useGetIsFreightVendorQuery,
    useGetOrganizationsQuery,
    useLazyGetCompanyQuery,
    useLazyGetProcurementExtensionOrderRequirementsQuery,
} from "../../store/Api";
import {ICompany} from "../../models/Company";
import {IOrder, IProcurementItem, IPurchaseRequirement} from "../../models/Order";
import {renderOrderItemColumns} from "../../logic/Columns/ProcurementItemColumns";
import WorkspaceCompanyDetails from "../Workspace/WorkspaceCompanyDetails";
import {cloneDeep} from "lodash";
import Accordion from "../Common/Accordion";
import ProcurementRequirements from "./ProcurementRequirements";
import FreightDetails from "./FreightDetails";
import ProcurementItemDetailsModal from "./ProcurementItemDetailsModal";
import {IFreightOption} from "../../models/Extension";
import {IOrganization} from "../../models/Settings";
import ToggleField from "../Common/ToggleField";


interface IOrderModalProps {
    selectedItems?: IProcurementItem[];
    displayModal: boolean;
    closer: () => void;
    selectedCompany?: string;
    onChange: (companyName: string, companyId: string) => void;
    vendor: string;
}
const OrderModal: React.FC<IOrderModalProps> = props => {
    const [orderCompany, setOrderCompany] = useState<ICompany>();
    const [endUserCompany, setEndUserCompany] = useState<ICompany>();
    const getExtensionId = useGetExtensionIdQuery({source: props.vendor ?? ''}, {skip: !props.vendor || props.vendor == ''})
    const [getSelectedCompany] = useLazyGetCompanyQuery();
    const organizations = useGetOrganizationsQuery();
    const [getOrderOptions] = useLazyGetProcurementExtensionOrderRequirementsQuery()
    const [displayErrorModal, setDisplayErrorModal] = useState<boolean>()
    const [displayProcurementSuccess, setDisplayProcurementSuccess] = useState<boolean>()
    const [orderSuccessMessage, setOrderSuccessMessage] = useState<string>()
    const [warn, setWarn] = useState<boolean>();
    const [warningMessage, setWarningMessage] = useState<string>();
    const [orderError, setOrderError] = useState<string>();
    const [placeOrder, orderInfo] = useCreateOrderMutation();
    const [currentItems, setCurrentItems] = useState<IProcurementItem[]>([])
    const [dropship, setDropship] = useState<boolean>(false)
    const [allowBackorder, setAllowBackorder] = useState<boolean>(false)
    const [poNumber, setPoNumber] = useState<string>('')
    const [requestedCarrier, setRequestedCarrier] = useState<string>();
    const [requestedWarehouse, setRequestedWarehouse] = useState<string>();
    const [customerOrderNumber, setCustomerOrderNumber] = useState<string>('')
    const [orderNotes, setOrderNotes] = useState<string>();
    const [orderName, setOrderName] = useState<string>();
    const [itemRequirements, setItemRequirements] = useState<boolean>();
    const [orderOptions, setOrderOptions] = useState<IPurchaseRequirement[]>([])
    const [internalOrderNotes, setInternalOrderNotes] = useState<string>();
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>();
    const [detailsItem, setDetailsItem] = useState<IProcurementItem>();
    const [columns, setColumns] = useState<IColumn[]>([])
    const isFreightVendorQuery = useGetIsFreightVendorQuery({source: props.vendor}, {skip: props.vendor == undefined || props.vendor == ''})
    const [isFreightVendor, setIsFreightVendor] = useState<boolean>();
    const [getFreightDetails, loadingFreightDetails] = useGetFreightOptionsMutation();
    const [freightOptions, setFreightOptions] = useState<IFreightOption[]>([]);
    const [freightMessage, setFreightMessage] = useState<string>()
    const [categoryToggles, setCategoryToggles] = useState<Map<string, boolean>>()
    const [org, setOrg] = useState<IOrganization>()
    const [extensionId, setExtensionId] = useState<string | undefined>();
    const [showManualModal, setShowManualModal] = useState<boolean>();
    const [manualOrder, setManualOrder] = useState<boolean>();
    const refColumns = useRef(columns)
    
    useEffect(() => {
        if(organizations?.data?.[0]) {
            setOrg(organizations.data[0])
        }
    }, [organizations]);

    useEffect(() => {
        if(getExtensionId.data) {
            setExtensionId(getExtensionId.data)
        }
    }, [getExtensionId]);
    useEffect(() =>{
        if(props.selectedItems){
            updateItems(props.selectedItems)
        }
    },[props.selectedItems])
    useEffect(() => {
        const categoryToggles = new Map<string, boolean>()
        currentItems?.map(i => i.procurementRequirements?.map(pr => {{
            if(pr.category != undefined && pr.category != ''){
                const predefinedToggle = (pr.selectedValues?.[0] != undefined && pr.selectedValues?.[0] != '');
                
                categoryToggles.set(pr.category, predefinedToggle)
            }}}
        ))
        setCategoryToggles(categoryToggles)

        const tempColumns = renderOrderItemColumns(
            (item) => {
                const index = currentItems?.findIndex(a => a.id === item.id)
                if(index != undefined && index != -1 && currentItems){
                    const editingItems = cloneDeep(currentItems)
                    editingItems[index] = item
                    updateItems(editingItems)
                }
            }, false, sortColumn)
        updateColumns(tempColumns)
        const itemsWithRequirements =currentItems.filter(a => a.procurementRequirements && a.procurementRequirements.length > 0)
        if(itemsWithRequirements && itemsWithRequirements.length > 0){
            setItemRequirements(true)
        }
    }, [currentItems]);
    
    useEffect(() =>{
        if(props.vendor && currentItems){
            createOrder();
        }
    }, [props.vendor, currentItems])
    
    useEffect(() => {
        if(isFreightVendorQuery.data){
            setIsFreightVendor(isFreightVendorQuery.data)
        }
    }, [isFreightVendorQuery]);
    
/*    useEffect(() =>{
        if(props.selectedItems){
            updateItems(props.selectedItems)
            const itemsWithRequirements = props.selectedItems.filter(a => a.procurementRequirements && a.procurementRequirements.length > 0)
            if(itemsWithRequirements && itemsWithRequirements.length > 0){
                setItemRequirements(true)
            }
        }
    }, [props.selectedItems])*/

    useEffect(() => {
        if(props.vendor && props.vendor != ''){
            getOrderOptions({source: props.vendor}).unwrap().then(a => {
                setOrderOptions(a)
            })
        }
    }, [props.vendor]);
    useEffect(() => {
        if(currentItems){
            validateItems()
        }
    }, [currentItems] )

    useEffect(() => {
        if(org && !orderCompany){
            const activeOrg = org;
            const company: ICompany = {
                companyName: activeOrg.name,
                contact: {
                    firstName: activeOrg.contact1,
                    lastName: activeOrg.contact2,
                    emailAddress: activeOrg.contactEmail,
                    phoneNumber: activeOrg.contactPhone
                },
                address: {
                    address1: activeOrg.address1,
                    address2: activeOrg.address2,
                    address3: '',
                    city: activeOrg.city,
                    stateTerritory: activeOrg.stateProvince,
                    postalCodeZip: activeOrg.postalCode,
                    country: activeOrg.country,
                    website: ''
                }
            }
            setOrderCompany(company)
        }
    }, [org, orderCompany]);
    
    useEffect(() => {
        if(props.selectedCompany){
            getSelectedCompany(props.selectedCompany).unwrap().then(a => setEndUserCompany(a))
        }
    }, [props.selectedCompany] )

    const updateItems = (items: IProcurementItem[]) => {
        setCurrentItems(items)
    }
    const updateColumns = (items: IColumn[]) => {
        setColumns(items)
        refColumns.current = items
    }
    const createOrder = () => {
        const order: IOrder = {
            name: orderName ?? '',
            active: true,
            source: props.vendor,
            purchaseOrderNumber: poNumber,
            orderCompany: orderCompany,
            endUserDetails: endUserCompany,
            items: currentItems ?? [],
            dropship: dropship,
            allowBackorder: allowBackorder,
            requestedCarrier: requestedCarrier,
            orderNotes: orderNotes,
            internalNotes: internalOrderNotes,
            endUserOrderNumber: customerOrderNumber,
            requestedWarehouse: requestedWarehouse
        }
        return order;
            
    }
    function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }
    const sortColumn = (column: IColumn): void => {
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const newSortedItems = copyAndSort(currentItems, column.fieldName!, isSortedDescending);

        refColumns.current.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });
        updateItems(newSortedItems)
    };

    const validateItems = () => {
        setWarn(undefined)
        setWarningMessage(undefined)
        if (currentItems) {
            for (let i = 0; i < currentItems.length; i++) {
                const item = currentItems[i]
                const unfinishedQuestions = item.procurementRequirements?.filter(a => a.required && (!a.selectedValues || a.selectedValues.length < 1))
                if (unfinishedQuestions && unfinishedQuestions.length > 0) {
                    const modifiedValidationMessage = `\r\nItem ${item.name} has unfinished requirement questions`
                    setWarningMessage(modifiedValidationMessage)
                    break;
                }
            }
        }
    }
    
    const validateItem = (item: IProcurementItem) => {
        const unfinishedQuestions = item.procurementRequirements?.filter(a => a.required && (!a.selectedValues || a.selectedValues.length < 1))
        return !(unfinishedQuestions && unfinishedQuestions.length > 0);
    }
    
    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        navigation: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        footerInner: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        content: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        commands: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
    });

    const close = () => {
        setOrderCompany(undefined)
        setRequestedCarrier('')
        setOrderOptions([])
        setFreightMessage(undefined)
        setShowManualModal(false)
        setShowDetailsModal(false)
        setDisplayErrorModal(false)
        setFreightOptions([])
        setOrderName('')
        setCustomerOrderNumber('')
        setPoNumber('')
        setAllowBackorder(false)
        setDropship(false)
        props.closer()
        
    }
    
    const getProcurementItemQuestions = (item: IProcurementItem, index: number) => {
        if(!item.procurementRequirements || item.procurementRequirements.length === 0) return <></>
        const isValid = validateItem(item);
        const itemHeader = (<span style={{display: 'inline-flex'}}>
            {!isValid && <Icon iconName={'Warning'} style={{height: '20px', width: '20px', fontSize: '16px', paddingTop: '30px', paddingLeft: '20px', color: 'red'}}/>}
            <h3>{item.name} - {item.manufacturerPartNumber}</h3>
        </span>)
        return(<div key={`orderitemreq-${item.id}`}><Accordion titleComponent={itemHeader} beginExpanded={!isValid || index === 0} useChevron={true}>
            <div style={{paddingLeft: '30px'}}>
                <ProcurementRequirements 
                    requirements={item.procurementRequirements ?? []}
                    source={item.source}
                    showCategoryToggles={false}
                    initialCategoryToggles={categoryToggles}
                    onApplyToAll={(requirement) => {
                        console.log('inner apply to all')
                        const items = cloneDeep(currentItems)
                        items.forEach((a) => {
                            const requirementToUpdate = a.procurementRequirements?.filter((a) => a.id = requirement.id)?.[0]
                            console.log('requirement to update', requirementToUpdate)
                            if(!requirementToUpdate) return;
                            Object.assign(requirementToUpdate, requirement)
                        })
                        console.log(items)
                        updateItems(items)
                    }}
                    onChange={(requirements) => {
                        if(currentItems) {
                            const items = cloneDeep(currentItems)
                            const index = items?.findIndex(a => a.id === item.id)
                            const product = cloneDeep(items[index])
                            if (product) {
                                product.procurementRequirements = requirements
                                items[index] = product
                                updateItems(items)
                            }
                        }
                    }}
                />
            </div>
            
        </Accordion></div>)
    }
    
    const getProcurementItemElements = () => {
        return(props?.selectedItems?.map((si, index) => getProcurementItemQuestions(si, index)))
    }
    
    const getCategoryToggleElements = () => {
        const elements = []
        console.log('category toggles:', categoryToggles)
        if(categoryToggles) {
            const keys = categoryToggles.keys()
            for (const key of keys) {
                const check = <Checkbox key={`toggle ${key}`}
                                        label={`Show ${key}`}
                                        checked={categoryToggles.get(key)}
                                        onChange={(e, v) => {
                                            const currentToggles = cloneDeep(categoryToggles)
                                            currentToggles.set(key, v ?? false)
                                            setCategoryToggles(currentToggles)
                                        }}
                />
                elements.push(check)
            }
        }
        
        return elements
    }
    
    const submitOrder = () => {
        placeOrder({order: createOrder(), source: props.vendor, manualOrder: manualOrder }).unwrap().then(a => {
            if(a.success) {
                setDisplayProcurementSuccess(true)
                setOrderSuccessMessage(`Order created with ID: ${a.message}`)
            }
            else{
                setOrderError(a.message)
                setDisplayErrorModal(true)
            }
        })
        
    }
    
    return (
        <div key={`ordermodal-${props.vendor}`}>
            <Panel
                onOuterClick={() => {console.log("swallowing modal close event")}}
                isOpen={props.displayModal}
                onDismiss={close}
                className={"order-panel"}
                isFooterAtBottom={true}
                type={PanelType.large}
                title={"Order"}
                styles={{
                    root: {
                        zIndex: 10,
                        marginTop: '48px'
                    },
                    content:contentStyles.content,
                    commands:contentStyles.commands,
                    scrollableContent: contentStyles.scrollableContent,
                    navigation: contentStyles.navigation,
                    footerInner: contentStyles.footerInner,
                }}
                onRenderFooter={() => (
                    <PrimaryButton
                        style={{width: '100%', height: '60px'}}
                        disabled={warn}
                        onClick={() => {
                            if(!extensionId || extensionId == ''){
                                setShowManualModal(true)
                            }
                            else {
                                submitOrder()
                            }
                        }}
                        text={"Process Order"}
                    />)}
            >
                {orderInfo.isLoading ? <Spinner size={SpinnerSize.large} label={"Submitting Order"}/> : <div>
                    <h1>Order With {props.vendor}</h1>
                    {warningMessage &&
                        <MessageBar messageBarType={MessageBarType.blocked}>{warningMessage}</MessageBar>}
                    <div style={{display: 'inline-flex', backgroundColor: "rgba(0, 0, 0, .1)", width: '100%'}}>
                        <div className={'ac-details-field'}>
                            <ToggleField
                                label={"Dropship"}
                                checked={dropship}
                                onChange={(event, value) => {
                                    setDropship(value ?? false)
                                }}
                            />
                            <ToggleField
                                label={"Allow Backorder"}
                                checked={allowBackorder}
                                onChange={(event, value) => {
                                    setAllowBackorder(value ?? false)
                                }}
                            />
                        </div>
                        <div className={'ac-details-field'}>
                            <TextField
                                label={"Order Name"}
                                value={orderName}
                                onChange={(e, v) => setOrderName(v ?? "")}
                            />
                        </div>
                        <div className={'ac-details-field'}>
                            <TextField
                                label={"PO Number"}
                                value={poNumber}
                                onChange={(e, v) => setPoNumber(v ?? "")}
                            />
                        </div>
                        <div className={'ac-details-field'}>
                            <TextField
                                label={"Customer Order Number"}
                                value={customerOrderNumber}
                                onChange={(e, v) => setCustomerOrderNumber(v ?? "")}
                            />
                        </div>
                    </div>
                    <Pivot overflowBehavior='menu' styles={{itemContainer: {marginTop: '1em'}}}>
                        <PivotItem headerText='Order Company'>
                            <WorkspaceCompanyDetails
                                currentCompany={orderCompany}
                                showParsedAddress={true}
                            />
                        </PivotItem>
                        <PivotItem headerText='Customer Company'>
                            <WorkspaceCompanyDetails
                                currentCompany={endUserCompany}
                                showParsedAddress={true}
                            />
                        </PivotItem>
                        <PivotItem headerText='Freight Options'>
                            <FreightDetails
                                canEstimateFreight={isFreightVendor}
                                freightMessage={freightMessage}
                                vendor={props.vendor}
                                freightOptions={freightOptions}
                                onSelectFreightOption={(option) => {
                                    setRequestedCarrier(option)
                                }}
                                selectedFreightOption={requestedCarrier}
                                selectedWarehouseOption={requestedWarehouse}
                                onSelectWarehouseOption={(option) => {
                                    setRequestedWarehouse(option)
                                }}
                                loadingFreightEstimate={loadingFreightDetails.isLoading}
                                onStartFreightEstimate={() => {
                                    setFreightMessage('')
                                    getFreightDetails({order: createOrder(), source: props.vendor}).unwrap().then(a => {
                                        if (!a.success) {
                                            setFreightMessage(a.error)
                                        }
                                        if (a.freightOptions && a.freightOptions.length > 0) {
                                            setFreightOptions(a.freightOptions)
                                        }
                                    })
                                }}
                            />

                        </PivotItem>
                        {orderOptions && orderOptions.length > 0 &&
                            <PivotItem headerText={`${props.vendor} Order Options`}>
                                <ProcurementRequirements
                                    requirements={orderOptions ?? []}
                                    source={props.vendor}
                                    onChange={(requirements) => {
                                        setOrderOptions(requirements)
                                    }}
                                    showCategoryToggles={true}
                                />
                            </PivotItem>}
                        <PivotItem headerText='Order Notes'>
                            <div>
                                <TextField
                                    label={'Order Notes'}
                                    multiline={true}
                                    rows={5}
                                    value={orderNotes}
                                    onChange={(e, d) => {
                                        setOrderNotes(d);
                                    }}
                                />
                                <TextField
                                    label={'Internal Order Notes'}
                                    multiline={true}
                                    rows={5}
                                    value={internalOrderNotes}
                                    onChange={(e, d) => {
                                        setInternalOrderNotes(d);
                                    }}
                                />
                            </div>
                        </PivotItem>
                        <PivotItem headerText={'Items'}>
                            <DetailsList
                                items={currentItems ?? []}
                                columns={refColumns.current}
                                selectionMode={SelectionMode.none}/>
                        </PivotItem>

                        {itemRequirements &&
                            <PivotItem headerText={`${props.vendor} Item Options`}
                                       itemIcon={(warningMessage ? 'Warning' : undefined)}>
                                {getCategoryToggleElements()}
                                {getProcurementItemElements()}
                            </PivotItem>
                        }
                    </Pivot>
                </div>}
            </Panel>

            <Dialog
                hidden={!displayErrorModal}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Procurement Error',
                    subText: (orderError ?? ""),
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <DefaultButton
                        primary
                        text='OK'
                        onClick={() => {
                            setDisplayErrorModal(false)
                        }}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={!showManualModal}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Confirm Manual Order',
                    subText: `No vendor extension available for ${props.vendor}. \r\nCreate a manual order?`,
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <PrimaryButton
                        primary
                        text='Yes, Create Manual Order'
                        onClick={() => {
                            setManualOrder(true)
                            submitOrder()
                            setShowManualModal(false)
                        }}
                    />
                    <DefaultButton
                        text='Cancel'
                        onClick={() => {
                            setShowManualModal(false)
                        }}
                    />
                </DialogFooter>
            </Dialog>

            <Dialog
                hidden={!displayProcurementSuccess}
                onDismiss={() => {close()}}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: (manualOrder ? `Manual Order Created for ${props.vendor}` : `Order Submitted to ${props.vendor}`),
                    subText: (orderSuccessMessage ?? ""),
                }}
                modalProps={{
                    isBlocking: true,
                }}
            >
                <DialogFooter>
                    <DefaultButton
                        primary
                        text='OK'
                        onClick={() => {
                            setDisplayProcurementSuccess(false)
                            close()
                        }}
                    />
                </DialogFooter>
            </Dialog>
            {detailsItem && <ProcurementItemDetailsModal
                product={detailsItem}
                open={showDetailsModal === true}
                onClose={() => {
                    setDetailsItem(undefined)
                    setShowDetailsModal(false)}}/>}
        </div>

    );
}
export default OrderModal;