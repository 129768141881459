import React, { useState, useEffect } from "react";
import {IProductReference} from "../../models/Extension";
import {useGetNotesQuery, usePutNotesMutation} from "../../store/Api";
import {PrimaryButton, TextField} from "@fluentui/react";
import CAN, {EDIT_ITEM} from "../../permissions/ability";

interface INotesDetailsProps {
    product: IProductReference;
}

export const NotesDetails: React.FC<INotesDetailsProps> = (props) => {
    const getNotes = useGetNotesQuery(props.product);
    const [saveNotes] = usePutNotesMutation();
    const [saving, setSaving] = useState(false);
    const [unsaved, setUnsaved] = useState<boolean>(false);
    const [noteValue, setNoteValue] = useState<string | undefined>();
    const [companyNoteValue, setCompanyNoteValue] = useState<string | undefined>();
    const canEditItem = CAN(EDIT_ITEM);

    useEffect(() => {
        if (!getNotes.isFetching && !getNotes.isLoading) {
            setNoteValue(getNotes.data?.userNote.text ?? '');
            setCompanyNoteValue(getNotes.data?.companyNote.text ?? '');
        }
    }, [getNotes.data, getNotes.isFetching, getNotes.isLoading])
    if (getNotes.isLoading || getNotes.isFetching) return <p>Loading</p>;
    return <>
        {canEditItem ? 
            <TextField
                multiline={true}
                label='Company Notes'
                rows={5}
                value={companyNoteValue}
                onChange={(e,d) => {
                    setCompanyNoteValue(d);
                    setUnsaved(true);
                }}
            /> : 
            <>
                {companyNoteValue && companyNoteValue != "" ?
                    <div>
                        <h4>Company Notes</h4>
                        <div>{companyNoteValue}</div>
                    </div>
                : <></>}
            </>
        }
        <TextField
            multiline={true}
            label='Product Notes'
            rows={10}
            value={noteValue}
            onChange={(e,d) => {
                setNoteValue(d);
                setUnsaved(true);
            }}
        />
        <PrimaryButton
            style={{marginTop: '1em'}}
            text='Save'
            disabled={!unsaved || saving}
            onClick={() => {
                setSaving(true);
                saveNotes({catalog: props.product.catalog, id: props.product.id, userNote: noteValue, companyNote: companyNoteValue})
                    .then(() => {
                        setSaving(false);
                        setUnsaved(false);
                    });
            }}
            />
    </>
};
