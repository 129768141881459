import React from 'react';
import {
    ICustomerRequestAnalysisResponse,
    IProductSearchSuggestion,
    IProductSuggestion
} from "../../models/MachineLearning";
import {
    useAddProductToWorkspaceMutation, useClearCustomerAnalysisRatingMutation,
    useGetCustomerAnalysisQuery, useThumbsDownCustomerAnalysisMutation,
    useThumbsUpCustomerAnalysisMutation
} from "../../store/Api";
import {
    CheckboxVisibility, CommandBar,
    DetailsList, ICommandBarItemProps,
    MessageBar,
    MessageBarType, PrimaryButton,
    ProgressIndicator,
    Selection,
    SelectionMode,
} from "@fluentui/react";
import {Markdown} from "react-showdown";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";

interface ICustomerRequestDisplayProps {
    id: string;
    workspaceId?: string;
    onClose: () => void;
    onSelectedChange: (selected: {[key: number]: IProductSuggestion | undefined}) => void;
}

const CustomerRequestDisplay: React.FC<ICustomerRequestDisplayProps> = (props) => {
    const [thumbsUp] = useThumbsUpCustomerAnalysisMutation();
    const [thumbsDown] = useThumbsDownCustomerAnalysisMutation();
    const [clearRating] = useClearCustomerAnalysisRatingMutation();
    const [rating, setRating] = React.useState<string | undefined>(undefined);
    const [shouldStopPolling, setShouldStopPolling] = React.useState(false);
    const [selected, setSelected] = React.useState<{[key: number]: IProductSuggestion | undefined}>({});
    const results = useGetCustomerAnalysisQuery(
        {id: props.id},
        { pollingInterval: 5000, skip: shouldStopPolling, refetchOnMountOrArgChange: true }
    );

    React.useEffect(() => {
        setRating(results.data?.rating);
        // If the score is at least 10 higher than the next highest score, select it
        if (results.data?.productSearchSuggestions) {
            const newSelected: { [key: number]: IProductSuggestion | undefined } = {};
            results.data.productSearchSuggestions.forEach((s, i) => {
                if (s.products == null || s.products.length === 0) return;
                if (s.products.length === 1) {
                    newSelected[i] = s.products[0];
                    return;
                }
                const first = s.products[0];
                const second = s.products[1];
                if (first.score && second.score && first.score - 10 > second.score) {
                    newSelected[i] = first;
                }
            });
            setSelected(newSelected);
        }
    }, [results.data]);

    React.useEffect(() => {
        if(results.data?.status === 'Completed' || results.data?.status === 'Failed') {
            setShouldStopPolling(true);
        }
    }, [results.data?.status])

    const commands: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: 'Back',
            iconProps: {iconName: 'Back'},
            onClick: props.onClose,
            iconOnly: true
        },
        {
            key: 'title',
            text: 'Customer Request Analysis',
        },
    ]

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'thumbsUp',
            text: 'Thumbs Up',
            iconProps: {iconName: rating === 'ThumbsUp' ? 'LikeSolid' : 'Like'},
            onClick: () => {
                if(rating !== 'ThumbsUp') {
                    thumbsUp({id: props.id});
                    setRating('ThumbsUp');
                } else {
                    clearRating({id: props.id});
                    setRating(undefined);
                }
            },
            iconOnly: true
        },
        {
            key: 'thumbsDown',
            text: 'Thumbs Down',
            iconProps: {iconName: rating === 'ThumbsDown' ? 'DislikeSolid' : 'Dislike'},
            onClick: () => {
                if(rating !== 'ThumbsDown') {
                    thumbsDown({id: props.id});
                    setRating('ThumbsDown');
                } else {
                    clearRating({id: props.id});
                    setRating(undefined);
                }
            },
            iconOnly: true
        },
    ]

    const {prefix, suffix} = splitMarkdownTable(results.data?.response ?? '');

    return <>
        <CommandBar items={commands} farItems={farCommands} />
        {results.data?.name && <h3>{results.data?.name}</h3>}
        {results.data?.request && <><h4>Request</h4><p>{results.data.request}</p></>}
        {results.data?.status === 'FAILED' && <MessageBar messageBarType={MessageBarType.error}>{results.data.error}</MessageBar>}
        {results.data?.status !== 'Completed' && <ProgressIndicator label={results.data?.status ?? 'Pending'} />}
        {results.data?.response == null && <p>No data found</p>}
        {results.data?.response && <>
            <h4>Response</h4>
            <Markdown markdown={prefix} />
            {results.data.productSearchSuggestions?.map((s, i) => (<ProductSuggestionsTable
                suggestion={s}
                selected={selected[i]}
                onSelect={(ref) => {
                    setSelected((prev) => {
                        prev[i] = ref;
                        props.onSelectedChange(prev);
                        return prev;
                    })
                }}
                key={i}
            />))}
            <Markdown
                markdown={suffix}
                options={{tables: true, emoji: true}}
            />
        </>}
    </>
}

const splitMarkdownTable = (markdown: string) => {
    let prefix = '';
    let suffix = '';
    let foundTable = false;
    let endTable = false
    for (const line of markdown.split('\n')) {
        if (!endTable && line.trim().startsWith('|')) {
            foundTable = true;
        } else {
            if (foundTable) {
                endTable = true
                suffix += line + '\n';
            } else {
                prefix += line + '\n';
            }
        }
    }
    return {prefix, suffix};
}

interface IProductSuggestionsTableProps {
    suggestion: IProductSearchSuggestion
    selected: IProductSuggestion | undefined;
    onSelect: (ref: IProductSuggestion | undefined) => void;
}

const ProductSuggestionsTable: React.FC<IProductSuggestionsTableProps> = (props) => {
    const selection = new Selection({
        onSelectionChanged: () => {
            if (!props.onSelect) return;
            const item = selection.getSelection()[0] as IProductSuggestion | undefined;
            props.onSelect(item);
        },
    });

    const columns = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('MFP', 'manufacturerPartNumber', 100, 150),
        ColumnGenerator('Manufacturer', 'manufacturer', 100, 200),
    ]
    if (props.suggestion.quantity == 0) {
        return null
    }

    return <>
        <h3>{props.suggestion.keywords} - {props.suggestion.quantity}</h3>
        {props.suggestion.products && <DetailsList
            items={props.suggestion.products ?? []}
            columns={columns}
            selectionMode={SelectionMode.single}
            selection={selection}
            checkboxVisibility={CheckboxVisibility.always}
        />}
    </>
}

export default CustomerRequestDisplay;