import React, {useState} from 'react';
import {IExtensionLog, IInstalledExtension} from "../../models/Extension";
import {
    CommandBar,
    IColumn, ICommandBarItemProps,
    Icon,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {useGetInstalledExtensionLogsQuery, useRunImportExtensionMutation} from "../../store/Api";

interface IExtensionLogDisplayProps {
    extension: IInstalledExtension;
}

const ExtensionLogDisplay: React.FC<IExtensionLogDisplayProps> = (props) => {
    const getLogs = useGetInstalledExtensionLogsQuery(props.extension.id);
    const [runImport] = useRunImportExtensionMutation();
    const [ran, setRan] = useState<boolean>(false);

    const columns: IColumn[] = [
        ColumnGenerator('Success', 'success', 50, 50,
            (item: IExtensionLog) => <Icon iconName={item.success ? 'Accept' : 'Error'} /> ),
        ColumnGenerator('Action', 'action', 100, 100),
        ColumnGenerator('Timestamp', 'timestamp', 150, 150),
        ColumnGenerator('User', 'username', 200, 200),
        {
            ...ColumnGenerator('Message', 'message', 100, undefined,
            (item: IExtensionLog) => <span>{item.message} {item.errorMessage}</span>),
            isMultiline: true,
        },
        ColumnGenerator('Product Count', 'productCount', 100, 100),
        ColumnGenerator('Seconds Taken', 'timeTaken', 100, 100),
    ]

    switch (props.extension?.extensionType) {
        case 'import':
            columns.push(ColumnGenerator('Created', 'productsCreated'));
            columns.push(ColumnGenerator('Updated', 'productsUpdated'));
            columns.push(ColumnGenerator('Skipped', 'productsSkipped'));
            break;
    }

    const commands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {getLogs.refetch()}
        }
    ]

    if (props.extension.extensionType === 'import') {
        commands.push({
            key: 'run',
            text: 'Run Import',
            iconProps: { iconName: 'Play' },
            disabled: ran,
            onClick: () => {
                runImport({extensionId: props.extension.id});
                setRan(true);
            }
        });
    }


    return (<>
        <CommandBar items={commands} />
        {getLogs.isFetching && <ProgressIndicator label={'Loading Logs'} />}
        {getLogs.error && <MessageBar messageBarType={MessageBarType.error}>There was an error loading</MessageBar> }
        <ShimmeredDetailsList selectionMode={SelectionMode.none} items={getLogs.data?.items ?? []} columns={columns} enableShimmer={getLogs.isFetching} />
    </>);
}

export default ExtensionLogDisplay;