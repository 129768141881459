import React, {useEffect, useState} from 'react';
import {IFilledFilters, IFilter, IFilterMetadata} from "../../models/Search";
import {cloneDeep} from "lodash";
import {createFilter, mergeFilters} from "../../logic/Search";
import {TextField, DefaultButton} from '@fluentui/react';
import {Stack} from '@fluentui/react/lib/Stack';
import Divider from "../Common/Divider";
import Accordion from "../Common/Accordion";


interface IProductFiltersProps {
    filters: IFilterMetadata;
    searchFilters: IFilledFilters;
    onChange: (filters: IFilledFilters) => void;
}

const ProductFilters: React.FC<IProductFiltersProps> = (props) => {
    const [filters, setFilters] = useState<IFilter[]>([]);

    useEffect(() => {
        setFilters(mergeFilters(createFilter(props.filters), props.searchFilters));
    }, [props.filters]);

    const clearRanges = () => {
        props.onChange({});
    };
    return filters && filters.length > 0
        ? (
            <div>
                <Divider title="Filter Ranges"/>
                {filters.map((filter) => (
                    <div key={filter.name}>
                        <Accordion title={filter.name}>
                            <Stack tokens={{childrenGap: '0.5em'}} horizontal>
                                <TextField
                                    placeholder={filter.filterMin ? Math.floor(filter.filterMin).toString() : ''}
                                    defaultValue={props.searchFilters[filter.name]?.min?.toFixed(2) ?? ''}
                                    onChange={(e, d) => {
                                        const newFilled = cloneDeep(props.searchFilters);
                                        if (d == undefined || d === '') {
                                            if (newFilled[filter.name] != undefined) {
                                                if (!newFilled[filter.name]?.max) delete newFilled[filter.name];
                                                else newFilled[filter.name].min = undefined;
                                            }
                                        } else {
                                            if (newFilled[filter.name] == undefined) newFilled[filter.name] = {}
                                            newFilled[filter.name].min = parseFloat(d);
                                        }
                                        props.onChange(newFilled);
                                    }}
                                />
                                <TextField
                                    placeholder={filter.filterMax ? Math.ceil(filter.filterMax).toString() : ''}
                                    defaultValue={props.searchFilters[filter.name]?.max?.toFixed(2) ?? ''}
                                    onChange={(e, d) => {
                                        const newFilled = cloneDeep(props.searchFilters);
                                        if (d == undefined || d === '') {
                                            if (newFilled[filter.name] != undefined) {
                                                if (!newFilled[filter.name]?.min) delete newFilled[filter.name];
                                                else newFilled[filter.name].max = undefined;
                                            }
                                        } else {
                                            if (newFilled[filter.name] == undefined) newFilled[filter.name] = {}
                                            newFilled[filter.name].max = parseFloat(d);
                                        }
                                        props.onChange(newFilled);
                                    }}
                                />
                            </Stack>
                        </Accordion>
                    </div>
                ))}
                <DefaultButton className='filterbutton' text='Clear Ranges' onClick={clearRanges}/>
            </div>
        )
        : null
}

export default ProductFilters;