import React, {useEffect, useState} from 'react';
import {ISavedSearchStorefrontDetails} from '../../models/Search';
import {Checkbox, Icon, TooltipHost} from '@fluentui/react';
import NumberField from '../Inputs/NumberField';
import MarkupTypeSelection from '../Storefront/MarkupTypeSelection';
import { IStorefrontSettings } from '../../models/Storefront';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {useLazyGetSavedSearchOnStorefrontCountQuery} from "../../store/Api";


interface ISavedSearchStorefrontEntryDetailsProps {
    storefront: IStorefrontSettings;
    storefrontEntry?: ISavedSearchStorefrontDetails;
    included?: boolean;
    onIncludedChange?: (included: boolean) => void;
    onStorefrontEntryChange: (storefrontEntry: ISavedSearchStorefrontDetails) => void;
    reachedTotalStorefrontLimit?: boolean;
}

const DEFAULT_STOREFRONT_ENTRY: ISavedSearchStorefrontDetails = {
    storefrontId: '',
    markup: 0,
    markupType: '',
}

const SavedSearchStorefrontEntryDetails: React.FC<ISavedSearchStorefrontEntryDetailsProps> = (props) => {
    const markupChange = (markup?:number) => {
        if (props.storefront.id == null) return;
        if (props.storefrontEntry == null) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefront.id, markup});
        else props.onStorefrontEntryChange({...props.storefrontEntry, markup});
    }
    const markupTypeChange = (markupType:string) => {
        if (props.storefront.id == null) return;
        if (props.storefrontEntry == null) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefront.id, markupType});
        else props.onStorefrontEntryChange({...props.storefrontEntry, markupType});
    }
    const onIncludedChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
        if (props.onIncludedChange) props.onIncludedChange(checked ?? false);
    }
    
    const onMinimumPriceChange = (minimumPrice?:number) => {
        if (props.storefront.id == null) return;
        if (props.storefrontEntry == null) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefront.id, minimumPrice});
        else props.onStorefrontEntryChange({...props.storefrontEntry, minimumPrice});
    }
    const onMinimumCostChange = (minimumCost?:number) => {
        if (props.storefront.id == null) return;
        if (props.storefrontEntry == null) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefrontId: props.storefront.id, minimumCost: minimumCost});
        else props.onStorefrontEntryChange({...props.storefrontEntry, minimumCost});
    }
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const [perStorefrontCount] = useLazyGetSavedSearchOnStorefrontCountQuery();

    const [reachedLimit, setReachedLimit] = useState<boolean>(false);
    useEffect(() => {
        if(props.reachedTotalStorefrontLimit){
            setReachedLimit(true);
        }
        else if(org?.storefrontSavedSearchLimit && props.storefront.id) {
            perStorefrontCount({storefrontId: props.storefront.id}).unwrap().then((resp => {
                setReachedLimit(resp.count > org.storefrontSavedSearchLimit);
            }));
        }
        else{
            setReachedLimit(false);
        }
    }, []);
    return (
        <tr>
            <td>{reachedLimit &&
                <TooltipHost
                    content={'Storefront has reached a maximum number of products and/or saved searches.'}><Icon
                    iconName={'Warning'} color={'red'}/></TooltipHost>
            }
            </td>
            <td style={{minWidth: '200px'}}>{props.storefront.name}</td>
            <td>
                <Checkbox
                    checked={props.included ?? false}
                    onChange={onIncludedChange}
                />
            </td>
            <td>
                <NumberField
                    value={props.storefrontEntry?.markup}
                    onChange={markupChange}
                    disabled={reachedLimit}
                />
            </td>
            <td>
                <MarkupTypeSelection
                    value={props.storefrontEntry?.markupType}
                    onChange={markupTypeChange}
                    disabled={reachedLimit}
                />
            </td>
            <td>
                <NumberField
                    value={props.storefrontEntry?.minimumPrice}
                    onChange={onMinimumPriceChange}
                    prefix='$'
                    disabled={reachedLimit}
                />
            </td>
            <td>
                <NumberField
                    value={props.storefrontEntry?.minimumCost}
                    onChange={onMinimumCostChange}
                    prefix='$'
                    disabled={reachedLimit}
                />
            </td>
        </tr>
    );
}

export default SavedSearchStorefrontEntryDetails;