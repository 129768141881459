import React from "react";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import CurrencyFormat from "react-currency-format";
import {calculateCost} from "../../logic/Product";
import {IWorkspaceItem} from "../../models/Workspace";
import { Icon, TooltipHost, Text } from "@fluentui/react";

interface ICostDisplayProps {
    source?: string;
    product?: INormalizedProduct;
    cost?: number;
    minTotal?: number;
    maxTotal?: number;
    wsItem?: IWorkspaceItem;
}

export const CostDisplay: React.FC<ICostDisplayProps> = (props) => {
    function formatCurrency(amount: number) {
        if (amount == null) return null;
        return <CurrencyFormat
            value={amount}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={true}
        />;
    }

    const calculateSelectedCost = () : {cost?: number, message?: string, icon?: React.ReactElement } | undefined => {
        if (props.cost) return {
            cost: props.cost
        }
        if (props.wsItem?.details?.overrideCost) return {
            cost: props.wsItem.details.overrideCost,
            message: "Override"
        }
        if (props.wsItem?.details?.discountedCost) return {
            cost: props.wsItem.details.discountedCost,
            icon: <Icon iconName='Coupon' />,
            message: "Discounted (Quantity Price Break)"
        }
        if (props.wsItem?.adhoc?.cost) return {
            cost: props.wsItem.adhoc.cost,
            message: "AdHoc"
        }
        if (props.product) {
            return {
                cost: calculateCost(props.product, props.source)
            }
        }
        return undefined;
    }

    const renderRange = () => {
        if (props.product) {
            if (props.product.lowestCost && props.product.highestCost) {
                return <>
                    {formatCurrency(props.product.lowestCost)} - {formatCurrency(props.product.highestCost)}
                </>;
            }
        }
        if (props.minTotal && props.maxTotal) {
            return <>
                {formatCurrency(props.minTotal)} - {formatCurrency(props.maxTotal)}
            </>;
        }
        return undefined;
    }

    const selectedCost = calculateSelectedCost();
    const range = renderRange();

    if ((selectedCost?.cost === undefined || selectedCost.cost === 0) && range === undefined) return <span>N/A</span>;

    return <span>
        {selectedCost?.cost && <>
        <Text variant='mediumPlus' style={{fontWeight: 'bold'}} >
            {formatCurrency(selectedCost.cost)}
        </Text>
            {selectedCost.icon && <span style={{marginLeft: '5px'}}>
                <TooltipHost content={selectedCost.message}>
                    {selectedCost.icon}
                </TooltipHost>
            </span>}
        </>}
        {range && <span style={{fontStyle: 'italic'}}>
            <br />
            {range}
        </span>}
    </span>
};