import React from 'react';
import {useGetMetadataQuery} from "../../store/Api";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {Dropdown} from "@fluentui/react";
import RadioButton from '../Common/RadioButton';

interface IVendorSelectionProps {
    inStock: string[];
    inCatalog: string[];
    onChange?: (inStock: string[], inCatalog: string[]) => void;
}

const VendorSelection: React.FC<IVendorSelectionProps> = (props) => {
    const selectedCatalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const metadata = useGetMetadataQuery(selectedCatalog?.name ?? '');

    const renderDropdownList = () => (
        <div style={{padding: '1em'}}>
            <table className='vendor-selection-table'>
                <thead>
                    <tr>
                        <th>Vendor</th>
                        <th>No Filter</th>
                        <th>In Catalog</th>
                        <th>In Stock</th>
                    </tr>
                </thead>
                <tbody>
                    {metadata.data?.vendors.map((vendor) => (
                        <tr key={vendor}>
                            <td>{vendor}</td>
                            <td style={{textAlign:'center'}}>
                                <RadioButton
                                    checked={!props.inStock.includes(vendor) && !props.inCatalog.includes(vendor)}
                                    onClick={() => {
                                        const newInStock = props.inStock.filter(i => i !== vendor);
                                        const newInCatalog = props.inCatalog.filter(i => i !== vendor);
                                        props.onChange?.(newInStock, newInCatalog);
                                    }}
                                    />
                            </td>
                            <td style={{textAlign:'center'}}>
                                <RadioButton
                                    checked={props.inCatalog.includes(vendor)}
                                    onClick={() => {
                                        const newInStock = props.inStock.filter(i => i !== vendor);
                                        const newInCatalog = props.inCatalog.filter(i => i !== vendor);
                                        newInCatalog.push(vendor);
                                        props.onChange?.(newInStock, newInCatalog);
                                    }}
                                    />
                            </td>
                            <td style={{textAlign:'center'}}>
                                <RadioButton
                                    checked={props.inStock.includes(vendor)}
                                    onClick={() => {
                                        const newInStock = props.inStock.filter(i => i !== vendor);
                                        const newInCatalog = props.inCatalog.filter(i => i !== vendor);
                                        newInStock.push(vendor);
                                        props.onChange?.(newInStock, newInCatalog);
                                    }}
                                    />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

    const phCat = props.inCatalog.length > 0 ? `Catalog: ${props.inCatalog.join(', ')}` : '';
    const phStock = props.inStock.length > 0 ? `Stock: ${props.inStock.join(', ')}` : '';
    const ph = phCat !== '' && phStock !== '' ? `${phCat} | ${phStock}` : `${phCat}${phStock}`;

    return (
        <Dropdown
            label='Vendor Filters'
            options={[]}
            selectedKey={null}
            onRenderList={renderDropdownList}
            placeholder={ph}
        />
    )
}

export default VendorSelection;