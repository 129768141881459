import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthSliceState {
    loading: boolean;
    user?: any;
    userName?: string;
    token: string | undefined;
    accessToken: string | undefined;
    isAuthed: boolean;
    sessionKey: string | undefined;
    permissions: string[],
    searchSession: string | undefined;
    embeddedSession: boolean;
}

const intialState: IAuthSliceState = {
    loading: true,
    user: null,
    userName: undefined,
    token: undefined,
    accessToken: undefined,
    isAuthed: false,
    sessionKey: undefined,
    permissions: [],
    searchSession: undefined,
    embeddedSession: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState: intialState,
    reducers: {
        setSessionAuth: (state, action: PayloadAction<string>) => {
            state.sessionKey = action.payload;
            state.isAuthed = true;
        },
        setEmbeddedAuth: (state, action: PayloadAction<string>) => {
            state.sessionKey = action.payload;
            state.isAuthed = true;
            state.embeddedSession = true;
        },
        setAuth: (state, action: PayloadAction<any>) => {
            state.loading = false;
            state.accessToken = action.payload?.accessToken;
            state.token = action.payload?.token;
            if (action.payload?.token) {
                try {
                    state.user = action.payload?.idToken?.claims;
                    state.userName = action.payload?.user;
                    state.isAuthed = true;
                } catch (error) {
                    console.error(error);
                    state.user = null;
                    state.userName = undefined;
                }
            } else {
                state.user = null;
                state.userName = undefined;

            }
        },
        setSearchSession: (state, action: PayloadAction<string>) => {
            state.searchSession = action.payload
        }
    }
});

export const { setAuth, setSessionAuth, setSearchSession, setEmbeddedAuth } = authSlice.actions;

export default authSlice.reducer;