import { Callout, ColorPicker, Label, TextField } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React from 'react';

interface ICompactColorPickerProps {
    label?: string;
    placeholder?: string;
    color: string;
    onChange: (color: string) => void;
}

const CompactColorPicker: React.FC<ICompactColorPickerProps> = (props) => {
    const [isCalloutVisible, setIsCalloutVisible] = React.useState(false);
    const fieldId = useId('field');
    return (
        <>
            <Label>{props.label}</Label>
            <TextField id={fieldId} value={props.color} readOnly onClick={() => setIsCalloutVisible(!isCalloutVisible)} placeholder={props.placeholder} />
            {isCalloutVisible && (
                <Callout
                    target={`#${fieldId}`}
                    onDismiss={() => setIsCalloutVisible(false)}
                    setInitialFocus
                >
                    <ColorPicker
                        color={props.color}
                        onChange={(e, color) => {
                            props.onChange(color.str);
                        }}
                        alphaType='none'
                        />
                </Callout>
            )}
        </>
    );
}

export default CompactColorPicker;