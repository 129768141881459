import React from 'react';
import {useGetItemDetailsQuery} from "../../store/Api";
import DetailsModal from "./DetailsModal";

interface IProductIdModalProps {
    catalog: string;
    productId: string;
    onDismiss: (id: string | undefined) => void;
}

const ProductIdModal: React.FC<IProductIdModalProps> = (props) => {
    const details = useGetItemDetailsQuery({id: props.productId, catalog: props.catalog});

    const dismiss = () => {
        props.onDismiss(undefined);
    }

    if (details.data) return (
        <DetailsModal product={details.data} open={true} onClose={dismiss} />
    )

    return null;
}

export default ProductIdModal;