import React from 'react';
import {IExtensionFile, IInstalledExtension} from "../../models/Extension";
import {
    useGetExtensionLibraryQuery, usePostDisableExtensionLibraryFileMutation, usePostEnableExtensionLibraryFileMutation,
} from "../../store/Api";
import {
    CommandBar, DefaultButton, IColumn,
    ICommandBarItemProps, MessageBar,
    MessageBarType,
    ShimmeredDetailsList
} from "@fluentui/react";
import ExtensionLibraryUploadModal from "./ExtensionLibraryUploadModal";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";

interface IExtensionLibraryTableProps {
    installedExtension: IInstalledExtension;
}

const ExtensionLibraryTable: React.FC<IExtensionLibraryTableProps> = (props) => {
    const library = useGetExtensionLibraryQuery({extensionId: props.installedExtension.id, enabledOnly: false});
    const [disableFile] = usePostDisableExtensionLibraryFileMutation();
    const [enableFile] = usePostEnableExtensionLibraryFileMutation();
    const [addModalOpen, setAddModalOpen] = React.useState(false);

    const commands: ICommandBarItemProps[] = [
        {
            key: 'Add',
            text: 'Add',
            iconProps: {iconName: 'Add'},
            onClick: () => {
                setAddModalOpen(true)
            }
        }
    ]

    const rightCommands: ICommandBarItemProps[] = [
        {
            key: 'Refresh',
            text: 'Refresh',
            iconProps: {iconName: 'Refresh'},
            iconOnly: true,
            onClick: () => {
                library.refetch();
            }
        }
    ]

    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('Version', 'version', 75, 75),
        ColumnGenerator('Enabled', 'enabled', 75, 75),
        ColumnGenerator('Actions', 'actions', 75, 75, (doc: IExtensionFile) => (<>
            <DefaultButton text={doc.enabled ? 'Disable' : 'Enable'} primary={!doc.enabled} onClick={() => {
                if (doc.enabled) disableFile({extensionId: props.installedExtension.id, fileId: doc.id});
                else enableFile({extensionId: props.installedExtension.id, fileId: doc.id});
            }} />
        </>)),
    ]

    return <>
        <CommandBar items={commands} farItems={rightCommands}/>
        {!library.isFetching && !library.data && <>
            <MessageBar messageBarType={MessageBarType.info}>
                It looks like you don't have any files in this extension library. Click the "Add" button above to add
                files.
            </MessageBar>
        </>}
        {library.isFetching || library.data && <ShimmeredDetailsList
            columns={columns}
            items={library.data?.items ?? []}
            enableShimmer={library.isFetching}
        />}
        <ExtensionLibraryUploadModal
            installedExtension={props.installedExtension}
            addModalOpen={addModalOpen}
            onClose={() => setAddModalOpen(false)}
            />
    </>
}

export default ExtensionLibraryTable;