import React, {useEffect, useState} from 'react';
import {
    useEnableFolioSubExtensionMutation,
    useGetExtensionFolioQuery, useValidateExtensionFolioMutation
} from '../../store/Api';
import {IInstalledExtensionFolio} from "../../models/Extension";
import {
    ExtensionFolioInstallForm,
    SubExtensionInstallForm
} from "../ExtensionInstallation/ExtensionDataForm";
import {MessageBar, MessageBarType, Spinner} from "@fluentui/react";
import {IActionResponse} from "../../dal/BaseDAL";

interface IGlobalStorefrontOpportunityExtensionForm {
    subExtensionFolioCompositeId?: string;
}

const GlobalStorefrontOpportunityExtensionForm: React.FC<IGlobalStorefrontOpportunityExtensionForm> = (props) => {
    const [folioId, setFolioId] = useState<string>();
    const [subExtensionsId, setSubExtensionId]  = useState<string>();
    const extensionFolioApi = useGetExtensionFolioQuery(folioId ?? '', {skip: (!folioId || folioId == '')})
    const validFolio = useValidateExtensionFolioMutation();
    const [enableFolio] = useEnableFolioSubExtensionMutation()
    const [currentFolio, setCurrentFolio] = useState<IInstalledExtensionFolio | undefined>();
    const [showOpportunityDetails, setShowOpportunityDetails] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [success, setSuccess] = useState<string | undefined>();
    const [detailsError, setDetailsError] = useState<string | undefined>();
    const [detailsSuccess, setDetailsSuccess] = useState<string | undefined>();
    const [validating, setValidating] = useState<boolean>(false);
    const [updatingCommon, setUpdatingCommon] = useState<boolean>(false);
    const [updatingDetails, setUpdatingDetails] = useState<boolean>(false);

    useEffect(() => {
        const folioIdSplits = props.subExtensionFolioCompositeId?.split('_');
        if(!folioIdSplits || folioIdSplits.length != 3) {
            return;
        }
        setFolioId(folioIdSplits[1]);
        setSubExtensionId(folioIdSplits[2]);
        setError(undefined)
        setSuccess(undefined)
        setShowOpportunityDetails(false)
    }, [props.subExtensionFolioCompositeId]);
    
    useEffect(() => { 
        if(extensionFolioApi.data) {
            setCurrentFolio((current) => extensionFolioApi.data);
        }
    }, [extensionFolioApi.data]);

    useEffect(() => {
        if(currentFolio?.sourceExtensionFolio?.id && subExtensionsId) {
            if(!currentFolio.enabledSubExtensions?.includes(subExtensionsId)) {
                enableFolio({id: currentFolio.sourceExtensionFolio.id, subExtension: subExtensionsId})

                setCurrentFolio((existing) => ({...existing, enabledSubExtensions: [...existing?.enabledSubExtensions ?? [], subExtensionsId]}));
            }
        }
    }, [currentFolio, subExtensionsId]);


    const getOnValidate = (response: IActionResponse) => {
            if (response.success) {
                setError(undefined);
                setSuccess('Extension settings validated');
                setShowOpportunityDetails(true)
            } else {
                setError(response.message ?? 'Unable to validate extension settings');
                setSuccess(undefined)
                setShowOpportunityDetails(false)
            }
            
            setValidating(false)
        };

    return (
        <>         
            {currentFolio && subExtensionsId &&
            <>


            <h3>Basic Integration Configuration</h3>
                <div style={{height: '2em'}}>
                    {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar> }
                    {success && <MessageBar messageBarType={MessageBarType.success}>{success}</MessageBar> }
                </div>
            <ExtensionFolioInstallForm
                onSetError={(error) => {
                    setError(error)
                }}
                onValidateStart={() => {setValidating(true)}}
                onValidateComplete={getOnValidate}
                folio={currentFolio}
                onUpdateStart={() => setUpdatingCommon(true)}
                onUpdateStop={() => setUpdatingCommon(false)}

            />
            <div style={{height: '2em'}}>
                {validating && <Spinner label='Validating' labelPosition='right' style={{display: 'inline-flex'}}/> }
                {updatingCommon && <Spinner label='Saving' labelPosition='right' style={{display: 'inline-flex'}}/> }
            </div>

            {showOpportunityDetails &&
            <div>
                <div style={{height: '2em'}}>
                    {detailsError && <MessageBar messageBarType={MessageBarType.error}>{detailsError}</MessageBar>}
                    {detailsSuccess && <MessageBar messageBarType={MessageBarType.success}>{detailsSuccess}</MessageBar>}
                </div>
                <SubExtensionInstallForm
                    key={`storefront-opp-subextension-install-${subExtensionsId}`}
                    folio={currentFolio}
                    subExtension={subExtensionsId}
                    onUpdateStart={() => setUpdatingDetails(true)}
                    onUpdateStop={() => setUpdatingDetails(false)}
                    onSetError={(error) => {
                        setDetailsError(error);
                        setDetailsSuccess(undefined);
                    }}
                />
                <div style={{height: '2em'}}>
                    {updatingDetails && <Spinner label='Saving' labelPosition='right' style={{display: 'inline-flex'}}/>}
                </div>
            </div>}</>
            }
        </>
    );
}

export default GlobalStorefrontOpportunityExtensionForm;