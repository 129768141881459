import React, {useEffect} from "react";
import "./App.css";
import AuthHandler from "./components/Authentication/AuthHandler";
import {Switch } from "react-router-dom";
import { Router } from "./Router";
import { initializeIcons, ThemeProvider, createTheme } from "@fluentui/react";
import PermissionsHandler from "./components/Authentication/PermissionsHandler";
import {useMsal} from "@azure/msal-react";
import {AccountInfo, EventType} from "@azure/msal-browser";
import {compareIssuingPolicy} from "./logic/claimUtils";
import {b2cPolicies, msalConfig} from "./authConfig";

const acTheme = createTheme({
    palette: {
      themePrimary: '#789c2f',
      themeLighterAlt: '#f9fbf4',
      themeLighter: '#e2e9cb',
      themeLight: '#e2e9cb',
      themeTertiary: '#a8c371',
      themeSecondary: '#e2e9cb',
      themeDarkAlt: '#6b8c2a',
      themeDark: '#5b7623',
      themeDarker: '#43571a',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#cfdcb5',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
      red: '#db2828',
      redDark: '#d01919',
    }});


export const App: React.FC = () => {
    initializeIcons();
    const { instance } = useMsal();
    useEffect(() => {
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                (event.payload as AccountInfo)
            ) {
                const accountInfo = (event.payload as AccountInfo);
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (accountInfo.idTokenClaims && (compareIssuingPolicy(accountInfo.idTokenClaims, b2cPolicies.names.editProfile))) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account) =>
                                account.idTokenClaims?.oid === accountInfo?.idTokenClaims?.oid &&
                                account.idTokenClaims?.sub === accountInfo?.idTokenClaims?.sub &&
                                account.idTokenClaims &&
                                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
                        );

                    const signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }
                

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (accountInfo.idTokenClaims && (compareIssuingPolicy(accountInfo.idTokenClaims, b2cPolicies.names.forgotPassword) 
                    || compareIssuingPolicy(accountInfo.idTokenClaims, b2cPolicies.names.changePassword))) {
                    instance.logoutRedirect();
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error?.message.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes: ['openid', 'profile', 'email', 'offline_access', msalConfig.auth.clientId],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }

                if (event.error?.message.includes('AADB2C90263')) {
                    const changePasswordRequest = {
                        authority: b2cPolicies.authorities.changePassword.authority,
                        scopes: ['openid', 'profile', 'email', 'offline_access', msalConfig.auth.clientId],
                    };
                    instance.loginRedirect(changePasswordRequest);
                }

                if (event.error?.message.includes('AADB2C90077')) {
                    const loginRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes: ['openid', 'profile', 'email', 'offline_access', msalConfig.auth.clientId],
                    };
                    instance.loginRedirect(loginRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [instance]);
    return (
        <>
            <ThemeProvider theme={acTheme}>
                <AuthHandler>
                    <Switch>
                        <Router />
                    </Switch>
                    <PermissionsHandler />
                </AuthHandler>
            </ThemeProvider>
        </>
    );
};

export default App;
