import React from 'react';
import Card from "./Card";
import {useGetTasksQuery} from "../../store/Api";
import { Stack } from '@fluentui/react';
import {NotificationDisplay} from "../NotificationsPanel/NotificationsPanel";

const NotificationsCard: React.FC = () => {
    const getTasks = useGetTasksQuery({includeDismissed: true}, {refetchOnMountOrArgChange: 30});

    return <Card title='Notifications'>
        <Stack tokens={{childrenGap: '1em', padding: '1em 0'}}>
            {getTasks.data?.map(task => <NotificationDisplay showDismissed task={task} key={task.id}/>)}
        </Stack>
    </Card>
}

export default NotificationsCard;