import React, {useEffect, useState} from "react";
import RichTextEditor, {EditorValue} from "react-rte";
import {cloneDeep} from "lodash";
import {IProductBase} from "../../models/Search";

interface IBasicDetailsProps {
    product: IProductBase;
    onChange?: (product: IProductBase) => void;
    editMode?: boolean;
    id?: string;
}

const BasicDetails: React.FC<IBasicDetailsProps> = (props) => {
    const [id, setId] = useState<string | undefined>();
    const [value, setValue] = useState<EditorValue | undefined>();

    useEffect(() => {
        if (id !== props.id) {
            setId(props.id);
            setValue(RichTextEditor.createValueFromString(props.product.description || '', 'html'));
        }
    }, [props.id, id]);

    return props.editMode
        ? <RichTextEditor
            value={value ?? RichTextEditor.createEmptyValue()}
            rootStyle={{minHeight: '40em'}}
            onChange={(value) => {
                if (!props.onChange) return;
                setValue(value);
                const newProduct = cloneDeep(props.product);
                newProduct.description = value.toString('html');
                props.onChange(newProduct);
            }}
        />
        : <div dangerouslySetInnerHTML={{ __html: props.product.description || '' }} />
};

export default BasicDetails;