import { LogLevel } from "@azure/msal-browser";

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

const SIGN_UP_SIGN_IN = window.config.signUpSignInPolicy ?? 'B2C_1_AC_SI';
const FORGOT_PASSWORD = window.config.forgotPasswordPolicy ?? 'B2C_1A_DISPLAYCONTROL_SENDGRID_PASSWORDRESET';
const EDIT_PROFILE = window.config.editProfilePolicy ?? 'B2C_1_editProfile';
const CHANGE_PASSWORD = window.config.changePasswordPolicy ?? 'B2C_1A_PASSWORDCHANGE';

export const b2cPolicies = {
    names: {
        signUpSignIn: SIGN_UP_SIGN_IN,
        forgotPassword: FORGOT_PASSWORD,
        editProfile: EDIT_PROFILE,
        changePassword: CHANGE_PASSWORD, 
    },
    authorities: {
        signUpSignIn: {
            authority: `https://adaptivecatalogauthn.b2clogin.com/adaptivecatalogauthn.onmicrosoft.com/${SIGN_UP_SIGN_IN}`,
        },
        forgotPassword: {
            authority: `https://adaptivecatalogauthn.b2clogin.com/adaptivecatalogauthn.onmicrosoft.com/${FORGOT_PASSWORD}`,
        },
        editProfile: {
            authority: `https://adaptivecatalogauthn.b2clogin.com/adaptivecatalogauthn.onmicrosoft.com/${EDIT_PROFILE}`,
        },
        changePassword: {
            authority: `https://adaptivecatalogauthn.b2clogin.com/adaptivecatalogauthn.onmicrosoft.com/${CHANGE_PASSWORD}`,
        }
    },
    authorityDomain: 'adaptivecatalogauthn.b2clogin.com',
};


/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: window.config.clientId ?? '6f16d544-c191-4a26-b5a2-96181e1744ec', // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: `${window.location.origin}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 * We're not really using OIDC for granular scopes right now, since we do our own permission per user figuring on the backend, 
 * but this is where we would set that up.
 */
export const protectedResources = {
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ['openid', 'profile', 'email', 'offline_access', msalConfig.auth.clientId],
};