import React, {useEffect, useState} from "react";
import {
    Image, ImageFit,
    MessageBar, MessageBarType,
    Panel,
    PanelType, Pivot, PivotItem, Stack, Text,
} from "@fluentui/react";
import {useGetExtensionFolioQuery, usePutExtensionFolioMutation} from "../../store/Api";

import {IActionResponse} from "../../dal/BaseDAL";
import {IInstalledExtension, ISubExtension} from "../../models/Extension";
import ExtensionLogDisplay from "../InstalledExtensions/ExtensionLogDisplay";
import {
    ExtensionFolioInstallButtons,
    ExtensionFolioInstallForm,
    SubExtensionInstallForm
} from "../ExtensionInstallation/ExtensionDataForm";
import {LabeledCheckboxGroup} from "../CheckboxGroup";
import {cloneDeep, forEach} from "lodash";
import {PLACEHOLDER_IMAGE} from "../../Constants";

interface IExtensionEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    installedExtension?: IInstalledExtension;
    extensionId: string;
    isNew: boolean;
}


const ExtensionFolioDetailsPanel: React.FC<IExtensionEditModalProps> = (props) => {
    const extensionFolio = useGetExtensionFolioQuery(props.installedExtension?.source ?? props.extensionId, {skip: !(props.installedExtension?.source ?? props.extensionId)});
    const [selectedPivot, setSelectedPivot] = useState<string>('edit');
    const [validationResponse, setValidationResponse] = useState<IActionResponse | undefined>(undefined);
    const [enabledSubExtensions, setEnabledSubExtensions] = useState<string[] | undefined>(undefined);
    const [subExtensionData, setSubExtensionData] = useState<{ [key: string]: ISubExtension }>();
    const [subExtensionLabelMap, setSubExtensionLabelMap] = useState<{[key: string]: string}>();
    const [subExtensionDescriptions, setSubExtensionDescriptions] = useState<{[key: string]: string}>();
    const close = () => {
        setValidationResponse(undefined);
        props.onClose();
    }

    useEffect(() => {
        if(extensionFolio?.data?.sourceExtensionFolio?.subExtensions) {
            setEnabledSubExtensions(extensionFolio.data?.enabledSubExtensions ?? []);
            const subs = extensionFolio.data?.sourceExtensionFolio?.subExtensions;
            setSubExtensionData(subs ?? []);
            const map: {[key: string]: string} = {}
            const descriptions: {[key: string]: string} = {}
            forEach(subs, (subExtension, key) => {
                map[subExtension.name] = key;
                if (subExtension.description) descriptions[subExtension.identifier] = subExtension.description;
            });
            setSubExtensionLabelMap(map)
            setSubExtensionDescriptions(descriptions)
        }
    }, [extensionFolio.data?.sourceExtensionFolio?.subExtensions]);
    const [folioUpdater] = usePutExtensionFolioMutation()
    
    const updateExtensionFolio = (subExtensions: string[]) => {
        const existingFolio = cloneDeep(extensionFolio.data)
        if(existingFolio?.sourceExtensionFolio?.id) {
            existingFolio.enabledSubExtensions = subExtensions;
            folioUpdater({extensionId: existingFolio.sourceExtensionFolio.id, request: existingFolio})
        }
    }
    const renderEnabledSubExtensions = (subExtensionData: { [key: string]: ISubExtension } , enabledSubExtensions: string[]) => {
        const subExtensions : ISubExtension[] = [];
        enabledSubExtensions?.forEach((key) => {
            const subExtension = subExtensionData[key];
            if(subExtension) {
                subExtensions.push(subExtension);

                return subExtension;
            }
        })
        if(!subExtensions || subExtensions.length === 0) return <></>;
        return subExtensions
            .filter(subExtension => enabledSubExtensions.includes(subExtension.identifier))
            .map((subExtension, index) => {return(
                <SubExtensionInstallForm
                    key={`subextension-install-${subExtension.identifier}`}
                    folio={extensionFolio.data}
                    subExtension={subExtension.identifier}
                    label={subExtension.name}
                />
            )});
    };
    return (
        <Panel
            onOuterClick={() => {
                console.log("swallowing modal close event")
            }}
            isOpen={props.isOpen}
            headerText={props.isNew ? 'Install Extension' : 'Extension Folio Details'}
            isFooterAtBottom={true}
            type={PanelType.medium}
            onDismiss={close}
            onRenderHeader={() => (<div style={{flexGrow: 1, paddingLeft: '25px', background: "white"}}>
                <Stack horizontal disableShrink>
                    <Stack.Item align='auto'>
                        <Image imageFit={ImageFit.contain}
                               src={extensionFolio?.data?.sourceExtensionFolio?.image ?? PLACEHOLDER_IMAGE}
                               height={150}
                               width={150}
                               className={'product-image'}
                               title={"Extension Image"}/>
                    </Stack.Item>
                    <Stack.Item align='center' style={{paddingLeft: '1em'}}>
                        <Text variant={'xLarge'} block>
                            {extensionFolio?.data?.sourceExtensionFolio?.name}
                        </Text>
                    </Stack.Item>
                </Stack>
            </div>)} 
            onRenderFooter={() => (<>
                {selectedPivot === 'edit' && 
                    <ExtensionFolioInstallButtons 
                        extensionFolio={extensionFolio.data}
                        onValidate={(response) => {
                            setValidationResponse(response)
                        }} 
                        onClose={close}
                    />}
            </>)}
        >

            {validationResponse && !validationResponse.success &&
                <MessageBar messageBarType={MessageBarType.error}>
                    {validationResponse.message}
                </MessageBar>
            }
            {validationResponse?.success && 
                <MessageBar messageBarType={MessageBarType.success} style={{paddingBottom: "10px"}}>
                    {validationResponse.message ?? 'Extension settings validated'}
                </MessageBar>}
            {extensionFolio.data === undefined ? extensionFolio.isFetching ? <>Loading</> :
                <p>An error occurred loading the extension folio. Please try again.</p>
                : (
                    <Pivot
                        selectedKey={selectedPivot}
                        onLinkClick={(item) => {
                            if (item?.props?.itemKey) setSelectedPivot(item.props.itemKey)
                        }}
                        overflowBehavior='menu'
                        styles={{itemContainer: '1em'}}
                    >

                        <PivotItem headerText='Extension Folio Settings' itemKey={'edit'}>
                            <h3 style={{marginBottom: 0, marginTop: '1em'}}>Common Settings</h3>
                            
                            <ExtensionFolioInstallForm
                                folio={extensionFolio.data}
                            />
                            <h3 style={{marginBottom: '1em', marginTop: '2em'}}>Extensions</h3>
                            {subExtensionLabelMap && <LabeledCheckboxGroup
                                options={subExtensionLabelMap}
                                selected={enabledSubExtensions ?? []}
                                onChange={(selected) => {
                                    setEnabledSubExtensions(selected);
                                    updateExtensionFolio(selected)
                                }}
                                descriptions={subExtensionDescriptions}
                            />}
                            {(subExtensionData && enabledSubExtensions) && renderEnabledSubExtensions(subExtensionData, enabledSubExtensions)}
                        </PivotItem>
                        {props.installedExtension && <PivotItem headerText='Logs' itemKey={'logs'}>
                            <ExtensionLogDisplay extension={props.installedExtension}/>
                        </PivotItem>}
                    </Pivot>
                )}
        </Panel>
    );
}

export default ExtensionFolioDetailsPanel;
