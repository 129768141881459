import React from 'react';
import { useUploadImageMutation } from '../../store/Api';
import { IsMutationSuccess } from '../../logic/MutationTypeChecker';
import { IUploadFileResponse } from '../../dal/BaseDAL';
import { Shimmer, ShimmerElementsGroup, ShimmerElementType, Image, IconButton, MessageBar, MessageBarType } from '@fluentui/react';

interface IImageUploaderProps {
    value?: string;
    onChange: (value: string) => void;
    disabled?: boolean;
    label?: string;
    width?: number;
    height?: number;
    onClear?: () => void;
    imageId?: string;
    imageType?: string;
}

const ImageUploader: React.FC<IImageUploaderProps> = (props) => {
    const [uploadImage] = useUploadImageMutation();
    const [saving, setSaving] = React.useState(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [saveMessage, setSaveMessage] = React.useState<string | undefined>();
    const [width, setWidth] = React.useState<number>(props.width ?? 150);
    const [height, setHeight] = React.useState<number>(props.height ?? 150);

    React.useEffect(() => {
        setWidth(props.width ?? 150);
        setHeight(props.height ?? 150);
    }, [props.width, props.height]);

    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const onButtonClick = () => {
        if (props.disabled) return;
        inputRef?.current?.click();
    }

    const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const file = e.target.files?.[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = async () => {
            const f = reader.result;
            if (!f || typeof f === 'string') return;
            if (file.size > 20971520) {
                setSaveMessage("File size exceeds 20 MB");
                setSaveSuccess(false);
                return;
            }
            setSaving(true);
            const response = await uploadImage({ 
                imageId: props.imageId ?? crypto.randomUUID(), 
                file: f,
                imageType: props.imageType ?? 'Custom',
            });
            if (IsMutationSuccess<IUploadFileResponse>(response) && response.data.url) {
                setSaveMessage('Image uploaded successfully');
                setSaveSuccess(true);
                props.onChange(response.data.url);
            } else {
                console.error(['FAILED UPLOAD', response])
                setSaveMessage('Failed to upload image');
                setSaveSuccess(false);
            }
            setSaving(false);
        };
        reader.readAsArrayBuffer(file);
    }

    const getCustomElements = (): JSX.Element => {
        return (
            <div>
                <ShimmerElementsGroup
                    shimmerElements={[
                        { type: ShimmerElementType.line, width, height},
                    ]}
                />
            </div>
        );
    };


    return <div style={{marginBottom: '2em'}}>
        <input 
            type="file" 
            ref={inputRef} 
            style={{ display: 'none' }} 
            onChange={onChangeFile} 
            accept='.gif,.png,.jpg,.jpeg,.webp'
        />
        <div style={{width, height, position: 'relative'}}>
            <Shimmer isDataLoaded={!saving} customElementsGroup={getCustomElements()}>
                <Image
                    src={props.value}
                    width={width}
                    height={height}
                    onClick={onButtonClick}
                    className={props.disabled ? 'product-image' : 'product-image-edit'}
                    title={props.disabled ? props.label : 'Click to change image'}
                    />
                    {props.onClear && props.value && <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        onClick={props.onClear}
                        style={{ position: 'absolute', top: 10, right: 10 }}
                    />}
            </Shimmer>
            {saveMessage && <MessageBar
                messageBarType={saveSuccess ? MessageBarType.success : MessageBarType.error}
                onDismiss={() => setSaveMessage(undefined)}
                dismissButtonAriaLabel="Close"
                dismissIconProps={{ iconName: 'Cancel' }}
                >
                {saveMessage}
            </MessageBar>}
        </div>
    </div>
}

export default ImageUploader;