import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RouteComponentProps, useHistory} from 'react-router';
import {ExtensionsDAL} from '../dal/ExtensionsDAL';
import {RootState} from '../store/rootReducer';
import {useGetSessionKeyMutation} from "../store/Api";
import {setSessionAuth} from "../store/authSlice";
import {IsMutationSuccess} from "../logic/MutationTypeChecker";
import {IActionResponse} from "../dal/BaseDAL";
import Header from "../components/Common/Header";
import Dimmer from "../components/Common/Dimmer";
interface EmbeddedExtensionRedirectParams {
    extensionId: string;
}

const EmbeddedExtensionRedirect: React.FC<RouteComponentProps<EmbeddedExtensionRedirectParams>> = (props) => {
    const queryString = window.location.search;
    const history = useHistory();
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    const apiKey = urlParams.get('apiKey');
    const organization = urlParams.get('organization');
    const auth = useSelector((state: RootState) => state.auth);
    const [getSessionKey] = useGetSessionKeyMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [error, setError] = useState<string | undefined>();
    const [isAuthed, setIsAuthed] = useState<boolean>(false);

    //Consider moving this to AuthHandler
    useEffect(() => {
        if (apiKey == null || organization == null) return;
        getSessionKey({organization: organization, key: apiKey}).then((response) => {
            if (IsMutationSuccess<IActionResponse>(response)) {
                if (response.data.success) {
                    dispatch(setSessionAuth(response.data.message));
                    setIsAuthed(true);
                } else
                    setError('Failed to get session key');
            } else {
                setError('Failed to get session key')
            }
        });

    }, [apiKey, organization])

    useEffect(() => {
        if (auth.isAuthed && !loaded && !loading && isAuthed) {
            load();
        }
    }, [auth.isAuthed, loaded, loading, isAuthed]);

    const load = async () => {
        const dal = new ExtensionsDAL(auth);
        if (id) {
            setLoading(true);
            const link = await dal.getExtensionLink(props.match.params.extensionId, id);
            if (link) {
                if (link.link === 'product') {
                    if (link.acCatalog && link.acId) {
                        history.push(`/embedded/product/${link?.acCatalog}/${link.acId}`);
                    } else {
                        history.push(`/embedded/establishLink/product/${props.match.params.extensionId}/${id}`);
                    }
                }
            }
            setLoaded(true);
            setLoading(false);
        }
    }

    return (
        <div className='main-content'>
            {error && <h3>{error}</h3>}
            {!loaded
                ? (
                    <Dimmer withSpinner={true}/>
                )
                : (
                    <Dimmer>
                        <Header icon={"Error"}>
                            Could not find link
                        </Header>
                    </Dimmer>
                )
            }
        </div>
    )
}

export default EmbeddedExtensionRedirect;