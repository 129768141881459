import React, {FormEvent, useEffect, useState} from 'react';
import {
    useGetStorefrontSubExtensionReferencesQuery,
} from '../../store/Api';
import {Dropdown, IDropdownOption, Spinner} from '@fluentui/react';
import {IExtensionFolioSubextensionReference} from "../../models/Extension";

interface IStorefrontOpportunityExtensionSelection {
    extension?: string;
    onChange: (extension: string) => void;
}

const StorefrontOpportunityExtensionSelection: React.FC<IStorefrontOpportunityExtensionSelection> = ({extension, onChange}) => {
    const extensionsApi = useGetStorefrontSubExtensionReferencesQuery()
    const [extensions, setExtensions] = useState<IExtensionFolioSubextensionReference[]>()
    const change = (e: FormEvent<HTMLDivElement>, v?: IDropdownOption<any> | undefined) => {
        if (v == null) return;
        onChange(v.key as string);
    }

    useEffect(() => {
        if(extensionsApi.data) {
            setExtensions(extensionsApi.data)
        }
    }, [extensionsApi.data]);

    return (
        <>
        {(extensionsApi.isLoading || extensionsApi.isFetching) ? <Spinner></Spinner> :
            (extensions && extensions.length > 0) ? 
            <Dropdown
                label='Opportunity Extension'
                selectedKey={extension}
                onChange={change}
                options={extensions ? extensions.map((extension: IExtensionFolioSubextensionReference) => ({
                    key: `folio_${extension.sourceFolio}_${extension.identifier}`,
                    text: extension.name ?? '',
                })) : []}
            /> : <div> No Opportunity Extensions Installed</div>}</>
    );
}

export default StorefrontOpportunityExtensionSelection;