import React from 'react';
import {Spinner, SpinnerSize} from '@fluentui/react';
interface IDimmerProps {
    withSpinner?: boolean;
    label?: string;
}
const Dimmer: React.FC<IDimmerProps> = (props) => {
    return (
        <div className={"ac-dimmer"}>
            <div className={"ac-dimmer-content"}>
                {props.withSpinner && <Spinner size={SpinnerSize.large} label={props.label ?? "Loading"}/>}
                {props.children}
            </div>
        </div>
    );
}

export default Dimmer;