import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {updateSelectedCatalog} from "../../store/searchSlice";
import {Dropdown, IDropdownOption} from "@fluentui/react";
import {useGetCatalogsQuery} from "../../store/Api";

interface ICatalogDropdownProps {
    onChange?: () => void;
    showLabel?: boolean;
}

const CatalogDropdown: React.FC<ICatalogDropdownProps> = (props) => {
    const dispatch = useDispatch();
    const getCatalogs = useGetCatalogsQuery();
    const selectedCatalog = useSelector((state: RootState) => state.search.selectedCatalog);

    const options: IDropdownOption[] = [];
    if (getCatalogs.data) {
        for (const catalog of getCatalogs.data) {
            if (catalog.active)
                options.push({
                    key: catalog.name,
                    text: catalog.description,
                    // value: catalog.name,
                });
        }
    }

    let defaultValue = "";
    if (getCatalogs.data && getCatalogs.data.length > 0) {
        defaultValue = selectedCatalog?.name ?? getCatalogs.data[0].name;
    }

    return (
        <>
            <Dropdown
                label={props.showLabel ? 'Catalog' : undefined}
                disabled={getCatalogs.isFetching && options.length === 0}
                selectedKey={selectedCatalog?.name ?? defaultValue}
                onChange={(element, value) => {
                    if (value) {
                        const result = value.key as string;
                        const chosenCatalog = getCatalogs.data?.find(e => e.name === result);
                        dispatch(updateSelectedCatalog(chosenCatalog));
                        if (props.onChange) {
                            props.onChange();
                        }
                    }
                }}
                options={options}
            />
        </>
    );
};

export default CatalogDropdown;