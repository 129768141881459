import React, {useState} from 'react';
import {IAdditionalData, IDirectImportItem, IInstalledExtension} from "../../models/Extension";
import {Checkbox, Label, TextField} from "@fluentui/react";
import FileUpload from "./FileUpload";
import LibrarySelection from "./LibrarySelection";
import ExtensionFieldSearch from "./ExtensionFieldSearch";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import ExtensionSearchResults from "./ExtensionSearchResults";
import ExtensionDirectImportPreview from "./ExtensionDirectImportPreview";

interface IExtensionFieldProps {
    field: IAdditionalData;
    sourceFieldValue?: string
    extension: IInstalledExtension;
    isDisabled?: boolean;
    value?: string;
    onChange: (value: string | boolean | undefined) => void;
    onExportSelection?: (value: IDirectImportItem[]) => void;
}

const ExtensionField: React.FC<IExtensionFieldProps> = (props) => {
    const targetExtension = useSelector((state: RootState) => state.settings.targetExtension);
    const targetExtensionMappings = useSelector((state: RootState) => state.settings.mappedExtensionFields);
    const [value, setValue] = useState<string | undefined>(undefined);
    
    React.useEffect(() => {
        if (targetExtension && targetExtensionMappings) {
            const mappedField = targetExtensionMappings[props.field.field];
            if (mappedField) {
                setValue(mappedField);
            }
        } else if (props.value) {
            setValue(props.value);
        }
    }, [props.value, targetExtension, targetExtensionMappings]);

    const change = (value: string | undefined) => {
        props.onChange(value);
        setValue(value)
    }

    if (props.field.fieldType === 'static') return null;

    if(props.field.fieldType === 'user' || props.field.fieldType === 'search') {
        if (props.field.searchable) {
            return (
                <div>
                    <ExtensionFieldSearch
                        isDisabled={props.isDisabled}
                        field={props.field}
                        extensionId={props.extension.id}
                        initialValue={value}
                        onChange={v => change(v)}
                    />
                    <br/>
                </div>
            )
        }
        return (
            <TextField
                label={props.field.description}
                id={props.field.field}
                onBlur={(e) => change(e.target.value)}
                onChange={(e, v) => change(v)}
                value={value}
                disabled={props?.value != undefined || props.isDisabled}
                placeholder={!props?.value ? 'Loading...' : ''}
            />
        )
    }

    if (props.field.fieldType === 'flag') {
        return (
            <div key={`extension-flag-${props.field.field}`}>
                <Label>{props.field.description}</Label>
                <Checkbox checked={value == "true"}
                          label={props.field.description}
                          onChange={(e, d) => change(d != undefined ? d.toString() : "")}
                />
            </div>
        );
    }

    if (props.field.fieldType === 'results' && props.sourceFieldValue ) {
        console.log('value for results is:', value)
        console.log('source field:', props.sourceFieldValue)
        return (
            <div key={`extension-results-${props.field.field}`}>
                <Label>{props.field.description}</Label>
                <ExtensionSearchResults 
                    extensionId={props.extension.id} 
                    initialValue={props.sourceFieldValue} 
                    isDisabled={props.sourceFieldValue == ''} 
                    field={props.field} 
                    onSelectOption={(selection) => {
                        change(selection)
                    }
                }/>
            </div>
        );
    }

    if (props.field.fieldType === 'directImportPreview' ) {

        return (
            <div key={`extension-directImportPreview-${props.field.field}`}>
                <Label>{props.field.description}</Label>
                <ExtensionDirectImportPreview
                    fieldName={props.field.field}
                    extensionId={props.extension.id}
                    importObjectId={props.sourceFieldValue}
                    sourceName={props.extension.source}
                    onSelectOption={(selection) => {
                        if(props.onExportSelection){
                            props.onExportSelection(selection)
                        }
                    }
                    }/>
            </div>
        );
    }

    if (props.field.fieldType === 'file') {
        return (
            <div key={`extension-upload-${props.field.field}`}>
                <Label>{props.field.description}</Label>
                <FileUpload
                    extensionId={props.extension.id}
                    onSuccess={change}
                />
            </div>
        );
    }

    if (props.field.fieldType === 'library') {
        return <LibrarySelection
            label={props.field.description}
            installedExtension={props.extension}
            onChange={change}
            value={value}
        />

    }
    return null;
}

export default ExtensionField;