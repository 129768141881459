import React, {useEffect} from 'react';
import {usePostSearchTableRowQuery} from "../../store/Api";
import {CheckboxVisibility, DetailsList, ProgressIndicator, Selection, SelectionMode} from "@fluentui/react";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {IProductReference} from "../../models/Extension";
import {IProduct} from "../../models/Search";

interface ITableRowSearchResultsProps {
    catalog?: string;
    values: string[];
    manufacturerPartNumber?: string;
    productName?: string;
    onSelectedChange?: (selected: IProductReference | undefined) => void;
}

const TableRowSearchResults: React.FC<ITableRowSearchResultsProps> = (props) => {
    const results = usePostSearchTableRowQuery({values: props.values, catalog: props.catalog, manufacturerPartNumber: props.manufacturerPartNumber, productName: props.productName}, {refetchOnMountOrArgChange: true})

    useEffect(() => {
        if (results.data?.items.length ?? 0 >= 2) {
            const first = results.data?.items[0];
            const second = results.data?.items[1];

            if (first?.score && second?.score && first.score / second.score > 3) {
                selection.setIndexSelected(0, true, true);
            }
        }
    }, [results.data?.items])
    if (results.isFetching) return <ProgressIndicator description='Searching' />;

    const columns = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('MFP', 'manufacturerPartNumber', 100, 150),
        ColumnGenerator('Internal PN', 'internalPartNumber', 100, 150),
        ColumnGenerator('Manufacturer', 'manufacturer', 100, 200),
    ]

    const selection = new Selection({
        onSelectionChanged: () => {
            if (!props.onSelectedChange) return;
            const item = selection.getSelection()[0] as IProduct | undefined;
            const catalog = item?.catalog == null || item.catalog === '' ? props.catalog ?? '' : item.catalog;
            props.onSelectedChange(item ? {id: item.id, catalog} : undefined);
        },
    });

    return (<>
        {/*<IconButton iconProps={{iconName: 'Refresh'}} onClick={() => results.refetch()} />*/}
        <DetailsList
            items={results.data?.items || []}
            columns={columns}
            selectionMode={SelectionMode.single}
            selection={selection}
            checkboxVisibility={CheckboxVisibility.always}
        />
    </>);
}

export default TableRowSearchResults;