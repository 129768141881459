import React from 'react';
import {
    IColumn,
    Checkbox, 
    PrimaryButton
} from "@fluentui/react";
import {
    IFulfillment, 
    IOrder, 
    IProcurementEvent, 
    IProcurementItem
} from "../../models/Order";

import {IProductColumnBase} from "./ProductColumns";

export interface IOrderColumns extends IProductColumnBase{
    refreshingVendors?: boolean;
    onPrepareOrderHeaderRender?: () => React.ReactElement;
    onPrepareOrderRender?: (product: IProcurementItem) => React.ReactElement;
}

export const PROCUREMENT_EVENT_COLUMNS: string[] = ['orderId', 'externalOrderId', 'eventType', 'eventDetails', 'eventDate', 'username']
export const PROCUREMENT_EVENT_COLUMNS_PROPERTIES: {[key: string]: IProductColumnBase } = {
    'orderId': {
        basicColumn: {
            key: 'orderId',
            name: 'Order Id',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'externalOrderId': {
        basicColumn: {
            key: 'externalOrderId',
            name: 'External Order Id',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'eventType': {
        basicColumn: {
            key: 'eventType',
            name: 'Event Type',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'eventDetails': {
        basicColumn: {
            key: 'eventDetails',
            name: 'Event Details',
            minWidth: 300,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
            isMultiline: true
        },
    },
    'eventDate': {
        basicColumn: {
            key: 'eventDate',
            name: 'Event Date',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
            isMultiline: true
        },
    },
    'username': {
        basicColumn: {
            key: 'username',
            name: 'Username',
            minWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    }
}
//TODO: Sorters are behaving in a less-than-reliable fashion, so I've commented them out until the issue becomes more clear
export const renderProcurementEventColumns = (sorter?: (column: IColumn) => void ) : IColumn[] => {
    const orderColumns: IColumn[] = []
    for (const field of PROCUREMENT_EVENT_COLUMNS) {
        const eventTempCol: IColumn | undefined = PROCUREMENT_EVENT_COLUMNS_PROPERTIES?.[field]?.basicColumn;
        if(!eventTempCol) {console.log('didn\'t find:', field); continue}
/*        eventTempCol.onColumnClick = (ev, column) =>{if(sorter){sorter(column)}}*/
        if(field === "orderId"){
            eventTempCol.onRender = (event: IProcurementEvent) => event
                ? <div className={'ac-grid-field'}>{event.orderId}</div>
                : null;
        }
        else if(field === "externalOrderId"){
            eventTempCol.onRender = (event: IProcurementEvent) => event
                ? <div className={'ac-grid-field'}>{event.externalOrderId}</div>
                : null;
        }
        else if(field === "eventType"){
            eventTempCol.onRender = (event: IProcurementEvent) => event
                ? <div className={'ac-grid-field'}>{event.eventType}</div>
                : null;
        }
        else if(field === "eventDetails"){
            eventTempCol.onRender = (event: IProcurementEvent) => event
                ? <div className={'ac-grid-field'}>{event.eventDetails}</div>
                : null;
        }
        else if(field === "eventDate"){
            eventTempCol.onRender = (event: IProcurementEvent) => event?.eventDate
                ? <div className={'ac-grid-field'}>{new Date(event.eventDate).toLocaleString()}</div>
                : null;
        }
        else if(field === "username"){
            eventTempCol.onRender = (event: IProcurementEvent) => event?.eventDate
                ? <div className={'ac-grid-field'}>{event.username}</div>
                : null;
        }
        
        orderColumns.push(eventTempCol);
    }
    return orderColumns;
}

export const FULFILLMENT_COLUMN: string[] = ['carrier', 'orderId', 'trackingNumber', 'trackingLink', 'items', 'eta', 'status', 'completeDate']
export const FULFILLMENT_COLUMNS_PROPERTIES: {[key: string]: IProductColumnBase } = {
    'carrier': {
        basicColumn: {
            key: 'carrier',
            name: 'Carrier Name',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'orderId': {
        basicColumn: {
            key: 'orderId',
            name: 'Order ID',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'trackingNumber': {
        basicColumn: {
            key: 'trackingNumber',
            name: 'Tracking Number',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'trackingLink': {
        basicColumn: {
            key: 'trackingLink',
            name: 'Tracking Link',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'items': {
        basicColumn: {
            key: 'items',
            name: 'Tracking Link',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
            isMultiline: true
        },
    },
    'eta': {
        basicColumn: {
            key: 'eta',
            name: 'ETA',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
        },
    },
    'status': {
        basicColumn: {
            key: 'status',
            name: 'Status',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
            isMultiline: true
        },
    },
    'completeDate': {
        basicColumn: {
            key: 'completeDate',
            name: 'Complete Date',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isPadded: true,
            isMultiline: true
        },
    },
}
export const renderFulfillmentColumns = (sorter?: (column: IColumn) => void ) : IColumn[] => {
    const orderColumns: IColumn[] = []
    for (const field of FULFILLMENT_COLUMN) {
        const orderTempColumn: IColumn | undefined = FULFILLMENT_COLUMNS_PROPERTIES?.[field]?.basicColumn;
        if(!orderTempColumn) {console.log('didn\'t find:', field); continue}
/*        orderTempColumn.onColumnClick = (ev, column) =>{if(sorter){sorter(column)}}*/
        if(field === "items"){
            orderTempColumn.onRender = (fulfillment: IFulfillment) => fulfillment?.itemReferences
                ? <div className={'ac-grid-field'}>{fulfillment.itemReferences.map(a => a.manufacturerPartNumber).join(', ')}</div>
                : null;
        }
        if(field === "trackingLink"){
            orderTempColumn.onRender = (fulfillment: IFulfillment) => fulfillment?.trackingLink
                ? <div className={'ac-grid-field'}><a href={fulfillment.trackingLink}>Link</a> </div>
                : null;
        }
        if(field === "eta"){
            orderTempColumn.onRender = (fulfillment: IFulfillment) => fulfillment?.eta
                ? <div className={'ac-grid-field'}>{new Date(fulfillment.eta).toLocaleString()}</div>
                : null;
        }
        else if(field === "completeData"){
            orderTempColumn.onRender = (fulfillment: IFulfillment) => fulfillment?.completeDate
                ? <div className={'ac-grid-field'}>{new Date(fulfillment.completeDate).toLocaleString()}</div>
                : null;
        }


        orderColumns.push(orderTempColumn);
    }
    return orderColumns;
}

export const ORDERCOLUMNS: string[] = ["details", "name", "source", "orderCompany", "dropship", "orderDate", "userName", "status", "purchaseOrderNumber", "endUserOrderNumber"]
export const ORDER_COLUMNS_PROPERTIES: {[key: string]: IOrderColumns } = {
    'details': {
        basicColumn: {
            key: 'details',
            name: 'Details',
            fieldName: 'details',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'source': {
        basicColumn: {
            key: 'source',
            name: 'Vendor',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            isPadded: true,
            isMultiline: true
        },
    },
    'name': {
        basicColumn: {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'orderCompany': {
        basicColumn: {
            key: 'orderCompany',
            name: 'Order Company',
            fieldName: 'orderCompany',
            minWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'dropship': {
        basicColumn: {
            key: 'dropship',
            name: 'Dropship',
            fieldName: 'dropship',
            minWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'orderDate': {
        basicColumn: {
            key: 'orderDate',
            name: 'Order Date',
            fieldName: 'orderDate',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'userName': {
        basicColumn: {
            key: 'userName',
            name: 'User',
            fieldName: 'userName',
            minWidth: 250,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'status': {
        basicColumn: {
            key: 'status',
            name: 'Order Status',
            fieldName: 'status',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'purchaseOrderNumber': {
        basicColumn: {
            key: 'purchaseOrderNumber',
            name: 'PO Number',
            fieldName: 'purchaseOrderNumber',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    'endUserOrderNumber': {
        basicColumn: {
            key: 'endUserOrderNumber',
            name: 'End-User Order Number',
            fieldName: 'endUserOrderNumber',
            minWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
    },
    
}

export const renderOrderColumns = (onDetailsClick?: (order: IOrder) => void, sorter?: (column: IColumn) => void ) : IColumn[] => {
    const orderColumns: IColumn[] = []
    for (const field of ORDERCOLUMNS) {
        const orderTempColumn: IColumn = ORDER_COLUMNS_PROPERTIES[field].basicColumn;
/*        orderTempColumn.onColumnClick = (ev, column) => {
            if (sorter) {
                sorter(column)
            }
        }*/
        if (field === "details") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? 
                    <PrimaryButton text='Details' onClick={() => {
                        if (onDetailsClick) {
                            onDetailsClick(order)
                        }
                    }}
                    /> 
                : <></>
            orderTempColumn.onColumnClick = undefined
        }
        if (field === "name") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>{order.name}</div>
                : null;
        } else if (field === "source") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>{order.source}</div>
                : null;
        } else if (field === "orderCompany") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>{order?.orderCompany?.companyName}</div>
                : null;
        } else if (field === "dropship") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>
                    <Checkbox
                        name={'Dropship'}
                        checked={order.dropship}
                        disabled={true}
                    />
                </div>
                : null;
        } else if (field === "userName") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>{order.username}</div>
                : null;
        } else if (field === "status") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>{order.status}</div>
                : null;
        } else if (field === "purchaseOrderNumber") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>{order.purchaseOrderNumber}</div>
                : null;
        } else if (field === "endUserOrderNumber") {
            orderTempColumn.onRender = (order: IOrder) => order
                ? <div className={'ac-grid-field'}>{order.endUserOrderNumber}</div>
                : null;
        } else if (field === "orderDate") {
            orderTempColumn.onRender = (order: IOrder) => order?.orderDate
                ? <div className={'ac-grid-field'}>{new Date(order.orderDate).toLocaleString()}</div>
                : null;
        }

        orderColumns.push(orderTempColumn);
    }
    return orderColumns;
}