import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {IUserRoleRecord} from "../../models/Settings";
import {SettingsDAL} from "../../dal/SettingsDAL";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import UserDetailsDialog from "../../components/UserManagement/UserDetailsDialog";
import CAN, {USER_MODIFY} from "../../permissions/ability"

const UserManagementPage: React.FC = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const selectedOrg = useSelector((state: RootState) => state.settings.selectedOrganization?.id);
    const [users, setUsers] = useState<IUserRoleRecord[] | undefined>(undefined);
    const [userLimit, setUserLimit] = useState<number | undefined>(undefined);
    const [licensedUserCount, setLicensedUserCount] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined)
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<IUserRoleRecord | undefined>(undefined)
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!loading && users === undefined && auth.isAuthed && error === undefined) {
            load();
        }
    }, [loading, users, auth, error]);

    const load = async () => {
        const dal = new SettingsDAL(auth, selectedOrg)
        setLoading(true);
        setError(undefined);
        try {
            const org = await dal.getOrganizationDetails();
            if (org != null) {
                setLicensedUserCount(org.licensedUserCount);
                setUserLimit(org.userLimit);
                setUsers(org.users);
            } else {
                setError('Could not load organization details');
                setUsers(undefined);
            }
        } catch {
            setError('Could not load organization details');
            setUsers(undefined);
        }
        setLoading(false);
    }

    const columns: IColumn[] = [
        {
            name: 'Username',
            key: 'username',
            fieldName: 'username',
            minWidth: 100,
        },
        {
            name: 'Role',
            key: 'role',
            fieldName: 'role',
            minWidth: 200,
            maxWidth: 300
        },
        {
            name: 'Actions',
            key: 'actions',
            minWidth: 100,
            maxWidth: 100,
            onRender: (user: IUserRoleRecord) => <PrimaryButton
                text='Details'
                onClick={() => {
                    setSelectedUser(user);
                    setDialogOpen(true);
                }}
            />
        }
    ]

    const commands: ICommandBarItemProps[] = [
        {
            key: 'new',
            text: 'New',
            iconProps: {iconName: 'Add'},
            onClick: () => setDialogOpen(true)
        }
    ]

    return (
        <div className='main-content'>
            <h1>User Management</h1>
            {error &&
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    actions={<MessageBarButton onClick={() => load()}>Retry</MessageBarButton>}
                >
                    {error}
                </MessageBar>
            }
            {CAN(USER_MODIFY) && (
            <CommandBar items={commands}/>
            )}
            {(licensedUserCount && userLimit) &&
                <MessageBar
                    messageBarType={licensedUserCount < userLimit ? MessageBarType.info : MessageBarType.warning}
                    isMultiline={false}>
                    You are using {licensedUserCount} of {userLimit} licenses.
                </MessageBar>
            }
            <ShimmeredDetailsList selectionMode={SelectionMode.none} enableShimmer={loading} items={users ?? []} columns={columns}/>

            <UserDetailsDialog
                isOpen={dialogOpen}
                onUpdate={() => {
                    load();
                    setSelectedUser(undefined);
                    setDialogOpen(false);
                }}
                onClose={() => {
                    setSelectedUser(undefined);
                    setDialogOpen(false);
                }}
                onDelete={() => {
                    load();
                    setSelectedUser(undefined);
                    setDialogOpen(false);
                }}
                user={selectedUser}
            />
        </div>
    )
}

export default UserManagementPage;