import React from 'react';
import Autosuggest from "react-autosuggest";
import allCountries from 'country-region-data/data.json';
import {Label} from "@fluentui/react";

interface ICountrySelection {
    value?: string;
    onChange?: (value: string) => void;
}

const CountrySelection: React.FC<ICountrySelection> = (props) => {
    const [suggestions, setSuggestions] = React.useState<string[]>([]);

    return (
        <div>
            <Label>Country</Label>
            <Autosuggest
                inputProps={{
                    value: props.value ?? '',
                    onChange: (e, {newValue}) => {
                        props.onChange?.(newValue);
                    },
                    style: {
                        width: '100%',
                        boxSizing: 'border-box',
                        padding: '8px',
                        height: '32px',
                        border: '1px solid rgb(96,94,92)',
                        borderRadius: '2px',
                    }
                }}
                containerProps={{
                    style: {
                        display: 'flex'
                    }
                }}
                suggestions={suggestions}
                onSuggestionsFetchRequested={({value}) => {
                    const filtered = allCountries.filter((c) =>
                        c.countryName.toLowerCase().startsWith(value.toLowerCase()) ||
                        c.countryShortCode.toLowerCase().startsWith(value.toLowerCase())
                    );
                    setSuggestions(filtered.map((c) => c.countryName));
                }}
                onSuggestionsClearRequested={() => setSuggestions([])}
                onSuggestionSelected={(e, {suggestionValue}) => {
                    props.onChange?.(suggestionValue);
                }}
                getSuggestionValue={(suggestion) => suggestion}
                renderSuggestion={(suggestion) => <span>{suggestion}</span>}
                />
        </div>
    )
}

export default CountrySelection;