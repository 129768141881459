import { Spinner } from '@fluentui/react';
import React from 'react';
import { Markdown } from 'react-showdown';

interface IChatMessageProps {
    replying?: boolean;
    message?: string;
    messageSource: 'USER' | 'SYSTEM';
    timestamp?: string;
}

const ChatMessage: React.FC<IChatMessageProps> = (props) => {
    const title = props.messageSource === 'USER' ? 'You' : 'System';
    const timestamp = props.timestamp ? new Date(props.timestamp) : undefined;
    return (
        <div>
            <div className={`chat-title chat-title-${props.messageSource.toLowerCase()}`}><span className='chat-title-text'>{title}</span>{timestamp && ` - ${new Date(timestamp)?.toLocaleTimeString()}`}</div>
            <div className={`speech-bubble chat-message chat-message-${props.messageSource.toLowerCase()}`}>
                <div className="message-body">
                    {props.message && <Markdown markdown={props.message} />}
                    {props.replying && <Spinner />}
                </div>
            </div>
        </div>
    );
}

export default ChatMessage;