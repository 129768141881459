import React from 'react';
import { Link } from '@fluentui/react';
import { IFilledFilters, ISearchRequest } from '../../models/Search';
import { useGetCountQuery } from '../../store/Api';
import { updateSearchCriteria } from "../../store/searchSlice";
import { useDispatch } from 'react-redux';
import Header from "../Common/Header";
interface INoResultsDisplayProps {
    message?: string;
    criteria: ISearchRequest;
    onFilterChange: (filters: IFilledFilters) => void;
}

const NoResultsDisplay: React.FC<INoResultsDisplayProps> = (props) => {
    const dispatch = useDispatch();
    const result = useGetCountQuery({criteria: props.criteria});
    const count = result ? result.data : 0;

    function handleClickOnLink() {
        if (props.criteria) {
            props.onFilterChange({});
            dispatch(updateSearchCriteria(props.criteria));
        }
    }
    const button = props.message ? '' : `Results without Filters: ${count}`;
    return <div className="text-center">
        <Header icon={"search"}>
            <div className="content">{props.message ?? 'No Results Found'}</div>
        </Header>
        <Link onClick={handleClickOnLink} underline>
          {button}
        </Link>
    </div>
}

export default NoResultsDisplay;
