import {
    DefaultButton,
    Dialog, DialogFooter,
    DialogType
} from "@fluentui/react";

import React from "react";
import {useRemoveWorkspaceMutation} from "../../store/Api";
interface IWorkspaceDeleteModalProps {
    selectedWorkspace?: string
    displayModal: boolean;
    onDeleteWorkspace?: () => void;
    closer: () => void;
}
const WorkspaceDeleteModal: React.FC<IWorkspaceDeleteModalProps> = props => {
    const [removeWorkspace] = useRemoveWorkspaceMutation();
    return (
        <Dialog
            hidden={!props.displayModal}
            onDismiss={props.closer}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Delete Workspace',
                subText: 'Are you sure you want to delete this workspace?',
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <DialogFooter>
                <DefaultButton
                    primary
                    text='Delete'
                    onClick={() => {
                        if (props.selectedWorkspace) {
                            removeWorkspace(props.selectedWorkspace).then(() => {
                                if(props.onDeleteWorkspace) props.onDeleteWorkspace()
                            });
                        }
                        props.closer()
                    }}
                />
                <DefaultButton
                    text='Cancel'
                    onClick={props.closer}
                />
            </DialogFooter>
        </Dialog>
    );
}
export default WorkspaceDeleteModal;