import React, {useState} from 'react';
import {
    DefaultButton,
    IconButton,
    Modal,
    PrimaryButton,
    Stack
} from "@fluentui/react";
import FileUploadDropzone from "../FileUploadDropzone/FileUploadDropzone";
import {IInstalledExtension} from "../../models/Extension";
import {usePostExtensionLibraryFileMutation} from "../../store/Api";
import {MODAL_ICON_BUTTON_STYLE, MODAL_STYLE} from "../../Constants";

interface IExtensionLibraryUploadModalProps {
    installedExtension: IInstalledExtension;
    addModalOpen: boolean;
    onClose: () => void;
}

const ExtensionLibraryUploadModal: React.FC<IExtensionLibraryUploadModalProps> = (props) => {
    const [file, setFile] = useState<ArrayBuffer | undefined>(undefined);
    const [files, setFiles] = useState<any[] | undefined>(undefined);
    const [uploadFile] = usePostExtensionLibraryFileMutation();


    const saveFile = async () => {
        console.log(['SAVING', props.installedExtension.id, file, files])
        if (file && files) {
            await uploadFile({
                extensionId: props.installedExtension.id,
                file,
                files
            });
            props.onClose();
        }
    }

    return <Modal
        isOpen={props.addModalOpen}
        containerClassName={MODAL_STYLE.container}
        onDismiss={props.onClose}
    >
        <div className={MODAL_STYLE.header}>
            <span>Add file to extension library</span>
            <IconButton
                styles={MODAL_ICON_BUTTON_STYLE}
                iconProps={{iconName: 'Cancel'}}
                ariaLabel="Close popup modal"
                onClick={props.onClose}
            />
        </div>
        <div className={MODAL_STYLE.body}>
            <FileUploadDropzone
                uploadingFile={false}
                onFileChange={(file, files) => {
                    setFile(file);
                    setFiles(files);
                }}
                clearFile={() => {
                    setFile(undefined);
                    setFiles(undefined);
                }}
                fileName={files?.[0]?.name}
            />
        </div>
        <div className={MODAL_STYLE.body}>
            <Stack tokens={{childrenGap: '1em'}} horizontal horizontalAlign='end'>
                <PrimaryButton onClick={saveFile}>Save</PrimaryButton>
                <DefaultButton onClick={props.onClose}>Cancel</DefaultButton>
            </Stack>
        </div>
    </Modal>
}

export default ExtensionLibraryUploadModal;