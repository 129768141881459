import React from "react";
import { ExtensionType } from "../../Constants";
import { useGetSubExtensionFolioSubExtensionReferencesQuery } from "../../store/Api";
import { IExtensionFolioSubextensionReference } from "../../models/Extension";
import { Dropdown, IDropdownOption } from "@fluentui/react";

interface ISubExtensionDropdownProps {
    type: ExtensionType;
    extension?: string;
    onChange: (extension: string) => void;
    label?: string;
}

const SubExtensionDropdown: React.FC<ISubExtensionDropdownProps> = (props) => {
    const subExtensions = useGetSubExtensionFolioSubExtensionReferencesQuery(props.type);

    const change = (e: React.FormEvent<HTMLDivElement>, v?: IDropdownOption | undefined) => {
        if (v == null) return;
        props.onChange(v.key as string);
    }

    return (<>
        {(subExtensions.isLoading || subExtensions.isFetching) ? <></> :
            (subExtensions.data?.items && subExtensions.data.items.length > 0) ?
                <Dropdown
                    label={props.label ?? 'Extension'}
                    selectedKey={props.extension}
                    onChange={change}
                    options={subExtensions.data.items.map((extension: IExtensionFolioSubextensionReference) => ({
                        key: `folio_${extension.sourceFolio}_${extension.identifier}`,
                        text: extension.name ?? '',
                    }))}
                /> : <div> No Extensions Available</div>}
    </>);
}

export default SubExtensionDropdown;