import React from 'react';
import { useGetStorefrontMonthlyOrdersQuery } from '../../store/Api';
import { IColumn, SelectionMode, ShimmeredDetailsList } from '@fluentui/react';
import { ColumnGenerator, CurrencyColumnGenerator } from '../../logic/Columns/ColumnGenerator';
import { IStorefrontMonthlyOrders } from '../../models/Storefront';
import { MONTHS } from '../../Constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const StorefrontMonthlyOrdersTable = () => {
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const year = new Date().getFullYear();
    const orders = useGetStorefrontMonthlyOrdersQuery({year});

    const columns: IColumn[] = [
        ColumnGenerator('Year', 'year', 100, 100),
        ColumnGenerator('Month', 'month', 200, undefined, (item: IStorefrontMonthlyOrders) => <>{MONTHS[item.month-1]}</>),
        ColumnGenerator('Orders', 'count', 100, 100),
        CurrencyColumnGenerator('Total', 'total', 200, 200),
        ColumnGenerator('Transaction Quota', 'transactionQuota', 200, 200, (item: IStorefrontMonthlyOrders) => <>
            {org?.storefrontTransactionLimit != null && (org.storefrontTransactionLimit < item.count ? `${org.storefrontTransactionLimit - item.count} over` : `${org.storefrontTransactionLimit - item.count} remaining`)}
        </>)
    ]

    return (
        <ShimmeredDetailsList
            selectionMode={SelectionMode.none}
            enableShimmer={orders.isLoading}
            items={orders.data?.items ?? []}
            columns={columns}
            />
    );
}

export default StorefrontMonthlyOrdersTable;