import React from 'react';
import Dropzone from "react-dropzone";
import Segment from "../Common/Segment";
import Header from "../Common/Header";

interface IFileUploadDropzoneProps {
    uploadingFile: boolean;
    onFileChange: (file: ArrayBuffer, files: any[]) => void;
    clearFile: () => void;
    fileName?: string;
    error?: string;
}

const FileUploadDropzone: React.FC<IFileUploadDropzoneProps> = (props) => {
    return <>
        <Dropzone disabled={props.uploadingFile} onDrop={(files) => {
            if (files && files.length > 0) {
                const reader = new FileReader();
                reader.onload = async () => {
                    const file = reader.result;
                    if (file && typeof file !== "string") {
                        props.onFileChange(file, files);
                    }
                };
                reader.readAsArrayBuffer(files[0]);
            }
        }}>
            {({getRootProps, getInputProps}) => {
                let message = 'Drag and drop your file or click to select one';
                if (props.uploadingFile) message = 'Uploading File';
                else if (props.error) message = props.error;
                else if (props.fileName) message = props.fileName;

                return <section>
                    <div {...getRootProps()}>
                        <Segment hasButton={true} buttonText={"Clear"} onButtonClick={() => {
                            props.clearFile();
                        }}>
                            <Header icon={"OpenFile"} showIconCircle={false}>
                                {message}
                                <input {...getInputProps()} />
                            </Header>
                        </Segment>
                    </div>
                </section>;
            }}
        </Dropzone>
    </>
}

export default FileUploadDropzone;