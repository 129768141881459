import React from 'react';
import {useGetSubOrganizationsQuery} from "../../store/Api";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    Image,
    ImageFit,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {ButtonColumnGenerator, ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {ISubOrganization} from "../../models/Settings";
import SubOrganizationModal from "../../components/SubOrganizationModal/SubOrganizationModal";

const SubOrganizationManagement: React.FC = () => {
    const organization = useSelector((state: RootState) => state.settings.selectedOrganization);
    const getSubOrganizations = useGetSubOrganizationsQuery();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectedSubOrg, setSelectedSubOrg] = React.useState<ISubOrganization | undefined>(undefined);

    if (organization?.subOrganizationsEnabled !== true) {
        return (
            <div>
                <h1>Sub Organization Management</h1>
                <p>Sub Organizations are not enabled for this organization.</p>
            </div>
        );
    }

    const commands: ICommandBarItemProps[] = [
        {
            key: 'new',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {setModalOpen(true);}
        }
    ];

    const farCommands: ICommandBarItemProps[] = [{
        key: 'refresh',
        text: 'Refresh',
        iconOnly: true,
        iconProps: { iconName: 'Refresh' },
        onClick: () => {getSubOrganizations.refetch();}
    }];

    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name', 100),
        ColumnGenerator('Country', 'country', 100, 100),
        ColumnGenerator('Logo', 'logo', 50, 50, (item: ISubOrganization) => (
            <Image src={item.logo} width={50} height={50} imageFit={ImageFit.contain} />
        )),
        ButtonColumnGenerator((s: ISubOrganization) => {setSelectedSubOrg(s); setModalOpen(true);}, '', 'Details'),
    ]

    return (
        <div>
            <h1>Sub Organization Management</h1>
            <CommandBar items={commands} farItems={farCommands} />
            <ShimmeredDetailsList
                selectionMode={SelectionMode.none}
                items={getSubOrganizations.data ?? []}
                enableShimmer={getSubOrganizations.isFetching}
                columns={columns}
            />
            <SubOrganizationModal
                isOpen={modalOpen}
                onClose={() => {setModalOpen(false); setSelectedSubOrg(undefined);}}
                subOrganization={selectedSubOrg}
            />
        </div>
    );
}

export default SubOrganizationManagement;