import React, {useEffect} from "react";
import {
    getTheme,
    IDetailsHeaderProps, 
    IDragDropContext, 
    IRenderFunction,
    Selection,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {IWorkspaceItem} from "../../models/Workspace";
import {
    useGetColumnsQuery,
    useGetWorkspaceProductsQuery,
    usePutWorkspaceItemDetailsMutation,
    useRemoveProductFromWorkspaceMutation, useUpdateWorkspaceItemSortOrderMutation
} from "../../store/Api";
import {renderWorkspaceColumns} from "../../logic/Columns/ProductColumns";
import {WORKSPACECOLUMNS} from "../../Constants";
import Header from "../Common/Header";
import Sticky from "react-stickynode"
import {mergeStyles} from "@fluentui/react/lib/Styling";

interface IWorkspaceTableProps {
    workspace: string;
    selectedItems: IWorkspaceItem[];
    onSelectedItemsChanged: (items: IWorkspaceItem[]) => void;
    onDetailsClick: (item:IWorkspaceItem) => void;
    onAdhocClick: (item:IWorkspaceItem | undefined) => void;
}

const WorkspaceTable: React.FC<IWorkspaceTableProps> = (props) => {
    const products = useGetWorkspaceProductsQuery(props.workspace, {skip: !props.workspace || props.workspace.length === 0});
    const [removeProduct] = useRemoveProductFromWorkspaceMutation();
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    const getColumns = useGetColumnsQuery();
    const [updateSortOrder] = useUpdateWorkspaceItemSortOrderMutation()
    const [draggedItem, setDraggedItem] = React.useState<IWorkspaceItem | undefined>();
    const [draggedIndex, setDraggedIndex] = React.useState<number>();
    const [displayProducts, setDisplayProducts] = React.useState<IWorkspaceItem[]>([]);
    const theme = getTheme();
    const dragEnterClass = mergeStyles({
        backgroundColor: theme.palette.blueMid,
        borderColor: theme.palette.blue
    });

    useEffect(() => {
        if(products.data) {
            setDisplayProducts(products.data);
        }
    }, [products.data]);


    if (props.workspace == null || (!products.isLoading && (products.data == null || products.data.length === 0))) {
        return (
            <Header icon={"FabricFolderFill"}>
                No Products on Workspace
            </Header>
        )
    }

    const selection = new Selection({
        onSelectionChanged: () => {
            const selected = selection.getSelection() as IWorkspaceItem[];
            props.onSelectedItemsChanged(selected);
        },
    })

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
        if (!props) {
            return null;
        }
        return (<Sticky enabled={true} top={48} innerZ={10}>{defaultRender!({...props})}</Sticky>);
    };

    const columns = renderWorkspaceColumns(
        getColumns.data?.workspaceColumns ?? WORKSPACECOLUMNS,
        false,
        false,
        (product, details) => {
            detailsChange({workspace: props.workspace, details, product: product.id})
        },
        (item) => {
            removeProduct({workspaceId: props.workspace, wsItemId: item});
        },
        props.onDetailsClick,
        props.onAdhocClick
    );

    const insertBeforeItem = (item: IWorkspaceItem): void => {
        if(draggedIndex !== undefined) {
            const draggedItems = selection.isIndexSelected(draggedIndex)
                ? (selection.getSelection() as IWorkspaceItem[])
                : [draggedItem!];

            const insertIndex = displayProducts.indexOf(item);
            const items = displayProducts.filter(itm => !draggedItems.some(a => a.id === itm.id));

            items.splice(insertIndex, 0, ...draggedItems);

            setDisplayProducts(items)
            updateSortOrder({workspaceId: props.workspace, req:{orderedWorkspaceItemIds: items.map(itm => itm.id)}})
        }
    }

    const getDragDropEvents = () => {
        return {
            canDrop: (dropContext?: IDragDropContext, dragContext?: IDragDropContext) => {
                return true;
            },
            canDrag: (item?: any) => {
                return true;
            },
            onDragEnter: (item?: any, event?: DragEvent) => {
                return dragEnterClass;
            },
            onDragLeave: (item?: any, event?: DragEvent) => {
                return;
            },
            onDrop: (item?: any, event?: DragEvent) => {
                if (draggedItem) {
                    insertBeforeItem(item);
                }
            },
            onDragStart: (item?: any, itemIndex?: number, selectedItems?: any[], event?: MouseEvent) => {
                setDraggedItem({...item});
                setDraggedIndex(itemIndex!);
            },
            onDragEnd: (item?: any, event?: DragEvent) => {
                setDraggedItem(undefined);
                setDraggedIndex(-1);
            },
        };
    }

    return (
        <>
            <ShimmeredDetailsList 
                dragDropEvents={getDragDropEvents()}
                enableShimmer={products.isFetching}
                items={displayProducts ?? []}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                onRenderDetailsHeader={onRenderDetailsHeader}
            />
        </>
    )
};

export default WorkspaceTable;