import React from 'react';
import SearchFilter from '../Common/SearchFilter';
import { TextField } from '@fluentui/react';

interface ISplitKeywordDisplayProps {
    keywords: string[];
    onChange: (keyword: string[]) => void;
    color?: string;
    quoted?: boolean;
}

const SplitKeywordDisplay: React.FC<ISplitKeywordDisplayProps> = (props) => {
    return (
        <>
            {props.keywords.map((keyword, i) => (
                <SplitKeywordSearchFilter
                    color={props.color}
                    key={keyword}
                    value={keyword}
                    quoted={props.quoted}
                    onChange={(value) => {
                        const keywords = [...props.keywords];
                        keywords[i] = value;
                        props.onChange(keywords);
                    }}
                    onDelete={() => {
                        const keywords = [...props.keywords];
                        keywords.splice(i, 1);
                        props.onChange(keywords);
                    }}
                />
            ))}
        </>
    )
}

interface ISplitKeywordSearchFilterProps {
    value: string;
    color?: string;
    quoted?: boolean;
    onChange: (value: string) => void;
    onDelete: () => void;
}

export const SplitKeywordSearchFilter: React.FC<ISplitKeywordSearchFilterProps> = (props) => {
    const [value, setValue] =  React.useState<string>('');

    React.useEffect(() => {
        setValue(props.value.replaceAll('"', ''));
    }, [props.value]);

    return (
        <SearchFilter
            onDelete={props.onDelete}
            color={props.color}
        >
            <TextField
                underlined
                value={value}
                styles={{
                    fieldGroup: {backgroundColor: 'transparent'},
                    prefix: {backgroundColor: 'transparent'},
                    suffix: {backgroundColor: 'transparent'},
                }}
                prefix={props.quoted ? '"' : undefined}
                suffix={props.quoted ? '"' : undefined}
                onChange={(e,v) => setValue(v ?? '')}
                onBlur={(e) => {
                    if (value === props.value.replaceAll('"','')) return;
                    props.onChange(props.quoted ? `"${value}"` : value);
                }}
                />
        </SearchFilter>
    )
}

export default SplitKeywordDisplay;