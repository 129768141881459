import React from 'react';
import { useGetUserChatsQuery } from '../../store/Api';
import { Dropdown } from '@fluentui/react';

interface IChatSelectionProps {
    selected?: string;
    onSelect?: (selected?: string) => void;
}

const ChatSelection: React.FC<IChatSelectionProps> = (props) => {
    const chats = useGetUserChatsQuery();
    return (
        <Dropdown
            label='Select Chat'
            options={[
                ...(chats.data?.items.map(c => ({key: c.id ?? '', text: c.name ?? c.id ?? ''})) ?? []),
                {key: '', text: 'New Chat'}
            ]}
            selectedKey={props.selected}
            onChange={(e, d) => {
                if (d?.key === '') {
                    props.onSelect?.(undefined);
                } else {
                    props.onSelect?.(d?.key?.toString());
                }
            }}
        />
    )
}

export default ChatSelection;