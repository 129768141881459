import React from 'react';
import {IInstalledExtension} from "../../models/Extension";
import {
    CommandBar,
    ICommandBarItemProps,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import {useRunAnalysisExtensionMutation} from "../../store/Api";
import {IsMutationSuccess} from "../../logic/MutationTypeChecker";

interface IAnalysisExtensionImportDisplayProps {
    extension: IInstalledExtension;
    onCancel: () => void;
    onRun: (id: string) => void;
}

const AnalysisExtensionImportDisplay: React.FC<IAnalysisExtensionImportDisplayProps> = (props) => {
    const [id, setId] = React.useState<string>()
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [runExtension] = useRunAnalysisExtensionMutation();

    const run = () => {
        if (id == null || id === '') return;
        setError(undefined);
        runExtension({
            externalId: id,
            extension: props.extension.id
        }).then((response) => {
            if (IsMutationSuccess(response)) {
                if (response.data.success)
                    props.onRun(response.data.id);
                else
                    setError(response.data.message ?? "Could not run extension");
            } else {
                setError("Could not run extension")
            }
        })
    }

    const commands: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: 'Back',
            iconProps: {iconName: 'Back'},
            onClick: props.onCancel,
            iconOnly: true
        },
        {
            key: 'title',
            text: 'New Customer Request',
        },
    ]

    return <div>
        <CommandBar items={commands} />
        {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar> }
        <Stack tokens={{childrenGap: '1em'}}>
            <TextField label={'External ID'} value={id} onChange={(e, v) => setId(v)} />
            <PrimaryButton
                onClick={run}
                text={'Run'}
            />
        </Stack>
    </div>
}

export default AnalysisExtensionImportDisplay;