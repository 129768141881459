import React from 'react'
import {IWorkspaceItem} from "../../models/Workspace";
import {TextField} from "@fluentui/react";
import { TooltipHost } from '@fluentui/react/lib/Tooltip';

interface IQuantityEntryProps {
    item: IWorkspaceItem;
    onChange: (qty: number) => void;
}

const QuantityEntry: React.FC<IQuantityEntryProps> = (props) => {
    const [tempValue, setTempValue] = React.useState<string | undefined>(props.item.details?.quantity?.toString() ?? "1");
    const [warn, setWarn] = React.useState<boolean>(false);

    React.useEffect(() => {
        const stock = calculateStock();
        if (props.item.details?.quantity && stock < props.item.details?.quantity) {
            setWarn(true);
        } else {
            setWarn(false);
        }
    }, [props.item.details?.quantity, props.item.details?.source])

    React.useEffect(() => {
        setTempValue(props.item.details?.quantity?.toString() ?? '1');
    }, [props.item.details?.quantity]);

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, v: string | undefined) => {
        setTempValue(v);
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.currentTarget.value === '') {
            setTempValue('1');
            props.onChange(1);
            return;
        }
        setTempValue(e.currentTarget.value ?? '1');
        const val = parseFloat(e.currentTarget.value ?? '1');
        if (!isNaN(val)) {
            props.onChange(val);
        }  else {
            props.onChange(1);
        }
    }

    const calculateStock = () => {
        let stock = 0;
        if(props.item.product?.vendors == null) return stock;
        if(props.item.details?.source === 'LowestPrice'){ //just lowest price
            let cost = 0;
            props.item.product?.vendors.forEach(v =>{
                if(cost == 0 || (v.cost && v.cost < cost)){
                    stock = v.inStock ? (v.onHand ?? 99999) : 0;
                    cost = v.cost ?? 0;
                }
            })
        }
        else if(props.item.details?.source === 'LowestInStock'){ //lowest price in stock
            let cost = 0;
            props.item.product?.vendors.forEach(v =>{
                if(cost == 0 || ((v.cost && v.cost < cost) && v.onHand)){
                    stock = v.inStock ? (v.onHand ?? 99999) : 0;
                    cost = v.cost ?? 0;
                }
            })
        }
        else{ //vendor specific selection
            const vendor = props.item.product?.vendors.find((v) => v.vendorName === props.item.details?.source);
            if (vendor) {
                stock = vendor.inStock ? (vendor.onHand ?? 99999) : 0;
            }
        }
        return stock;
    }

    return (<>
        <TooltipHost
            content="Quantity exeeds stock"
            hidden={!warn}
        >
            <TextField
                iconProps={warn ? {iconName: 'WarningSolid', style:{color: 'red'}} : undefined}
                value={tempValue}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            </TooltipHost>
        </>);
}

export default QuantityEntry;