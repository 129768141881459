import React from 'react';
import SessionKeyNavBar from "./SessionKeyNavBar";

const SessionKeyLayout: React.FC = (props) => {
    return (<>
        <SessionKeyNavBar />
        <div className='all-content'>
            {props.children}
        </div>
    </>);
}

export default SessionKeyLayout;