import {IDisplayedField} from "./models/Settings";
import {FontWeights, getTheme, IButtonStyles, mergeStyleSets} from "@fluentui/react";

export const WORKSPACE_NAME = "workspace_name";
export const DISPLAY_FORMAT_STORAGE_KEY = "display_format";

export const EXTENSION_TYPES = [
    "Import",
    "Export",
    "Webhook",
    "Realtime"
];

export enum ExtensionType {
    Import = "import",
    Export = "export",
    Webhook = "webhook",
    Realtime = "realtime",
    Recommendation = "recommendation",
    Procurement = "procurement",
    StorefrontOpportunity = "storefrontOpportunity",
    Locations = "locations",
    Customer = "customer",
    Tax = "tax",
}

interface IProductFieldNames {
    [key: string]: string,
}

export const PRODUCT_FIELDS: IProductFieldNames = {
    'costDisplay': 'Cost',
    'stockDisplay': 'Availability',
    'imageDisplay': 'Image',
    'manufacturer': 'Manufacturer',
    'manufacturerPartNumber': 'MFP',
    'msrp': 'MSRP',
    'name': 'Name',
    'segment': 'Segment',
    'category': 'Category',
}

export const DEFAULT_DISPLAY_FIELDS: IDisplayedField[] = [
    {
        id: 1,
        field: 'imageDisplay',
        size: 1,
    },
    {
        id: 2,
        field: 'manufacturer',
        size: 2,
    },
    {
        id: 3,
        field: 'manufacturerPartNumber',
        size: 2,
    },
    {
        id: 4,
        field: 'name',
        size: 5,
    },
    {
        id: 5,
        field: 'stockDisplay',
        size: 2,
    },
    {
        id: 6,
        field: 'costDisplay',
        size: 2,
    }
]

export const MULTI_EDIT_FIELDS = [
    'manufacturer'
];

export const MANUFACTURER = 'manufacturer';

export const SEGMENT = 'segment';

export const CATEGORY = 'category';

export const PLACEHOLDER_IMAGE = 'https://resources.adaptivecatalog.com/images/no-image.jpg';

export const SEARCHCOLUMNS: string[] = ["favorite", "image", "manufacturer", "mfp", "name", "availability", "cost", "details"];

export const WORKSPACECOLUMNS: string[] = ["sortOrder","image", "name", "availability", "cost", "quantity", "actions"];
export const DEFAULTPROCUREMENTCOLUMNS: string[] = ["image", "name", "manufacturer", "manufacturerPartNumber", "source", "quantity"];

export const EXPORTABLEPROCUREMENTCOLUMNS: string[] = ["name", "manufacturer", "manufacturerPartNumber", "source", "quantity", "id", "userName", "productId", "workspace",
    "workspaceName", "cost", "vendorPartNumber", "company", "order", "stock", "requirementNotes", "externalItemId", 
    "requestedCarrier", "orderedQuantity", "backOrderedQuantity", "confirmedQuantity", "confirmedCost", "orderLineNumber"];

export const PREPAREORDERITEMCOLUMNS: string[] = ["details", "name", "manufacturer", "mfp", "vpn", "prepareQuantity", "stock", "cost", "userName", "workspace"];
export const ORDERITEMCOLUMNS: string[] = ["name", "manufacturer", "mfp", "vpn", "quantity", "stock", "cost", "userName", "workspace"];
export const DIRECTIMPORTCOLUMNS: string[] = ["manufacturerPartNumber", "manufacturer", "vendorPartNumber", "cost", "price", "name", "description", "quantity", "opportunity", "salesOrder", "company", "vendor", "businessUnit", "location", "productClass", "purchaseDate", "status", "isAcProduct"];
export const DEFAULTDIRECTIMPORTCOLUMNS: string[] =["isAcProduct", "manufacturerPartNumber", "manufacturer", "cost", "price", "name", "description", "quantity", "opportunity", "purchaseDate",]

const theme = getTheme();
export const MODAL_STYLE = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: '4px solid #789c2f',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'unset',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    },
    footer: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'unset',
        textAlign: 'right',
        selectors: {
            p: {margin: '14px 0'},
            'p:first-child': {marginTop: 0},
            'p:last-child': {marginBottom: 0},
        },
    }
});

export const MODAL_ICON_BUTTON_STYLE: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];