import React from 'react';
import { INormalizedProduct } from '../../models/NormalizedProduct';
import { ProgressIndicator } from '@fluentui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import CurrencyFormat from 'react-currency-format';

interface IBestPriceDisplayProps {
    product: INormalizedProduct;
    refreshingVendors?: string[];
}

const BestPriceDisplay: React.FC<IBestPriceDisplayProps> = (props) => {
    const desiredQuantity = useSelector((state: RootState) => state.search.desiredQuantity);
    const inStock = props.product.vendors?.some(v => v.inStock);
    // If any vendors still refreshing, show a loading spinner
    if (props.refreshingVendors && props.refreshingVendors.length > 0) {
        return (<div
            className={`vendor-dense text-center ${inStock ? 'vendor-loading-in-stock-cell' : 'vendor-loading-no-stock-cell'})}`}
            >
            <ProgressIndicator label='Loading'/>
        </div>);
    }

    if (props.product.bestPrice == null ||
        props.product.bestBasePrice == null ||
        props.product.bestVendor == null)
        return (<div>No data</div>);

    const discount = props.product.bestBasePrice > props.product.bestPrice
        ? (props.product.bestBasePrice - props.product.bestPrice).toFixed(2)
        : null;


    return (
        <div 
            className={`vendor-dense ${inStock ? 'in-stock-dense' : 'no-stock-dense'}`}
        >
            <h4>{props.product.bestVendor}</h4>
            <p style={{fontWeight: 'bold'}}>
                <CurrencyFormat
                    value={props.product.bestPrice}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            </p>
            {discount && <p style={{fontStyle:'italic'}}>
                Discount<br />
                <CurrencyFormat
                    value={discount}
                    displayType="text"
                    thousandSeparator=","
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale={true}
                />
            </p>}
            {props.product.bestVendorOnHand != null && props.product.bestVendorOnHand > 0 && desiredQuantity && props.product.bestVendorOnHand < desiredQuantity && <p style={{color: 'red'}}>Only {props.product.bestVendorOnHand} in stock</p>}
            {props.product.bestVendorOnHand === 0 && <p style={{color: 'red'}}>No Stock</p>}
        </div>
    );
};

export default BestPriceDisplay;