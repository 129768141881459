import React, {useEffect} from 'react';
import {IImportMapping} from "../../models/Extension";
import {DetailsList, Dropdown, IColumn, IDropdownOption, SelectionMode} from "@fluentui/react";
import {clone, zip} from "lodash";

interface IMappingTableProps {
    sampleData: string[][];
    initialMapping: IImportMapping[];
    onChange: (mapping: IImportMapping[]) => void;
    transpose?: boolean;
}

const MappingTable: React.FC<IMappingTableProps> = (props) => {
    const [sampleData, setSampleData] = React.useState<any[]>([]);
    const [mappings, setMappings] = React.useState<IImportMapping[]>([])
    const [copiedMappings, setCopiedMappings] = React.useState(false);

    useEffect(() => {
        props.onChange(mappings);
    }, [mappings]);

    useEffect(() => {
        if (!copiedMappings && mappings.length === 0 && props.initialMapping.length > 0) {
            setMappings(props.initialMapping);
            setCopiedMappings(true);
        }
    }, [props.initialMapping, copiedMappings]);

    useEffect(() => {
        let raw: (string | undefined)[][] = props.sampleData;
        if (props.transpose) {
            raw = zip(...raw);
        }
        const s: { [colNum: string]: any }[] = raw.map((row) => {
            const data: { [colNum: string]: any } = {};
            for (let i = 0; i < row.length; i++) {
                data[`Row ${i + 1}`] = row[i] ?? '';
            }
            return data;
        });
        setSampleData(s);
    }, [props.sampleData, props.transpose]);

    const fieldMappingOptions: IDropdownOption[] = [
        {key: 'UNMAPPED', text: 'Unmapped'},
        {key: 'vendorPartNumber', text: 'VPN'},
        {key: 'manufacturerPartNumber', text: 'MFP'},
        {key: 'manufacturer', text: 'Manufacturer'},
        {key: 'name', text: 'Name',},
        {key: 'msrp', text: 'MSRP'},
        {key: 'upc', text: 'UPC'},
        {key: 'cost', text: 'Cost'},
        {key: 'onHand', text: 'On Hand (Quantity)'},
        {key: 'inStock', text: 'In Stock (Boolean)'}
    ];

    const columns: IColumn[] = sampleData.length > 0 ? [
        {
            key: 'ColumnNumber',
            minWidth: 100,
            maxWidth: 100,
            name: 'Column #',
            onRender: (c,i) => <span>{i != null && i+1}</span>
        },
        {
            key: 'Mapping',
            minWidth: 200,
            name: 'Mapping',
            maxWidth: 200,
            onRender: (c: any, i) => (<Dropdown
                options={fieldMappingOptions}
                onChange={(e, d) => {
                    if (i !== undefined) {
                        const newMappings = clone(mappings);
                        const mi = newMappings.findIndex(m => m.column === i);
                        if (mi === -1) {
                            newMappings.push({column: i, field: d?.key.toString() ?? ''});
                        } else {
                            newMappings[mi].field = d?.key.toString() ?? ''
                        }
                        setMappings(newMappings);
                    }
                }}
            />)
        },
        ...Object.keys(sampleData[0]).map(k => ({
            key: k,
            minWidth: 100,
            fieldName: k,
            name: k,
            maxWidth: 200,
            isMultiline: true,
        })),
    ] : [];

    return (
        <DetailsList items={sampleData} columns={columns} selectionMode={SelectionMode.none}/>
    )
}

export default MappingTable;