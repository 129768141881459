import React from 'react';
import {IAttribute} from "../../models/Search";
import {cloneDeep} from "lodash";
import SearchFilter from "../Common/SearchFilter";
import FacetDropdown from "./FacetDropdown";
import {IDropdownOption} from "@fluentui/react/lib/Dropdown";

interface ISelectedFacetDisplayProps {
    mustFilter?: IAttribute[];
    boostFilter?: IAttribute[];
    excludeFilter?: IAttribute[];
    color?: string;
    onFacetChange: (must: IAttribute[], boost: IAttribute[], exclude: IAttribute[]) => void;
}
const SelectedFacetDisplay: React.FC<ISelectedFacetDisplayProps> = (props) => {

    function handleChange(facet: IAttribute, option?: IDropdownOption) {
        const selection = option?.key ?? 'remove';
        const must = props.mustFilter ? cloneDeep(props.mustFilter) : [];
        const boost = props.boostFilter ? cloneDeep(props.boostFilter) : [];
        const exclude = props.excludeFilter ? cloneDeep(props.excludeFilter) : [];

        const mIndex = must.findIndex(a => a.name === facet.name && a.value === facet.value);
        const bIndex = boost.findIndex(a => a.name === facet.name && a.value === facet.value);
        const eIndex = exclude.findIndex(a => a.name === facet.name && a.value === facet.value);

        if (selection === 'MUST' && mIndex === -1) must.push(facet);
        else if (selection !== 'MUST' && mIndex !== -1) must.splice(bIndex,1);

        if (selection === 'BOOST' && bIndex === -1) boost.push(facet);
        else if (selection !== 'BOOST' && bIndex !== -1) boost.splice(bIndex,1);

        if (selection === 'EXCLUDE' && eIndex === -1) exclude.push(facet);
        else if (selection !== 'EXCLUDE' && eIndex !== -1) exclude.splice(bIndex,1);

        props.onFacetChange(must, boost, exclude);
    }
    
    return (
        <>
            {props.mustFilter?.map((attr, i) => (
                <SearchFilter key={`must-${attr.type}${attr.name}${attr.value}${i}`} color={props.color} onDelete={() => {
                    if (props.mustFilter == null) return;
                    handleChange(attr);
                }}>
                    <FacetDropdown 
                        facet={attr} 
                        onFacetChange={handleChange} 
                        selectedKey={'MUST'} 
                    />
                    {attr.name}: {attr.value}
                </SearchFilter>
                )
            )}
            {props.boostFilter?.map((attr,i) => (
                <SearchFilter key={`boost-${attr.type}${attr.name}${attr.value}`} color={props.color} onDelete={() => {
                    if (props.boostFilter == null) return;
                    handleChange(attr);
                }}>
                    <FacetDropdown 
                        facet={attr} 
                        onFacetChange={handleChange} 
                        selectedKey={'BOOST'} 
                    />
                    {attr.name}: {attr.value}
                </SearchFilter>
                )
            )}
            {props.excludeFilter?.map((attr,i) => (
                <SearchFilter key={`exclude-${attr.type}${attr.name}${attr.value}`} color={props.color} onDelete={() => {
                    if (props.excludeFilter == null) return;
                    handleChange(attr);
                }}>
                    <FacetDropdown 
                        facet={attr} 
                        onFacetChange={handleChange} 
                        selectedKey={'EXCLUDE'} 
                    />
                    {attr.name}: {attr.value}
                </SearchFilter>

                )
            )}
        </>
    );
}

export default SelectedFacetDisplay;