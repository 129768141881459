import { Dropdown, IDropdownOption } from '@fluentui/react';
import React from 'react';

interface IMarkupTypeSelectionProps {
    value?: string;
    onChange?: (markupType: string) => void;
    label?: string;
    disabled?: boolean;
}

export const MARKUP_TYPES = {
    '' : 'Inherit from global settings',
    'MARKUP_FIXED': 'Fixed',
    'MARKUP_PERCENTAGE': 'Percentage',
    'GROSS_MARGIN': 'Gross Margin',
}

const MarkupTypeSelection: React.FC<IMarkupTypeSelectionProps> = (props) => {
    const options: IDropdownOption[] = Object.entries(MARKUP_TYPES).map(([key, value]) => ({key, text: value}));
    return (
        <Dropdown
            label={props.label}
            selectedKey={props.value || ''}
            disabled={props.disabled}
            onChange={(e, option) => {
                if (option && props.onChange) {
                    props.onChange(option.key as string);
                }
            }}
            options={options}
        />
    );
}

export default MarkupTypeSelection;