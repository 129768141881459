import {Icon} from "@fluentui/react";
import React from "react";

interface IHeaderProps {
    icon?: string;
    size?: string;
    align?: string;
    reverseIcon?: boolean;
    showIconCircle?: boolean;
    padding?: number;
}

const Header: React.FC<IHeaderProps> = (props) => {
    const size = props.size ?? "h2";
    const align = props.align ?? "center";
    const modifier = (props.reverseIcon === true ? "-reverse" : (props.showIconCircle !== false ? "" : "-large")) 
    const iconCircle = props.showIconCircle !== false ? "ac-icon-container-circle" : "ac-icon-container"
    const iconContent = props.icon ? <div className={iconCircle} key={"icon-container"}><Icon iconName={props.icon} className={"ac-icon" + modifier}/></div> : <></>;
    const innerContent = <div className="content" key={"header-details"}>{props.children}</div>

    return (
        React.createElement(size, {className: 'ac-header', align: align}, [iconContent, innerContent])
    );
}

export default Header;