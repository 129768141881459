import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {SerializedError} from "@reduxjs/toolkit";

export function IsMutationSuccess<T>(result: any): result is {data:T} {
    return (result as {data:T}) !== undefined;
}

export function IsMutationError(result: any): result is {error: FetchBaseQueryError | SerializedError} {
    return (result as {error: FetchBaseQueryError | SerializedError}) !== undefined;
}

export function IsSerializedError(result: any): result is SerializedError {
    return (result as SerializedError) !== undefined;
}

export function IsFetchBaseQueryError(result: any): result is FetchBaseQueryError {
    return (result as FetchBaseQueryError) !== undefined;
}