import { combineReducers } from 'redux';
import authReducer from './authSlice';
import searchReducer, { ISearchSliceState } from './searchSlice';
import settingsSlice, { ISettingsSliceState } from './settingsSlice';
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist';
import api from "./Api";

const searchPersistConfig = {
    key: 'search',
    storage,
}

const settingsPersistConfig = {
    key: 'settings',
    storage,
    blacklist: [
        'loggingOut', 
        'punchoutWorkspace',
        'effectiveClaims', 
        'externalQuoteId', 
        'externalQuotePartitionId', 
        'punchoutMode',
        'selectedWorkspaceId'
    ]
}

export const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    search: persistReducer<ISearchSliceState>(searchPersistConfig, searchReducer),
    settings: persistReducer<ISettingsSliceState>(settingsPersistConfig, settingsSlice),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;