import React from "react";

import { DEFAULTPROCUREMENTCOLUMNS} from "../../Constants";

import CustomColumnEdit from "../../components/ColumnCustomization/CustomColumnEdit";
import {
    PROCUREMENT_ITEM_COLUMN_PROPERTIES,
    DEMO_PROCUREMENT_ITEMS,
    getProcurementItemColumnOptions
} from "../../logic/Columns/ProcurementItemColumns";

const CustomColumnManagement: React.FC = () => {
    
    return (
        <div className="main-content">
            <h1>Custom Column Management</h1>
            <CustomColumnEdit 
                columnSetName={"ProcurementItem"} 
                defaultColumns={DEFAULTPROCUREMENTCOLUMNS} 
                dropdownOptions={getProcurementItemColumnOptions()} 
                testItems={DEMO_PROCUREMENT_ITEMS} 
                columnMetadata={PROCUREMENT_ITEM_COLUMN_PROPERTIES}
            />
        </div>
    );
}

export default CustomColumnManagement;
