import React from 'react';
import SearchFilter from "../Common/SearchFilter";

interface ISelectedDisplayProps {
    selections: string[];
    onChange: (value: string[]) => void;
    color?: string;
    prefix?: string;
}

const SelectedDisplay: React.FC<ISelectedDisplayProps> = (props) => {
    return (
        <>
            {props.selections.map((item) => (
                <SearchFilter key={item} color={props.color} onDelete={() => {
                    const items = [...props.selections];
                    const index = items.indexOf(item);
                    items.splice(index, 1);
                    props.onChange(items);
                }}>{props.prefix? `${props.prefix}: `: ""}{item}</SearchFilter>
            ))}
        </>
    )
}

export default SelectedDisplay;