import React from 'react';
import {useGetMonthlyApiUsageReportQuery} from "../../../store/Api";
import {CommandBar, IColumn, ICommandBarItemProps, SelectionMode, ShimmeredDetailsList} from "@fluentui/react";
import {ColumnGenerator, CurrencyColumnGenerator} from "../../../logic/Columns/ColumnGenerator";
import {range} from "lodash";
import { MONTHS } from '../../../Constants';

const ApiUsageReport: React.FC = () => {
    const [year, setYear] = React.useState(new Date().getFullYear());
    const [month, setMonth] = React.useState(new Date().getMonth() + 1);
    const report = useGetMonthlyApiUsageReportQuery({year: year, month: month});

    const pricingTable = [
        {
            name: 'Base Rate',
            price: null,
            quantity: null,
            total: report.data?.baseCost
        },
        {
            name: 'Included API Calls',
            price: null,
            quantity: `${Math.min(report.data?.totalCalls ?? 0, report.data?.includedCalls ?? 0)} of ${report.data?.includedCalls}`,
            total: null
        },
        {
            name: `Additional API Calls (per ${report.data?.callsPerBundle}) - ${(report.data?.totalCalls ?? 0) - (report.data?.includedCalls ?? 0)} calls`,
            price: report.data?.costPerBundle,
            quantity: report.data?.bundles,
            total: (report.data?.costPerBundle ?? 0) * (report.data?.bundles ?? 0)
        },
        {
            name: 'Total',
            price: null,
            quantity: null,
            total: report.data?.totalCost
        }
    ]

    const totalColumns: IColumn[] = [
        ColumnGenerator('Name', 'name', 100, undefined, undefined, true),
        CurrencyColumnGenerator('Price', 'price', 100, 100),
        ColumnGenerator('Quantity', 'quantity', 100, 100, undefined, undefined, 'right'),
        CurrencyColumnGenerator('Total', 'total', 100, 100),
    ]

    const commands: ICommandBarItemProps[] = [
        {
            key: 'monthSelection',
            text: MONTHS[month - 1],
            iconProps: {iconName: 'Calendar'},
            subMenuProps: {
                items: MONTHS.map((month, index) => ({
                    key: `month_${index}`,
                    text: month,
                    onClick: () => setMonth(index + 1)
                }))
            }
        },
        {
            key: 'yearSelection',
            text: year.toString(),
            iconProps: {iconName: 'CalendarYear'},
            subMenuProps: {
                items: range(new Date().getFullYear() - 4, new Date().getFullYear() + 1).map((year) => ({
                    key: `year_${year}`,
                    text: year.toString(),
                    onClick: () => setYear(year)
                }))
            }

        }
    ]

    const farCommands: ICommandBarItemProps[] = [
        { key: 'refresh', text: 'Refresh', iconProps: { iconName: 'Refresh' }, onClick: () => {report.refetch()}, iconOnly: true },
    ]

    return <>
        <h2>API Usage</h2>
        <CommandBar items={commands} farItems={farCommands} />
        <ShimmeredDetailsList
            selectionMode={SelectionMode.none}
            items={pricingTable}
            enableShimmer={report.isLoading}
            columns={totalColumns}
        />
        <ApiUsageData data={report.data?.countByUser} title='Top Usage by User' />
        <ApiUsageData data={report.data?.countBySubOrganization} title='Top Usage by Sub-Organization' />
        <ApiUsageData data={report.data?.countByApiKey} title='Top Usage by API Key' />
    </>
}

interface IApiUsageDataProps {
    data?: {[key:string]: number}
    title: string;
}

const ApiUsageData: React.FC<IApiUsageDataProps> = (props) => {
    if (!props.data || Object.keys(props.data).length === 0) return null;

    const tableData = Object.entries(props.data).map(([name, count]) => ({name, count}));
    const columns: IColumn[] = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('Count', 'count', 100, 100),
    ]

    return <>
        <h3>{props.title}</h3>
        <ShimmeredDetailsList items={tableData} columns={columns} selectionMode={SelectionMode.none} />
    </>
}

export default ApiUsageReport;