import React from 'react';
import { useGetPaymentProcessorSetupQuery } from '../../../store/Api';
import { DefaultButton, ShimmeredDetailsList } from '@fluentui/react';
import { ColumnGenerator } from '../../../logic/Columns/ColumnGenerator';
import StripeSetupDialog from './StripeSetupDialog';

interface IPaymentProcessorRow {
    processor: string;
    setup?: boolean;
    button: React.ReactNode;
}

const PaymentProcessorDetails: React.FC = () => {
    const [setupStripe, setSetupStripe] = React.useState<boolean>(false);
    const setupProcessors = useGetPaymentProcessorSetupQuery();
    const [data, setData] = React.useState<IPaymentProcessorRow[]>([])

    React.useEffect(() => {
        if (setupProcessors.data == null) return;
        setData([
            { processor: 'Stripe', setup: setupProcessors.data.stripeSetup, button: <DefaultButton text='Setup' onClick={() => setSetupStripe(true)} /> }
        ])
    }, [setupProcessors.data])

    const columns = [
        ColumnGenerator('Processor', 'processor', 100),
        ColumnGenerator('Status', 'setup', 100, 100, (item: IPaymentProcessorRow) => <>{item.setup ? 'Setup' : 'Not Setup'}</>),
        ColumnGenerator('Setup', 'button', 100, 100),
    ]

    return <>
        <ShimmeredDetailsList
            enableShimmer={setupProcessors.isLoading}
            items={data}
            columns={columns}
            />
        <StripeSetupDialog isOpen={setupStripe} onDismiss={() => setSetupStripe(false)} />
    </>
}

export default PaymentProcessorDetails;
