import React from "react";
import {FacetSelection} from "./FacetSelection";
import {IAttribute, ISearchQueryResponse} from "../../models/Search";
import {calculateAttribute, mergeAttributes} from "../../logic/Search";
import {cloneDeep} from "lodash";
import Divider from "../Common/Divider";
import Accordion from "../Common/Accordion";

interface IFacetPanelProps {
    mustFilter?: IAttribute[];
    boostFilter?: IAttribute[];
    excludeFilter?: IAttribute[];
    onUpdate: (must: IAttribute[], boost: IAttribute[], exclude: IAttribute[]) => void;
    results?: ISearchQueryResponse;
    attributeType: string;
}

const FacetPanel: React.FC<IFacetPanelProps> = React.memo((props) => {
    let merged = mergeAttributes(props.results?.metadata?.facets ? cloneDeep(props.results.metadata.facets) : {}, props.mustFilter ?? [], props.attributeType)
    merged = mergeAttributes(merged, props.boostFilter ?? [], props.attributeType);
    merged = mergeAttributes(merged, props.excludeFilter ?? [], props.attributeType);
    const calculated = calculateAttribute(
        merged,
        props.mustFilter ?? [],
        props.boostFilter ?? [],
        props.excludeFilter ?? [],
    );
    const sortedFacets = Object.entries(calculated).sort();

    const updateAttributes = (attribute: IAttribute, destination: 'must' | 'boost' | 'exclude' | 'remove') => {
        const must = props.mustFilter ? cloneDeep(props.mustFilter) : [];
        const boost = props.boostFilter ? cloneDeep(props.boostFilter) : [];
        const exclude = props.excludeFilter ? cloneDeep(props.excludeFilter) : [];
        const mIndex = must.findIndex(a => a.name === attribute.name && a.value === attribute.value);
        const bIndex = boost.findIndex(a => a.name === attribute.name && a.value === attribute.value);
        const eIndex = exclude.findIndex(a => a.name === attribute.name && a.value === attribute.value);
        if (destination === 'must' && mIndex === -1) must.push(attribute);
        else if (destination !== 'must' && mIndex !== -1) must.splice(bIndex,1);

        if (destination === 'boost' && bIndex === -1) boost.push(attribute);
        else if (destination !== 'boost' && bIndex !== -1) boost.splice(bIndex,1);

        if (destination === 'exclude' && eIndex === -1) exclude.push(attribute);
        else if (destination !== 'exclude' && eIndex !== -1) exclude.splice(bIndex,1);

        props.onUpdate(must,boost,exclude);
    }

    const addMust = (attribute: IAttribute) => updateAttributes(attribute, 'must');
    const addBoost = (attribute: IAttribute) => updateAttributes(attribute, 'boost')
    const addExclude = (attribute: IAttribute) => updateAttributes(attribute, 'exclude')
    const removeCriteria = (attribute: IAttribute) => updateAttributes(attribute, 'remove');

    return sortedFacets && sortedFacets.length > 0
        ? (
            <div>
                <Divider title="Attributes"/>
                    {sortedFacets.map((facet) => (
                        <div key={facet[0]}>
                            <Accordion title={facet[0]}>
                                <FacetSelection
                                    key={facet[0]}
                                    attributeType={props.attributeType}
                                    facet={facet[0]}
                                    facets={facet[1] || {}}
                                    onAddMust={addMust}
                                    onAddBoost={addBoost}
                                    onAddExclude={addExclude}
                                    onRemoveCriteria={removeCriteria}
                                    boostFilter={props.boostFilter}
                                    mustFilter={props.mustFilter}
                                    excludeFilter={props.excludeFilter}
                                />
                            </Accordion>
                        </div>
                    ))}
            </div>
        )
        : null;
});

export default FacetPanel;