import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import {DefaultButton, IContextualMenuItem, IContextualMenuProps} from "@fluentui/react";
import { INormalizedProduct } from "../../models/NormalizedProduct";
import {
    useAddProductToWorkspaceMutation, 
    useGetRecentWorkspacesQuery
} 
    from "../../store/Api";
import {IWorkspace} from "../../models/Workspace";

interface IAddToWorkspaceButtonProps {
    onChange?: () => void;
    product: INormalizedProduct;
    source?: string;
    priceLevel?: string;
}


const AddToWorkspaceButton: React.FC<IAddToWorkspaceButtonProps> = (props) => {
    const [addedWorkspaces, setAddedWorkspaces] = useState<string[]>([])
    const getWorkspaces = useGetRecentWorkspacesQuery();
    const [addProduct] = useAddProductToWorkspaceMutation();
    const defaultWorkspace = useSelector((state: RootState) => state.settings.selectedWorkspaceId);
    const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkspace>()
    const desiredQuantity = useSelector((state: RootState) => state.search.desiredQuantity);

    useEffect(() => {
        if(!getWorkspaces.data || getWorkspaces.data.length === 0) return;
        if(defaultWorkspace){
            const foundWorkspace = getWorkspaces.data.filter(a => a.id === defaultWorkspace)?.[0]
            if(foundWorkspace){
                console.log('setting found workspace', foundWorkspace)
                setSelectedWorkspace(foundWorkspace)
                return
            }
        }
        setSelectedWorkspace(getWorkspaces.data[0])
    }, [getWorkspaces, defaultWorkspace]);
    
    function addToWorkspace(workspace: string) {
        addProduct({
            workspace,
            product: props.product,
            req: { source: props.source, priceLevelPartNumber: props.priceLevel, quantity: desiredQuantity }
        });
        const newAddedList = [workspace, ...addedWorkspaces];
        setAddedWorkspaces(newAddedList);
        if(props.onChange) {
            props.onChange();
        }
    }
    
    const getMenuProps = () : IContextualMenuItem[] => {
        return getWorkspaces.data?.map((ws) => ({
            disabled: addedWorkspaces.includes(ws.id),
            key: ws.id,
            text: ws?.name == undefined ? `Loading...`: `Add to ${ws.name} workspace`,
            onClick: () => addToWorkspace(ws.id)
        }))  ?? []
    }

    const menuProps: IContextualMenuProps = {
        items: getMenuProps()
    }

    return ( 
        <div id={'addToWorkspace-container'} style={{display: 'inline-flex'}}>
            {props.onChange ?
            <DefaultButton
                text={ selectedWorkspace?.name == undefined ? `Loading...` : `Add to ${selectedWorkspace.name} workspace`}
                menuProps={menuProps}
                primary
                onClick={() => {
                    if (selectedWorkspace) {
                        addToWorkspace(selectedWorkspace.id)
                    }
                }}
                disabled={getWorkspaces.isLoading}
                primaryDisabled={!selectedWorkspace}
                style={{ marginLeft: '1em'}}
                split
                iconProps={{ iconName: 'Add' }}
            />
            : <DefaultButton
                text={'Add'}
                menuProps={menuProps}
                primary
                disabled={getWorkspaces.isFetching}
                style={{height: '36px', padding:'0px 6px'}}
            />}
        </div>
    )
}

export default AddToWorkspaceButton;
