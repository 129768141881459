import React from 'react';
import {
    useDeleteCustomerAnalysisMutation,
    useGetCustomerAnalysisForWorkspaceQuery,
    useGetInstalledExtensionsQuery
} from "../../store/Api";
import {ButtonColumnGenerator, ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {ICustomerRequestAnalysisOverview} from "../../models/MachineLearning";
import {CommandBar, DetailsList, ICommandBarItemProps} from "@fluentui/react";
import {IInstalledExtension} from "../../models/Extension";

interface ICustomerRequestOverviewProps {
    workspaceId: string;
    onSelect: (id: string) => void;
    onExtensionSelect: (ext: IInstalledExtension) => void;
    onNew: () => void;
}

const CustomerRequestOverview: React.FC<ICustomerRequestOverviewProps> = (props) => {
    const requests = useGetCustomerAnalysisForWorkspaceQuery({workspace: props.workspaceId});
    const [deleteReq] = useDeleteCustomerAnalysisMutation();
    const installedExtensions = useGetInstalledExtensionsQuery();

    const columns = [
        ColumnGenerator('Name', 'name'),
        ColumnGenerator('Status', 'status'),
        // ColumnGenerator('User', 'username', 150, 200),
        // ColumnGenerator('Created', 'timestamp', 200, 200),
        ButtonColumnGenerator((i) => deleteReq({id: i.id}), '', 'Delete'),
        ButtonColumnGenerator((i: ICustomerRequestAnalysisOverview) => props.onSelect(i.id), '', 'View')
    ]

    const analysisExtensions = installedExtensions.data?.filter(i => i.extensionType === 'analysis');

    const commands: ICommandBarItemProps[] = [
        {
            key: 'title',
            text: 'Existing Customer Requests',
        },
        {
            key: 'new',
            text: 'New',
            iconProps: {iconName: 'Add'},
            onClick: props.onNew,
            iconOnly: true
        },
    ]
    if (analysisExtensions?.length) {
        commands.push({
            key: 'import',
            iconOnly: true,
            iconProps: {iconName: 'Upload'},
            subMenuProps: {
                items: analysisExtensions.map(ext => ({
                    key: ext.id,
                    text: ext.name,
                    onClick: () => props.onExtensionSelect(ext)
                }))
            }
        });
    }

    const farCommands: ICommandBarItemProps[] = [
        { key: 'refresh', text: 'Refresh', iconProps: { iconName: 'Refresh' }, onClick: () => {requests.refetch()}, iconOnly: true }
    ]
    return <div>
        <CommandBar items={commands} farItems={farCommands} />
        <DetailsList items={requests.data?.items ?? []} columns={columns} />
    </div>
}

export default CustomerRequestOverview;