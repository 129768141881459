import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IOrganization} from '../models/Settings';
export interface ISettingsSliceState {
    selectedWorkspaceId?: string;
    selectedOrganization?: IOrganization;
    loggingOut: boolean;
    punchoutWorkspace?: string;
    effectiveClaims?: string[];
    availableClaims?: {[key: string]: string;};
    externalQuoteId?: string;
    externalQuotePartitionId?: string;
    punchoutMode?: string;
    mappedExtensionFields?: {[key: string]: string;};
    targetExtension?: string;
    refreshSource: boolean
    sourceInstance?: string;
    sessionRefreshCode?: string;
    dismissedNotification?: boolean;
}

const initialState: ISettingsSliceState = {
    loggingOut: false,
    refreshSource: false
}


const settingsSlice = createSlice({
    name: 'settingsSlice',
    initialState,
    reducers: {
        setSelectedWorkspaceId: (state, action: PayloadAction<string | undefined>) => {
            console.log('Setting the global workspace!', action.payload)
            state.selectedWorkspaceId = action.payload;
        },
        setPunchoutWorkspace: (state, action: PayloadAction<string | undefined>) => {
            state.punchoutWorkspace = action.payload;
        },
        setSelectedOrganization: (state, action:PayloadAction<IOrganization | undefined>) => {
            state.selectedOrganization = action.payload;
        },
        updateLoggingOut: (state, action: PayloadAction<boolean>) => {
            state.loggingOut = action.payload;
        },
        clearSettings: (state) => {
            state.selectedWorkspaceId = undefined;
        },
        setEffectivePermissions: (state, action: PayloadAction<string[] | undefined>) => {
            state.effectiveClaims = action.payload;
        },
        setAvailablePermissions: (state, action: PayloadAction<{[key: string]: string;} | undefined>) => {
            state.availableClaims = action.payload;
        },
        setExternalQuoteId: (state, action: PayloadAction<string | undefined>) => {
            state.externalQuoteId = action.payload;
        },
        setExternalQuotePartitionId: (state, action: PayloadAction<string | undefined>) => {
            state.externalQuotePartitionId = action.payload;
        },
        setPunchoutMode: (state, action: PayloadAction<string | undefined>) => {
            state.punchoutMode = action.payload;
        },
        setTargetExtension: (state, action: PayloadAction<string | undefined>) => {
            state.targetExtension = action.payload;
        },
        setMappedExtensionFields: (state, action: PayloadAction<{[key: string]: string;} | undefined>) => {
            state.mappedExtensionFields = action.payload;
        },
        setRefreshSource: (state, action: PayloadAction<boolean>) => {
            state.refreshSource = action.payload;
        },
        setSourceInstance: (state, action: PayloadAction<string>) => {
            state.sourceInstance = action.payload;
        },
        setSessionRefreshCode: (state, action: PayloadAction<string>) => {
            state.sessionRefreshCode = action.payload;
        },
        
        setDismissedNotification: (state) => {
            state.dismissedNotification = true;
        },
    }
});

export const {
    setSelectedOrganization,
    setPunchoutWorkspace,
    updateLoggingOut,
    setSelectedWorkspaceId,
    clearSettings,
    setEffectivePermissions,
    setAvailablePermissions,
    setExternalQuotePartitionId,
    setExternalQuoteId,
    setSourceInstance,
    setPunchoutMode,
    setMappedExtensionFields,
    setTargetExtension,
    setRefreshSource,
    setSessionRefreshCode,
    setDismissedNotification
} = settingsSlice.actions;


export default settingsSlice.reducer;