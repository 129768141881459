import React, {useEffect, useState} from "react";
import {TextField} from "@fluentui/react";
import {ICompany, IContact} from "../../models/Company";
import WorkspaceCompanyAddressDetail from "./WorkspaceCompanyAddressDetail";
import DataField from "../Common/DataField";
import {useLazyGetCompanyQuery} from "../../store/Api";

interface IWorkspaceCompanyDetailsProps {
    currentCompany?: ICompany;
    currentContact?: IContact;
    showParsedAddress?: boolean;
}

const WorkspaceCompanyDetails: React.FC<IWorkspaceCompanyDetailsProps> = props => {
    const [currentContact, setCurrentContact] = useState<IContact>();
    const [parsedAddress, setParsedAddress] = useState<string>('')
    const [getCompany] = useLazyGetCompanyQuery();
    const [currentCompany, setCurrentCompany] = useState<ICompany | undefined>(undefined);
    
    useEffect(() => {
        if(props.currentCompany && !props.currentContact){
            const contact: IContact = {
                id: props.currentCompany.contact?.id ?? '',
                emailAddress: props.currentCompany.contact?.emailAddress ?? '',
                firstName: props.currentCompany.contact?.firstName ?? '',
                lastName: props.currentCompany.contact?.lastName ?? '',
                phoneNumber: props.currentCompany.contact?.phoneNumber ?? '',
                externalId: props.currentCompany.contact?.externalId ?? ''
            }
            setCurrentContact(contact);
            setCurrentCompany(props.currentCompany);
        }
        else{
            setCurrentContact(props.currentContact);
            if(props.currentCompany) setCurrentCompany(props.currentCompany);
            else if(props.currentContact?.company) {
                getCompany(props.currentContact?.company).unwrap().then(a => setCurrentCompany(a))
            }
        }
    }, [props.currentContact, props.currentCompany]);
    
    useEffect(() => {
        if(props.showParsedAddress) {
            const addressString = getDefaultAddressString()
            setParsedAddress(addressString)
        }
    }, [currentCompany]);
    
    const generateContactFields = () => {
        if(currentContact){
            return (<>       
                <DataField
                    hideIfEmpty={true}
                    readOnly={true}
                    label={'First Name'}
                    value={currentContact.firstName ?? ""}
                />
                <DataField
                    hideIfEmpty={true}
                    readOnly={true}
                    label={'Last Name'}
                    value={currentContact.lastName ?? ""}
                />
                <DataField
                    hideIfEmpty={true}
                    readOnly={true}
                    label={'Email Address'}
                    value={currentContact.emailAddress ?? ""}
                />
                <DataField
                    hideIfEmpty={true}
                    readOnly={true}
                    label={'Phone Number'}
                    mask={"(999) 999 - 9999"}
                    value={currentContact.phoneNumber ?? ""}
                />
            </>)
        }
    }
    
    const getDefaultAddressString = () : string => {
        let addressBuilder = ''
        if(currentCompany?.contact?.firstName){
            addressBuilder += currentCompany.contact.firstName + ' ' + (currentCompany.contact?.lastName ?? '') + '\r\n'
        }
        if(currentCompany?.address?.address1){
            addressBuilder += currentCompany.address.address1 + '\r\n'

        }
        else if(currentCompany?.address?.address2){
            addressBuilder += currentCompany.address.address2 + ' ' + (currentCompany.address.address3 ?? '') + '\r\n'
        }
        if(currentCompany?.address?.city){
            addressBuilder += currentCompany.address.city
        }
        if(currentCompany?.address?.stateTerritory){
            addressBuilder += ' ' + currentCompany.address.stateTerritory + ',\r\n'
        }
        if(currentCompany?.address?.postalCodeZip){
            addressBuilder += currentCompany.address.postalCodeZip
        }
        return addressBuilder
    }
    return(
        <div className={"row"}>
            <div className={"column"}>
                <h3>Contact Details</h3>
                {generateContactFields()}
            </div>
            <div className={"column"}>
                <h3>Company Details</h3>
                <DataField
                    label='Company Name *'
                    readOnly={true}
                    value={currentCompany?.companyName}
                />
                <DataField
                    hideIfEmpty={true}
                    readOnly={true}
                    label='Company Identifier'
                    value={currentCompany?.companyIdentifier ?? ""}
                />
                <DataField
                    hideIfEmpty={true}
                    readOnly={true}
                    label='Company Website'
                    value={currentCompany?.website ?? ""}
                />
                <h3>Address</h3>

                <WorkspaceCompanyAddressDetail
                    currentAddress={currentCompany?.address}
                    canEdit={false}
                />
                <br/>
                {props.showParsedAddress &&
                    <TextField
                        rows={6}
                        value={parsedAddress ?? ""}
                        placeholder={"123 Main St. \r\nToledo OH, 43537"}
                        readOnly={true}
                        multiline={true}
                    />
                }
            </div>
        </div>);
}
export default WorkspaceCompanyDetails;