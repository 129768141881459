import React, {useEffect, useState} from "react";
import {useGetOrderCarrierOptionsQuery, useGetOrderWarehouseOptionsQuery} from "../../store/Api";
import {IExtensionOption, IFreightOption} from "../../models/Extension";
import {
    ComboBox,
    DetailsList, IColumn, IComboBoxOption,
    MessageBar, MessageBarType, PrimaryButton,
    Selection,
    SelectionMode,
    TextField
} from "@fluentui/react";
import {renderFreightEstimateColumns} from "../../logic/Columns/FreightEstimateColumns";
import {copyAndSort} from "../../logic/Columns/ColumnUtil";

interface IFreightDetailsProps{
    selectedFreightOption?: string;
    selectedWarehouseOption?: string;
    freightOptions?: IFreightOption[];
    onSelectFreightOption: (option?: string) => void;
    onSelectWarehouseOption: (option?: string) => void;
    loadingFreightEstimate: boolean;
    onStartFreightEstimate: () => void;
    freightMessage?: string;
    vendor?: string;
    canEstimateFreight?: boolean;
}
const FreightDetails: React.FC<IFreightDetailsProps> = props => {
    const [freightOptions, setFreightOptions] = useState<IFreightOption[]>([])
    const [selectedCarrierOption, setSelectedCarrierOption] = useState<string>();
    const [selectedWarehouseOption, setSelectedWarehouseOption] = useState<string>();
    const carrierOptionsData = useGetOrderCarrierOptionsQuery({source: props.vendor ?? ""}, {skip: props.vendor == undefined || props.vendor == ''})
    const [carrierOptions, setCarrierOptions] = useState<IExtensionOption[]>([])
    const warehouseOptionsData = useGetOrderWarehouseOptionsQuery({source: props.vendor ?? ""}, {skip: props.vendor == undefined || props.vendor == ''})
    const [warehouseOptions, setWarehouseOptions] = useState<IExtensionOption[]>([])
    const [columns, setColumns] = useState<IColumn[]>([])
    
    const sortColumn = (column: IColumn): void => {
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const newSortedItems = copyAndSort(freightOptions, column.fieldName!, isSortedDescending);

        columns.map(col => {
            col.isSorted = col.key === column.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }

            return col;
        });

        setFreightOptions(newSortedItems);
    };
    useEffect(() => {
        if(freightOptions){
            setColumns(renderFreightEstimateColumns(sortColumn))
        }
    }, [freightOptions]);
    useEffect(() => {
        if(carrierOptionsData.data){
            setCarrierOptions(carrierOptionsData.data?.options)
        }
    }, [carrierOptionsData]);
    useEffect(() => {
        if(warehouseOptionsData.data){
            setWarehouseOptions(warehouseOptionsData.data?.options)
        }
    }, [warehouseOptionsData]);
    useEffect(() =>{
        setSelectedCarrierOption(props.selectedFreightOption)
    }, [props.selectedFreightOption])

    useEffect(() =>{

        setSelectedWarehouseOption(props.selectedWarehouseOption)
    }, [props.selectedWarehouseOption])

    useEffect(() => {
        if(props.freightOptions){
           setFreightOptions(props.freightOptions) 
        }
    }, [props.freightOptions]);
    
    
    const selection = new Selection({
        onSelectionChanged: () => {
            const selected = selection.getSelection() as IFreightOption[];
            const selectedOption = selected?.[0]
            props.onSelectFreightOption(selectedOption?.carrierIdentifier)
            
        },
    })
    
    const getCarrierOptions = () => {
        const options: IComboBoxOption[] = [{key: '', text: '(Not Selected)'}]
        
        if(carrierOptions) {
            options.push(...carrierOptions.map((a) => {
                return {key: a.value, text: a.display}
            }))
        }
        
        return options;
        
    }
    const getWarehouseOptions = () => {
        const options: IComboBoxOption[] = [{key: '', text: '(Not Selected)'}]

        if(warehouseOptions) {
            options.push(...warehouseOptions.map((a) => {
                return {key: a.value, text: a.display}
            }))
        }

        return options;

    }
    return(
        <div>
            {props.freightMessage &&
                <MessageBar messageBarType={MessageBarType.blocked}>{props.freightMessage}</MessageBar>}
            <div className={'ac-details-field'}>
                {carrierOptions && carrierOptions.length > 1 || carrierOptionsData.isLoading ? <ComboBox
                    label='Requested Carrier'
                    allowFreeform={true}
                    selectedKey={selectedCarrierOption}
                    defaultValue={'Select Carrier'}
                    autoComplete={"on"}
                    options={getCarrierOptions()}
                    onChange={(e, s) =>{
                        props.onSelectFreightOption(s?.key?.toString())
                    } }

                /> :
                    <TextField
                        label={"Requested Carrier"}
                        value={selectedCarrierOption}
                        onChange={(e, v) => props.onSelectFreightOption(v)}/>}
            </div>
            <div className={'ac-details-field'}>
                {warehouseOptions && warehouseOptions.length > 1 || warehouseOptionsData.isLoading ? <ComboBox
                        label='Requested Warehouse'
                        allowFreeform={true}
                        selectedKey={selectedWarehouseOption}
                        defaultValue={'Select Warehouse'}
                        autoComplete={"on"}
                        options={getWarehouseOptions()}
                        onChange={(e, s) =>{
                            props.onSelectWarehouseOption(s?.key?.toString())
                        } }
                    /> :
                    <TextField
                        label={"Requested Warehouse"}
                        value={selectedWarehouseOption}
                        onChange={(e, v) => props.onSelectWarehouseOption(v)}/>}
            </div>
            <div className={'ac-details-field'}>
                <PrimaryButton
                    label={'Freight Estimate'}
                    text={!props.canEstimateFreight ? 'Freight Estimate Unavailable' : 'Get Freight Estimate'}
                    disabled={!props.canEstimateFreight || props.loadingFreightEstimate}
                    onClick={() => {
                        props.onStartFreightEstimate()
                    }}
                />
            </div>
            {freightOptions && freightOptions.length > 0 && <DetailsList
                columns={columns}
                items={freightOptions}
                selectionMode={SelectionMode.single} 
                selection={selection}
            />}
        </div>)
}
export default FreightDetails;