import React from 'react';
import { useGetStorefrontQuery } from '../../store/Api';
import { Spinner } from '@fluentui/react';

interface IStorefrontLabelProps {
    storefrontId?: string;
    fallbackStorefrontId?: string;
}

const StorefrontLabel: React.FC<IStorefrontLabelProps> = (props) => {
    const sId = props.storefrontId ?? props.fallbackStorefrontId;
    if (!sId) return <span>Default</span>;
    return <LoadedStorefrontLabel id={sId} />;
}

const LoadedStorefrontLabel: React.FC<{id:string}> = (props) => {
    const storefront = useGetStorefrontQuery(props.id)
    if (storefront.isLoading) return <Spinner />
    return (
        <span>{storefront.data?.name ?? 'Default'}</span>
    );
}

export default StorefrontLabel;