import { Dropdown, IDropdownOption } from '@fluentui/react';
import React from 'react';

interface IFontPickerProps {
    selectedFont?: string;
    onChange: (font: string) => void;
    label?: string;
    placeholder?: string;
}

const FontPicker: React.FC<IFontPickerProps> = (props) => {
    const dropdownOptions: IDropdownOption[] = [
        { key: '', text: 'Default' },
        { key: 'Helvetica, Arial, sans-serif', text: 'Helvetica, Arial, sans-serif' },
        { key: 'Georgia, serif', text: 'Georgia, serif' },
        { key: 'Palatino Linotype, Book Antiqua, Palatino, serif', text: 'Palatino Linotype, Book Antiqua, Palatino, serif' },
    ]
    return (
        <Dropdown
            label={props.label}
            selectedKey={props.selectedFont}
            placeholder={props.placeholder}
            onChange={(e, option) => {
                if (option) {
                    props.onChange(option.key as string);
                }
            }}
            options={dropdownOptions}
        />
    );
}

export default FontPicker;