import {
    DefaultButton,
    Dialog, DialogFooter,
    DialogType
} from "@fluentui/react";

import React from "react";
interface IClearSearchCriteriaModalProps {
    displayModal: boolean;
    onClear: () => void;
    closer: () => void;
}
const ClearSearchDialog: React.FC<IClearSearchCriteriaModalProps> = props => {
    return (
        <Dialog
            hidden={!props.displayModal}
            onDismiss={props.closer}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Clear Search Criteria',
                subText: `Are you sure you want to clear all search criteria?`,
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <DialogFooter>
                <DefaultButton
                    primary
                    text='Clear'
                    onClick={() => {
                        props.onClear()
                        props.closer()
                    }}
                />
                <DefaultButton
                    text='Cancel'
                    onClick={props.closer}
                />
            </DialogFooter>
        </Dialog>
    );
}
export default ClearSearchDialog;