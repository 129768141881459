import React, {useEffect} from 'react';
import { IInstalledExtension } from '../../models/Extension';
import { Checkbox, DefaultButton, Panel, PrimaryButton, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useLazyGetExtensionCompanyQuery, useLazyGetImportCompanyExtensionOptionsQuery } from '../../store/Api';
import DataField from "../Common/DataField";

interface IContactImportPanelProps {
    extension: IInstalledExtension;
    onImport: () => void;
    onClose: () => void;
}

const ContactImportPanel: React.FC<IContactImportPanelProps> = (props) => {
    const [contactSearchText, setContactSearchText] = React.useState<string>('');
    const [companySearchText, setCompanySearchText] = React.useState<string>('');
    const [hasSearched, setHasSearched] = React.useState(false);
    const [lazyCompanySearch] = useLazyGetImportCompanyExtensionOptionsQuery();
    const [loadCompany] = useLazyGetExtensionCompanyQuery();
    const [searching, setSearching] = React.useState(false);
    const [importing, setImporting] = React.useState(false);
    const [contacts, setContacts] = React.useState<{key: string, text: string, value: string}[] | undefined>();
    const [selectedContacts, setSelectedContacts] = React.useState<string[]>([]);
    const [splitContactCompanySearch, setSplitContactCompanySearch] = React.useState(false);



    useEffect(() => {
        if(props.extension && props.extension.additionalData.some(a => a.field == "companyThenContact")){
            setSplitContactCompanySearch(true)
        }
        else{
            setSplitContactCompanySearch(false)
        }
    }, [props.extension]);

    const getCombinedSearch =() => {
        if(splitContactCompanySearch){
            return `${encodeURIComponent(contactSearchText)}&${encodeURIComponent(companySearchText)}`;
        }
        return(companySearchText);
    }
    const search = () => {
        setSearching(true);
        lazyCompanySearch({extensionId: props.extension.id, search: getCombinedSearch()}).then((result) => {
            setSelectedContacts([]);
            setHasSearched(true);
            if (result.data?.options) {
                setContacts(result.data.options.map(o => ({
                    key: o.value,
                    text: o.display,
                    value: o.value
                })));
            } else {
                setContacts(undefined);
            }
            setSearching(false);
        });
    }

    const searchKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') search();
    }

    const importContacts = async () => {
        if (selectedContacts) {
            setImporting(true);
            // Do import
            for (const contact of selectedContacts) {
                await loadCompany({extensionId: props.extension.id, companyIdentifier: contact});
            }
            setImporting(false);
            props.onImport();
            props.onClose();
        }
    }

    return (
        <Panel
            headerText={props.extension.name}
            isOpen={true}
            onDismiss={props.onClose}
            closeButtonAriaLabel='Close'
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <PrimaryButton
                    style={{width: '100%'}}
                    text={importing ? 'Importing' : 'Import'}
                    onClick={importContacts}
                    disabled={selectedContacts.length === 0 || importing}
                />
            )}
        >
            <div>
                <Stack
                    tokens={{childrenGap: '1em'}}
                >

                    <Stack
                        horizontal
                        grow
                        tokens={{childrenGap: '1em'}}
                    >
                        <div>
                            <DataField
                                label={'Company'}
                                toolTip={splitContactCompanySearch ? 'Filters contact results by companies comparing company name or address with the search term provided. If left blank, only contact fields will be searched.' : ''}
                                placeholder='Keywords'
                                value={companySearchText}
                                onChange={(e, v) => setCompanySearchText(() => v ?? '')}
                                onKeyDown={searchKeyDown}
                            />
                            {splitContactCompanySearch &&

                                <DataField
                                    label={'Contact'}
                                    toolTip={'Searches contacts that exist in a company that matches the company criteria, and match the contact criteria in email, first name or last name fields. If left blank, only company fields will be searched.'}
                                    placeholder='Keywords'
                                    value={contactSearchText}
                                    onChange={(e, v) => setContactSearchText(() => v ?? '')}
                                    onKeyDown={searchKeyDown}
                                />
                            }
                            <br/>
                            <DefaultButton
                                text='Search'
                                onClick={search}
                            />                        
                        </div>
                    </Stack>
                    {searching && <div><Spinner size={SpinnerSize.large} label='Loading' /></div>}
                    {hasSearched && !searching && (!contacts || contacts.length === 0) && (
                        <div>No contacts found</div>
                    )}
                    {!searching && contacts && contacts.length > 0 && (<>
                        <h3 style={{marginBottom: 0}}>Select Contacts to Import</h3>
                        <table className='list-table'>
                            <tbody>
                            {contacts.map(c => (
                                <tr
                                    key={c.key}
                                    onClick={() =>
                                        setSelectedContacts(sel => {
                                            if (sel.indexOf(c.key) !== -1) {
                                                return sel.filter(s => s !== c.key);
                                            }
                                            return [...sel, c.key];
                                        })
                                    }
                                >
                                    <td>
                                        <Checkbox
                                            checked={selectedContacts.indexOf(c.key) !== -1}
                                            onChange={() => setSelectedContacts(sel => {
                                                if (sel.indexOf(c.key) !== -1) {
                                                    return sel.filter(s => s !== c.key);
                                                }
                                                return [...sel, c.key];
                                            })}
                                        />
                                    </td>
                                    <td>{c.text}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </>)}
                </Stack>
            </div>
        </Panel>
    )
}

export default ContactImportPanel;