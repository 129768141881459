import React from 'react';
import {Route} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import CatalogManagement from './pages/Settings/CatalogManagement';
import EmbeddedExtensionRedirect from './pages/EmbeddedExtensionRedirect';
import EmbeddedProductPage from './pages/EmbeddedProduct';
import EmbeddedProductLinkPage from './pages/EmbeddedProductLinkPage';
import ExtensionManagement from './pages/ExtensionManagement';
import Home from './pages/Home';
import SearchPage from './pages/Search';
import WorkspacePage from './pages/WorkspacePage';
import EmbeddedPunchoutPage from "./pages/EmbeddedPunchout";
import SessionKeyLayout from "./components/SessionKeyLayout/SessionKeyLayout";
import ApiKeyManagementPage from "./pages/ApiKeyManagementPage";
import SettingsLayout from "./components/SettingsLayout/SettingsLayout";
import UserManagementPage from "./pages/Settings/UserManagementPage";
import SearchReportingPage from "./pages/Reporting/SearchReportingPage";
import ReportingLayout from "./components/ReportingLayout/ReportingLayout";
import TaxCodeManagement from "./pages/Settings/TaxCodeManagement";
import SubOrganizationManagement from "./pages/Settings/SubOrganizationManagement";
import OrganizationManagement from "./pages/Settings/OrganizationManagement";
import SettingsHomePage from "./pages/Settings/SettingsHomePage";
import ApiUsageReport from "./pages/Settings/Reporting/ApiUsageReport";
import AuthorizedRoute from "./AuthorizedRoutes"
import PrepareOrderPage from "./pages/Procurement/PrepareOrdersPage";
import ActiveOrdersPage from "./pages/Procurement/ActiveOrdersPage";
import CompletedOrdersPage from "./pages/Procurement/CompletedOrdersPage";
import CustomColumnManagement from "./pages/Settings/CustomColumnManagement";
import EmbeddedLogin from "./pages/Auth/EmbeddedLogin";
import SearchSettingsPage from './pages/Settings/SearchSettingsPage';
import StorefrontManagementPage from './pages/Settings/StorefrontManagementPage';
import ContactManagementPage from './pages/Settings/ContactManagementPage';

export const Router: React.FC = () => {
    
    
    return (
        <>
            <Route path='/' exact>
                <Layout>
                    <AuthorizedRoute exact path="/" component={Home}/>
                </Layout>
            </Route>
            <Route path={['/search', '/workspace', '/settings*', '/reporting/*', '/procurement*']}>
                <Layout>
                    <AuthorizedRoute path="/search" component={SearchPage}/>
                    <AuthorizedRoute path="/Workspace/:workspaceId" component={WorkspacePage}/>
                    <AuthorizedRoute path='/settings' exact component={SettingsHomePage} />
                    <Route path={'/settings/*'}>
                        <SettingsLayout>
                            <AuthorizedRoute path='/settings/organization' component={OrganizationManagement} />
                            <AuthorizedRoute path="/settings/catalogs" component={CatalogManagement}/>
                            <AuthorizedRoute path="/settings/extensions" component={ExtensionManagement}/>
                            <AuthorizedRoute path='/settings/apikeys' component={ApiKeyManagementPage}/>
                            <AuthorizedRoute path='/settings/users' component={UserManagementPage}/>
                            <AuthorizedRoute path='/settings/taxcodes' component={TaxCodeManagement}/>
                            <AuthorizedRoute path='/settings/subOrganizations' component={SubOrganizationManagement} />
                            <AuthorizedRoute path='/settings/columns' component={CustomColumnManagement} />
                            <AuthorizedRoute path='/settings/reporting/api' component={ApiUsageReport} />
                            <AuthorizedRoute path='/settings/search' component={SearchSettingsPage} />
                            <AuthorizedRoute path='/settings/storefront' component={StorefrontManagementPage} />
                            <AuthorizedRoute path='/settings/contacts' component={ContactManagementPage} />
                        </SettingsLayout>
                    </Route>
                    <Route path={'/procurement/*'}>
                        <AuthorizedRoute path="/procurement/prepare" component={PrepareOrderPage}/>
                        <AuthorizedRoute path="/procurement/orders" component={ActiveOrdersPage}/>
                        <AuthorizedRoute path="/procurement/completed" component={CompletedOrdersPage}/>
                    </Route>
                    <Route path={'/reporting/*'}>
                        <ReportingLayout>
                            <AuthorizedRoute path='/reporting/search/usage' component={SearchReportingPage} />
                        </ReportingLayout>
                    </Route>
                </Layout>
            </Route>

            <Route path='/embedded/*'>
                <Route path='/embedded/login' component={EmbeddedLogin}/>
                <Route path='/embedded/product/:catalog/:id' component={EmbeddedProductPage}/>
                <Route path='/embedded/extension/:extensionId' component={EmbeddedExtensionRedirect}/>
                <Route path='/embedded/embedded/establishLink/product/:extensionId/:id'
                             component={EmbeddedProductLinkPage}/>
            </Route>

            <Route path={['/punchout', '/punchout/*']}>
                <SessionKeyLayout>
                    <Route path='/punchout' exact component={EmbeddedPunchoutPage}/>
                    <Route path='/punchout/search' component={SearchPage}/>
                    <Route path='/punchout/workspace/:workspaceId' component={WorkspacePage}/>
                </SessionKeyLayout>
            </Route>
        </>
    )
}