import React from 'react';
import { IProductStorefrontEntry } from '../../models/Search';
import { Checkbox } from '@fluentui/react';
import NumberField from '../Inputs/NumberField';
import MarkupTypeSelection from '../Storefront/MarkupTypeSelection';
import { useGetStorefrontProductCountQuery, useGetStorefrontsQuery } from '../../store/Api';
import { IStorefrontSettings } from '../../models/Storefront';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import ToggleField from "../Common/ToggleField";

interface IProductStorefrontDetailsProps {
    editMode?: boolean;
    storefrontOverrideMarkup?: number;
    storefrontOverrideMarkupType?: string;
    storefrontAlwaysAvailable?: boolean;
    storefronts?: {[key: string]: IProductStorefrontEntry};
    includedStorefronts?: string[];
    onIncludedStorefrontsChange: (includedStorefronts: string[]) => void;
    featuredStorefronts?: string[];
    onFeaturedStorefrontsChange: (featuredStorefronts: string[]) => void;
    onStorefrontsChange: (storefronts: {[key:string]: IProductStorefrontEntry}) => void;
    onStorefrontOverrideMarkupChange: (markup: number | undefined) => void;
    onStorefrontOverrideMarkupTypeChange: (markupType: string | undefined) => void;
    onStorefrontAlwaysAvailableChange: (checked?: boolean) => void;
}

const ProductStorefrontDetails: React.FC<IProductStorefrontDetailsProps> = (props) => {
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const productCount = useGetStorefrontProductCountQuery();
    const storefronts = useGetStorefrontsQuery();
    const [reachedLimit, setReachedLimit] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (org?.storefrontProductLimit != null && productCount.data?.count != null) {
            setReachedLimit(productCount.data.count > org.storefrontProductLimit);
        }
    }, [org, productCount.data]);


    return (
        <div>
            <h3>Default Storefront Settings</h3>
            {reachedLimit && <p>Product limit reached. Please remove products from storefronts to add more.</p>}
            <NumberField
                disabled={!props.editMode || reachedLimit}
                label='Storefront Override Markup'
                value={props.storefrontOverrideMarkup}
                onChange={props.onStorefrontOverrideMarkupChange}
            />
            <MarkupTypeSelection
                disabled={!props.editMode || reachedLimit}
                label='Storefront Override Markup Type'
                value={props.storefrontOverrideMarkupType}
                onChange={props.onStorefrontOverrideMarkupTypeChange}
            />
            <ToggleField
                disabled={!props.editMode || reachedLimit}
                label='Always Available'
                checked={props.storefrontAlwaysAvailable}
                toolTip={<div>This forces Adaptive Catalog to include this product in the storefront, even if it is not otherwise considered in stock.<br></br><br></br> <b>When enabling this option, it is highly recommended that you set a minimum price to ensure only valid items are shown on your storefront. </b></div>}
                onChange={(ev, v) => props.onStorefrontAlwaysAvailableChange(v)}
            />
            <h3>Specific Storefront Settings</h3>
            <table className='data-table'>
                <thead>
                <tr>
                    <th>Storefront</th>
                    <th>Included</th>
                    <th>Featured</th>
                    <th>Markup</th>
                    <th>Markup Type</th>
                    <th>Minimum Price</th>
                </tr>
                </thead>
                <tbody>
                {storefronts.data?.items?.map((storefront) =>  (
                    <ProductStorefrontEntryDetails
                        reachedLimit={reachedLimit}
                        editMode={props.editMode}
                        key={storefront.id}
                        storefront={storefront}
                        included={props.includedStorefronts?.includes(storefront.id ?? '')}
                        featured={props.featuredStorefronts?.includes(storefront.id ?? '')}
                        onFeaturedChange={(featured) => props.onFeaturedStorefrontsChange(featured ? [...(props.featuredStorefronts ?? []), storefront.id ?? ''] : (props.featuredStorefronts ?? []).filter((id) => id !== storefront.id))}
                        onIncludedChange={(included) => props.onIncludedStorefrontsChange(included ? [...(props.includedStorefronts ?? []), storefront.id ?? ''] : (props.includedStorefronts ?? []).filter((id) => id !== storefront.id))}
                        storefrontEntry={props.storefronts?.[storefront.id ?? '']}
                        onStorefrontEntryChange={(storefrontEntry) => props.onStorefrontsChange({...props.storefronts, [storefront.id ?? '']: storefrontEntry})}
                    />
                ))}
                </tbody>
            </table>
        </div>
    );
}

interface IProductStorefrontEntryDetailsProps {
    storefront: IStorefrontSettings;
    storefrontEntry?: IProductStorefrontEntry;
    included?: boolean;
    featured?: boolean;
    onIncludedChange?: (included: boolean) => void;
    onFeaturedChange?: (featured: boolean) => void;
    onStorefrontEntryChange: (storefrontEntry: IProductStorefrontEntry) => void;
    editMode?: boolean;
    reachedLimit?: boolean;
}

const DEFAULT_STOREFRONT_ENTRY: IProductStorefrontEntry = {
    storefront: '',
    overrideMarkup: 0,
    overrideMarkupType: '',
}

const ProductStorefrontEntryDetails: React.FC<IProductStorefrontEntryDetailsProps> = (props) => {
    const markupChange = (overrideMarkup?:number) => {
        if (props.storefront.id == null) return;
        if (props.storefrontEntry == null) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefront: props.storefront.id, overrideMarkup});
        else props.onStorefrontEntryChange({...props.storefrontEntry, overrideMarkup});
    }
    const markupTypeChange = (overrideMarkupType:string) => {
        if (props.storefront.id == null) return;
        if (props.storefrontEntry == null) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefront: props.storefront.id, overrideMarkupType});
        else props.onStorefrontEntryChange({...props.storefrontEntry, overrideMarkupType});
    }
    const onIncludedChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
        if (props.onIncludedChange) props.onIncludedChange(checked ?? false);
    }

    const onFeaturedChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
        if (props.onFeaturedChange) props.onFeaturedChange(checked ?? false);
    }

    const onMinimumPriceChange = (minimumPrice?:number) => {
        if (props.storefront.id == null) return;
        if (props.storefrontEntry == null) props.onStorefrontEntryChange({...DEFAULT_STOREFRONT_ENTRY, storefront: props.storefront.id, minimumPrice});
        else props.onStorefrontEntryChange({...props.storefrontEntry, minimumPrice});
    }


    if (!props.editMode) return (
        <tr>
            <td>{props.storefront.name}</td>
            <td style={{textAlign: 'center'}}>{props.included ? '✓' : ''}</td>
            <td style={{textAlign: 'center'}}>{props.featured ? '✓' : ''}</td>
            <td>{props.storefrontEntry?.overrideMarkup}</td>
            <td>
                <MarkupTypeSelection
                    value={props.storefrontEntry?.overrideMarkupType}
                    disabled={true}
                />
            </td>
            <td>{props.storefrontEntry?.minimumPrice ?? 'Not Set'}</td>
        </tr>
    );
    return (
        <tr>
            <td>{props.storefront.name}</td>
            <td>
                <Checkbox
                    checked={props.included ?? false}
                    onChange={onIncludedChange}
                />
            </td>
            <td>
                <Checkbox
                    checked={props.featured ?? false}
                    onChange={onFeaturedChange}
                    disabled={props.reachedLimit}
                />
            </td>
            <td>
                <NumberField
                    value={props.storefrontEntry?.overrideMarkup}
                    onChange={markupChange}
                    disabled={props.reachedLimit}
                />
            </td>
            <td>
                <MarkupTypeSelection
                    value={props.storefrontEntry?.overrideMarkupType}
                    onChange={markupTypeChange}
                    disabled={props.reachedLimit}
                />
            </td>
            <td>
                <NumberField
                    value={props.storefrontEntry?.minimumPrice}
                    onChange={onMinimumPriceChange}
                    prefix='$'
                    disabled={props.reachedLimit}
                />
            </td>
        </tr>
    );
}

export default ProductStorefrontDetails;