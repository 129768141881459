import {
    DefaultButton,
    Dialog, DialogFooter,
    DialogType
} from "@fluentui/react";

import React from "react";
import {useDeleteTaxCodeMutation} from "../../store/Api";
import {ITaxCode} from "../../models/TaxCode";
interface ITaxCodeDeleteModalProps {
    selectedTaxCodes?: ITaxCode[]
    displayModal: boolean;
    onDeleteTaxCodes?: () => void;
    closer: () => void;
}
const TaxCodeDeleteModal: React.FC<ITaxCodeDeleteModalProps> = props => {
    const [deleteTaxCode] = useDeleteTaxCodeMutation();
    return (
        <Dialog
            hidden={!props.displayModal || !props.selectedTaxCodes}
            onDismiss={props.closer}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Delete Tax Codes',
                subText: `Are you sure you want to delete the selected ${props.selectedTaxCodes?.length} Tax Codes?`,
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <DialogFooter>
                <DefaultButton
                    primary
                    text='Delete'
                    onClick={() => {
                        if (props.selectedTaxCodes) {
                            props.selectedTaxCodes.map((a) => {
                                if(a.id) {deleteTaxCode(a.id)}
                            })
                        }
                        props.closer()
                    }}
                />
                <DefaultButton
                    text='Cancel'
                    onClick={props.closer}
                />
            </DialogFooter>
        </Dialog>
    );
}
export default TaxCodeDeleteModal;