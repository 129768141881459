import React from 'react';
import { useGetContactsForCompanyQuery } from '../../store/Api';
import {CommandBar, IColumn, ICommandBarItemProps, SelectionMode, ShimmeredDetailsList} from '@fluentui/react';
import { ButtonColumnGenerator, ColumnGenerator } from '../../logic/Columns/ColumnGenerator';
import ContactDetailsModal from './ContactDetailsModal';
import { IContact } from '../../models/Company';

interface ICompanyContactsDisplayProps {
    companyId: string;
}

const CompanyContactsDisplay: React.FC<ICompanyContactsDisplayProps> = (props) => {
    const contacts = useGetContactsForCompanyQuery({companyId: props.companyId});
    const [selectedContact, setSelectedContact] = React.useState<IContact | undefined>(undefined);
    const [newContact, setNewContact] = React.useState(false);
    
    const columns: IColumn[] = [
        ColumnGenerator('Email', 'emailAddress', 200),
        ColumnGenerator('First Name', 'firstName', 100, 200),
        ColumnGenerator('Last Name', 'lastName', 100, 200),
        ButtonColumnGenerator((item: IContact) => setSelectedContact(item), 'Details'),
    ]
    const newCommand: ICommandBarItemProps[] = [{
        key: 'new',
        text: 'Add New Contact',
        iconProps: { iconName: 'Add' },
        onClick: () => {setNewContact(true);},
    }]
    return (<>
        <CommandBar
            items={newCommand}
        />
        <ShimmeredDetailsList
            selectionMode={SelectionMode.none}
            items={contacts.data?.items ?? []}
            columns={columns}
            enableShimmer={contacts.isLoading}
            />
        <ContactDetailsModal
            contact={selectedContact}
            isOpen={selectedContact !== undefined || newContact}
            onDismiss={() => {
                setSelectedContact(undefined);
                setNewContact(false);
            }}
            />
        </>
    );
}

export default CompanyContactsDisplay;