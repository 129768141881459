import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { useGetStorefrontProductCountQuery } from '../../store/Api';

const StorefrontProductCount = () => {
    const org = useSelector((state: RootState) => state.settings.selectedOrganization);
    const productCount = useGetStorefrontProductCountQuery();

    return (
        <div>
            {org?.storefrontLimit && <p>Max Product Count: {org?.storefrontProductLimit}</p>}
            {productCount.data && <p>Current Product Count: {productCount.data.count}</p>}
        </div>
    );
}

export default StorefrontProductCount;