import { BaseDAL } from "./BaseDAL";
import {
    ISubmitSessionResponse
} from "../models/Workspace";

export class WorkspaceDAL extends BaseDAL {
    public postSubmitWorkspaceSession(workspace: string) {
        const url = `${this.baseUrl}/api/keyauth/sessionkey/submit/workspace/${workspace}?`;
        return this.post<ISubmitSessionResponse>(url);
    }
    //TODO: Delete this if and when CW allows us to map extrinsic fields
    public postSubmitCwCpqWorkspaceSession(workspace: string) {
        const url = `${this.baseUrl}/api/keyauth/sessionkey/submit/cwcpqworkspace/${workspace}?`;
        return this.post<ISubmitSessionResponse>(url);
    }

    public postSubmitJsonWorkspaceSession(workspace: string) {
        const url = `${this.baseUrl}/api/jsonpunchout/submit/workspace/${workspace}?`;
        return this.post<string>(url);
    }
}
