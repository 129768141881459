import React, {ReactElement, useEffect, useState} from 'react';
import {Icon} from "@fluentui/react";

interface IAccordionProps {
    title?: string;
    titleComponent?: ReactElement
    beginExpanded?: boolean;
    onToggle?: (expanded: boolean) => void;
    useChevron?: boolean;
}

const Accordion: React.FC<IAccordionProps> = (props) => {
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if(props.beginExpanded){
            setIsActive(true)
        }
    }, [props.beginExpanded]);
    return (                
        <div className="accordion">
            <div className="accordion-item">
                {props.titleComponent ? 
                    <div onClick={(event) => {
                        if(props?.onToggle){
                            props.onToggle(!isActive)
                        }
                        setIsActive(!isActive)}}>
                        {props.useChevron && 
                            <Icon 
                                iconName={isActive ? 'ChevronDown': 'ChevronUp'} 
                                style={{height: '20px', width: '20px', paddingTop: '30px'}}
                            />}
                        {props.titleComponent} 
                    </div> : 
                    <div className="accordion-title" onClick={(event) => {
                        if(props?.onToggle){
                            props.onToggle(!isActive)
                        }
                        setIsActive(!isActive)
    
                    }}>
                    <div className={"accordion-inner"}>{props.title}</div>
                </div>}
                {isActive && <div className="accordion-content">{props.children}</div>}
            </div>
        </div>
    );
}

export default Accordion;