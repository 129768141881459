import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import api from "./Api";
import Tracker from "@openreplay/tracker";
import trackerAssist from '@openreplay/tracker-assist';
import IsProduction from "../logic/IsProduction";
import trackerRedux from "@openreplay/tracker-redux";

export const tracker = new Tracker({
  projectKey: window.config.openReplayKey ?? '',
  ingestPoint: window.config.openReplayUrl,
  obscureTextEmails: false,
  obscureInputEmails: false,
  defaultInputMode: 0,
  network: {
    failuresOnly: false,
    capturePayload: true,
    sessionTokenHeader: 'X-OpenReplay-Session',
    ignoreHeaders: ['Authorization', 'Cookie', 'Set-Cookie'],
    captureInIframes: true,
  },
});

tracker.use(trackerAssist({}));

if (IsProduction() && window.config.openReplayKey && window.config.openReplayUrl) {
  tracker.start();
}

export const openReplayMiddleware = tracker.use(trackerRedux({}));

const store = configureStore({
  reducer: rootReducer,
  middleware: [api.middleware, thunkMiddleware, openReplayMiddleware],
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;

export default {
  store,
  persistor,
};