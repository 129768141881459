import React from 'react';

interface IRadioButtonProps {
    checked: boolean;
    onClick: () => void;
}

const RadioButton: React.FC<IRadioButtonProps> = (props) => {
    return <div
        className={`radio-button ${props.checked ? 'radio-button-checked' : 'radio-button-unchecked'}`}
        onClick={props.onClick}
        />
};

export default RadioButton;