import React, {useState} from 'react';
import {IExtension} from "../../models/Extension";
import {
    Stack,
    Panel,
    PanelType,
    ProgressIndicator,
    Dropdown,
    TextField,
    DocumentCard,
    DocumentCardPreview,
    ImageFit,
    DocumentCardDetails,
    IDocumentCardStyles, IconButton
} from "@fluentui/react";
import {EXTENSION_TYPES} from "../../Constants";
import {useClearExtensionsMutation, useGetAvailableExtensionsQuery} from "../../store/Api";

interface IAvailableExtensionsPanelProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (extension: IExtension) => void;
}

const AvailableExtensionsPanel: React.FC<IAvailableExtensionsPanelProps> = (props) => {
    const availableExtensions = useGetAvailableExtensionsQuery();
    const [clearExtensions] = useClearExtensionsMutation();
    const [search, setSearch] = useState<string>('');
    const [extensionTypeFilter, setExtensionTypeFilter] = useState('All');

    const extensionTypes = [
        {
            key: "All",
            text: "All",
            value: "All",
        },
        ...EXTENSION_TYPES.map((t) => (
            {
                key: t,
                text: t,
                value: t,
            }
        )),
        {
            key: "directImport",
            text: "Workspace Import",
            value: "directImport"
        },
        {
            key: "procurement",
            text: "Ordering",
            value: "procurement"
        }
    ];
    
    const getExtensionType =() => {
        return extensionTypes
    }

    const filteredExtensions: IExtension[] = availableExtensions?.data?.filter(e =>
        (extensionTypeFilter === 'All' || e.category.toLowerCase() === extensionTypeFilter.toLowerCase()) &&
        (search === '' || e.name.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    ).sort((a, b) => {
        const aName = a.name.toUpperCase();
        const bName = b.name.toUpperCase();
        return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
    })?? [];

    const cardStyles: IDocumentCardStyles = {
        root: {width: '250px'},
    };

    return (
        <Panel
            isOpen={props.isOpen}
            headerText='Available Extensions'
            type={PanelType.large}
            onDismiss={props.onClose}
        >
            <Stack horizontal tokens={{childrenGap: 10}}>
                <Stack.Item grow>
                    <Dropdown
                        label='Extension Type'
                        style={{width: '100%'}}
                        options={getExtensionType()}
                        onChange={(e, d) => {
                            setExtensionTypeFilter(d?.key.toString() ?? 'All')
                        }}
                        selectedKey={extensionTypeFilter}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label='Extension Name'
                        style={{width: '100%'}}
                        value={search}
                        onChange={(e, d) => setSearch(d ?? '')}
                    />
                </Stack.Item>
                <Stack.Item align='end'><IconButton iconProps={{iconName:'Refresh'}} onClick={() => clearExtensions()}/> </Stack.Item>
            </Stack>
            {availableExtensions.isFetching && <div style={{marginTop: '2em'}}>
                <ProgressIndicator label='Loading Available Extensions'/>
            </div>}
            <div style={{marginTop: '2em'}}>
                <Stack horizontal wrap tokens={{childrenGap: '2em'}}>
                    {filteredExtensions.map(extension => (
                        <DocumentCard
                            styles={cardStyles}
                            onClick={() => props.onSelect(extension)}
                            key={`${extension.name}-${extension.id}`}
                        >
                            <DocumentCardPreview
                                previewImages={[{
                                    name: extension.name,
                                    previewImageSrc: extension.image,
                                    imageFit: ImageFit.contain,
                                }]}
                            />
                            <DocumentCardDetails>
                                <h3 style={{padding: '1em', margin: 0}}>{extension.name}</h3>
                                <div style={{padding: '1em'}}>{extension.description}</div>
                            </DocumentCardDetails>
                        </DocumentCard>
                    ))}
                </Stack>
            </div>
        </Panel>
    )
}

export default AvailableExtensionsPanel;