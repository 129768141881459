import React, { FormEvent } from 'react';
import { useGetStorefrontThemesQuery } from '../../store/Api';
import { Dropdown, IDropdownOption } from '@fluentui/react';

interface IStorefrontThemeSelection {
    theme?: string;
    onChange: (theme: string) => void;
}

const StorefrontThemeSelection: React.FC<IStorefrontThemeSelection> = ({theme, onChange}) => {
    const themes = useGetStorefrontThemesQuery();
    const change = (e: FormEvent<HTMLDivElement>, v?: IDropdownOption<any> | undefined) => {
        if (v == null) return;
        onChange(v.key as string);
    }

    return (
        <Dropdown
            label='Theme'
            selectedKey={theme}
            onChange={change}
            options={themes.data ? Object.entries(themes.data).map(([key, value]) => ({key, text: value.name})) : []}
            />
    );
}

export default StorefrontThemeSelection;