import React from 'react';
import { useGetStorefrontsQuery } from '../../store/Api';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import CAN, { STOREFRONT_ADMIN } from '../../permissions/ability';

interface IStorefrontDropdownProps {
    label?: string;
    defaultLabel?: string;
    selected?: string;
    inheritedStorefrontId?: string;
    useId?: boolean;
    onChange: (selected?: string) => void;
}

const StorefrontDropdown: React.FC<IStorefrontDropdownProps> = (props) => {
    const storefronts = useGetStorefrontsQuery();
    const [inherited, setInherited] = React.useState<string | undefined>();
    const canStorefrontAdmin = CAN(STOREFRONT_ADMIN);
    const options: IDropdownOption[] = [
        { key: '', text: props.defaultLabel ?? 'Default' },
        ...storefronts.data?.items.map((storefront) => ({
            key: props.useId ? (storefront.id ?? '') : (storefront.subUrl ?? ''),
            text: storefront.subUrl ?? ''
        })) ?? [],
    ]

    React.useEffect(() => {
        const sf = props.inheritedStorefrontId ? storefronts.data?.items.find((sf) => sf.id === props.inheritedStorefrontId) : undefined;
        setInherited(sf?.name);
    }, [props.inheritedStorefrontId, storefronts.data?.items])

    return (
        <div>
            <Dropdown
                label={props.label ?? 'Storefront'}
                selectedKey={props.selected}
                placeholder={inherited ? `Inherited: ${inherited}` : (props.defaultLabel ?? 'Default')}
                onChange={(e, option) => {
                    props.onChange(option?.key as string | undefined);
                }}
                options={options}
                disabled={!canStorefrontAdmin}
                />
        </div>
    );
}

export default StorefrontDropdown;