import React from 'react';
import { IStorefrontOrder } from '../../../models/Storefront';
import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import { useGetStorefrontOrderStatusesQuery, useProcessStorefrontOrderMutation, useUpdateStorefrontOrderStatusMutation } from '../../../store/Api';
import { IsMutationSuccess } from '../../../logic/MutationTypeChecker';
import StorefrontLabel from '../StorefrontLabel';
import StorefrontOrderProductsTable from './StorefrontOrderProductsTable';
import StorefrontOrderBundlesTable from './StorefrontOrderBundlesTable';

interface IStorefrontOrderPanel {
    order?: IStorefrontOrder;
    onDismiss: () => void;
}

const StorefrontOrderPanel: React.FC<IStorefrontOrderPanel> = (props) => {
    const [processOrder] = useProcessStorefrontOrderMutation();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const statuses = useGetStorefrontOrderStatusesQuery();
    const [statusOptions, setStatusOptions] = React.useState<{ key: string, text: string }[]>([]);
    const [updateOrderStatus] = useUpdateStorefrontOrderStatusMutation();
    const [status, setStatus] = React.useState<string | undefined>(props.order?.status);

    React.useEffect(() => {
        setStatus(props.order?.status);
    }, [props.order?.status])

    React.useEffect(() => {
        if (statuses.data == null) return;
        setStatusOptions(Object.entries(statuses.data).map(([key,text]) => ({ key, text})));
    }, [statuses.data])

    const process = async () => {
        if (props.order == null) return;
        setProcessing(true);
        await processOrder({id: props.order.id});
        setProcessing(false);
    }

    const updateStatus = async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        const res = await updateOrderStatus({id: props.order?.id ?? '', status: option?.key as string});
        if (IsMutationSuccess(res)) {
            setStatus(option?.key as string);
        }
    }

    return (
        <Panel
            isOpen={props.order != null}
            onDismiss={props.onDismiss}
            type={PanelType.medium}
            headerText={`Order ${props.order?.orderNumber.toString()}`}
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <Stack horizontal tokens={{childrenGap:'1em'}}>
                    <PrimaryButton text={processing ? 'Processing' : 'Process'} onClick={process} disabled={processing} />
                    <DefaultButton text='Cancel' onClick={props.onDismiss} />
                </Stack>
            )}
            >
            {props.order && <div>
                <p>
                    <span style={{fontWeight: 'bold'}}>Order Number: </span>
                    {props.order.orderNumber}
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>Storefront: </span>
                    {props.order.storefront && <StorefrontLabel storefrontId={props.order.storefront} />}
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>Email Address: </span>
                    {props.order.emailAddress}
                </p>
                <p>
                    <span style={{fontWeight: 'bold'}}>Order Date: </span>
                    {props.order.createDate && new Date(props.order.createDate).toLocaleString()}
                </p>
                <Dropdown
                    label='Status'
                    options={statusOptions}
                    selectedKey={status}
                    onChange={updateStatus}
                    />
                <h3>Products</h3>
                <StorefrontOrderProductsTable products={props.order.products} /> 
                <h3>Bundles</h3>
                <StorefrontOrderBundlesTable bundles={props.order.bundles} />
            </div>}
        </Panel>
    );
}

export default StorefrontOrderPanel;