import React from 'react';
import { PAYMENT_PROCESSOR_DISPLAY, PAYMENT_PROCESSOR_KEYS } from '../../models/Storefront';
import { Dropdown, IDropdownOption, MessageBar, MessageBarType } from '@fluentui/react';
import { useGetPaymentProcessorSetupQuery } from '../../store/Api';

interface IPaymentProcessorDropdownProps {
    label?: string;
    value: string;
    onChange: (value: string) => void;
}

const PaymentProcessorDropdown: React.FC<IPaymentProcessorDropdownProps> = (props) => {
    const options = Object.entries(PAYMENT_PROCESSOR_DISPLAY).map(([key,value]) => ({ key: key, text: value }));
    const setupProcessors = useGetPaymentProcessorSetupQuery();
    const [warning, setWarning] = React.useState<string | undefined>();

    React.useEffect(() => {
        if (props.value === PAYMENT_PROCESSOR_KEYS.NONE) setWarning(undefined);
        else if (props.value === PAYMENT_PROCESSOR_KEYS.STRIPE && !setupProcessors.data?.stripeSetup) setWarning('Stripe not setup');
    }, [props.value, setupProcessors.data])

    const onChange = (e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        props.onChange(option?.key as string);
    }

    return (<div>
        <Dropdown
            disabled={setupProcessors.isLoading}
            label={props.label ?? 'Payment Processor'}
            options={options}
            selectedKey={props.value}
            onChange={onChange}
        />
        {warning && <MessageBar messageBarType={MessageBarType.warning}>{warning}</MessageBar>}
    </div>);
}

export default PaymentProcessorDropdown;