import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {
    useClearWorkspaceRecommendationsMutation, 
    useGetExtensionPotentialMatchesQuery,
    useGetWorkspaceExtensionMetadataQuery,
    useGetWorkspaceProductsQuery,
    useGetWorkspaceQuery,
    useRunDirectImportExtensionMutation,
    useRunExportExtensionMutation, 
    useSetWorkspaceExtensionFieldMutation
} from "../../store/Api";
import {
    IAdditionalData,
    IDirectImportItem,
    IExtensionResponse,
    IInstalledExtension,
    ISavedExtensionField
} from "../../models/Extension";
import {
    DefaultButton,
    IColumn,
    IconButton, LayerHost, mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    Panel,
    PanelType,
    Pivot,
    PivotItem,
    PrimaryButton,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
} from "@fluentui/react";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {IWorkspaceItem} from "../../models/Workspace";
import ExtensionItemDropdown from "./ExtensionItemDropdown";
import ExtensionMatchButton from "./ExtensionMatchButton";
import {
    IsFetchBaseQueryError,
    IsMutationError,
    IsMutationSuccess,
    IsSerializedError
} from "../../logic/MutationTypeChecker";
import ExtensionModalMessage from "./ExtensionModalMessage";
import {MODAL_ICON_BUTTON_STYLE, MODAL_STYLE} from "../../Constants";
import ExtensionField from "./ExtensionField";

interface IExtensionModalProps {
    extension: IInstalledExtension
    isOpen?: boolean;
    onClose: () => void;
    workspaceId: string;
}

const ExtensionModal: React.FC<IExtensionModalProps> = (props) => {
    
    const closeAndRefreshParent = useSelector((state: RootState) => state.settings.refreshSource);
    
    const [running, setRunning] = useState(false);
    const [extensionResponse, setExtensionResponse] = useState<IExtensionResponse | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [workspaceMetadata, setWorkspaceMetadata] = useState<ISavedExtensionField[]>()
    const [directImportSelections, setDirectImportSelections] = useState<IDirectImportItem[]>([])
    const [additionalData, setAdditionalData] = useState<IAdditionalData[]>([])
    
    const [runExtension] = useRunExportExtensionMutation();
    const [runDirectImportExtension] = useRunDirectImportExtensionMutation();
    const [clearRecommendations] = useClearWorkspaceRecommendationsMutation();
    const [setWorkspaceExtensionField] = useSetWorkspaceExtensionFieldMutation();
    
    const getProducts = useGetWorkspaceProductsQuery(props.workspaceId, 
        {skip: (!props.workspaceId || props.workspaceId == '')});

    const potentialMatches = useGetExtensionPotentialMatchesQuery({
        extensionId: props.extension.id,
        products: getProducts.data?.map(d => ({catalog: d.catalog, id: d.productId})) ?? []}, 
        {skip: (!getProducts.data)});
    
    const existingExtensionWorkspaceMetadata = useGetWorkspaceExtensionMetadataQuery(
        {workspace: props.workspaceId, extension: props.extension.id},
        {skip: (!props.workspaceId || props.workspaceId == '')});
    
    const selectedWorkspace = useGetWorkspaceQuery(props.workspaceId, 
        {skip: (!props.workspaceId || props.workspaceId == '')} )
    
    useEffect(() => {
        if (!existingExtensionWorkspaceMetadata.isFetching && existingExtensionWorkspaceMetadata.data) {
            setWorkspaceMetadata(existingExtensionWorkspaceMetadata.data)
        }
    }, [existingExtensionWorkspaceMetadata.data])
    
    useEffect(() => {
        setAdditionalData(props.extension.additionalData)
    }, [props.extension]);
    
    if (props.extension === undefined || getProducts?.data == null) {
        return null;
    }

    const change = (field: string, value: string | boolean | undefined) => {
        if (props.workspaceId && props.extension.id) {
            setWorkspaceExtensionField({
                field: field,
                extension: props.extension.id,
                workspace: props.workspaceId,
                value: value?.toString(),
            })
        }
    }
    
    
    let itemTable = <></>;

    if (additionalData.find(f => f.fieldType === 'item')) {
        const itemColumns: IColumn[] = [
            {
                ...ColumnGenerator('Item', 'normalized.name', 100, undefined, (item: IWorkspaceItem) => <>{item.product?.name}</>),
                isMultiline: true
            },
            ColumnGenerator(
                'Existing Product',
                'existing',
                200,
                200,
                (item: IWorkspaceItem) =>
                    <ExtensionMatchButton
                        loading={potentialMatches.isFetching}
                        potentialMatches={potentialMatches.data}
                        item={item}
                        extension={props.extension}
                    />
            ),
            ...additionalData.filter(f => f.fieldType === 'item').map((data) => ({
                key: data.field,
                name: data.description,
                minWidth: 200,
                maxWidth: 200,
                onRender: (item: IWorkspaceItem) => {
                    return <>
                        <ExtensionItemDropdown
                            item={item}
                            field={data}
                            extension={props.extension}
                        />
                    </>
                }
            }))
        ];
        itemTable = <ShimmeredDetailsList
            selectionMode={SelectionMode.none}
            items={getProducts.data}
            enableShimmer={getProducts.isLoading}
            columns={itemColumns}
        />
    }

    const run = async () => {
        if (props.extension.extensionType === 'recommendation') {
            props.onClose();
            if (selectedWorkspace.data?.id) {
                await clearRecommendations({
                    extensionId: props.extension.id,
                    workspace: selectedWorkspace.data.id
                })
            }
        } 
        else if(props.extension.extensionType === 'directImport'){
            setRunning(true);
            runDirectImportExtension({
                extensionId: props.extension.id,
                request: {
                    catalog: props.extension.targetCatalog ?? "", 
                    workspaceId: props.workspaceId, 
                    items: directImportSelections}
            }).then((response) => {
                console.log(['RESPONSE', response])
                if (IsMutationSuccess<IExtensionResponse>(response)) {
                    setExtensionResponse(response.data);
                } else if (IsMutationError(response)) {
                    if (IsSerializedError(response.error))
                        setError(`Error serializing response: ${response.error.message}`)
                    else if (IsFetchBaseQueryError(response.error))
                        setError(`Error communicating with API: ${response.error.status}`)
                }
                setRunning(false);
            }).catch((ex) => {
                console.log(['EXCEPTION', ex]);
                setError(`Error running extension: ${ex}`);
            })
        }
        else {
            setRunning(true);
            runExtension({
                extensionId: props.extension.id,
                workspace: selectedWorkspace?.data?.id,
                products: getProducts?.data?.map(wsi => ({catalog: wsi.catalog, id: wsi.productId, quantity: wsi.details.quantity}))
            }).then((response) => {
                console.log(['RESPONSE', response])
                if (IsMutationSuccess<IExtensionResponse>(response)) {
                    setExtensionResponse(response.data);
                    if (closeAndRefreshParent) {
                        try {
                            console.log('Posting message to parent');
                            parent.postMessage({'func': 'CloseAdaptiveCatalog'}, '*');
                        } catch(e) {
                            //Do nothing
                            console.log('Error posting message to parent', e);
                        }
                    }
                } else if (IsMutationError(response)) {
                    if (IsSerializedError(response.error))
                        setError(`Error serializing response: ${response.error.message}`)
                    else if (IsFetchBaseQueryError(response.error))
                        setError(`Error communicating with API: ${response.error.status}`)
                }
                setRunning(false);
            }).catch((ex) => {
                console.log(['EXCEPTION', ex]);
                setError(`Error running extension: ${ex}`);
            })
        }
    }
    
    const checkIfActiveStep = (stepIdentifier: string) => {
        const step = props.extension.steps?.filter((a) => a.identifier == stepIdentifier)?.[0]
        if(!step) return true;
        if(!step.flag && (!step.requiredFields || step.requiredFields.length === 0)) return true;
        
        let isActive = true;
        const relevantField = additionalData.filter(f => f.field == step.flag)?.[0]
        
        if(relevantField) {
            const getWorkspaceMetadataField = workspaceMetadata?.filter(a => a.key == relevantField.field)?.[0]
            if (!getWorkspaceMetadataField) return true;
            isActive = getWorkspaceMetadataField?.value == "true";
        }
        if(step.requiredFields && step.requiredFields.length > 0) {
            for(const field of step.requiredFields){
                const getWorkspaceMetadataField = workspaceMetadata?.filter(a => a.key == field)?.[0]
                if(!getWorkspaceMetadataField || getWorkspaceMetadataField.value == ''){
                    isActive = false;
                    break;
                }
            }
        }
        
        return isActive;
        
    }
    
    const disableDirectImport = () => {
        return props.extension.extensionType === 'directImport' && (!directImportSelections || directImportSelections.length === 0);
        
    }
    
    const actions =
        <div style={{ bottom:'0', right:'0', display: 'inline-flex', paddingTop: '10px'}}>
            <Stack tokens={{childrenGap: '1em'}} horizontal horizontalAlign='end' verticalAlign={'end'}>
                <PrimaryButton
                    onClick={run}
                    disabled={running || error !== undefined || extensionResponse !== undefined || disableDirectImport() }
                    text={running ? 'Running' : 'Run'}
                />
                <DefaultButton onClick={props.onClose} text='Close' />
            </Stack>
        </div>

    const panelActions =
        <div>
            <PrimaryButton
                style={{width: '200px'}}
                onClick={run}
                disabled={running || error !== undefined || extensionResponse !== undefined || disableDirectImport() }
                text={running ? 'Running' : 'Run'}
            />
            <DefaultButton
                style={{width: '200px'}}
                onClick={props.onClose} 
                text='Close' 
            />

        </div>
    
    const getSourceFieldValue = (fieldName: string | undefined) => {
        if(!fieldName) return undefined
        return existingExtensionWorkspaceMetadata?.data?.filter((a) => a.key == fieldName)?.[0]?.value ?? ""
    }
    const getPivots = () => {
        if(!props.extension.steps) {
            return (
                <>
                    {additionalData.map((m) =>
                        <ExtensionField
                            onChange={(a) => {
                                change(m.field, a)
                            }}
                            key={`extension-field-${m.field}`}
                            field={m}
                            extension={props.extension}
                            value={workspaceMetadata?.filter((a) => a.key == m.field)?.[0]?.value ?? ""}
                            onExportSelection={(value) => {
                                setDirectImportSelections(value)
                            }}
                        />)}
                    {itemTable}
                </>
            )
        }

        const steps = props.extension?.steps.map((s, i) => {
            return checkIfActiveStep(s.identifier) ?
                <PivotItem headerText={`${i + 1}.) ${s.name}`} hidden={!checkIfActiveStep(s.identifier)}>
                    {additionalData.filter((f) => f.step == s?.identifier).map((m) =>
                        <ExtensionField
                            onChange={(a) => {change(m.field, a)}}
                            key={`extension-field-${m.field}`}
                            sourceFieldValue={getSourceFieldValue(m.parentField)}
                            field={m}
                            extension={props.extension}
                            value={workspaceMetadata?.filter((a) => a.key == m.field)?.[0]?.value ?? ""}
                            onExportSelection={(value) => {
                                setDirectImportSelections(value)
                            }}
                        />)}
                    {s.showItems ? itemTable : <></>}
                </PivotItem> : <PivotItem></PivotItem>;
        });
        const width = props.extension.displayMode?.toLowerCase() === 'panel' ? 'unset' : '800px'
        return(
            <Pivot 
                overflowBehavior='menu' 
                styles={{itemContainer:{marginTop:'1em', height: '400px', width: width}}}>
                {steps}
            </Pivot>
        )
    }
    
    const getExtensionModalContent = () => {
            return (
                <div style={{minWidth: '400px', minHeight: '350px'}}>
                    <div className={MODAL_STYLE.body}>
                        {extensionResponse && <ExtensionModalMessage response={extensionResponse} />} 
                        {<><br/></>}
                        {!extensionResponse && <> { getPivots() }

                        </>}
                    </div>
                </div>
            )
    }
    
    const getExtensionFooterContent = () => {
        return ( <div>{actions}</div>)
    }
    
    const styles = {
        ...mergeStyleSets({
            customHost: {
                padding: 10,
                background: 'white',
                position: 'absolute',
                left:'0%',
                bottom:'0'
            },
        }),
    };
    const getPanelExtensionFooterContent = () => {
        return(<LayerHost id={'footer-layerhost'} className={styles.customHost}>
            {panelActions}
        </LayerHost>)
    }

    return (<>
        {props.extension.displayMode?.toLowerCase() != 'panel' &&
            <Modal
                isOpen={props.isOpen}
                onDismiss={() => props.onClose()}
                containerClassName={MODAL_STYLE.container}
                styles={{root:{overflow: 'visible', minHeight: '400px', minWidth: '400px'}}}
                
            >
                <div className={MODAL_STYLE.header}>
                    <span>{props.extension.name}</span>
                    {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                    <IconButton
                        styles={MODAL_ICON_BUTTON_STYLE}
                        iconProps={{iconName: 'Cancel'}}
                        ariaLabel="Close popup modal"
                        onClick={() => props.onClose()}
                    />
                </div>
                {getExtensionModalContent()}
                <div className={MODAL_STYLE.footer} style={{paddingTop: '80px'}}>{getExtensionFooterContent()}</div>
            </Modal>
        }
        {props.extension.displayMode?.toLowerCase() === 'panel' &&             
            <Panel
                isFooterAtBottom={true}
                className={"detail-panel"}
                isOpen={props.isOpen}
                onDismiss={() => props.onClose()}
                type={PanelType.large}
            >
                <div className={MODAL_STYLE.header}>
                    <span>{props.extension.name}</span>
                    {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>}
                </div>
                {getExtensionModalContent()}
                {getPanelExtensionFooterContent()}
            </Panel>
        }
        </>
    );
}

export default ExtensionModal;