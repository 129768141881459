import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {useGetTaxCodesQuery, useGetWorkspaceQuery, usePutWorkspaceMutation} from "../../store/Api";
import {ContextualMenu, Icon, IContextualMenuItem} from "@fluentui/react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";

const WorkspaceTaxCodeHeader: React.FC = () => {
    const selectedWorkspace = useSelector((state: RootState) => state.settings.selectedWorkspaceId);
    if (selectedWorkspace == null) return <>Tax Code</>
    return <WorkspaceTaxCodeEntry workspace={selectedWorkspace} />
}

interface IWorkspaceTaxCodeEntryProps {
    workspace: string;
}

const WorkspaceTaxCodeEntry: React.FC<IWorkspaceTaxCodeEntryProps> = (props) => {
    const workspace = useGetWorkspaceQuery(props.workspace);
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const headerRef = React.useRef<HTMLSpanElement>(null);
    const [val, setVal] = React.useState<string | undefined>(undefined);
    const [patchWorkspace] = usePutWorkspaceMutation();
    const taxCodes = useGetTaxCodesQuery();
    React.useEffect(() => {
        if (workspace.data) {
            setVal(workspace.data.defaultTaxCode ?? "");
        }
    }, [workspace.data?.defaultTaxCode]);

    const update = (v: string | undefined) => {
        if (workspace.data) {
            patchWorkspace({
                ...workspace.data,
                id: props.workspace,
                defaultTaxCode: v,
                defaultTaxRate: v ? getTaxRateFromCode(v) : 0,
                documentIsCurrent: false
            });
            setShowMenu(false);
        }
    }

    const getTaxRateFromCode = (taxCode: string) => {
        return taxCodes.data?.filter(f => f.name == taxCode)?.[0]?.rate
    }
    
    const getTaxCodeDefaultHeader = () => {
        if(val) {
            return `(${val})`
        }
        return ''
    }

    const menuItems: IContextualMenuItem[] = [
        {
            onRender: () =>
                <div style={{margin: '1em'}}>
                    <Dropdown
                        placeholder={'Not Set'}
                        selectedKey={workspace.data?.defaultTaxCode}
                        options={[...(taxCodes?.data?.map(taxCode => (
                            {
                                key: taxCode.name ?? '',
                                text: `${taxCode.name ?? ''} (${taxCode.rate?.toString()}%)`
                            }
                        )) ?? [])
                        ]}
                        onChange={(e,d) => {
                            if (d) {
                                update(d.key.toString())
                            }
                        }}
                    />
                </div>,
            key: 'taxCode',
        }
    ]
    return (<>
        <div
            onClick={() => {
                setShowMenu(!showMenu);
            }}
            style={{
                cursor: 'pointer',
                width: '400px',
            }}
        >
            <span ref={headerRef}>
                Tax Code {getTaxCodeDefaultHeader()}
                <ContextualMenu items={menuItems} hidden={!showMenu} target={headerRef}/>
                <Icon iconName={'Edit'} style={{marginLeft: '0.5em'}}/>
            </span>
        </div>
    </>);
}

export default WorkspaceTaxCodeHeader;