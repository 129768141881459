import React, {FocusEventHandler, useEffect} from 'react';
import {Icon, Label, MaskedTextField, TextField, TooltipHost} from '@fluentui/react';

interface IDataFieldProps {
    required?: boolean,
    readOnly?: boolean,
    disabled?: boolean,
    label?: string,
    value?: string,
    type?: string,
    mask?: string,
    maskFormat?: string,
    maskChar?: string,
    onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void,
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | undefined>,
    hideIfEmpty?: boolean,
    placeholder?: string,
    multiline?: boolean,
    toolTip?: string,
    onKeyDown?: (e: React.KeyboardEvent) => void
}

const DataField: React.FC<IDataFieldProps> = (props) => {
    const [maskFormat, setMaskFormat] = React.useState<{ [key: string]: RegExp }>();
    useEffect(() => {
        console.log('mask:', props.mask);
        console.log('mask format:', props.maskFormat)
        console.log('mask char:', props.maskChar)
        if (props.maskFormat) {
            setMaskFormat({[props.maskChar ?? '*']: new RegExp(props.maskFormat)});
        }
    }, [props.maskFormat]);

    const getErrorMessage = (value: string): string => {
        return value.length < 1 && props.required ? 'Field is required' : '';
    };
    if (props.hideIfEmpty && (!props.value || props.value == '')) {
        return <></>
    }
    if (props.readOnly || props.disabled) {
        return (
            <div className={'ac-details-field'}>
                {props.label && <h4>{props.label}</h4>}
                {props.value ?? props.placeholder}
            </div>
        )
    }
    const label = () => {
        if (props.toolTip) {
            return (<TooltipHost content={props.toolTip}>
                <div style={{display: "inline-flex"}}><Label><Icon iconName={'Info'}
                                                                   style={{paddingRight: '5px'}}/>{props.label}</Label>
                </div>
            </TooltipHost>)
        }
        return (<Label>{props.label}</Label>)
    }
    if (props.mask) {
        return (
            <React.Fragment>
                {label()}
                <MaskedTextField
                    required={props.required}
                    type={props.type}
                    mask={props.mask}
                    maskFormat={maskFormat}
                    value={props.value ?? ""}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    placeholder={props.placeholder}
                    styles={{root: {paddingBottom: '2px'}}}
                    onGetErrorMessage={getErrorMessage}
                    multiline={props.multiline}
                    onKeyDown={props.onKeyDown}
                />
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            {label()}
            <TextField
                type={props.type}
                required={props.required}
                value={props.value ?? ""}
                onChange={props.onChange}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                styles={{root: {paddingBottom: '2px'}}}
                onGetErrorMessage={getErrorMessage}
                multiline={props.multiline}
                onKeyDown={props.onKeyDown}
            />
        </React.Fragment>
    );
}

export default DataField;