import React from "react";
import {IWorkspaceItem} from "../../models/Workspace";
import {IExtensionLink, IExtensionPotentialMatchResponse, IInstalledExtension} from "../../models/Extension";
import {DefaultButton, Spinner} from "@fluentui/react";
import {IVendor} from "../../models/Search";
import {useConfirmExtensionPotentialMatchMutation} from "../../store/Api";

interface IExtensionMatchButtonProps {
    loading?: boolean;
    potentialMatches?: IExtensionPotentialMatchResponse;
    item: IWorkspaceItem;
    extension: IInstalledExtension;
}

const ExtensionMatchButton: React.FC<IExtensionMatchButtonProps> = (props) => {
    const [confirm] = useConfirmExtensionPotentialMatchMutation();
    const [confirming, setConfirming] = React.useState<boolean>(false);

    if (props.loading) return <><Spinner /></>
    if (props.item.product?.vendors?.findIndex(v => v.vendorName === props.extension.linkName) !== -1) return <>Linked</>
    if (props.potentialMatches == null) return <></>
    const potentialMatch = props.potentialMatches?.products?.find(p => p.acId === props.item.productId)
    if (potentialMatch == null) return <></>
    return <DefaultButton
        text={`Confirm ${potentialMatch?.name}
        (${potentialMatch?.mfp}`}
        disabled={confirming}
        onClick={() => {
            setConfirming(true);
            confirm({
                extensionId: props.extension.id,
                externalId: potentialMatch.id,
                catalog: props.item.catalog,
                productId: props.item.productId
            }).then(() => {
                setConfirming(false);
            })
        }}
    />
}

export default ExtensionMatchButton;