import React, {useState} from "react";
import {Modal, Stack} from "@fluentui/react";
import {MODAL_STYLE} from "../../Constants";
import {DefaultButton} from "@fluentui/react/lib/Button";
import {useDismissNotificationMutation, useGetNotificationsQuery} from "../../store/Api";
import MarkdownView from 'react-showdown';
import {useDispatch} from "react-redux";
import {setDismissedNotification} from "../../store/settingsSlice";

const NotificationModal: React.FC = () => {
    const notification = useGetNotificationsQuery();
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [dismissNotification] = useDismissNotificationMutation();
    const dispatch = useDispatch();
    const notificationId = notification?.data?.id != null ? notification.data.id : "";
    React.useEffect(() => {
        setNotificationModalOpen(notification.data != null);
    }, [notification.data]);
    
    const onClose = (id: string) => {
        dismissNotification({id: id});
        setNotificationModalOpen(false);
        dispatch(setDismissedNotification());
    }
    
    return(
        <Modal
            isOpen={notificationModalOpen}
            onDismiss={() => onClose(notificationId)}
            containerClassName={MODAL_STYLE.container}
        >
            <div className={MODAL_STYLE.header}>
            </div>
            <div className={MODAL_STYLE.body}>
                <MarkdownView
                    markdown={notification?.data?.content?? ""}
                    options={{ tables: true, emoji: true }}
                />
                <Stack horizontal style={{paddingTop: '10px'}} horizontalAlign='end'>
                    <DefaultButton text="Close" onClick={() => onClose(notificationId)}/>
                </Stack>
            </div>

        </Modal>);
}

export default NotificationModal;