import React from 'react';
import {
    MessageBar,
    MessageBarType,
    Panel,
    PanelType, PrimaryButton,
} from "@fluentui/react";
import {
    useAddProductReferenceToWorkspaceMutation,
} from "../../store/Api";
import CustomerRequestOverview from "./CustomerRequestOverview";
import NewCustomerRequest from "./NewCustomerRequest";
import CustomerRequestDisplay from "./CustomerRequestDisplay";
import {IProductSuggestion} from "../../models/MachineLearning";
import {IInstalledExtension} from "../../models/Extension";
import AnalysisExtensionImportDisplay from "./AnalysisExtensionImportDisplay";

interface ICustomerRequestWindowProps {
    isOpen: boolean;
    onClose: () => void;
    workspaceId?: string;
}

const CustomerRequestWindow: React.FC<ICustomerRequestWindowProps> = (props) => {
    const [addProducts] = useAddProductReferenceToWorkspaceMutation();
    const [id, setId] = React.useState<string | undefined>(undefined);
    const [createNew, setCreateNew] = React.useState<boolean>(false);
    const [selected, setSelected] = React.useState<{[key: number]: IProductSuggestion | undefined}>({});
    const [prods, setProds] = React.useState<IProductSuggestion[]>([]);
    const [selectedExtension, setSelectedExtension] = React.useState<IInstalledExtension | undefined>();

    const add = () => {
        if (!props.workspaceId || !id) return;
        if (prods.length === 0) return;
        const promises = [];
        for(const [,prod] of Object.entries(selected)) {
            if (prod == null) continue;
            promises.push(addProducts({
                workspace: props.workspaceId,
                product: {
                    id: prod.id,
                    catalog: prod.catalog,
                },
                req: {
                    quantity: prod.quantity ?? 1,
                }
            }));
        }
        Promise.all(promises)
            .then(() => {
                props.onClose();
            });
    }

    return <>
        <Panel
            headerText={'Customer Request Analysis'}
            isOpen={props.isOpen}
            onDismiss={props.onClose}
            type={PanelType.medium}
            isFooterAtBottom={true}
            onRenderFooterContent={() => {
                if (id == null) return <></>
                return <>
                    <PrimaryButton
                        text={`Add ${prods.length} to Workspace`}
                        disabled={prods.length === 0}
                        onClick={add}
                        />
                </>
            }}
        >
            <MessageBar messageBarType={MessageBarType.info}>This tool is currently in early ALPHA and is powered by
                AI, so mistakes are possible.</MessageBar>
            {props.workspaceId && !id && <div>
                {!createNew && !selectedExtension && <CustomerRequestOverview
                    workspaceId={props.workspaceId}
                    onSelect={(id) => setId(id)}
                    onNew={() => setCreateNew(true)}
                    onExtensionSelect={setSelectedExtension}
                />}
                {createNew && <NewCustomerRequest
                    onClose={() => setCreateNew(false)}
                    workspaceId={props.workspaceId}
                    onSubmit={(id) => {
                        setId(id);
                        setCreateNew(false);
                    }}
                />}
                {selectedExtension && <AnalysisExtensionImportDisplay
                    extension={selectedExtension}
                    onCancel={() => setSelectedExtension(undefined)}
                    onRun={(id) => {
                        setId(id);
                        setSelectedExtension(undefined);
                    }}
                    />}
            </div>}
            {id && <CustomerRequestDisplay
                id={id}
                onClose={() => setId(undefined)}
                workspaceId={props.workspaceId}
                onSelectedChange={(sel) => {
                    setSelected(sel);
                    const newProds: IProductSuggestion[] = []
                    for(const s of Object.values(sel)) {
                        if (s != null) newProds.push(s);
                    }
                    setProds(newProds);
                }}
            />}
        </Panel>
    </>
}

export default CustomerRequestWindow;