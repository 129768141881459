import React, {useEffect, useState} from "react";
import {Checkbox, TextField} from "@fluentui/react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {IGlobalStorefrontSettings, IStorefrontSettings, PAYMENT_PROCESSOR_KEYS} from "../../models/Storefront";
import DataField from "../Common/DataField";
import ToggleField from "../Common/ToggleField";

interface IStorefrontOrderSettingsProps {
    settings: IStorefrontSettings;
    globalSettings?: IGlobalStorefrontSettings;
    onChange: (settings: IStorefrontSettings) => void;
}
const StorefrontOrderSettings: React.FC<IStorefrontOrderSettingsProps> = (props) => {
    
    const notesModeDropdownValues = () => { return [
        {key: '', text: 'Global Default'},
        {key: 'Enabled', text: 'True'},
        {key: 'Disabled', text: 'False'}]
    }

    return(<div style={{paddingTop: '2em'}}>
        <h3>Order Settings</h3>
        <div style={{paddingBottom: '1em'}}>
            <ToggleField
                toolTip={'When a value is entered, this will override the Terms and Conditions from the global Storefront Settings screen'}
                label='Override Terms and Conditions' 
                checked={props.settings.overrideTermsAndConditions} 
                onChange={(e, c) => props.onChange({...props.settings, overrideTermsAndConditions: c})}/>
        </div>
        <Dropdown options={notesModeDropdownValues()} selectedKey={props.settings.notesMode ?? ''}
                  onChange={(e, v) => props.onChange({...props.settings, notesMode: v?.key.toString()})}
                  label={'Use Notes'}
        />
        <DataField label='Notes Label' value={props.settings.notesLabel} 
                   toolTip={'When enabled, Notes provides a dynamic input for the customer to provide additional information about their order. Use this field to provide a label for the Notes input field which will appear when the customer goes to check out from storefront. This will override the global Notes Label field from the global Storefront Settings screen.'}
                   onChange={(e, v) => props.onChange({...props.settings, notesLabel: v})}
                   placeholder={props.globalSettings?.notesLabel ?? 'Additional Order Information'}/>
        <DataField multiline label={'Notes Instructions'}
                   toolTip={'Use Notes Instructions to provide detailed instructions about how to fill out the Notes field. This will override the global Notes Instructions field from the global Storefront Settings screen. Markdown is supported.'}
                   value={props.settings.notesInstructions}
                   onChange={(e, v) => {
                       props.onChange({...props.settings, notesInstructions: v})
                   }}
                   placeholder={props.globalSettings?.notesInstructions}/>
        <DataField multiline
                   toolTip={props.settings.paymentProcessor && props.settings.paymentProcessor != PAYMENT_PROCESSOR_KEYS.NONE ? '' : 'Markdown is supported'}
                   label={'Terms and Conditions'}
                   value={props.settings.termsAndConditions}
                   onChange={(e, v) => {
                       props.onChange({...props.settings, termsAndConditions: v})
                   }}
        />
        <TextField type='email' label='Order Email' value={props.settings.orderEmail} onChange={(e, v) => props.onChange({...props.settings, orderEmail: v})}
                   placeholder={props.globalSettings?.orderEmail}/>
        <TextField multiline label='Customer Email Text' value={props.settings.customerEmailText}
                   onChange={(e, v) => props.onChange({...props.settings, customerEmailText: v})} placeholder={props.globalSettings?.customerEmailText}/>
        <TextField label='Customer Email Subject' value={props.settings.customerEmailSubject}
                   onChange={(e, v) => props.onChange({...props.settings, customerEmailSubject: v})}
                   placeholder={props.globalSettings?.customerEmailSubject}/>
    </div>)
}

export default StorefrontOrderSettings;