import {BaseDAL, IActionResponse} from "./BaseDAL";
import {
    IGetPermissionsResponse,
    IOrganization,
    IUserCreateRequest,
} from "../models/Settings";

export class SettingsDAL extends BaseDAL {
    public getOrganizationDetails(): Promise<IOrganization | null>  {
        const url = `${this.baseUrl}/api/settings/organization?`;
        return this.get<IOrganization>(url);
    }

    public saveUser(user: IUserCreateRequest): Promise<IActionResponse | null> {
        const url = `${this.baseUrl}/api/settings/organization/users/${user.username}?`;
        return this.put<IActionResponse>(url, JSON.stringify(user));
    }

    public deleteUser(username: string): Promise<IActionResponse | null> {
        const url = `${this.baseUrl}/api/settings/organization/users/${username}?`;
        return this.delete<IActionResponse>(url);
    }

    public getPermissions(): Promise<IGetPermissionsResponse | null> {
        const url = `${this.baseUrl}/api/settings/permissions?`;
        return this.get<IGetPermissionsResponse>(url);
    }
}
