import React from 'react';
import {useGetApiKeysQuery, useGetSearchReportQuery} from "../../store/Api";
import {ProgressIndicator, SelectionMode, ShimmeredDetailsList} from "@fluentui/react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import CAN, {ORG_REPORTING} from "../../permissions/ability";

const SearchReportingPage: React.FC = () => {
    const report = useGetSearchReportQuery();
    const apiKeys = useGetApiKeysQuery();
    const selectedOrg = useSelector((state: RootState) => state.settings.selectedOrganization);
    const page = <>
        <h1>Search Reporting</h1>
        {report.isFetching && <ProgressIndicator />}
        {selectedOrg?.searchCredits && <h3>Total Credits Used: {report.data?.searchCreditsUsed} of {selectedOrg.searchCredits}</h3>}
        <h3 style={{marginBottom: 0}}>Users</h3>
        <p style={{fontSize: '80%', marginTop: 0, fontStyle: 'italic'}}>Note: User usage includes any API keys that the user has generated</p>
        <ShimmeredDetailsList
            items={report.data?.users
                ? Object.keys(report.data.users).map((user) => ({
                    User: user,
                    'Searches': report.data?.users[user].searchCount,
                    'Credits Used': report.data?.users[user].searchCreditsUsed,
                }))
                : []
            }
            enableShimmer={report.isFetching}
            selectionMode={SelectionMode.none}
        />
        <h3>API Keys</h3>
        <ShimmeredDetailsList
            items={report.data?.users
                ? Object.keys(report.data.apiKeys).map((key) => ({
                    'API Key': apiKeys.data?.find(k => k.id == key)?.name ?? key,
                    'Searches': report.data?.apiKeys[key].searchCount,
                    'Credits Used': report.data?.apiKeys[key].searchCreditsUsed,
                }))
                : []
            }
            enableShimmer={report.isFetching || apiKeys.isFetching}
            selectionMode={SelectionMode.none}
        />
    </>;
    return (CAN(ORG_REPORTING) ? page : <></>)
}

export default SearchReportingPage;