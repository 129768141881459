import React, {useEffect, useState} from 'react';
import {IAdditionalData} from '../../models/Extension';
import {useLazyGetExportExtensionOptionsQuery} from "../../store/Api";
import {
    Selection,
    SelectionMode,
    ShimmeredDetailsList, Spinner
} from "@fluentui/react";
import {IDictionary} from "../../models/ICollectionResponse";

interface IExtensionSearchResultsProps {
    field: IAdditionalData;
    isDisabled?: boolean;
    extensionId: string;
    initialValue: string;
    onSelectOption?: (value: string) => void;
}

const ExtensionSearchResults: React.FC<IExtensionSearchResultsProps> = (props) => {
    const [optionValues, setOptionValues] = useState<(IDictionary<string | number | boolean>)[]>([])
    const [getExtensionOptions] = useLazyGetExportExtensionOptionsQuery();
    const [isLoading, setIsLoading] = useState<boolean>()
    useEffect(() => {
        if(props.initialValue){
            setIsLoading(true)
            getExtensionOptions({extensionId: props.extensionId, field: props.field.field, search: props.initialValue, additionalData: undefined})
                .unwrap().then((result) => {
                    if (result) {
                        const optionValues: IDictionary<string | number | boolean>[] = []
                        for(let i = 0; i < result.options.length; i++){
                            if(result.options?.[i]?.additionalProperties) {
                                optionValues.push(result.options[i].additionalProperties as (IDictionary<string | number | boolean>))
                            }
                        }
                        setOptionValues(optionValues)
                        setIsLoading(false)
                    }
                }
            )
        }
    }, [props.initialValue])
    

    const selection = new Selection({
        onSelectionChanged: () => {
            if (!props.onSelectOption) return;
            const item = selection.getSelection()[0] as IDictionary<string | number | boolean> | undefined;
            const value = item?.["Id"] ? item["Id"].toString() : "";
            props.onSelectOption(value);
        },
    });
    return (<>
        {!optionValues || optionValues.length === 0 && <Spinner></Spinner>}
        {optionValues && optionValues.length > 0 && <ShimmeredDetailsList
            items={optionValues ?? []}
            enableShimmer={!optionValues || isLoading}
            selectionMode={SelectionMode.single}
            selection={selection}
        />}
        </>
    );
}

export default ExtensionSearchResults;