import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ICatalog, IGetCatalogsResponse, IGetNotesResponse, IProductInsights} from "../models/Catalog";
import {
    IColumns, ICreateSubOrganizationRequest, ICustomColumnSet,
    IGetOrganizationsResponse,
    IGetPermissionsResponse,
    IOrganization,
    ISubOrganization, IUpdateOrganizationRequest,
    IUserSettings,
    IUserSettingsUpdateRequest
} from "../models/Settings";
import {RootState} from "./rootReducer";
import {IActionResponse, IUploadFileResponse} from "../dal/BaseDAL";
import {
    IAdHocWorkspaceItem,
    IWorkspace, IWorkspaceExtensionMetadata, IWorkspaceFileImportRequest, IWorkspaceFileImportResponse,
    IWorkspaceItem,
    IWorkspaceItemDetails, IWorkspaceItemSortOrderUpdateRequest,
    IWorkspaceMetadata,
    IWorkspacePatchRequest
} from "../models/Workspace";
import {ExtensionType, SEARCHCOLUMNS, WORKSPACECOLUMNS} from "../Constants";
import {IColumn} from "@fluentui/react";
import {
    ICountResponse,
    IFavoriteLookup, IMetadata, INewProduct,
    IProduct, IProductTagsResponse,
    IQuickSearchResponse, ISavedSearch,
    ISearchQueryResponse,
    ISearchRequest,
} from "../models/Search";
import {INormalizedProduct} from "../models/NormalizedProduct";
import {setSelectedOrganization, setSelectedWorkspaceId} from "./settingsSlice";
import {
    IDirectImportExtensionPreviewRequest,
    IDirectImportExtensionPreviewResponse,
    IDirectImportExtensionRequest,
    IDirectImportExtensionResponse,
    IExtension,
    IExtensionFile, 
    IExtensionFolioSubextensionReference,
    IExtensionInstallRequest,
    IExtensionLog,
    IExtensionOptionsResponse,
    IExtensionPotentialMatchResponse,
    IExtensionResponse,
    IFolioValidationRequest,
    IFreightOptionResponse,
    IGetExtensionFieldsResponse,
    IImportExtensionMappingSampleRequest,
    IImportExtensionMappingSampleResponse,
    IInstalledExtension,
    IInstalledExtensionFolio,
    IProcurementCsvGenerationRequest,
    IProcurementOrderRequirementsResponse,
    IProcurementRealTimePriceQuantityUpdateResponse,
    IProductReference,
    IRealTimeExtensionResponse,
    IRecommendationResponse,
    ISavedExtensionField,
    IUnmatchedExtensionProduct
} from "../models/Extension";
import {ArrayBufferToBase64} from "../logic/ArrayBufferConverter";
import {cloneDeep} from "lodash";
import {NormalizeResult, updateProductFromRealtime, updateWorkspaceItemFromRealtime} from "../logic/Product";
import {criteriaIsBlank} from "../logic/Search";
import {ICollectionResponse} from "../models/ICollectionResponse";
import {IAdaptiveCatalogTask} from "../models/Tasks";
import {
    IApiKey,
    IGenerateApiKeyRequest,
    IGenerateApiKeyResponse,
    IGetApiKeysResponse,
    IGetSessionKeyRequest, IKeyAuthTypesResponse
} from "../models/ApiKey";
import {IApiUsageMonthlyReport, ISearchReport} from "../models/Reporting";
import {setSearchSession} from "./authSlice";
import {updateSearchId} from "./searchSlice";
import {IGetTaxCodeResponse, ITaxCode} from "../models/TaxCode";
import {ICompany, ICompanyResponse, IContact} from "../models/Company";
import {
    ICustomerRequestAnalysisOverview,
    ICustomerRequestAnalysisRequest,
    ICustomerRequestAnalysisResponse,
    IUserChat,
    IUserChatMessageRequest
} from "../models/MachineLearning";
import {
    IFulfillment,
    IOrder, IProcurementEvent,
    IProcurementItem,
    IProcurementRequirementAnswer,
    IPurchaseRequirement
} from "../models/Order";

const LIST = 'LIST';
const CATALOG_TYPE = 'Catalogs';
const ORGANIZATION_TYPE = 'Organizations';
const TAX_CODE_TYPE = 'TaxCodes';
const COLUMNS_TYPE = 'Columns';
const WORKSPACES_TYPE = 'Workspaces';
const WORKSPACE_TEMPLATES_TYPE = 'WorkspaceTemplates';
const WORKSPACE_PRODUCTS_TYPE = 'WorkspaceProducts';
const WORKSPACE_METADATA_TYPE = 'WorkspaceMetadata';
const WORKSPACE_RECOMMENDATIONS_TYPE = 'WorkspaceRecommendations';
const COMPANY_TYPE = 'Companies';
const EXTENSION_COMPANY_TYPE = 'ExtensionCompanies';
const COMPANY_COUNT_TYPE = 'CompanyCount';
const FAVORITES_TYPE = 'Favorites';
const SEARCH_RESULTS_TYPE = 'SearchResults;'
const INSTALLED_EXTENSION_TYPE = 'InstalledExtensions';
const INSTALLED_EXTENSION_LOGS_TYPE = 'InstalledExtensionLogs';
const EXTENSION_LIBRARY_TYPE = 'ExtensionLibrary';
const EXTENSION_UNMATCHED_PRODUCT_TYPE = 'ExtensionUnmatchedProducts';
const PROCUREMENT_UNORDERED_PRODUCT_TYPE = 'ProcurementUnorderedProducts';
const PROCUREMENT_ORDER_REQUIREMENTS_TYPE = 'ProcurementOrderRequirements'
const EXTENSION_TYPE = 'Extensions';
const ITEM_EXTENSION_FIELD_TYPE = 'ItemExtensionFields';
const EXTENSION_LINK_TYPE = 'ExtensionLinks';
const EXTENSION_POSSIBLE_MATCH = 'ExtensionPossibleMatches'
const PRODUCT_NOTES_TYPE = 'ProductNotes';
const ITEM_DETAILS_TYPE = 'ItemDetails';
const TASKS_TYPE = 'Tasks';
const API_KEYS = 'ApiKeys'
const SUB_ORGANIZATIONS = 'SubOrganizations';
const ORDER_CARRIER_OPTIONS_TYPE = 'CarrierOptions';
const ORDER_WAREHOUSE_OPTIONS_TYPE = 'WarehouseOptions';
const IS_FREIGHT_VENDOR_TYPE = "IsFreightVendor"
const ORDER_PROCUREMENT_ITEMS_TYPE = "OrderProcurementItems"
const ORDER_FULFILLMENTS_TYPE = "OrderFulfillments"
const ORDER_EVENTS_TYPE = "OrderEvents"
const EXTENSION_ID_BY_SOURCE_TYPE = "ExtensionId";
const PROCUREMENT_SOURCE_VENDORS = "ProcurementSourceVendors";
const PAYMENT_PROCESSOR_SETUP_DETAILS = "PaymentProcessorSetupDetails";
const STOREFRONT_ORDERS = "StorefrontOrders";
const EXTENSION_FOLIO_TYPE = "ExtensionFolio";
const ML_USER_CHAT_TYPE = "MLUserChat";
import {pca} from '../index';
import {loginRequest} from "../authConfig";
import {
    IGlobalStorefrontSettings,
    IPaymentProcessorSetupDetails,
    IStorefrontAdminSessionResponse,
    IStorefrontMonthlyOrders,
    IStorefrontOrder,
    IStorefrontOrderStatuses,
    IStorefrontSettings,
    IStorefrontTheme,
    IStripeSetupRequest
} from "../models/Storefront";
import { ISearchDataRequest } from "../models/SearchData";
import {INotification} from "../models/Notification";

const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${window.config.baseUrl}/api/`,
        prepareHeaders: async (headers, api) => {
            if (api.endpoint == "getSessionKey") return headers;
            const state = api.getState() as RootState;
            headers.append('Content-Type', 'application/json');
            if (state.auth.sessionKey)
                headers.append('x-session-key', state.auth.sessionKey);
            else if (state.auth.accessToken) {
                const account = pca.getAllAccounts()[0];
                if (account) {
                    try {
                        const response = await pca.acquireTokenSilent({
                            scopes: loginRequest.scopes,
                            account: account,
                        });
                        headers.append('Authorization', `Bearer ${response.accessToken}`);
                    } catch (error) {
                        console.log(error);
                        throw new Error("Not authenticated");
                    }
                } else {
                    headers.append('Authorization', `Bearer ${state.auth.accessToken}`);
                }
            } else {
                throw new Error("Not authenticated");
            }
            if (state.settings.selectedOrganization?.id)
                headers.append('x-organization', state.settings.selectedOrganization?.id);
            if (state.auth.searchSession)
                headers.append('x-search-session', state.auth.searchSession)
            if (state.search.searchId)
                headers.append('x-search-id', state.search.searchId)
            if (window.config.apiVersion)
                headers.append('x-api-version', window.config.apiVersion.toString())
            return headers;
        }
    }),
    tagTypes: [
        CATALOG_TYPE,
        TAX_CODE_TYPE,
        ORGANIZATION_TYPE,
        COLUMNS_TYPE,
        WORKSPACES_TYPE,
        WORKSPACE_TEMPLATES_TYPE,
        WORKSPACE_PRODUCTS_TYPE,
        WORKSPACE_METADATA_TYPE,
        WORKSPACE_RECOMMENDATIONS_TYPE,
        COMPANY_TYPE,
        EXTENSION_COMPANY_TYPE,
        FAVORITES_TYPE,
        SEARCH_RESULTS_TYPE,
        EXTENSION_TYPE,
        INSTALLED_EXTENSION_TYPE,
        INSTALLED_EXTENSION_LOGS_TYPE,
        EXTENSION_LIBRARY_TYPE,
        ITEM_EXTENSION_FIELD_TYPE,
        EXTENSION_LINK_TYPE,
        EXTENSION_POSSIBLE_MATCH,
        PRODUCT_NOTES_TYPE,
        ITEM_DETAILS_TYPE,
        TASKS_TYPE,
        API_KEYS,
        EXTENSION_UNMATCHED_PRODUCT_TYPE,
        SUB_ORGANIZATIONS,
        COMPANY_COUNT_TYPE,
        PROCUREMENT_UNORDERED_PRODUCT_TYPE,
        PROCUREMENT_ORDER_REQUIREMENTS_TYPE,
        ORDER_CARRIER_OPTIONS_TYPE,
        ORDER_WAREHOUSE_OPTIONS_TYPE,
        IS_FREIGHT_VENDOR_TYPE,
        ORDER_PROCUREMENT_ITEMS_TYPE,
        ORDER_FULFILLMENTS_TYPE,
        ORDER_EVENTS_TYPE,
        EXTENSION_ID_BY_SOURCE_TYPE,
        PROCUREMENT_SOURCE_VENDORS,
        PAYMENT_PROCESSOR_SETUP_DETAILS,
        STOREFRONT_ORDERS,
        EXTENSION_FOLIO_TYPE,
        ML_USER_CHAT_TYPE,
    ],
    endpoints: (build) => ({
        //region CATALOGS
        getCatalogs: build.query<ICatalog[], void>({
            query: () => ({
                url: 'catalog?',
            }),
            transformResponse: (response: IGetCatalogsResponse) => response.catalogs,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(c => ({type: CATALOG_TYPE, id: c.name} as const)),
                        {type: CATALOG_TYPE, id: LIST},
                    ]
                    : [{type: CATALOG_TYPE, id: LIST}],
        }),
        addCatalog: build.mutation<IActionResponse, ICatalog>({
            query: (catalog: ICatalog) => ({
                url: 'catalog',
                method: 'POST',
                body: catalog,
            }),
            invalidatesTags: [{type: CATALOG_TYPE, id: LIST}],
        }),
        deleteCatalog: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `catalog/${arg}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_result, _error, arg) => [{type: CATALOG_TYPE, id: arg}],
        }),
        //endregion
        //region Tax Codes
        getTaxCodes: build.query<ITaxCode[], void>({
            query: () => ({
                url: 'settings/taxCodes?',
            }),
            transformResponse: (response: IGetTaxCodeResponse) => response.taxCodes,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(c => ({type: TAX_CODE_TYPE, id: c.name} as const)),
                        {type: TAX_CODE_TYPE, id: LIST},
                    ]
                    : [{type: TAX_CODE_TYPE, id: LIST}],
        }),
        addUpdateTaxCode: build.mutation<IActionResponse, ITaxCode>({
            query: (taxCode: ITaxCode) => ({
                url: `settings/taxCodes`,
                method: 'PUT',
                body: taxCode
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const finishedData = await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getTaxCodes', undefined , (draft) => {
                        const codes = cloneDeep(draft);
                        const index = codes.findIndex(a => a.name == arg.name)
                        if(index > -1 && finishedData.data != undefined){
                            codes[index] = arg
                            codes[index].id = finishedData.data.id
                        }
                        else {
                            if(finishedData.data != undefined){
                                arg.id = finishedData.data.id
                                codes.push(arg)
                            }
                        }
                        Object.assign(draft, codes);
                    })
                )
            }
        }),
        deleteTaxCode: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `settings/taxCodes/${arg}`,
                method: 'DELETE'
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const finishedData = await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getTaxCodes', undefined , (draft) => {
                        const index = draft.findIndex(a => a.id == arg)
                        if(index > -1 && finishedData.data != undefined){
                            draft.splice(index, 1)
                        }

                    })
                );
            }

        }),

        //endregion
        
        //region ORGANIZATIONS
        getOrganizations: build.query<IOrganization[], void>({
            query: () => 'settings/organizations?',
            transformResponse: (response: IGetOrganizationsResponse) => response.organizations,
            providesTags: (result) => result
                ? [...result.map(o => ({type: ORGANIZATION_TYPE, id: o.id} as const)), {
                    type: ORGANIZATION_TYPE,
                    id: LIST
                }]
                : [{type: ORGANIZATION_TYPE, id: LIST}],
        }),
        updateOrganization: build.mutation<IActionResponse, IUpdateOrganizationRequest>({
            query: (arg) => ({
                url: 'settings/organization',
                method: 'PATCH',
                body: arg,
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const finishedData = await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getOrganizations', undefined , (draft) => {
                        const index = draft.findIndex(a => a.name == arg.name)
                        if(index > -1 && finishedData.data != undefined){
                            draft[index] = {...draft[index], ...arg}
                            dispatch(setSelectedOrganization({...draft[index], ...arg}))
                        }
                    })
                );
            }
        }),
        setSelectedOrganization: build.mutation<null, IOrganization | undefined>({
            queryFn: (arg, {dispatch}) => {
                console.log('setting org...', arg)
                dispatch(setSelectedOrganization(arg));
                return {data: null};
            },
            invalidatesTags: [
                {type: WORKSPACES_TYPE, id: LIST},
                {type: CATALOG_TYPE, id: LIST},
                {type: COLUMNS_TYPE, id: LIST},
                {type: WORKSPACE_PRODUCTS_TYPE, id: LIST},
                {type: FAVORITES_TYPE, id: LIST},
                {type: SEARCH_RESULTS_TYPE, id: LIST},
            ]
        }),
        //endregion

        //region CUSTOMIZABLE COLUMNS
        getColumns: build.query<IColumns, void>({
            query: () => 'settings/columns?',
            providesTags: [COLUMNS_TYPE],
            transformResponse: (response: IColumns) => ({
                searchColumns: response.searchColumns != null && response.searchColumns.length > 0 ? response.searchColumns : SEARCHCOLUMNS,
                workspaceColumns: response.workspaceColumns != null && response.workspaceColumns.length > 0 ? response.workspaceColumns : WORKSPACECOLUMNS,
            }),
        }),
        setSearchColumns: build.mutation<IActionResponse, IColumn[]>({
            query: (columns: IColumn[]) => {
                const cols = columns.map(i => i.key);
                return {
                    url: '/settings/columns/search',
                    method: 'POST',
                    body: cols
                }
            },
            invalidatesTags: [COLUMNS_TYPE],
        }),
        setWorkspaceColumns: build.mutation<IActionResponse, IColumn[]>({
            query: (columns: IColumn[]) => {
                const cols = columns.map(i => i.key);
                return {
                    url: '/settings/columns/workspace',
                    method: 'POST',
                    body: cols
                }
            },
            invalidatesTags: [COLUMNS_TYPE],
        }),
        //endregion

        //region WORKSPACES
        getWorkspaces: build.query<IWorkspace[], void>({
            query: () => 'workspaces',
            transformResponse: (response: ICollectionResponse<IWorkspace>) => response.items,
            providesTags: (results) => results
                ? [...results.map(w => ({type: WORKSPACES_TYPE, id: w.id} as const)), {type: WORKSPACES_TYPE, id: LIST}]
                : [{type: WORKSPACES_TYPE, id: LIST}]
        }),
        getTemplateWorkspaces: build.query<IWorkspace[], void>({
            query: () => 'workspaces/templates',
            transformResponse: (response: ICollectionResponse<IWorkspace>) => response.items,
            providesTags: (results) => results
                ? [...results.map(w => ({type: WORKSPACE_TEMPLATES_TYPE, id: w.id} as const)), {type: WORKSPACE_TEMPLATES_TYPE, id: LIST}]
                : [{type: WORKSPACE_TEMPLATES_TYPE, id: LIST}]
        }),
        getRecentWorkspaces: build.query<IWorkspace[], void>({
            query: () => 'workspaces/recent',
            transformResponse: (response: ICollectionResponse<IWorkspace>) => response.items,
            providesTags: (results) => results
                ? [...results.map(w => ({type: WORKSPACES_TYPE, id: w.id} as const)), {type: WORKSPACES_TYPE, id: LIST}]
                : [{type: WORKSPACES_TYPE, id: LIST}]
        }),
        getWorkspace: build.query<IWorkspace, string >({
            query: (arg) => `workspaces/${arg}?`,
            providesTags: (results) => results
                ? [{type: WORKSPACES_TYPE, id: results.id}]
                : [],

            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const finishedData = await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getRecentWorkspaces', undefined , (draft) => {
                        
                        const index = draft.findIndex(a => a.id == finishedData.data.id)
                        if (index !== -1) {
                            const [removed] = draft.splice(index, 1);
                            draft.unshift(removed);
                        }
                        else{
                            draft.splice(0, 0, finishedData.data);
                            draft.splice(draft.length - 1, 1);
                        }
                    })
                );
            }
        }),
        addWorkspace: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `workspaces?name=${arg}`,
                method: 'POST'
            }),
            invalidatesTags: () => [
                {type: WORKSPACES_TYPE, id: LIST}
            ],
        }),
        addWorkspaceWithTemplateDefaults: build.mutation<IActionResponse, {name: string, template: string}>({
            query: (arg) => ({
                url: `workspaces/templates/${arg.template}/createFromTemplate/${arg.name}`,
                method: 'POST'
            }),
            invalidatesTags: () => [
                {type: WORKSPACES_TYPE, id: LIST}
            ],
        }),
        removeWorkspace: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `workspaces/${arg}`,
                method: 'DELETE'
            }),
            invalidatesTags: (_1, _2, arg) => [
                {type: WORKSPACES_TYPE, id: arg},
                {type: WORKSPACE_PRODUCTS_TYPE, id: LIST},
            ],
        }),
        putWorkspace: build.mutation<null, IWorkspacePatchRequest>({
            query: (arg) => ({
                url: `workspaces/${arg.id}`,
                method: 'PUT',
                body: {
                    ...arg,
                }
            }),
            invalidatesTags: (_response, _error, arg) =>  [
                {type: WORKSPACES_TYPE, id: arg.id},
            ],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                if (arg.id) {
                    const patchResult = dispatch(
                        api.util.updateQueryData('getWorkspace', arg.id, (draft) => {
                            Object.assign(draft, arg)
                        })
                    )
                    const recentResult = dispatch(
                        api.util.updateQueryData('getRecentWorkspaces', undefined, (draft) => {
                            const updatedIndex = draft.findIndex(a => a.id == arg)
                            if(updatedIndex != -1) {
                                const updatedWorkspace = cloneDeep(draft)
                                updatedWorkspace[updatedIndex].documentIsCurrent = arg.documentIsCurrent ?? false
                                updatedWorkspace[updatedIndex].name = arg.name ?? draft[updatedIndex].name
                                updatedWorkspace[updatedIndex].defaultMarkup = arg.defaultMarkup
                                updatedWorkspace[updatedIndex].defaultListDiscount = arg.defaultListDiscount
                                updatedWorkspace[updatedIndex].defaultTaxable = arg.defaultTaxable
                                updatedWorkspace[updatedIndex].defaultTaxCode = arg.defaultTaxCode
                                updatedWorkspace[updatedIndex].defaultTaxRate = arg.defaultTaxRate
                                updatedWorkspace[updatedIndex].defaultSource = arg.defaultSource
                                updatedWorkspace[updatedIndex].isTemplate = arg.isTemplate
                                updatedWorkspace[updatedIndex].companyId = arg.companyId
                                updatedWorkspace[updatedIndex].companyName = arg.companyName
                                updatedWorkspace[updatedIndex].storefrontBundleDetails = {...arg.storefrontBundleDetails}

                                Object.assign(draft, updatedWorkspace)
                            }
                        })
                    )
                    try {
                        await queryFulfilled;
                    } catch (error) {
                        console.log(error);
                        patchResult.undo();
                        recentResult.undo();
                    }
                }
            }
        }),
        // Workspace Products
        getWorkspaceProducts: build.query<IWorkspaceItem[], string>({
            query: (arg) => `workspaces/${arg}/items`,
            transformResponse: (response: ICollectionResponse<IWorkspaceItem>) => response.items.map(p => ({
                ...p,
                normalized: p.product ? NormalizeResult(p.product) : undefined
            })),
            providesTags: (results) => results
                ? [...results.map(w => ({type: WORKSPACE_PRODUCTS_TYPE, id: w.id} as const)),
                    { type: WORKSPACE_PRODUCTS_TYPE, id: LIST }]
                : [{type: WORKSPACE_PRODUCTS_TYPE, id: LIST}],
        }),
        updateWorkspaceItemSortOrder: build.mutation<IActionResponse, { workspaceId: string, req: IWorkspaceItemSortOrderUpdateRequest }>({
            query: (arg) => {
                return {
                    url: `workspaces/${arg.workspaceId}/items/sortOrder`,
                    method: 'PATCH',
                    body: arg.req,
                }
            },
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const result = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('getWorkspaceProducts', arg.workspaceId, draft => {
                            // Create a mapping of item IDs to their current index in the draft
                            const itemIndexMap = new Map(draft.map((item, index) => [item.id, index]));

                            // Create a new array to hold the sorted items
                            const sortedItems = arg.req.orderedWorkspaceItemIds.map(id => {
                                const index = itemIndexMap.get(id);
                                return index !== undefined ? draft[index] : null; // Get the item or null if not found
                            }).filter(item => item !== null); // Filter out any nulls

                            // Update the draft with the sorted items
                            Object.assign(draft, sortedItems);
                        })
                    );
                } catch (error) {
                    console.log(error);
                }
            }
        }),
        addProductToWorkspace: build.mutation<IActionResponse, { workspace: string, product: INormalizedProduct, req: IWorkspaceItemDetails }>({
            query: (arg) => {
                return {
                    url: `catalog/${arg.product.catalog}/products/${arg.product.id}/addToWorkspace/${arg.workspace}`,
                    method: 'POST',
                    body: arg.req,
                }
            },
            invalidatesTags: (_result, _error, arg) => [
                {type: WORKSPACES_TYPE, id: arg.workspace},
                {type: WORKSPACE_PRODUCTS_TYPE, id: LIST},
            ],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled
                    dispatch(
                        api.util.updateQueryData('getWorkspace', arg.workspace, draft => {
                            draft.documentIsCurrent = false
                        })
                    )
                } catch (error){
                    console.log(error)
                }
            },
        }),
        importWorkspaceProductsFromTemplate: build.mutation<IWorkspaceItem[], {sourceId: string, destinationId: string}>({
            query: (arg) => {
                return {
                    url: `workspaces/${arg.destinationId}/addItemsFromTemplate/${arg.sourceId}`,
                    method: 'POST'
                }
            },
            transformResponse: (response: ICollectionResponse<IWorkspaceItem>) => response.items.map(p => ({
                ...p,
                normalized: p.product ? NormalizeResult(p.product) : undefined
            })),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    const finishedData = await queryFulfilled
                    dispatch(
                        api.util.updateQueryData('getWorkspaceProducts', arg.destinationId, (draft) => {
                            finishedData.data.forEach((a) => {
                                draft.push(a)
                            })
                        })
                    )
                    dispatch(
                        api.util.updateQueryData('getWorkspace', arg.destinationId, draft => {
                            draft.documentIsCurrent = false
                        }))
                } catch (error){
                    console.log(error)
                }
            },
        }),
        addProductReferenceToWorkspace: build.mutation<IActionResponse, { workspace: string, product: IProductReference, req: IWorkspaceItemDetails }>({
            query: (arg) => ({
                url: `catalog/${arg.product.catalog}/products/${arg.product.id}/addToWorkspace/${arg.workspace}`,
                method: 'POST',
                body: arg.req,
            }),
            invalidatesTags: (_result, _error, arg) => [
                {type: WORKSPACES_TYPE, id: arg.workspace},
                {type: WORKSPACE_PRODUCTS_TYPE, id: LIST},
            ],
        }),
        removeMultipleProductsFromWorkspace: build.mutation<IActionResponse, { workspace: IWorkspace, products: string[] }>({
            query: (arg) => ({
                url: `workspaces/${arg.workspace.id}/items`,
                method: 'DELETE',
                body: {
                    items: arg.products
                }
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', arg.workspace.id, (draft) => {
                        arg.products.forEach((a) => {
                            const i = draft.findIndex(p => p.id === a);
                            if (i !== -1) {
                                draft.splice(i, 1)
                            }
                        })
                    })
                )
                const workspacePatch = dispatch(
                    api.util.updateQueryData('getWorkspace', arg.workspace.id, draft => {
                        draft.documentIsCurrent = false
                }))
                try {
                    await queryFulfilled
                } catch (error){
                    patchResult.undo()
                    workspacePatch.undo()
                    console.log(error)
                }
            },
        }),
        removeProductFromWorkspace: build.mutation<IActionResponse, { workspaceId: string, wsItemId: string }>({
            query: (arg) => ({
                url: `workspaces/${arg.workspaceId}/items/${arg.wsItemId}`,
                method: 'DELETE'
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', arg.workspaceId, (draft) => {
                        const i = draft.findIndex(p => p.id === arg.wsItemId);
                        if (i === -1) {console.log("no product found"); return}
                        draft.splice(i, 1)
                    })
                )
                const workspacePatch = dispatch(
                    api.util.updateQueryData('getWorkspace', arg.workspaceId, draft => {
                        draft.documentIsCurrent = false
                    }))
                try {
                    await queryFulfilled
                } catch (error){
                    patchResult.undo()
                    workspacePatch.undo()
                    console.log(error)
                }
            },
        }),
        putWorkspaceItemDetails: build.mutation<IActionResponse, { workspace: string, product: string, details: IWorkspaceItemDetails }>({
            query: (arg) => ({
                url: `workspaces/${arg.workspace}/items/${arg.product}`,
                method: 'PUT',
                body: arg.details
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', arg.workspace, (draft) => {
                        const products = cloneDeep(draft);
                        const i = products.findIndex(p => p.id === arg.product);
                        if (i === -1) {console.log("no product found"); return}
                        const prod = products[i]
                        prod.details = arg.details;
                        Object.assign(draft, products)
                    })
                )
                const workspacePatch = dispatch(
                    api.util.updateQueryData('getWorkspace', arg.workspace, draft => {
                        draft.documentIsCurrent = false
                    }))
                try {
                    await queryFulfilled
                } catch (error) {
                    console.log(error)
                    patchResult.undo()
                    workspacePatch.undo()
                }
            },
        }),
        addAdhocProductToWorkspace: build.mutation<IWorkspaceItem, { workspace: string, req: IAdHocWorkspaceItem }>({
            query: (arg) => {
                return {
                    url: `workspaces/${arg.workspace}/items/addAdhoc`,
                    method: 'POST',
                    body: arg.req,
                }
            },
            invalidatesTags: (_result, _error, arg) => [
                {type: WORKSPACES_TYPE, id: arg.workspace},
                {type: WORKSPACE_PRODUCTS_TYPE, id: LIST},
            ],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled
                    dispatch(
                        api.util.updateQueryData('getWorkspace', arg.workspace, draft => {
                            draft.documentIsCurrent = false
                        })
                    )
                } catch (error){
                    console.log(error)
                }
            },
        }),
        putWorkspaceAdhocItem: build.mutation<IActionResponse, { workspace: string, adhocItem: string, details: IAdHocWorkspaceItem }>({
            query: (arg) => ({
                url: `workspaces/${arg.workspace}/items/adhoc/${arg.adhocItem}`,
                method: 'PUT',
                body: arg.details
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', arg.workspace, (draft) => {
                        const products = cloneDeep(draft);
                        const i = products.findIndex(p => p.id === arg.adhocItem);
                        if (i === -1) {console.log("no product found"); return}
                        const prod = products[i]
                        prod.adhoc = arg.details;
                        Object.assign(draft, products)
                    })
                )
                const workspacePatch = dispatch(
                    api.util.updateQueryData('getWorkspace', arg.workspace, draft => {
                        draft.documentIsCurrent = false
                    }))
                try {
                    await queryFulfilled
                } catch (error) {
                    console.log(error)
                    patchResult.undo()
                    workspacePatch.undo()
                }
            },
        }),
        setSelectedWorkspace: build.mutation<null, string>({
            queryFn: (arg, {dispatch}) => {
                dispatch(setSelectedWorkspaceId(arg));
                return {data: null};
            },
            invalidatesTags: [
                {type: WORKSPACE_PRODUCTS_TYPE, id: LIST},
            ]
        }),
        clearWorkspaceCache: build.mutation<null, void>({
            queryFn: () => ({data: null}),
            invalidatesTags: [
                {type: WORKSPACES_TYPE, id: LIST},
                {type: WORKSPACE_PRODUCTS_TYPE, id: LIST}
            ]
        }),
        getWorkspaceMetadata: build.query<ISavedExtensionField[], string>({
            query: (arg) => `workspaces/${arg}/extensionFields`,
            transformResponse: (response: IWorkspaceMetadata) => response.fields,
            providesTags: (results) => results
                ? [
                    ...results.map(m => ({type: WORKSPACE_METADATA_TYPE, id: `${m.extensionId}-${m.key}`} as const)),
                    {type: WORKSPACE_METADATA_TYPE, id: LIST}
                ]
                : [{type: WORKSPACE_METADATA_TYPE, id: LIST}]
        }),
        getWorkspaceMetadataField: build.query<ISavedExtensionField, { workspace: string, extension: string, field: string }>({
            query: (arg) => `workspaces/${arg.workspace}/extensionFields/${arg.extension}/${arg.field}`,
            transformResponse: (response: ISavedExtensionField, _base, arg) =>  response ??
                {extensionId: arg.extension, key: arg.field, value: ''},
            providesTags: (results) => results
                ? [{type: WORKSPACE_METADATA_TYPE, id: `${results.extensionId}-${results.key}`}]
                : []
        }),

        getWorkspaceExtensionMetadata: build.query<ISavedExtensionField[], {workspace: string, extension: string}>({
            query: (arg) => `workspaces/${arg.workspace}/extensionFields/${arg.extension}`,
            transformResponse: (response: IWorkspaceExtensionMetadata) => response.items,
            providesTags: (results) => results
                ? [
                    ...results.map(m => ({type: WORKSPACE_METADATA_TYPE, id: `${m.extensionId}-${m.key}`} as const)),
                    {type: WORKSPACE_METADATA_TYPE, id: LIST}
                ]
                : [{type: WORKSPACE_METADATA_TYPE, id: LIST}]
        }),
        setWorkspaceExtensionField: build.mutation<IActionResponse, { workspace: string, extension: string, field: string, value?: string }>({
            query: (arg) => ({
                url: `workspaces/${arg.workspace}/extensionFields/${arg.extension}/${arg.field}`,
                body: {value: arg.value},
                method: 'PUT',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getWorkspaceMetadataField', {workspace: arg.workspace, extension: arg.extension, field: arg.field}, (draft) => {
                        const newData : ISavedExtensionField = {extensionId: arg.extension, key: arg.field, value: arg.value ?? ""}
                        Object.assign(draft, newData);
                    })
                )
                try {
                    await queryFulfilled
                } catch (error) {
                    console.log(error)
                    patchResult.undo()
                }
                const workspaceMetadataResult = dispatch(
                    api.util.updateQueryData('getWorkspaceExtensionMetadata', {workspace: arg.workspace, extension: arg.extension}, (draft) => {
                        if(draft) {
                            const newData: ISavedExtensionField = {
                                extensionId: arg.extension,
                                key: arg.field,
                                value: arg.value ?? ""
                            }
                            const index = draft?.findIndex((a) => a.key == arg.field)
                            if(index === -1){
                                draft.push(newData)
                            }
                            else {
                                draft[index] = newData
                            }
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch (error) {
                    console.log(error)
                    workspaceMetadataResult.undo()
                }
            },
            
        }),

        //region Contacts
        getCompanies: build.query<ICompany[], string>({
            query: (arg) => ({
                url: `contacts/companies/search`,
                method: 'POST',
                body: {
                    query: arg
                }
            }),
            transformResponse: (response: ICollectionResponse<ICompany>) => response.items,
            providesTags: (results) => results
                ? [...results.map(w => ({type: COMPANY_TYPE, id: w.id} as const)), {type: COMPANY_TYPE, id: LIST}]
                : [{type: COMPANY_TYPE, id: LIST}]
        }),
        getCompany: build.query<ICompany, string >({
            query: (arg) => `contacts/companies/${arg}?`,
            providesTags: (results) => results
                ? [{type: COMPANY_TYPE, id: results.id}]
                : [],
        }),
        getExtensionCompany: build.query<ICompany, { extensionId: string, companyIdentifier: string}>({
            query: (arg) => `extensions/company/${arg.extensionId}/companyDetails/${arg.companyIdentifier}`,
            transformResponse: (results: ICompanyResponse) => results?.company
        }),
        getImportCompanyExtensionOptions: build.query<IExtensionOptionsResponse, { extensionId: string, search: string }>({
            query: (arg) => ({
                url: `extensions/company/${arg.extensionId}/options`,
                method: 'POST',
                body: {
                    search: arg.search
                },
            })
        }),
        putCompany: build.mutation<IActionResponse, ICompany>({
            query: (arg) => ({
                url: arg.id == null ? `contacts/companies` : `contacts/companies/${arg.id}`,
                method: arg.id == null ? 'POST' : 'PUT',
                body: arg
            }),
            async onQueryStarted(arg, {dispatch}) {
                dispatch(
                    api.util.updateQueryData('getCompanies', '' , (draft) => {
                        const index = draft.findIndex(a => a.id == arg.id)
                        if(index != -1) {
                            draft[index] = arg
                        }
                        else{
                            draft.push(arg)
                        }
                    })
                )
                dispatch(
                    api.util.updateQueryData('getCompany', '' , (draft) => {
                        Object.assign(draft, arg)
                    })
                );
            }
        }),
        deleteCompany: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `settings/companies/${arg}`,
                method: 'DELETE'
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const finishedData = await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getCompanies', '' , (draft) => {
                        const index = draft.findIndex(a => a.id == arg)
                        if(index > -1 && finishedData.data != undefined){
                            draft.splice(index, 1)
                        }
                    })
                );
            }

        }),
        getWorkspaceCompanyCount: build.query<number, string>({
            query: (arg) => `settings/companies/${arg}/workspaceCount`,
            providesTags: (results, _error, arg) => results
                ? [{type: COMPANY_COUNT_TYPE, id: arg}]
                : [],
        }),
        postGenerateContactsFromCompanies: build.mutation<IActionResponse, void>({
            query: () => ({
                url: 'contacts/contacts/generate/fromCompanies',
                method: 'POST',
            }),
        }),
        getContacts: build.query<ICollectionResponse<IContact>, {query: ISearchDataRequest, includeCompanyDetails: boolean}>({
            query: (arg) => ({
                url: `contacts/contacts/search?includeCompanyDetails=${arg.includeCompanyDetails}`,
                method: 'POST',
                body: arg.query,
            })
        }),
        getContactById: build.query<IContact, {contactId: string}>({
           query: (arg) => ({
               url: `contacts/contacts/${arg.contactId}`,
               method: 'GET'
           }) 
        }),
        getContactsForCompany: build.query<ICollectionResponse<IContact>, {companyId: string}>({
            query: (arg) => ({
                url: `contacts/companies/${arg.companyId}/contacts`,
            })
        }),
        saveContact: build.mutation<IActionResponse, IContact>({
            query: (arg) => ({
                url: arg.id == null ? `contacts/contacts` : `contacts/contacts/${arg.id}`,
                method: arg.id == null ? 'POST' : 'PUT',
                body: arg
            }),
        }),
        //endregion
        uploadRecommendationFile: build.mutation<IUploadFileResponse, { extension: string, file: ArrayBuffer, fileName: string }>({
            query: (arg) => ({
                url: `extensions/recommendation/${arg.extension}/files`,
                method: 'POST',
                body: {
                    file: ArrayBufferToBase64(arg.file),
                    fileName: arg.fileName
                }
            })
        }),
        getWorkspaceRecommendations: build.query<IRecommendationResponse, { extensionId: string, workspace: string }>({
            query: (arg) => `workspaces/${arg.workspace}/recommendations/${arg.extensionId}`,
            providesTags: (_res, _err, arg) => ([
                {type: WORKSPACE_RECOMMENDATIONS_TYPE, id: `${arg.workspace}-${arg.extensionId}`},
            ])
        }),
        clearWorkspaceRecommendations: build.mutation<null, { extensionId: string, workspace: string }>({
            queryFn: () => ({data: null}),
            invalidatesTags: (_res, _err, arg) => ([
                {type: WORKSPACE_RECOMMENDATIONS_TYPE, id: `${arg.workspace}-${arg.extensionId}`},
            ])
        }),
        //endregion
        
        //region PROCUREMENT

        refreshProcurementPricing: build.mutation<IProcurementRealTimePriceQuantityUpdateResponse, { sourceVendor: string} >({
            query: (arg) => ({
                url: `extensions/realtime/procurement/run?sourceVendor=${encodeURIComponent(arg.sourceVendor)}`,
                method: 'POST',
            })
        }),
        
        getOrderItems: build.query<IProcurementItem[], {orderId: string}>({
            query: (arg) => ({
                url: `procurement/orders/${arg.orderId}/items`,
                method: 'GET'
            }),
            providesTags: (results) => results
                ? [...results.map(w => ({type: ORDER_PROCUREMENT_ITEMS_TYPE, id: w.id} as const)),
                    { type: ORDER_PROCUREMENT_ITEMS_TYPE, id: LIST }]
                : [{type: ORDER_PROCUREMENT_ITEMS_TYPE, id: LIST}],
        }),
        getIsFreightVendor: build.query<boolean, {source: string}>({
            query: (arg) => ({
                url: `procurement/isFreightVendor?source=${encodeURIComponent(arg.source)}`,
                method: 'GET'
            }),
            providesTags: (_res, _err, arg) => ([
                {type: IS_FREIGHT_VENDOR_TYPE, id: `${arg.source}`},
            ])
        }),
        getExtensionId: build.query<string, {source: string}>({
            query: (arg) => ({
                url: `procurement/extensionId?source=${encodeURIComponent(arg.source)}`,
                method: 'GET'
            }),
            providesTags: (_res, _err, arg) => ([
                {type: EXTENSION_ID_BY_SOURCE_TYPE, id: `${arg.source}`},
            ])
        }),
        getOrderWarehouseOptions: build.query<IExtensionOptionsResponse, { source: string }>({
            query: (arg) => ({
                url: `procurement/warehouseOptions?source=${encodeURIComponent(arg.source)}`,
                method: 'GET'
            }),
            providesTags: (_res, _err, arg) => ([
                {type: ORDER_WAREHOUSE_OPTIONS_TYPE, id: `${arg.source}`},
            ])
        }),
        getOrderFulfillments: build.query<IFulfillment[], { orderId: string }>({
            query: (arg) => ({
                url: `procurement/orders/${arg.orderId}/fulfillments`,
                method: 'GET'
            }),
            providesTags: (results) => results
                ? [...results.map(w => ({type: ORDER_FULFILLMENTS_TYPE, id: w.id} as const)),
                    { type: ORDER_FULFILLMENTS_TYPE, id: LIST }]
                : [{type: ORDER_FULFILLMENTS_TYPE, id: LIST}]
        }),
        getOrderEvents: build.query<IProcurementEvent[], { orderId: string }>({
            query: (arg) => ({
                url: `procurement/orders/${arg.orderId}/events`,
                method: 'GET'
            }),
            providesTags: (results) => results
                ? [...results.map(w => ({type: ORDER_EVENTS_TYPE, id: w.id} as const)),
                    { type: ORDER_EVENTS_TYPE, id: LIST }]
                : [{type: ORDER_EVENTS_TYPE, id: LIST}]
        }),
        getOrderCarrierOptions: build.query<IExtensionOptionsResponse, { source: string }>({
            query: (arg) => ({
                url: `procurement/carrierOptions?source=${encodeURIComponent(arg.source)}`,
                method: 'GET'
            }),
            providesTags: (_res, _err, arg) => ([
                {type: ORDER_CARRIER_OPTIONS_TYPE, id: `${arg.source}`},
            ])
        }),
        procureWorkspaceItems: build.mutation<IActionResponse, { workspaceId: string, procurementItems: IProcurementItem[]}>({
            query: (arg) => ({
                url: `workspaces/${arg.workspaceId}/procure`,
                method: 'POST',
                body: {
                    id: arg.workspaceId,
                    items: arg.procurementItems
                },
            }),
        }),
        createOrder: build.mutation<IActionResponse, {order: IOrder, source: string, manualOrder?: boolean}>({
            query: (arg) => ({
                url: (arg.manualOrder == true ? `procurement/order?source=${encodeURIComponent(arg.source)}&manualOrder=true` : `procurement/order?source=${encodeURIComponent(arg.source)}`),
                method: 'POST',
                body: arg.order
            }),
        }),
        updateOrderStatus: build.mutation<boolean, {orderId: string, status: string}>({
            query: (arg) => ({
                url: `procurement/orders/${arg.orderId}/status/${arg.status}`,
                method: 'POST'
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getOpenOrders', undefined, (draft) => {
                        const i = draft.findIndex(p => p.id === arg.orderId);
                        if(i != -1) {
                            const change = cloneDeep(draft)
                            Object.assign(draft, change)
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch (error){
                    patchResult.undo()
                    console.log(error)
                }
            },
        }),
        getProcurementSourceVendors: build.query<string[], void>({
            query: () => `procurement/sourceVendors`,
        }),
        getOpenOrders: build.query<IOrder[], void>({
            query: () => `procurement/orders`,
        }),
        getProcurementItemsBySourceVendor: build.query<IProcurementItem[], { sourceVendor: string }>({
            query: (arg) => `procurement/unordered?sourceVendor=${encodeURIComponent(arg.sourceVendor)}`,
        }),
        getOrderVendorUpdate: build.query<IOrder, {orderId: string}>({
            query: (arg) => `procurement/orders/${arg.orderId}/update`,
            async onQueryStarted(arg, {queryFulfilled}) {
                queryFulfilled.then((onfulfilled) => {
                    api.util.updateQueryData('getOpenOrders', undefined, (draft) => {
                        const i = draft.findIndex(p => p.id === arg.orderId);
                        if (i != -1 && onfulfilled.data) {
                            Object.assign(draft[i], onfulfilled.data)
                        }
                    })
                })
            },
        }),
        getClosedOrders: build.query<IOrder[], void>({
            query: () => `procurement/orders/completed`,
        }),
        putProcurementRequirementAnswer: build.mutation<boolean, {source: string, request: IProcurementRequirementAnswer}> ({
            query: (arg) => ({
                url: `procurement/requirementAnswers?source=${encodeURIComponent(arg.source)}`,
                method: 'PUT',
                body: arg.request
            })
        }),
        postProcurementProduct: build.mutation<IActionResponse, {request: IProcurementItem, adjacentItem?: string}> ({
            query: (arg) => ({
                url: `procurement/items`,
                method: 'POST',
                body: arg.request
            }),

/*            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled;
                const patchResult = dispatch(
                    api.util.updateQueryData('getUnorderedProcurementItemGroups', undefined, (draft) => {
                        if (result.data){
                            const draftCopy = cloneDeep(draft)
                            let i = -1
                            if(arg.adjacentItem){
                                i = draftCopy?.[arg.request.source]?.findIndex(p => p.id == arg.adjacentItem);
                            }
                            else {
                                i = draftCopy?.[arg.request.source]?.findIndex(p => p.source === arg.request.source && p.productId == arg.request.productId);
                            }
                            if (i === -1) {
                                console.log("no product found: ", result.data.id);
                                return
                            }
                            draftCopy[arg.request.source]?.splice(i, 0, arg.request)
                            Object.assign(draft, draftCopy)
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch (error){
                    patchResult.undo()
                    console.log(error)
                }
            },*/
        }),
        putProcurementProduct: build.mutation<IActionResponse, {procurementProductId: string, request: IProcurementItem}> ({
            query: (arg) => ({
                url: `procurement/items/${arg.procurementProductId}`,
                method: 'PUT',
                body: arg.request
            }),
/*            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getUnorderedProcurementItemGroups', undefined, (draft) => {
                        const draftCopy = cloneDeep(draft)
                        //const collection = draftCopy[arg.request.source];
                        const i = draftCopy?.[arg.request.source]?.findIndex(p => p.id === arg.procurementProductId);
                        if (i === -1) {
                            console.log("no product found");
                            return
                        }
                        draftCopy[arg.request.source][i] = arg.request
                        Object.assign(draft, draftCopy)
                    })
                )
                try {
                    await queryFulfilled
                } catch (error){
                    patchResult.undo()
                    console.log(error)
                }
            },*/
        }),
        removeProcurementProduct: build.mutation<IActionResponse, {procurementProductId: string, procurementSource: string }>({
            query: (arg) => ({
                url: `procurement/items/${arg.procurementProductId}`,
                method: 'DELETE'
            }),
/*            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getUnorderedProcurementItemGroups', undefined, (draft) => {
                        const collection = draft[arg.procurementSource];
                        const i = collection.findIndex(p => p.id === arg.procurementProductId);
                        if (i === -1) {console.log("no product found"); return}
                        collection.splice(i, 1)
                    })
                )
                try {
                    await queryFulfilled
                } catch (error){
                    patchResult.undo()
                    console.log(error)
                }
            },*/
        }),
        getProcurementExtensionOrderRequirements: build.query<IPurchaseRequirement[], { source: string }>({
            query: (arg) => ({
                url: `procurement/orderRequirements?source=${encodeURIComponent(arg.source)}`,
                method: 'GET'
            }),
            transformResponse: (results: IProcurementOrderRequirementsResponse) => results.requirementOptions ?? [],
            providesTags: (result, _thing, arg) =>
                result
                    ? [
                        ...result.map((c) => ({type: PROCUREMENT_ORDER_REQUIREMENTS_TYPE, id: `${arg.source}-${c.id}`} as const)),
                        {type: PROCUREMENT_ORDER_REQUIREMENTS_TYPE, id: LIST},
                    ]
                    : [{type: PROCUREMENT_ORDER_REQUIREMENTS_TYPE, id: LIST}],
        }),
        getFreightOptions: build.mutation<IFreightOptionResponse, {order: IOrder, source: string}>({
            query: (arg) => ({
                url: `procurement/freightOptions?source=${encodeURIComponent(arg.source)}`,
                method: 'POST',
                body: arg.order
            }),
        }),
        createCsv: build.mutation<IUploadFileResponse, {request: IProcurementCsvGenerationRequest}>({
            query: (arg) => ({
                url: 'files/csv/procurementItems',
                method: 'POST',
                body: arg.request
            }),

        }),
        //endregion

        //region EXTENSIONS
        getUnmatchedExtensionProducts: build.query<ICollectionResponse<IUnmatchedExtensionProduct>, { extensionId: string, page?: number }>({
            query: (arg) => `extensions/installed/${arg.extensionId}/unmatched?${arg.page ? 'page=' + arg.page : ''}`,
            providesTags: () => [{id: LIST, type: EXTENSION_UNMATCHED_PRODUCT_TYPE}]
        }),
        postConfirmExtensionProductMatch: build.mutation<IActionResponse, { extensionId: string, matchId: string, catalog: string, product: string}>({
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}/unmatched/${arg.matchId}/confirm/${arg.catalog}/${arg.product}`,
                method: 'POST'
            }),
            invalidatesTags: () => [{type: EXTENSION_UNMATCHED_PRODUCT_TYPE, id: LIST}]
        }),
        postCreateExtensionProductFromUnmatched: build.mutation<IActionResponse, { extensionId: string, matchId: string, catalog: string }>({
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}/unmatched/${arg.matchId}/create/${arg.catalog}`,
                method: 'POST'
            }),
            invalidatesTags: () => [{type: EXTENSION_UNMATCHED_PRODUCT_TYPE, id: LIST}]
        }),
        getExtensionLibrary: build.query<ICollectionResponse<IExtensionFile>, { extensionId: string, enabledOnly: boolean }>({
            query: (arg) => `extensions/installed/${arg.extensionId}/library?enabledOnly=${arg.enabledOnly}`,
            providesTags: (results, _, arg) => results
                ? [{type: EXTENSION_LIBRARY_TYPE, id: `${arg.extensionId}-${arg.enabledOnly}`}]
                : []
        }),
        postExtensionLibraryFile: build.mutation<IActionResponse, { extensionId: string, file: ArrayBuffer, files: any[] }>({
            invalidatesTags: (_1, _2, arg) =>
                [{type: EXTENSION_LIBRARY_TYPE, id: `${arg.extensionId}-true`}, { type: EXTENSION_LIBRARY_TYPE, id: `${arg.extensionId}-false`}],
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}/library`,
                method: 'POST',
                body: {
                    file: ArrayBufferToBase64(arg.file),
                    fileName: arg.files[0].name,
                }
            })
        }),
        postEnableExtensionLibraryFile: build.mutation<IActionResponse, { extensionId: string, fileId: string }>({
            invalidatesTags: (_response, _error, arg) =>
                [{type: EXTENSION_LIBRARY_TYPE, id: `${arg.extensionId}-true`}, { type: EXTENSION_LIBRARY_TYPE, id: `${arg.extensionId}-false`}],
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}/library/${arg.fileId}/enable`,
                method: 'POST',
            })
        }),
        postDisableExtensionLibraryFile: build.mutation<IActionResponse, { extensionId: string, fileId: string }>({
            invalidatesTags: (_response, _error, arg) =>
                [{type: EXTENSION_LIBRARY_TYPE, id: `${arg.extensionId}-true`}, { type: EXTENSION_LIBRARY_TYPE, id: `${arg.extensionId}-false`}],
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}/library/${arg.fileId}/disable`,
                method: 'POST',
            })
        }),

        getInstalledExtensions: build.query<IInstalledExtension[], void>({
            query: () => 'extensions/installed',
            transformResponse: (results: IInstalledExtension[]) => results,
            providesTags: (results) => results
                ? [
                    ...results.map(e => ({type: INSTALLED_EXTENSION_TYPE, id: e.id} as const)),
                    {type: INSTALLED_EXTENSION_TYPE, id: LIST}
                ]
                : [{type: INSTALLED_EXTENSION_TYPE, id: LIST}],
        }),
        getInstalledExtension: build.query<IInstalledExtension, string>({
            query: (arg) => `extensions/installed/${arg}`,
            transformResponse: (results: IInstalledExtension) => results,
        }),
        disableInstalledExtension: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `extensions/installed/${arg}/disable`,
                method: 'POST',
            }),
            async onQueryStarted(arg, queryApi) {
                queryApi.dispatch(
                    api.util.updateQueryData('getInstalledExtensions', undefined, (draft) => {
                        const index = draft.findIndex((a) => a.id == arg)
                        draft[index].enabled = false;
                    })
                )
            }
        }),
        enableInstalledExtension: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `extensions/installed/${arg}/enable`,
                method: 'POST',
            }),
            async onQueryStarted(arg, queryApi) {
                queryApi.dispatch(
                    api.util.updateQueryData('getInstalledExtensions', undefined, (draft) => {
                        const index = draft.findIndex((a) => a.id == arg)
                        draft[index].enabled = true;
                    })
                )
            }
        }),
        deleteInstalledExtension: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `extensions/installed/${arg}`,
                method: 'DELETE',
            }),
            async onQueryStarted(arg, queryApi) {
                queryApi.dispatch(
                    api.util.updateQueryData('getInstalledExtensions', undefined, (draft) => {
                        const index = draft.findIndex((a) => a.id == arg)
                        draft.splice(index, 1);
                    })
                )
            }
        }),
        installExtension: build.mutation<IActionResponse, {extensionId: string, request: IExtensionInstallRequest}>({
            query: (arg) => ({
                url: `extensions/available/${arg.extensionId}/install`,
                method: 'POST',
                body: arg.request
            }),
            async onQueryStarted(arg, queryApi) {
                const id = await queryApi.queryFulfilled.then((a) => a.data.id)
                let extensions: IExtension[]  = [];
                queryApi.dispatch(
                    api.util.updateQueryData('getAvailableExtensions', undefined, (draft) => {
                        extensions = cloneDeep(draft);
                    })
                )
                await queryApi.queryFulfilled.then( () => {
                    const sourceExtensions = extensions.filter((a) => a.id == arg.extensionId);
                    if (sourceExtensions != undefined && sourceExtensions.length > 0) {
                        const sourceExtension = sourceExtensions[0];
     
                        queryApi.dispatch(
                            api.util.updateQueryData('getInstalledExtensions', undefined, (draft) => {
                                const extensions = cloneDeep(draft);
                                for (const object of sourceExtension.additionalData) {
                                    object.defaultValue = arg.request.additionalData[object.field]
                                }
                                //const additionalData = sourceExtension.additionalData.map((a) => a.defaultValue = arg.request.additionalData[a.field])
                                const newExtension: IInstalledExtension = {
                                    id: id,
                                    name: sourceExtension.name,
                                    enabled: true,
                                    extensionType: sourceExtension.category,
                                    source: sourceExtension.id,
                                    additionalData: sourceExtension.additionalData,
                                    nickname: arg.request.extensionNickname,
                                    vendor: arg.request.vendor ?? ""
                                }
                                extensions.push(newExtension);
                                Object.assign(draft, extensions);
                            })
                        )
                    }
                });
            },
        }),

        putInstalledExtension: build.mutation<IActionResponse, {extensionId: string, request: IExtensionInstallRequest}>({
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}`,
                method: 'PUT',
                body: arg.request
            }),
            async onQueryStarted(arg, queryApi) {
                let extensions: IExtension[]  = [];
                queryApi.dispatch(
                    api.util.updateQueryData('getAvailableExtensions', undefined, (draft) => {
                        extensions = cloneDeep(draft);
                    })
                )
                const sourceExtensions = extensions.filter((a) => a.id == arg.extensionId);
                if (sourceExtensions != undefined && sourceExtensions.length > 0) {
                    const sourceExtension = sourceExtensions[0];
                    for (const object of sourceExtension.additionalData) {
                        object.defaultValue = arg.request.additionalData[object.field]
                    }
                    queryApi.dispatch(
                        api.util.updateQueryData('getInstalledExtensions', undefined, (draft) => {
                            const index = draft.findIndex((a) => a.id == arg.extensionId)
                            const update = draft[index]
                            update.nickname = arg.request.extensionNickname;
                            update.additionalData = sourceExtension.additionalData
                        })
                    )
                }
            }
            
        }),
        getAvailableExtensions: build.query<IExtension[], void>({
            query: () => 'extensions/available',
            transformResponse: (results: IExtension[]) => results,
            providesTags: (results) => results
                ? [
                    ...results.map(e => ({type: EXTENSION_TYPE, id: e.id} as const)),
                    {type: EXTENSION_TYPE, id: LIST}
                ]
                : [{type: EXTENSION_TYPE, id: LIST}],
        }),
        clearExtensions: build.mutation<null, void>({
            queryFn: () => ({data: null}),
            invalidatesTags: [
                EXTENSION_TYPE,
                {type: INSTALLED_EXTENSION_TYPE, id: LIST},
            ]
        }),
        getAvailableExtension: build.query<IExtension, string>({
            query: (arg) => `extensions/available/${arg}`,
            providesTags: (results) => [{type: EXTENSION_TYPE, id: results?.id }],
        }),
        getExtensionFolio: build.query<IInstalledExtensionFolio, string>({
            query: (arg) => `extensions/folios/${arg}`,
            providesTags: (_res, _err, arg) => ([
                {type: EXTENSION_FOLIO_TYPE, id: `${arg}`},
            ])
        }),
        getStorefrontSubExtensionReferences: build.query<IExtensionFolioSubextensionReference[], void>({
            query: () => `extensions/folios/storefront`
        }),
        getSubExtensionFolioSubExtensionReferences: build.query<ICollectionResponse<IExtensionFolioSubextensionReference>, ExtensionType>({
            query: (arg) => `extensions/folios/subextensions/${arg}`
        }),
        putExtensionFolio: build.mutation<IActionResponse, {extensionId: string, request: IInstalledExtensionFolio}>({
            query: (arg) => ({
                url: `extensions/folios/${arg.extensionId}`,
                method: 'PUT',
                body: arg.request
            }),
            async onQueryStarted(arg, queryApi) {
                queryApi.dispatch(
                    api.util.updateQueryData('getExtensionFolio', arg.extensionId, (draft) => {
                        Object.assign(draft, arg.request)
                    })
                );
            }
        }),
        validateExtensionFolio: build.mutation<IActionResponse, {id: string, request?: IFolioValidationRequest}>({
            query: (arg) => ({
                url: `extensions/folios/${arg.id}/validate`,
                method: 'POST',
                body: arg.request,
            })
        }),
        enableFolioSubExtension: build.mutation<IActionResponse, {id: string, subExtension: string}>({
            query: (arg) => ({
                url: `extensions/folios/${arg.id}/${arg.subExtension}/enable`,
                method: 'POST'
            }),
            async onQueryStarted(arg, queryApi) {
                queryApi.dispatch(
                    api.util.updateQueryData('getExtensionFolio', arg.id, (draft) => {
                        if(!draft?.enabledSubExtensions?.includes(arg.subExtension)){
                            draft?.enabledSubExtensions?.push(arg.subExtension)
                        }
                    })
                );
            }
        }),
        simpleValidateExtensionFolio: build.mutation<IActionResponse, {id: string}>({
            query: (arg) => ({
                url: `extensions/folios/${arg.id}/simpleValidate`,
                method: 'POST'
            })
        }),
        getInstalledExtensionLogs: build.query<ICollectionResponse<IExtensionLog>, string>({
            query: (arg) => `extensions/installed/${arg}/logs`,
            providesTags: (_results, _error, arg) => [{type: INSTALLED_EXTENSION_LOGS_TYPE, id: arg}]
        }),
        getExportExtensionOptions: build.query<IExtensionOptionsResponse, { extensionId: string, field: string, search?: string, additionalData?: { [key: string]: string } }>({
            query: (arg) => ({
                url: `extensions/export/${arg.extensionId}/${arg.field}/options`,
                method: 'POST',
                body: {
                    additionalData: arg.additionalData,
                    search: arg.search
                },
            })
        }),
        getExportExtensionOptionFriendlyName: build.query<string, { extensionId: string, field: string, search: string }>({
            query: (arg) => ({
                url: `extensions/export/${arg.extensionId}/${arg.field}/options/friendlyName`,
                method: 'POST',
                body: {
                    search: arg.search
                },
            })
        }),
        getExtensionInstallOptions: build.query<IExtensionOptionsResponse, { extensionId: string, field: string}>({
            query: (arg) => ({
                url: `extensions/available/${arg.extensionId}/${arg.field}/installOptions`,
                method: 'GET'
            })
        }),
        getExtensionPotentialMatches: build.query<IExtensionPotentialMatchResponse, { extensionId: string, products: IProductReference[] }>({
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}/possibleMatches/products`,
                method: 'POST',
                body: {
                    products: arg.products,
                }
            }),
            providesTags: [EXTENSION_POSSIBLE_MATCH]
        }),
        confirmExtensionPotentialMatch: build.mutation<IActionResponse, { extensionId: string, catalog: string, productId: string, externalId: string}>({
            query: (arg) => ({
                url: `extensions/installed/${arg.extensionId}/possibleMatches/products/${arg.catalog}/${arg.productId}/confirm/${arg.externalId}`,
                method: 'POST',
            }),
        }),
        getImportSample: build.query<IImportExtensionMappingSampleResponse, { extensionId: string, request: IImportExtensionMappingSampleRequest }>({
            query: (arg) => ({
                url: `extensions/available/${arg.extensionId}/getSample`,
                method: 'POST',
                body: arg.request
            }),
        }),
        getDirectImportPreview: build.query<IDirectImportExtensionPreviewResponse, { extensionId: string, fieldName :string, request: IDirectImportExtensionPreviewRequest }>({
            query: (arg) => ({
                url: `extensions/directImport/${arg.extensionId}/itemPreview/${arg.fieldName}`,
                method: 'POST',
                body: arg.request
            })
        }),
        
        runExportExtension: build.mutation<IExtensionResponse, { extensionId: string, products?: IProductReference[], workspace?: string, additionalFields?: { [key: string]: string }}>({
            query: (arg) => ({
                url: (`extensions/export/${arg.extensionId}/run`),
                method: 'POST',
                body: {
                    products: arg.products,
                    workspace: arg.workspace,
                    additionalFields: arg.additionalFields
                }
            }),
            invalidatesTags: [
                {type: EXTENSION_LINK_TYPE, id: LIST},
                {type: EXTENSION_POSSIBLE_MATCH}
            ]
        }),
        runImportExtension: build.mutation<IActionResponse, { extensionId: string }>({
            query: (arg) => ({
                url: (`extensions/import/${arg.extensionId}/run`),
                method: 'POST'
            }),
        }),
        runDirectImportExtension: build.mutation<IDirectImportExtensionResponse, { extensionId: string, request: IDirectImportExtensionRequest}>({
            query: (arg) => ({
                url: (`extensions/directImport/${arg.extensionId}/run`),
                body: arg.request,
                method: 'POST'
            }),
            async onQueryStarted(arg, queryApi) {
                const result = await queryApi.queryFulfilled;
                queryApi.dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', arg.request.workspaceId, (draft) => {
                        if (result?.data?.items) {
                            draft.push(...result.data.items)
                        }
                    })
                );
            }
        }),
        runDocumentExtension: build.mutation<IExtensionResponse, { extensionId: string, workspaceId: string, templateId?: string }>({
            query: (arg) =>{ 
                const url = arg.templateId ? `extensions/document/${arg.extensionId}/workspace/${arg.workspaceId}?templateId=${arg.templateId}` :
                    `extensions/document/${arg.extensionId}/workspace/${arg.workspaceId}`
                return ({
                url: url,
                method: 'POST'
            })},
            async onQueryStarted(arg, queryApi) {
                const result = await queryApi.queryFulfilled;
                queryApi.dispatch(
                    api.util.updateQueryData('getWorkspace', arg.workspaceId, draft => {
                        if (result.data.redirectUrl) {
                            draft.documentIsCurrent = true
                            draft.documentUrl = result.data.redirectUrl
                            draft.mostRecentDocumentExtension = arg.extensionId
                        }
                    })
                );
            }
        }),
        runRealtimeExtension: build.mutation<IRealTimeExtensionResponse, { extension: IInstalledExtension, products?: IProductReference[], workspace?: string }>({
            query: (arg) => ({
                url: (`extensions/realtime/${arg.extension.id}/run`),
                method: 'POST',
                body: {
                    products: arg.products,
                    workspace: arg.workspace,
                }
            }),
            async onQueryStarted(arg, queryApi) {
                try {
                    const result = await queryApi.queryFulfilled;
                    const state = await queryApi.getState() as RootState;
                    const criteria = state.search.criteria;
                    const catalog = state.search.selectedCatalog;
                    if (result.data.items == null || result.data.items.length === 0) {
                        return;
                    }
                    if (arg.products) {
                        queryApi.dispatch(api.util.updateQueryData('getSearchResults', {
                            catalog: catalog?.name,
                            criteria
                        }, (draft) => {
                            const products = cloneDeep(draft.products);
                            for (const product of products) {
                                const pRef = arg.products?.find(p => p.id === product.id);
                                if (pRef == null) continue;
                                updateProductFromRealtime(product, result.data, arg.extension.vendor, pRef);
                            }
                            Object.assign(draft, {...draft, products});
                        }));
                    }
                    if (arg.workspace) {
                        queryApi.dispatch(api.util.updateQueryData('getWorkspaceProducts', arg.workspace, (draft) => {
                            const products = cloneDeep(draft);
                            for (const product of products) {
                                if (product.normalized != null) {
                                    const pRef = {id: product.normalized.id, catalog: product.normalized.catalog, quantity: product.details.quantity};
                                    updateProductFromRealtime(product.normalized, result.data, arg.extension.vendor, pRef);
                                }
                                if (product.product != null) {
                                    const pRef = {id: product.product.id, catalog: product.product.catalog, quantity: product.details.quantity};
                                    updateProductFromRealtime(product.product, result.data, arg.extension.vendor, pRef);
                                }
                                updateWorkspaceItemFromRealtime(product, result.data, arg.extension.vendor);
                            }
                            Object.assign(draft, products);
                        }));
                    }
                } catch {
                    console.error(`Error getting realtime for ${arg.extension.id}`)
                }
            }
        }),
        validateExtensionSettings: build.mutation<IActionResponse, {id: string, request: IExtensionInstallRequest}>({
            query: (arg) => ({
                url: `extensions/available/${arg.id}/validate`,
                method: 'POST',
                body: arg.request,
            }),
        }),
        validateInstalledExtension: build.mutation<IActionResponse, {id: string, request: IExtensionInstallRequest}>({
            query: (arg) => ({
                url: `extensions/installed/${arg.id}/validate`,
                method: 'POST',
                body: arg.request,
            })
        }),
        simpleValidateInstalledExtension: build.mutation<IActionResponse, {id: string}>({
            query: (arg) => ({
                url: `extensions/installed/${arg.id}/validate`,
            })
        }),
        //endregion

        //region PRODUCTS
        getMetadata: build.query<IMetadata, string | undefined>({
            query: (arg) => arg ? `search/metadata?catalog=${arg}` : 'search/metadata',
        }),
        getFavorites: build.query<IFavoriteLookup, void>({
            query: () => 'search/favorites?',
            providesTags: ['Favorites'],
            transformResponse: (response: ISearchQueryResponse) => {
                const favorites: IFavoriteLookup = {};
                for (const product of response.products) {
                    if (product.favorite.company || product.favorite.user) {
                        favorites[product.id] = {company: product.favorite.company, user: product.favorite.user}
                    } else {
                        favorites[product.id] = {company: false, user: true}
                    }
                }
                return favorites;
            }
        }),
        getFavoritesProducts: build.query<ISearchQueryResponse, void>({
            query: () => 'search/favorites?',
            providesTags: ['Favorites'],
        }),
        setUserFavorite: build.mutation<IActionResponse, { catalog: string, id: string }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/favorites/user`,
                method: 'PUT'
            }),
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                const patchResult = dispatch(
                    api.util.updateQueryData('getFavorites', undefined, draft => {
                        draft[arg.id] = {
                            company: draft[arg.id]?.company ?? false,
                            user: true,
                        }
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        removeUserFavorite: build.mutation<IActionResponse, { catalog: string, id: string }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/favorites/user`,
                method: 'DELETE'
            }),
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                const patchResult = dispatch(
                    api.util.updateQueryData('getFavorites', undefined, draft => {
                        draft[arg.id] = {
                            company: draft[arg.id]?.company ?? false,
                            user: false,
                        }
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        }),
        setCompanyFavorite: build.mutation<IActionResponse, { catalog: string, id: string }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/favorites/organization`,
                method: 'PUT'
            }),
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                const patchResult = dispatch(
                    api.util.updateQueryData('getFavorites', undefined, draft => {
                        draft[arg.id] = {
                            company: true,
                            user: draft[arg.id]?.user ?? false,
                        }
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        removeCompanyFavorite: build.mutation<IActionResponse, { catalog: string, id: string }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/favorites/organization`,
                method: 'DELETE'
            }),
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                const patchResult = dispatch(
                    api.util.updateQueryData('getFavorites', undefined, draft => {
                        draft[arg.id] = {
                            company: false,
                            user: draft[arg.id]?.user ?? false,
                        }
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        }),
        getProductTags: build.query<IProductTagsResponse, {searchTerm: string}>({
            query: (arg) => ({
                url: `search/tags/${arg.searchTerm}`,
                method: 'GET',
            })
        }),
        getSearchResults: build.query<ISearchQueryResponse, { catalog?: string, criteria: ISearchRequest }>({
            query: (arg) => {
                if (criteriaIsBlank(arg.criteria))
                    return {
                        url: 'search/favorites?'
                    }
                let url = 'search?';
                if (arg.catalog) {
                    url += `&catalog=${arg.catalog}`
                }
                return {
                    url,
                    method: 'POST',
                    body: arg.criteria
                }
            },
            providesTags: [SEARCH_RESULTS_TYPE],
            async onQueryStarted(_arg, {dispatch, queryFulfilled}) {
                queryFulfilled.then((a) => {
                    dispatch(updateSearchId(a.data.searchLogId))
                })
            }
        }),
        getSavedSearches: build.query<ISavedSearch[], {searchTerm?: string}>({
            query: (arg) => ({
                url: arg.searchTerm ? `search/savedSearch?nameFilter=${arg.searchTerm}` : 'search/savedSearch',
                method: 'GET',
            }),
            transformResponse: (result: ICollectionResponse<ISavedSearch>) => result.items
        }),
        getSavedSearchOnStorefrontCount: build.query<ICountResponse, {storefrontId: string}>({
            query: (arg) => ({
                url: `search/savedSearch/storefrontSavedSearchCount/${arg.storefrontId}`,
                method: 'GET',
            })
        }),
        getSavedSearch: build.query<ISavedSearch, {id: string}>({
            query: (arg) => ({
                url: `search/savedSearch/${arg.id}`,
                method: 'GET',
            }),
        }),
        getTopSavedSearches: build.query<ISavedSearch[], void>({
            query: (arg) => ({
                url: 'search/savedSearch/top',
                method: 'GET',
            }),
            transformResponse: (result: ICollectionResponse<ISavedSearch>) => result.items

        }),
        saveSearch: build.mutation<IActionResponse, {savedSearch: ISavedSearch}>({
            query: (arg) => ({
                url: `search/savedSearch/save`,
                method: 'PUT',
                body: arg.savedSearch
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const result = await queryFulfilled;
                const id = result.data.id;
                
                const patchResult = dispatch(
                    api.util.updateQueryData('getSavedSearches', {searchTerm: ''}, (draft) => {
                        const updatedSaved = cloneDeep(draft)
                        if(arg.savedSearch.id) {
                            const existingIndex = draft.findIndex(s => s.id === arg.savedSearch.id);
 
                            if (existingIndex > -1) {
                                updatedSaved[existingIndex] = arg.savedSearch
                            }
                        }
                        else{
                            const updatedNew = cloneDeep(arg.savedSearch)
                            updatedNew.id = id
                            updatedSaved.push(updatedNew)
                        }
                        Object.assign(draft, updatedSaved);
                    })
                );

                const individualPatchResult = dispatch(
                    api.util.updateQueryData('getSavedSearch', {id: id}, (draft) => {
                        const updatedSaved = cloneDeep(arg.savedSearch)
                        updatedSaved.id = id
                        Object.assign(draft, updatedSaved);
                    })
                );

            }
        }),
        deleteSavedSearch: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `search/savedSearch/${arg}`,
                method: 'DELETE',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const savedSearchesPatch = dispatch(
                    api.util.updateQueryData('getSavedSearches', {searchTerm: ''}, (draft) => {
                        const updatedSaved = cloneDeep(draft)
                        if(arg) {
                            const existingIndex = draft.findIndex(s => s.id === arg);

                            if (existingIndex > -1) {
                                updatedSaved.splice(existingIndex, 1)
                            }
                        }
                        Object.assign(draft, updatedSaved);
                    })
                )
                const topSavedSearchesPatch = dispatch(
                    api.util.updateQueryData('getTopSavedSearches', undefined, (draft) => {
                        const updatedSaved = cloneDeep(draft)
                        if(arg) {
                            const existingIndex = draft.findIndex(s => s.id === arg);

                            if (existingIndex > -1) {
                                updatedSaved.splice(existingIndex, 1)
                            }
                        }
                        Object.assign(draft, updatedSaved);
                    })
                )
                const getSavedSearchesPatch = dispatch(
                    api.util.updateQueryData('getSavedSearches', {}, (draft) => {
                        const updatedSaved = cloneDeep(draft)
                        if(arg) {
                            const existingIndex = draft.findIndex(s => s.id === arg);

                            if (existingIndex > -1) {
                                updatedSaved.splice(existingIndex, 1)
                            }
                        }
                        Object.assign(draft, updatedSaved);
                    })
                )

                try {
                    await queryFulfilled
                } catch {
                    savedSearchesPatch.undo();
                    topSavedSearchesPatch.undo();
                    getSavedSearchesPatch.undo();
                }
            }
        }),
        clearSearch: build.mutation<null, void>({
            queryFn: () => ({data: null}),
            invalidatesTags: [
                SEARCH_RESULTS_TYPE,
            ]
        }),
        postSearchTableRow: build.query<ICollectionResponse<IProduct>, { catalog?: string, values: string[], manufacturerPartNumber?: string, productName?: string }>({
            query: (arg) => ({
                url: `search/tablerow`,
                method: 'POST',
                body: arg
            })
        }),
        updateProductVisit: build.mutation<boolean, {catalog: string, id: string}>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/logvisit`,
                method: 'POST'
            })
        }),
        getItemExtensionFields: build.query<ISavedExtensionField[], { catalog: string, id: string, extension: string }>({
            query: (arg) => `catalog/${arg.catalog}/products/${arg.id}/extensionFields/${arg.extension}`,
            transformResponse: (response: IGetExtensionFieldsResponse) => response.fields,
            providesTags: (results, _base, arg) => results
                ? [
                    ...results.map(r => ({
                        type: ITEM_EXTENSION_FIELD_TYPE,
                        id: `${arg.catalog}-${arg.id}-${arg.extension}-${r.key}`
                    } as const)),
                    {type: ITEM_EXTENSION_FIELD_TYPE, id: LIST}
                ]
                : [{type: ITEM_EXTENSION_FIELD_TYPE, id: LIST}]
        }),
        putItemExtensionField: build.mutation<IActionResponse, { catalog: string, id: string, extension: string, field: string, value: string }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/extensionFields/${arg.extension}/${arg.field}`,
                method: 'PUT',
                body: {
                    value: arg.value
                }
            }),
            invalidatesTags: (_result, _base, arg) => [
                {type: ITEM_EXTENSION_FIELD_TYPE, id: `${arg.catalog}-${arg.id}-${arg.extension}-${arg.field}`},
            ],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getItemExtensionFields', {
                        catalog: arg.catalog,
                        id: arg.id,
                        extension: arg.extension
                    }, (draft) => {
                        const newFields = cloneDeep(draft);
                        newFields[newFields.findIndex(f => f.key === arg.field)] = {
                            extensionId: arg.extension,
                            key: arg.field,
                            value: arg.value
                        };
                        Object.assign(draft, newFields);
                    })
                );
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo();
                }
            }
        }),
        getItemDetails: build.query<INormalizedProduct, IProductReference>({
            query: (arg) => `catalog/${arg.catalog}/products/${arg.id}`,
            transformResponse: (result: IProduct) => NormalizeResult(result),
            providesTags: (_result, _error, arg) => [{type: ITEM_DETAILS_TYPE, id: `${arg.catalog}-${arg.id}`}]
        }),
        getMultipleItemDetails: build.query<INormalizedProduct[], { catalog: string, ids: string[] }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/retrieveProducts`,
                method: 'POST',
                body: {
                    ids: arg.ids,
                }
            }),
            transformResponse: (result: IProduct[]) => result.map(p => NormalizeResult(p)),
            providesTags: (result) => result
                ? [
                    ...result.map(p => ({type: ITEM_DETAILS_TYPE, id: `${p.catalog}-${p.id}`} as const)),
                    {type: ITEM_DETAILS_TYPE, id: LIST}
                ]
                : [{type: ITEM_DETAILS_TYPE, id: LIST}]
        }),
        postProduct: build.mutation<IActionResponse, {product: INewProduct, catalog: string }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products`,
                method: 'POST',
                body: arg.product
            })
        }),
        putProduct: build.mutation<IActionResponse, INormalizedProduct>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}`,
                method: 'PUT',
                body: arg
            }),
            async onQueryStarted(arg, queryApi) {
                const state = await queryApi.getState() as RootState;
                const workspace = state.settings?.selectedWorkspaceId ?? ""
                
                const criteria = state.search.criteria;
                const catalog = state.search.selectedCatalog;
                
                //Fix search results with modified product
                const searchResultsUpdate = queryApi.dispatch(api.util.updateQueryData('getSearchResults', {
                    catalog: catalog?.name,
                    criteria
                }, (draft) => {
                    const products = cloneDeep(draft.products);
                    const item = products.findIndex(a => a.id == arg.id)
                    products[item] = arg;
                    Object.assign(draft, {...draft, products});
                }));
                
                let products: IWorkspaceItem[] = [];
                
                //Fix workspace with modified product
                const workspaceUpdate = queryApi.dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', workspace, (draft) => {
                        products = cloneDeep(draft);
                        const i = products.findIndex(p => p.productId === arg.id);
                        if (i === -1) return;
                        const prod = products[i]
                        prod.product = arg;
                        Object.assign(draft, products)
                    })
                );

                let favorites: ISearchQueryResponse;

                //Fix favorites with modified product
                const favoritesUpdate = queryApi.dispatch(
                    api.util.updateQueryData('getFavoritesProducts', undefined, (draft) => {
                        favorites = cloneDeep(draft);
                        const i = favorites.products.findIndex(p => p.id === arg.id);
                        if (i === -1) return;
                        favorites.products[i] = arg
                        Object.assign(draft, favorites)
                    })
                );

                try {
                    await queryApi.queryFulfilled
                } catch (error) {
                    console.log(error);
                    workspaceUpdate.undo()
                    searchResultsUpdate.undo()
                    favoritesUpdate.undo()
                }
            },
        }),
        putTags: build.mutation<IActionResponse, {tags: string[], productId: string, catalog: string}>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.productId}/tags`,
                method: 'PUT',
                body: arg.tags
            }),
            async onQueryStarted(arg, queryApi) {
                const state = await queryApi.getState() as RootState;
                const workspace = state.settings?.selectedWorkspaceId ?? ""

                const criteria = state.search.criteria;
                const catalog = state.search.selectedCatalog;

                //Fix search results with modified product
                const searchResultsUpdate = queryApi.dispatch(api.util.updateQueryData('getSearchResults', {
                    catalog: catalog?.name,
                    criteria
                }, (draft) => {
                    const products = cloneDeep(draft.products);
                    const item = products.findIndex(a => a.id == arg.productId)
                    products[item].tags = arg.tags;
                    Object.assign(draft, {...draft, products});
                }));

                let products: IWorkspaceItem[] = [];

                //Fix workspace with modified product
                const workspaceUpdate = queryApi.dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', workspace, (draft) => {
                        products = cloneDeep(draft);
                        const i = products.findIndex(p => p.productId === arg.productId);
                        if (i === -1) return;
                        const prod = products[i];
                        if(prod.product) {
                            prod.product.tags = arg.tags
                            Object.assign(draft, products)
                        }
                    })
                );

                let favorites: ISearchQueryResponse;

                //Fix favorites with modified product
                const favoritesUpdate = queryApi.dispatch(
                    api.util.updateQueryData('getFavoritesProducts', undefined, (draft) => {
                        favorites = cloneDeep(draft);
                        const i = favorites.products.findIndex(p => p.id === arg.productId);
                        if (i === -1) return;
                        const prod = favorites.products[i]
                        prod.tags = arg.tags
                        Object.assign(draft, favorites)
                    })
                );

                try {
                    await queryApi.queryFulfilled
                } catch (error) {
                    console.log(error);
                    workspaceUpdate.undo()
                    searchResultsUpdate.undo()
                    favoritesUpdate.undo()
                }
            },
        }),

        putUserTags: build.mutation<IActionResponse, {tags: string[], productId: string, catalog: string}>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.productId}/userTags`,
                method: 'PUT',
                body: arg.tags
            }),
            async onQueryStarted(arg, queryApi) {
                const state = queryApi.getState() as RootState;
                const workspace = state.settings?.selectedWorkspaceId ?? ""

                const criteria = state.search.criteria;
                const catalog = state.search.selectedCatalog;

                //Fix search results with modified product
                const searchResultsUpdate = queryApi.dispatch(api.util.updateQueryData('getSearchResults', {
                    catalog: catalog?.name,
                    criteria
                }, (draft) => {
                    const products = cloneDeep(draft.products);
                    const item = products.findIndex(a => a.id == arg.productId)
                    products[item].userTags = arg.tags;
                    Object.assign(draft, {...draft, products});
                }));

                let products: IWorkspaceItem[] = [];

                //Fix workspace with modified product
                const workspaceUpdate = queryApi.dispatch(
                    api.util.updateQueryData('getWorkspaceProducts', workspace, (draft) => {
                        products = cloneDeep(draft);
                        const i = products.findIndex(p => p.productId === arg.productId);
                        if (i === -1) return;
                        const prod = products[i];
                        if(prod.product) {
                            prod.product.userTags = arg.tags
                            Object.assign(draft, products)
                        }
                    })
                );

                let favorites: ISearchQueryResponse;

                //Fix favorites with modified product
                const favoritesUpdate = queryApi.dispatch(
                    api.util.updateQueryData('getFavoritesProducts', undefined, (draft) => {
                        favorites = cloneDeep(draft);
                        const i = favorites.products.findIndex(p => p.id === arg.productId);
                        if (i === -1) return;
                        const prod = favorites.products[i]
                        prod.userTags = arg.tags
                        Object.assign(draft, favorites)
                    })
                );

                try {
                    await queryApi.queryFulfilled
                } catch (error) {
                    console.log(error);
                    workspaceUpdate.undo()
                    searchResultsUpdate.undo()
                    favoritesUpdate.undo()
                }
            },
        }),
        setImage: build.mutation<IUploadFileResponse, { imageId: string, file: ArrayBuffer, imageType: string|null}>({
            query: (arg) => ({
                url: `files/product/images`,
                method: 'POST',
                body: {
                    file: ArrayBufferToBase64(arg.file),
                    fileName: `${arg.imageId}.png`,
                    imageType: arg.imageType ?? "Default"
                }
            })
        }),
        deleteProductImages: build.mutation<IActionResponse, {productId: string, imageIds: string[]}>({
            query: (arg) => ({
                url: `files/product/${arg.productId}/images`,
                method: 'DELETE',
                body:{
                    customImageIds: arg.imageIds
                }
            }),
        }),
        uploadImage: build.mutation<IUploadFileResponse, { imageId: string, file: ArrayBuffer, imageType: string|null}>({
            query: (arg) => ({
                url: 'files/images',
                method: 'POST',
                body: {
                    file: ArrayBufferToBase64(arg.file),
                    fileName: `${arg.imageId}.png`,
                    imageType: arg.imageType ?? 'Default',
                }
            })
        }),
        getNotes: build.query<IGetNotesResponse, IProductReference>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/notes`
            }),
            providesTags: (_results, _error, arg) =>
                [{type: PRODUCT_NOTES_TYPE, id: `${arg.catalog}-${arg.id}`}],
        }),
        putNotes: build.mutation<IActionResponse, { catalog: string, id: string, userNote: string | undefined, companyNote: string | undefined }>({
            query: (arg) => ({
                url: `catalog/${arg.catalog}/products/${arg.id}/notes`,
                method: 'PUT',
                body: {
                    userNote: arg.userNote,
                    companyNote: arg.companyNote
                }
            }),async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getNotes', {
                        catalog: arg.catalog,
                        id: arg.id
                    }, (draft) => {
                        const newNotes = cloneDeep(draft);
                        newNotes.userNote.text = arg.userNote ?? '';
                        newNotes.companyNote.text = arg.companyNote ?? '';
                        Object.assign(draft, newNotes);
                    })
                );
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo();
                }
            }
        }),
        getInsights: build.query<IProductInsights, IProductReference>({
            query: (arg) => `catalog/${arg.catalog}/products/${arg.id}`,
        }),

        getCount: build.query<number, { catalog?: string, criteria: ISearchRequest }>({
            query: (arg) => ({
                url: `search/count`,
                method: 'POST',
                body: arg.criteria
            }),
            transformResponse: (result: ICountResponse) => result.count,
        }),
        getQuicksearch: build.query<IQuickSearchResponse, { catalog?: string, value: string | undefined }>({
            query: (arg) => `search/quicksearch?search=${arg.value}`,
        }),

        //endregion

        //region notifications
        getTasks: build.query<IAdaptiveCatalogTask[], {includeDismissed: boolean}>({
            query: (arg) => `tasks?includeDismissed=${arg.includeDismissed}`,
            transformResponse: (result: ICollectionResponse<IAdaptiveCatalogTask>) => result.items,
            providesTags: (result) => result
                ? [
                    ...result.map(t => ({type: TASKS_TYPE, id: t.id} as const)),
                    {type: TASKS_TYPE, id: LIST}
                ]
                : [{type: TASKS_TYPE, id: LIST}]
        }),
        dismissTask: build.mutation<void, IAdaptiveCatalogTask>({
            query: (arg) => ({
                url: `tasks/${arg.id}/dismiss`,
                method: 'POST',
            }),
            invalidatesTags: () => [{type: TASKS_TYPE, id: LIST}],
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                const patchResult = dispatch(
                    api.util.updateQueryData('getTasks', {includeDismissed: false}, (draft) => {
                        const newTasks = cloneDeep(draft);
                        const i = newTasks.findIndex(i => i.id === arg.id);
                        if (i !== -1) newTasks[i].dismissed = true;
                        Object.assign(draft, newTasks);
                    })
                );
                const dismissedPatchResult = dispatch(
                    api.util.updateQueryData('getTasks', {includeDismissed: true}, (draft) => {
                        const newTasks = cloneDeep(draft);
                        const i = newTasks.findIndex(i => i.id === arg.id);
                        if (i !== -1) newTasks[i].dismissed = true;
                        Object.assign(draft, newTasks);
                    })
                );
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo();
                    dismissedPatchResult.undo();
                }
            }
        }),
        //endregion
        
        createSearchSession: build.mutation({
            query: () => ({
                url: `keyAuth/searchSession`,
                method: 'POST'
            }),
            async onQueryStarted(_arg, {dispatch, queryFulfilled}) {
                queryFulfilled.then((a) => dispatch(setSearchSession(a.data)))
            }
        }),
        //region Key Auth
        
        getSessionKey: build.mutation<IActionResponse, IGetSessionKeyRequest>({
            query: (arg) => ({
                url: `keyAuth/sessionkey`,
                method: 'POST',
                body: arg
            }),
        }),

        getApiKeyTypes: build.query<IKeyAuthTypesResponse, void>({
            query: () => 'keyauth/apikeytypes'
        }),
        getApiKeys: build.query<IApiKey[], void>({
            query: () => 'keyauth/apikey',
            transformResponse: (result: IGetApiKeysResponse) => result.keys,
            providesTags:[API_KEYS]
        }),

        addApiKey: build.mutation<IGenerateApiKeyResponse, {request: IGenerateApiKeyRequest, username: string }>({
            query: (arg) => ({
                url: `keyauth/apikey`,
                method: 'POST',
                body: arg.request
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                await queryFulfilled.then(a => {
                    dispatch(
                        api.util.updateQueryData('getApiKeys', undefined, (draft) => {
                            const newKey = { 
                                id: a.data.id ?? "",
                                name: arg.request.name,
                                enabled: true,
                                keyType: arg.request.keyType,
                                username: arg.username ?? "",
                                createDate: new Date()
                            }
                            draft.push(newKey)
                        })
                    );
                    }
                )
            }
        }),

        disableApiKey: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `keyauth/apikey/${arg}/disable?`,
                method: 'POST',
            }),

            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                await queryFulfilled.then(() => {
                    dispatch(
                            api.util.updateQueryData('getApiKeys', undefined, (draft) => {
                                const updatedKeys = cloneDeep(draft);
                                const i = updatedKeys.findIndex(i => i.id === arg);
                                updatedKeys[i].enabled = false;
                                Object.assign(draft, updatedKeys);
                            })
                        );
                    }
                )
            }
        }),
        enableApiKey: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `keyauth/apikey/${arg}/enable?`,
                method: 'POST',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                await queryFulfilled.then(() => {
                    dispatch(
                            api.util.updateQueryData('getApiKeys', undefined, (draft) => {
                                const updatedKeys = cloneDeep(draft);
                                const i = updatedKeys.findIndex(i => i.id === arg);
                                updatedKeys[i].enabled = true;
                                Object.assign(draft, updatedKeys);
                            })
                        );
                    }
                )
            }

        }),
        deleteApiKey: build.mutation<IActionResponse, string>({
            query: (arg) => ({
                url: `keyauth/apikey/${arg}`,
                method: 'DELETE',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                await queryFulfilled.then(() => {
                        dispatch(
                            api.util.updateQueryData('getApiKeys', undefined, (draft) => {
                                const updatedKeys = cloneDeep(draft);
                                const i = updatedKeys.findIndex(i => i.id === arg);
                                draft.splice(i, 1)
                            })
                        );
                    }
                )
            }
        }),

        //endregion

        //region Reporting
        getSearchReport: build.query<ISearchReport, void>({
            query: () => '/reporting/search/usage'
        }),
        getMonthlyApiUsageReport: build.query<IApiUsageMonthlyReport, {year: number, month: number}>({
            query: (arg) => `/reporting/api/monthly/${arg.year}/${arg.month}`
        }),
        //endregion

        //region Permissions
        getPermissions: build.query<IGetPermissionsResponse, void>({            
            query: () => 'settings/permissions'
        }),

        //region SubOrganization
        getSubOrganizations: build.query<ISubOrganization[], void>({
            query: () => 'settings/subOrganizations',
            providesTags: [SUB_ORGANIZATIONS],
            transformResponse: (result: ICollectionResponse<ISubOrganization>) => result.items,
        }),

        addSubOrganization: build.mutation<ISubOrganization, ICreateSubOrganizationRequest>({
            query: (arg) => ({
                url: `settings/subOrganizations`,
                method: 'POST',
                body: arg
            }),
            invalidatesTags: [SUB_ORGANIZATIONS],
        }),

        updateSubOrganization: build.mutation<ISubOrganization, {body: ICreateSubOrganizationRequest, id: string}>({
            query: (arg) => ({
                url: `settings/subOrganizations/${arg.id}`,
                method: 'PUT',
                body: arg.body
            }),
            invalidatesTags: [SUB_ORGANIZATIONS],
        }),
        //endregion

        //region Workspace Import
        uploadWorkspaceImportFile: build.mutation<IWorkspaceFileImportResponse, {file: IWorkspaceFileImportRequest, workspace: string}>({
            query: (arg) => ({
                url: `workspaces/${arg.workspace}/import/file`,
                method: 'POST',
                body: arg.file,
            })
        }),
        //endregion

        //region ML
        analyzeCustomerRequest: build.mutation<IActionResponse, {req: ICustomerRequestAnalysisRequest, workspace: string}>({
            query: (arg) => ({
                url: `workspaces/${arg.workspace}/ml/customerRequest/analyze`,
                method: 'POST',
                body: arg.req,
            })
        }),
        getCustomerAnalysis: build.query<ICustomerRequestAnalysisResponse, {id: string}>({
            query: (arg) => `ml/customerRequest/${arg.id}`,
        }),
        getCustomerAnalysisForWorkspace: build.query<ICollectionResponse<ICustomerRequestAnalysisOverview>, {workspace: string}>({
            query: (arg) => `workspaces/${arg.workspace}/ml/customerRequest`,
        }),
        deleteCustomerAnalysis: build.mutation<IActionResponse, {id: string}>({
            query: (arg) => ({
                url: `ml/customerRequest/${arg.id}`,
                method: 'DELETE',
            })
        }),
        thumbsUpCustomerAnalysis: build.mutation<IActionResponse, {id: string}>({
            query: (arg) => ({
                url: `ml/customerRequest/${arg.id}/rating/thumbsUp`,
                method: 'PUT',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getCustomerAnalysis', {id: arg.id} , (draft) => {
                        draft.rating = 'ThumbsUp';
                    })
                );
            }
        }),
        thumbsDownCustomerAnalysis: build.mutation<IActionResponse, {id: string}>({
            query: (arg) => ({
                url: `ml/customerRequest/${arg.id}/rating/thumbsDown`,
                method: 'PUT',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getCustomerAnalysis', {id: arg.id} , (draft) => {
                        draft.rating = 'ThumbsDown';
                    })
                );
            }
        }),
        clearCustomerAnalysisRating: build.mutation<IActionResponse, {id: string}>({
            query: (arg) => ({
                url: `ml/customerRequest/${arg.id}/rating`,
                method: 'DELETE',
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
                await queryFulfilled
                dispatch(
                    api.util.updateQueryData('getCustomerAnalysis', {id: arg.id} , (draft) => {
                        draft.rating = 'None';
                    })
                );
            }
        }),
        uploadCustomerRequestDocument: build.mutation<IActionResponse, { file: ArrayBuffer, fileName: string, workspace: string }>({
            query: (arg) => ({
                url: `workspaces/${arg.workspace}/ml/customerRequest/analyze/document`,
                method: 'POST',
                body: {
                    file: ArrayBufferToBase64(arg.file),
                    fileName: arg.fileName,
                }
            })
        }),
        runAnalysisExtension: build.mutation<IActionResponse, { extension: string, externalId: string }>({
            query: (arg) => ({
                url: `ml/customerRequest/import/extensions/${arg.extension}/run/${arg.externalId}`,
                method: 'POST',
            })
        }),
        getUserChats: build.query<ICollectionResponse<IUserChat>, void>({
            query: () => 'ml/chat/sessions',
            providesTags: (result) => result?.items ?
                [
                    ...result.items.map(c => ({type: ML_USER_CHAT_TYPE, id: c.id} as const)),
                    {type: ML_USER_CHAT_TYPE, id: LIST}
                ]
                : [{type: ML_USER_CHAT_TYPE, id: LIST}]
        }),
        getUserChat: build.query<IUserChat, string>({
            query: (arg) => `ml/chat/sessions/${arg}`,
            providesTags: (result) => [{type: ML_USER_CHAT_TYPE, id: result?.id}]
        }),
        createUserChat: build.mutation<IActionResponse, { request: IUserChatMessageRequest }>({
            query: (arg) => ({
                url: `ml/chat/sessions`,
                method: 'POST',
                body: arg.request,
            }),
            invalidatesTags: [{type: ML_USER_CHAT_TYPE, id: LIST}]
        }),
        addUserChatMessage: build.mutation<IActionResponse, { id: string, request: IUserChatMessageRequest }>({
            query: (arg) => ({
                url: `ml/chat/sessions/${arg.id}/messages`,
                method: 'POST',
                body: arg.request,
            }),
            invalidatesTags: (_r, _e, arg) => [{type: ML_USER_CHAT_TYPE, id: LIST}, {type: ML_USER_CHAT_TYPE, id: arg.id}]
        }),
        //endregion
        //REGION Custom Column Set
        putCustomColumnSet: build.mutation<IActionResponse, {columnSet: ICustomColumnSet}>({
            query: (arg) => ({
                url: `settings/customColumnSets`,
                method: 'PUT',
                body: arg.columnSet
            }),
            async onQueryStarted(arg, {dispatch}) {
                dispatch(
                    api.util.updateQueryData('getColumnSet', {columnType: arg.columnSet.columnType } , (draft) => {
                        if(draft != undefined) {
                            Object.assign(draft, arg.columnSet)
                        }
                    })
                );
            }
        }),
        getColumnSet: build.query<ICustomColumnSet, {columnType: string } >({
            query: (arg) => `settings/customColumnSets/${arg.columnType}`
        }),
        //endregion
        //region User Settings
        getUserSettings: build.query<IUserSettings, void>({
            query: () => 'settings/user/settings'
        }),
        patchUserSettings: build.mutation<IActionResponse, IUserSettingsUpdateRequest>({
            query: (arg) => ({
                url: 'settings/user/settings',
                method: 'PATCH',
                body: arg
            }),
            async onQueryStarted(arg, {dispatch}) {
                dispatch(
                    api.util.updateQueryData('getUserSettings', undefined, (draft) => {
                        Object.assign(draft, {...draft, ...arg})
                    })
                );
            }
        }),
        //endregion
        //region Storefront
        getGlobalStorefrontSettings: build.query<IGlobalStorefrontSettings, void>({
            query: () => 'storefront/settings/global'
        }),
        saveGlobalStorefrontSettings: build.mutation<IActionResponse, IGlobalStorefrontSettings>({
            query: (arg) => ({
                url: 'storefront/settings/global',
                method: 'PUT',
                body: arg
            }),
            async onQueryStarted(arg, {dispatch}) {
                dispatch(
                    api.util.updateQueryData('getGlobalStorefrontSettings', undefined, (draft) => {
                        Object.assign(draft, {...arg})
                    })
                );
            }
        }),
        getStorefrontProductCount: build.query<ICountResponse, void>({
            query: () => 'storefront/products/count'
        }),
        getStorefrontLoginLink: build.query<IStorefrontAdminSessionResponse, string>({
            query: (arg) => `keyAuth/storefront/${arg}/adminLogin`
        }),
        getStorefronts: build.query<ICollectionResponse<IStorefrontSettings>, void>({
            query: () => 'storefront/storefronts'
        }),
        getStorefront: build.query<IStorefrontSettings, string>({
            query: (arg) => `storefront/storefronts/${arg}`
        }),
        saveStorefront: build.mutation<IActionResponse, {storefront:IStorefrontSettings, id: string}>({
            query: (arg) => ({
                url: `storefront/storefronts/${arg.id}`,
                method: 'PUT',
                body: arg.storefront
            }),
            async onQueryStarted(arg, {dispatch, queryFulfilled}) {
 
                    const singleUpdate = dispatch(
                        api.util.updateQueryData('getStorefront', arg.id, (draft) => {
                            Object.assign(draft, {...arg})
                        })
                    );
                    const multiUpdate = dispatch(
                        api.util.updateQueryData('getStorefronts', undefined, (draft) => {

                            const existingIndex = draft.items?.findIndex(a => a.id === arg.id)
                            if(existingIndex != -1){
                                draft.items[existingIndex] = arg.storefront
                            }
                        })
                    );
                try {
                    await queryFulfilled;
                }
                catch{
                    singleUpdate.undo();
                    multiUpdate.undo();
                }

            }
        }),
        createStorefront: build.mutation<IActionResponse, IStorefrontSettings>({
            query: (arg) => ({
                url: 'storefront/storefronts',
                method: 'POST',
                body: arg
            })
        }),
        getStorefrontOrders: build.query<ICollectionResponse<IStorefrontOrder>, void>({
            query: () => 'storefront/orders',
            providesTags: (result) => result?.items ?
                [
                    ...result.items.map(c => ({type: STOREFRONT_ORDERS, id: c.id} as const)),
                    {type: STOREFRONT_ORDERS, id: LIST}
                ]
                : [{type: STOREFRONT_ORDERS, id: LIST}]
        }),
        processStorefrontOrder: build.mutation<IActionResponse, {id: string}>({
            query: (arg) => ({
                url: `storefront/orders/${arg.id}/process`,
                method: 'POST'
            }),
            invalidatesTags: () => [{type: STOREFRONT_ORDERS, id: LIST}]
        }),
        getStorefrontOrderStatuses: build.query<IStorefrontOrderStatuses, void>({
            query: () => 'storefront/orders/statuses'
        }),
        updateStorefrontOrderStatus: build.mutation<IActionResponse, {id: string, status: string}>({
            query: (arg) => ({
                url: `storefront/orders/${arg.id}/status/${arg.status}`,
                method: 'POST'
            }),
            invalidatesTags: (_result, _error, arg) => [{type: STOREFRONT_ORDERS, id: LIST}, {type: STOREFRONT_ORDERS, id: arg.id}],
            onQueryStarted: async (arg, {dispatch, queryFulfilled}) => {
                const patchResult = dispatch(
                    api.util.updateQueryData('getStorefrontOrders', undefined, draft => {
                        const i = draft.items.findIndex(i => i.id === arg.id);
                        draft.items[i].status = arg.status;
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            }
        }),
        getStorefrontMonthlyOrders: build.query<ICollectionResponse<IStorefrontMonthlyOrders>, {year:number}>({
            query: (arg) => `storefront/orders/monthly/${arg.year}`
        }),
        getStorefrontOrdersForStorefront: build.query<ICollectionResponse<IStorefrontOrder>, string>({
            query: (arg) => `storefront/orders/${arg}`
        }),
        getStorefrontThemes: build.query<{[key:string]: IStorefrontTheme}, void>({
            query: () => 'storefront/settings/themes'
        }),
        getPaymentProcessorSetup: build.query<IPaymentProcessorSetupDetails, void>({
            query: () => 'storefront/settings/paymentProcessor',
            providesTags: () => [{type: PAYMENT_PROCESSOR_SETUP_DETAILS, id: LIST}],
        }),
        setupStripe: build.mutation<IActionResponse, IStripeSetupRequest>({
            query: (arg) => ({
                url: 'storefront/settings/paymentProcessor/stripe',
                method: 'POST',
                body: arg
            }),
            invalidatesTags: () => [{type: PAYMENT_PROCESSOR_SETUP_DETAILS, id: LIST}],
        }),
        //endregion
        //REGION Notifications
        getNotifications: build.query<INotification, void>({
            query: () => `notifications`,
        }),
        dismissNotification: build.mutation<IActionResponse, {id: string} >({
            query: (arg) => ({
                url: `notifications/dismiss/${arg.id}`,
                method: 'PUT',
            })
        }),
        //endregion
        
        getVendors: build.query<ICollectionResponse<string>, string>({
            query: (arg) => `catalog/${arg}/products/vendors`
        })
    })
});


export default api;

export const {
    useGetCatalogsQuery,
    useAddCatalogMutation,
    useGetOrganizationsQuery,
    useLazyGetOrganizationsQuery,
    useGetTaxCodesQuery,
    useAddUpdateTaxCodeMutation,
    useDeleteTaxCodeMutation,
    useGetColumnsQuery,
    useSetSearchColumnsMutation,
    useSetWorkspaceColumnsMutation,
    useGetWorkspacesQuery,
    useGetRecentWorkspacesQuery,
    useGetFavoritesQuery,
    useGetFavoritesProductsQuery,
    useGetSearchResultsQuery,
    useAddProductToWorkspaceMutation,
    useRemoveMultipleProductsFromWorkspaceMutation,
    useRemoveProductFromWorkspaceMutation,
    usePutWorkspaceMutation,
    useAddWorkspaceMutation,
    useDeleteCatalogMutation,
    useRemoveWorkspaceMutation,
    useSetUserFavoriteMutation,
    useRemoveUserFavoriteMutation,
    useSetCompanyFavoriteMutation,
    useRemoveCompanyFavoriteMutation,
    useSetSelectedOrganizationMutation,
    useSetSelectedWorkspaceMutation,
    useSetWorkspaceExtensionFieldMutation,
    useGetWorkspaceQuery,
    useClearWorkspaceCacheMutation,
    useDeleteInstalledExtensionMutation,
    useDisableInstalledExtensionMutation,
    useEnableInstalledExtensionMutation,
    useGetAvailableExtensionQuery,
    useGetAvailableExtensionsQuery,
    useGetInstalledExtensionLogsQuery,
    useGetUnmatchedExtensionProductsQuery,
    usePostConfirmExtensionProductMatchMutation,
    usePostCreateExtensionProductFromUnmatchedMutation,
    useGetInstalledExtensionsQuery,
    useInstallExtensionMutation,
    usePutInstalledExtensionMutation,
    useGetExtensionLibraryQuery,
    usePostExtensionLibraryFileMutation,
    usePostDisableExtensionLibraryFileMutation,
    usePostEnableExtensionLibraryFileMutation,
    useGetWorkspaceProductsQuery,
    useLazyGetImportSampleQuery,
    useGetWorkspaceMetadataFieldQuery,
    useGetWorkspaceMetadataQuery,
    useGetWorkspaceRecommendationsQuery,
    useClearWorkspaceRecommendationsMutation,
    useUploadRecommendationFileMutation,
    useGetExportExtensionOptionsQuery,
    useGetItemExtensionFieldsQuery,
    usePutItemExtensionFieldMutation,
    useGetExtensionPotentialMatchesQuery,
    useRunExportExtensionMutation,
    useRunImportExtensionMutation,
    useAddProductReferenceToWorkspaceMutation,
    useGetItemDetailsQuery,
    useRunRealtimeExtensionMutation,
    useClearSearchMutation,
    useClearExtensionsMutation,
    usePostProductMutation,
    usePutProductMutation,
    useSetImageMutation,
    useGetNotesQuery,
    usePutNotesMutation,
    useGetMultipleItemDetailsQuery,
    useGetMetadataQuery,
    usePutWorkspaceItemDetailsMutation,
    useGetTasksQuery,
    useDismissTaskMutation,
    useValidateExtensionSettingsMutation,
    useValidateInstalledExtensionMutation,
    useSimpleValidateInstalledExtensionMutation,
    useGetSessionKeyMutation,
    useGetSearchReportQuery,
    useGetMonthlyApiUsageReportQuery,
    useGetApiKeysQuery,
    useDisableApiKeyMutation,
    useEnableApiKeyMutation,
    useAddApiKeyMutation,
    useDeleteApiKeyMutation,
    useGetCountQuery,
    useLazyGetQuicksearchQuery,
    useDeleteProductImagesMutation,
    useCreateSearchSessionMutation,
    useUpdateProductVisitMutation,
    useGetProductTagsQuery,
    usePutTagsMutation,
    usePutUserTagsMutation,
    useImportWorkspaceProductsFromTemplateMutation,
    useGetTemplateWorkspacesQuery,
    useGetExtensionInstallOptionsQuery,
    useGetWorkspaceExtensionMetadataQuery,
    useGetSubOrganizationsQuery,
    useAddSubOrganizationMutation,
    useUpdateSubOrganizationMutation,
    useAddWorkspaceWithTemplateDefaultsMutation,
    useGetCompaniesQuery,
    useGetCompanyQuery,
    usePutCompanyMutation,
    useLazyGetCompaniesQuery,
    useLazyGetCompanyQuery,
    usePostGenerateContactsFromCompaniesMutation,
    useGetContactsQuery,
    useGetContactByIdQuery,
    useGetContactsForCompanyQuery,
    useSaveContactMutation,
    useLazyGetWorkspaceQuery,
    useUploadImageMutation,
    useUpdateOrganizationMutation,
    useGetApiKeyTypesQuery,
    useGetExtensionCompanyQuery,
    useLazyGetExtensionCompanyQuery,
    useLazyGetImportCompanyExtensionOptionsQuery,
    useLazyGetWorkspaceCompanyCountQuery,
    useRunDocumentExtensionMutation,
    useGetInstalledExtensionQuery,
    useUploadWorkspaceImportFileMutation,
    usePostSearchTableRowQuery,
    useLazyGetWorkspaceProductsQuery,
    useProcureWorkspaceItemsMutation,
    useRemoveProcurementProductMutation,
    usePutProcurementProductMutation,
    useCreateOrderMutation,
    usePutProcurementRequirementAnswerMutation,
    useAddAdhocProductToWorkspaceMutation,
    useLazyGetExportExtensionOptionsQuery,
    useRunDirectImportExtensionMutation,
    useLazyGetDirectImportPreviewQuery,
    usePutWorkspaceAdhocItemMutation,
    useAnalyzeCustomerRequestMutation,
    useGetCustomerAnalysisQuery,
    useGetCustomerAnalysisForWorkspaceQuery,
    useDeleteCustomerAnalysisMutation,
    useThumbsUpCustomerAnalysisMutation,
    useThumbsDownCustomerAnalysisMutation,
    useClearCustomerAnalysisRatingMutation,
    useUploadCustomerRequestDocumentMutation,
    useRunAnalysisExtensionMutation,
    useGetUserChatsQuery,
    useGetUserChatQuery,
    useCreateUserChatMutation,
    useAddUserChatMessageMutation,
    useConfirmExtensionPotentialMatchMutation,
    useGetFreightOptionsMutation,
    useGetProcurementExtensionOrderRequirementsQuery,
    useLazyGetProcurementExtensionOrderRequirementsQuery,
    useGetOpenOrdersQuery,
    useGetOrderCarrierOptionsQuery,
    useGetOrderWarehouseOptionsQuery,
    useGetIsFreightVendorQuery,
    useGetOrderItemsQuery,
    useUpdateOrderStatusMutation,
    useGetClosedOrdersQuery,
    useGetOrderFulfillmentsQuery,
    useGetOrderEventsQuery,
    useLazyGetOrderVendorUpdateQuery,
    useGetExtensionIdQuery,
    usePutCustomColumnSetMutation,
    useGetColumnSetQuery,
    useCreateCsvMutation,
    useGetDirectImportPreviewQuery,
    useLazyGetColumnSetQuery,
    usePostProcurementProductMutation,
    useRefreshProcurementPricingMutation,
    useGetUserSettingsQuery,
    usePatchUserSettingsMutation,
    useGetProcurementSourceVendorsQuery,
    useGetProcurementItemsBySourceVendorQuery,
    useGetExtensionFolioQuery,
    useLazyGetExtensionFolioQuery,
    usePutExtensionFolioMutation,
    useGetStorefrontProductCountQuery,
    useGetStorefrontThemesQuery,
    useGetPaymentProcessorSetupQuery,
    useSetupStripeMutation,
    useGetGlobalStorefrontSettingsQuery,
    useSaveGlobalStorefrontSettingsMutation,
    useGetStorefrontsQuery,
    useGetStorefrontQuery,
    useSaveStorefrontMutation,
    useCreateStorefrontMutation,
    useGetStorefrontOrdersQuery,
    useProcessStorefrontOrderMutation,
    useGetStorefrontMonthlyOrdersQuery,
    useGetStorefrontOrdersForStorefrontQuery,
    useGetStorefrontOrderStatusesQuery,
    useUpdateStorefrontOrderStatusMutation,
    useGetNotificationsQuery,
    useDismissNotificationMutation,
    useValidateExtensionFolioMutation,
    useSimpleValidateExtensionFolioMutation,
    useGetStorefrontSubExtensionReferencesQuery,
    useGetSubExtensionFolioSubExtensionReferencesQuery,
    useEnableFolioSubExtensionMutation,
    useLazyGetExportExtensionOptionFriendlyNameQuery,
    useGetVendorsQuery,
    useLazyGetStorefrontLoginLinkQuery,
    useLazyGetSavedSearchesQuery,
    useGetSavedSearchesQuery,
    useGetSavedSearchQuery,
    useGetTopSavedSearchesQuery,
    useSaveSearchMutation,
    useLazyGetSavedSearchOnStorefrontCountQuery,
    useDeleteSavedSearchMutation,
    useUpdateWorkspaceItemSortOrderMutation
} = api;