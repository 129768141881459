import React, {useEffect, useState} from "react";
import {ICatalog} from "../../models/Catalog";
import {useAddCatalogMutation, useGetCatalogsQuery} from "../../store/Api";
import {
    IsFetchBaseQueryError,
    IsMutationError,
    IsMutationSuccess,
    IsSerializedError
} from "../../logic/MutationTypeChecker";
import {
    Checkbox,
    DefaultButton,
    FontWeights,
    getTheme,
    IButtonStyles,
    IconButton,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Modal,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";

interface IEditCatalogModalProps {
    catalog?: ICatalog;
    isOpen: boolean;
    onClose: () => void;
}

const EditCatalogModal: React.FC<IEditCatalogModalProps> = (props) => {
    const getCatalogs = useGetCatalogsQuery();
    const [saveCatalog] = useAddCatalogMutation();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(true);
    const [checkRealtime, setCheckRealtime] = useState(true);
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState<string | undefined>()
    const [savable, setSavable] = useState(true);
    const [duplicateName, setDuplicateName] = useState(false);

    useEffect(() => {
        if (name !== props.catalog?.name) {
            setName(props.catalog?.name ?? '');
            setDescription(props.catalog?.description ?? '');
            setActive(props.catalog?.active ?? true);
            setCheckRealtime(props.catalog?.checkRealtime ?? true);
        }
    }, [props.catalog]);

    useEffect(() => {
        if (getCatalogs.data) {
            if (getCatalogs.data.findIndex(i => i.name.toLowerCase() === name.toLowerCase()) !== -1 && props.catalog == undefined) {
                setSavable(false);
                setDuplicateName(true);
            } else {
                setSavable(true);
                setDuplicateName(false);
            }
        } else {
            setSavable(false);
        }
    }, [getCatalogs.data, name, props.catalog]);

    const save = () => {
        setSaving(true);
        saveCatalog({name, description, active, checkRealtime})
            .then((result) => {
                if (IsMutationSuccess(result)) {
                    if (!result.data.success) setSaveError(result.data.message ?? 'Error saving catalog');
                    setSaving(false);
                    props.onClose();
                } else if (IsMutationError(result)) {
                    if (IsFetchBaseQueryError(result)) {
                        setSaveError(`Network error while communicating with API: ${result.error}`)
                    } else if (IsSerializedError(result)) {
                        setSaveError(`Response error while communicating with API: ${result.error}`)
                    }
                }
                setSaving(false);
            });
    }

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: '4px solid #789c2f',
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: {margin: '14px 0'},
                'p:first-child': {marginTop: 0},
                'p:last-child': {marginBottom: 0},
            },
        },
    });
    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    return (
        <Modal
            isOpen={props.isOpen}
            onDismiss={props.onClose}
        >
            <div className={contentStyles.header}>
                <span>Create New Catalog</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{iconName: 'Cancel'}}
                    ariaLabel="Close popup modal"
                    onClick={() => props.onClose()}
                />
            </div>
            <div className={contentStyles.body}>
                <Stack tokens={{childrenGap: '1em'}}>
                    {saveError && <MessageBar messageBarType={MessageBarType.error}>{saveError}</MessageBar> }
                    <TextField
                        label='Name'
                        value={name}
                        onChange={(e, d) => setName(d ?? '')}
                        disabled={props.catalog != undefined}
                        errorMessage={duplicateName ? 'Catalog name must be unique' : undefined}
                    />
                    <TextField
                        label='Description'
                        value={description}
                        onChange={(e, d) => setDescription(d ?? '')}
                    />
                    <Checkbox
                        label='Search Realtime'
                        checked={checkRealtime}
                        onChange={(e, d) => setCheckRealtime(d ?? false)}
                    />
                    <Checkbox
                        label='Active'
                        checked={active}
                        onChange={(e, d) => setActive(d ?? false)}
                    />
                </Stack>
            </div>
            <div className={contentStyles.body}>
                <Stack horizontal tokens={{childrenGap: '1em'}} horizontalAlign='end'>
                    <PrimaryButton
                        text='Save'
                        disabled={saving || !savable}
                        onClick={() => save()}
                    />
                    <DefaultButton
                        onClick={() => props.onClose()}
                        text='Close'
                    />
                </Stack>
            </div>
        </Modal>
    );
}

export default EditCatalogModal;
