export class AnonDAL {
    protected baseUrl = window.config.baseUrl;
    protected readonly _organization?: string;
    constructor(
        organization?: string
    ) {
        this._organization = organization;
    }
    
    protected async post<T>(url: string, body?: string, signal?: AbortSignal): Promise<T | null> {
        if (this._organization) {
            url += `&organization=${this._organization}`;
        }
        return this
            ._post<T>(url, body, signal)
            .catch((error) => {
                if (error.name === 'AbortError') return null;
                throw error;
            });
    }

    private async _post<T>(url: string, body?: string, signal?: AbortSignal): Promise<T> {
        const response = await fetch(url, {
            method: "POST",
            headers: new Headers({'Content-Type': 'application/json'}),
            body: body,
            signal: signal,
        });
        if (response.status === 401) {
            // eslint-disable-next-line
            throw {
                response: response,
            };
        }
        const data = await response.json();
        return data as T;
    }

    protected async _get<T>(url: string, signal?: AbortSignal): Promise<T> {
        const response = await fetch(url, {
            headers: new Headers({'Content-Type': 'application/json'}),
            signal: signal,
        });
        if (response.status === 401) {
            // eslint-disable-next-line
            throw {
                response: response,
            };
        }
        const data = await response.json();
        return data as T;
    }
    protected async get<T>(url: string, signal?: AbortSignal): Promise<T | null> {
        return this
            ._get<T>(url, signal)
            .catch((error) => {
                if (error.name === 'AbortError') return null;
                if (error.response && error.response.status === 401) {
                    // return this._authContext.userManager.signinSilent().then((renewedUser) => {
                    //     return this._get<T>(url, renewedUser.access_token);
                    // });
                }
                throw error;
            });
    }
    

    
}

export interface IActionResponse {
    success: boolean;
    message: string;
    id: string;
}

