import React from 'react';
import {Icon} from '@fluentui/react';
interface ISearchFilterProps {
    onDelete?: () => void;
    protected?: boolean;
    color?: string;
}
const SearchFilter: React.FC<ISearchFilterProps> = (props) => {
    const style = props.color ? {backgroundColor:props.color} : {}
    return (
            <div className={"ac-search-filter"} style={style}>
                {props.children}
                {!props.protected ? <Icon iconName={'StatusCircleErrorX'} className={"ac-search-filter-icon"} onClick={props.onDelete}></Icon> : <></>}
            </div>
    );
}

export default SearchFilter;