import {
    CommandBar, getTheme,
    IColumn, ICommandBarItemProps,
    mergeStyleSets,
    Panel,
    PanelType,
    PrimaryButton,
    SearchBox,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import React, {useState} from "react";
import {IWorkspace} from "../../models/Workspace";
import {
    useGetRecentWorkspacesQuery,
    useGetWorkspacesQuery,
    useImportWorkspaceProductsFromTemplateMutation, 
    useSetSelectedWorkspaceMutation
} from "../../store/Api";
import WorkspaceDeleteModal from "./WorkspaceDeleteModal";
import {setPunchoutWorkspace} from "../../store/settingsSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {cloneDeep} from "lodash";
import {useHistory} from "react-router-dom";
interface IWorkspaceSearchModalProps {
    displayModal: boolean;
    closer: () => void;
}
const WorkspaceSearchModal: React.FC<IWorkspaceSearchModalProps> = props => {
    const isPunchoutWorkspace = window.location.pathname.toLowerCase().indexOf('punchout/workspace') !== -1;

    const initialSelectedWorkspace = useSelector((state: RootState) => state.settings.selectedWorkspaceId);
    let selectedWorkspace = cloneDeep(initialSelectedWorkspace);
    const [setSelectedWorkspace] = useSetSelectedWorkspaceMutation();
    const theme = getTheme();
    const history = useHistory();
    const initialColumns: IColumn[] = [
        {
            key: 'SelectButton',
            name: '',
            fieldName: 'selectWorkspace',
            onRender: (key: IWorkspace) => <PrimaryButton 
                //disabled={selectedWorkspace == key.id} 
                onClick={() => {
                    setSelectedWorkspace(key.id);
                    selectedWorkspace = key.id

                    const newPath = isPunchoutWorkspace ? `/punchout/workspace/${key.id}` : `/workspace/${key.id}`;
                    history.replace(newPath);
                    props.closer()
                }
            }>Open</PrimaryButton>,
            minWidth: 80,
            maxWidth: 80
        },
        {
            key: 'Name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 200,
            maxWidth: 300,
            isRowHeader: true,
        },
        {
            key: 'Template',
            name: 'Is Template',
            fieldName: 'template',
            onRender: (key: IWorkspace) => <div style={{textAlign: "center"}}>{key.isTemplate ? "✓" : "" }</div>,
            minWidth: 100,
            maxWidth: 100,
        },
        {
            key: 'LastAccessed',
            name: 'Last Accessed',
            fieldName: 'lastAccessed',
            onRender: (key: IWorkspace) => key.lastAccessed != null ? getDateTimeString(key.lastAccessed) : "",
            minWidth: 150,
            maxWidth: 150,
            data: 'date'
        },
        {
            key: 'Import',
            name: '',
            fieldName: 'import',
            minWidth: 80,
            maxWidth: 80,
            onRender: (a: IWorkspace) => selectedWorkspace && a.isTemplate && a.id != selectedWorkspace  && !isPunchoutWorkspace?
                <PrimaryButton iconProps={{iconName: 'WebTemplate'}} onClick={() => {
                    {
                        importProductsFromWorkspace({sourceId: a.id, destinationId: selectedWorkspace ?? ""})
                    }
                }}>Import</PrimaryButton> : <></>,
        },
        {
            key: 'Delete',
            name: '',
            fieldName: 'delete',
            minWidth: 80,
            maxWidth: 80,
            ariaLabel: 'Delete Workspace',
            onRender: (a: IWorkspace) => isPunchoutWorkspace ? <></> : 
                <PrimaryButton styles={{root: { backgroundColor: theme.palette.red, color: theme.palette.white }, rootFocused: { backgroundColor: theme.palette.redDark, color: theme.palette.white }, rootHovered: { backgroundColor: theme.palette.redDark, color: theme.palette.white },}} 
                               iconProps={{iconName: 'Delete'}} 
                               onClick={() => {{
                                   setDeleteWorkspaceId(a.id);setDeleteWorkspaceModalOpen(true)
                               }
                               }}>
                </PrimaryButton>,

        },


    ]
    const recentWorkspaces = useGetRecentWorkspacesQuery();
    const [importProductsFromWorkspace] = useImportWorkspaceProductsFromTemplateMutation()
    const workspaces = useGetWorkspacesQuery();
    
    const punchoutWorkspaceId = useSelector((state: RootState) => state.settings.punchoutWorkspace);
    const [searchText, setSearchText] = useState<string>("")
    const [workspaceTypeFilter, setWorkspaceTypeFilter] = useState<string>("All")
    const [deleteWorkspaceId, setDeleteWorkspaceId] = useState<string>()
    const [deleteWorkspaceModalOpen, setDeleteWorkspaceModalOpen] = React.useState(false);
    const [value] = useState<string | undefined>()
    const [columns] = useState<IColumn[]>(initialColumns)

    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        navigation: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        footerInner: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        content: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
        commands: {
            backgroundColor: 'rgb(255, 255, 255)',
        },
    });
    
    const getDateTimeString = (dateValue: Date) => {
        const date = new Date(dateValue); return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
    }

    const getApplicableWorkspaces = (name: string) => {
        const searchFilteredWorkspaces =  workspaces.data ? workspaces.data.filter((a) => a.name.toLowerCase().includes(name.toLowerCase())) : []
        
        if (workspaceTypeFilter == 'Templates'){
            return searchFilteredWorkspaces.filter((a) => a.isTemplate)
        }
        else if (workspaceTypeFilter == 'Active')
            return searchFilteredWorkspaces.filter((a) => !a.isTemplate)
        
        return searchFilteredWorkspaces;
    }

    const commandItems: ICommandBarItemProps[] = [
        {
            key: 'allFilter',
            text: 'Active & Templates',
            onClick: () => setWorkspaceTypeFilter('Active & Templates'),
            buttonStyles: {
                root: {
                    backgroundColor: (workspaceTypeFilter == 'Active & Templates') ? '#ddd' : 'white'
                }
            },
        },
        {
            key: 'activeFilter',
            text: 'Active',
            onClick: () => setWorkspaceTypeFilter("Active"),
            buttonStyles: {
                root: {
                    backgroundColor: (workspaceTypeFilter == 'Active') ? '#ddd' : 'white'
                }
            },
        },
        {
            key: 'templatesFilter',
            text: 'Templates',
            onClick: () => setWorkspaceTypeFilter("Templates"),
            buttonStyles: {
                root: {
                    backgroundColor: (workspaceTypeFilter == 'Templates') ? '#ddd' : 'white'
                }
            },
        }
        ]
    const rightCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: '',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {workspaces.refetch(); recentWorkspaces.refetch(); }
        }
    ]
    return (
        <div>
            <Panel
                onOuterClick={() => {console.log("swallowing modal close event")}}
                isOpen={props.displayModal} 
                onDismiss={props.closer} 
                className={"workspace-search-panel"} 
                type={PanelType.custom}
                customWidth={"auto"}
                isFooterAtBottom={true}
                title={"Search Workspaces"}
                styles={{
                    root: {
                        zIndex: 10,
                        marginTop: '48px',
                    },
                    content:contentStyles.content,
                    commands:contentStyles.commands,
                    scrollableContent: contentStyles.scrollableContent,
                    navigation: contentStyles.navigation,
                    footerInner: contentStyles.footerInner,
                }}
            >
                <h1>Search Workspaces</h1>
                <CommandBar
                    items={commandItems}
                    farItems={rightCommands}
                />
                <br/>
                <SearchBox
                    defaultValue={searchText ?? undefined}
                    placeholder={"Search Workspace Name"}
                    value={value}
                    onChange={(e, d) => {setSearchText(d ?? '');
                    }}
                />
                <br/>
                <ShimmeredDetailsList 
                    items={getApplicableWorkspaces(searchText)} 
                    enableShimmer={workspaces.isFetching} 
                    columns={columns} 
                    selectionMode={SelectionMode.none} 
                />

            </Panel>
            <WorkspaceDeleteModal
                onDeleteWorkspace={() => {
                    if(selectedWorkspace != "" && selectedWorkspace == punchoutWorkspaceId){
                        setPunchoutWorkspace(undefined);
                    }
                    const data = recentWorkspaces?.data;
                    if (data === undefined) return;
                    const selectedWs = data[0].id == selectedWorkspace ? data[1] : data[0]
                    setSelectedWorkspace(selectedWs.id)
                }}
                selectedWorkspace={deleteWorkspaceId}
                displayModal={deleteWorkspaceModalOpen} 
                closer={() => setDeleteWorkspaceModalOpen(false)}
            />
        </div>

    );
}
export default WorkspaceSearchModal;