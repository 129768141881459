import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {AttributesTable, IAttributeKV} from '../components/DetailsModal/AttributesTable';
import BasicDetails from '../components/DetailsModal/BasicDetails';
import BasicDetailsTable from '../components/DetailsModal/BasicDetailsTable';
import LinkedItemsDetails from '../components/DetailsModal/LinkedItemsDetails';
import './Embedded.css'
import {useGetItemDetailsQuery} from "../store/Api";
import {ImageFit, Pivot, PivotItem, Stack, Text, Image, SpinnerSize, Spinner} from "@fluentui/react";
import VendorCards from "../components/DetailsModal/VendorCards";

interface EmbeddedProductPageParams {
    id: string;
    catalog: string;
}

const EmbeddedProductPage: React.FC<RouteComponentProps<EmbeddedProductPageParams>> = (props) => {
    const getProduct = useGetItemDetailsQuery({catalog: props.match.params.catalog, id: props.match.params.id});

    if (getProduct.isLoading || getProduct.isFetching) return <Spinner size={SpinnerSize.large}/>
    if (getProduct.data == null) return <div>Failed to load</div>

    const attributes: IAttributeKV[] = Object.entries(getProduct.data.attributes).map((a) => ({key: a[0], value: a[1]}));
    const facets: IAttributeKV[] = Object.entries(getProduct.data.facets).map((a) => ({key: a[0], value: a[1]}));
    const filters: IAttributeKV[] = Object.entries(getProduct.data.filters).map((a) => ({key: a[0], value: a[1]}));

    // var logo = <Image src='mainLogo.png'/>
    return (
        <div style={{padding: '1em'}}>
            <div style={{flexGrow: 1, paddingLeft: '25px' }}>
                <Stack horizontal disableShrink>
                    <Stack.Item align='auto'>
                        <Image imageFit={ImageFit.contain} src={getProduct.data.image} width={100} height={100} />
                    </Stack.Item>
                    <Stack.Item align='center' style={{paddingLeft: '1em'}}>
                        <Text variant={'xLarge'} block>
                            {getProduct.data.name}
                        </Text>
                        <Text variant={'small'} block>
                            Manufacturer Part Number: {getProduct.data.manufacturerPartNumber}
                        </Text>
                    </Stack.Item>
                </Stack>
            </div>
            <Pivot overflowBehavior='menu' styles={{itemContainer:{marginTop:'1em'}}}>
                <PivotItem headerText='Details'>
                    <BasicDetails product={getProduct.data} />
                </PivotItem>
                <PivotItem headerText='Attributes'>
                    <BasicDetailsTable product={getProduct.data} catalog={getProduct.data.catalog} />
                    <AttributesTable title='Attributes' attributes={attributes} />
                    <AttributesTable title='Facets' attributes={facets} />
                    <AttributesTable title='Filters' attributes={filters} />
                </PivotItem>
                <PivotItem headerText='Vendors'>
                    <VendorCards
                        vendors={getProduct.data.vendors ?? []}
                        product={getProduct.data}
                        refreshing={false}
                    />
                </PivotItem>
                {(getProduct.data.links && getProduct.data.links.length > 0) && <PivotItem headerText='Linked Products'>
                    <LinkedItemsDetails product={getProduct.data} editMode={false} />
                </PivotItem>}
            </Pivot>
        </div>
    )
}

export default EmbeddedProductPage;