
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "./store/rootReducer";

export const AuthorizedRoute: React.FC<{
    errorComponent?: React.ComponentType<{ error: Error }>;
} & RouteProps> = ({ errorComponent, ...routeProps }) => {
    const auth = useSelector((state: RootState) => state.auth);
if (!auth.isAuthed) {
    return (<></>);
}

    return (
        <Route
            { ...routeProps }
        />
    );
};

export default AuthorizedRoute;
