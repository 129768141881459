import React from 'react';
import {IVendor} from "../../models/Search";
import {Callout, DefaultButton, DirectionalHint, Icon, ProgressIndicator, TooltipHost} from "@fluentui/react";
import CurrencyFormat from "react-currency-format";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {useId} from "@fluentui/react-hooks";
import AddToWorkspaceButton from "../ProductDetails/AddToWorkspaceButton";
import {WarehouseTable} from "./WarehouseTable";
import PriceBreaksTable from './PriceBreakTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

interface IVendorDisplayProps {
    refreshing?: boolean;
    loadingWarehouses?: boolean;
    vendor?: IVendor[];
    product: INormalizedProduct;
    dense?: boolean;
}

const VendorDisplay: React.FC<IVendorDisplayProps> = (props) => {
    const desiredQuantity = useSelector((state: RootState) => state.search.desiredQuantity);
    const [open, setOpen] = React.useState(false);
    const id = useId('vendor-button');

    if (props.vendor == null) return (<div>
        <DefaultButton
            className={`text-center ${props.refreshing && 'refreshing-vendor-display'}`}
            style={{
                width: '100%',
                height: '3em',
            }}
            disabled={true}
        >
            <div>No Data</div>
        </DefaultButton>
        {props.refreshing && <ProgressIndicator/>}
    </div>);

    function formatCurrency(amount: number) {
        if (amount == null) return null;
        return <CurrencyFormat
            value={amount}
            displayType="text"
            thousandSeparator=","
            prefix="$"
            decimalScale={2}
            fixedDecimalScale={true}
        />;
    }

    let stock = false;
    let vendorName;
    let costMessage;
    let hasPriceBreaks = false;
    let highInStockCost;
    let lowInStockCost;
    let highCost;
    let lowCost;
    let totalStock = 0;
    for (const v of props.vendor) {
        if (v.onHand) totalStock += v.onHand;
        if (v.vendorName) vendorName = v.vendorName;
        if (v.costMessage) costMessage = v.costMessage;
        if (v.inStock) {
            stock = true;
            if (v.cost) {
                if (highInStockCost == null || v.cost > highInStockCost) highInStockCost = v.cost;
                if (lowInStockCost == null || v.cost < lowInStockCost) lowInStockCost = v.cost;
            }
        }
        if (v.cost) {
            if (highInStockCost == null || v.cost > highInStockCost) highInStockCost = v.cost;
            if (lowInStockCost == null || v.cost < lowInStockCost) lowInStockCost = v.cost;
        }
        if (v.priceBreaks != null) {
            let pbLow;
            for (const pb of v.priceBreaks) {
                if (pbLow == null || pb.price < pbLow) pbLow = pb.price;
                if (highCost == null || pb.price > highCost) highCost = pb.price;
                if (lowCost == null || pb.price < lowCost) lowCost = pb.price;
                if (v.inStock) {
                    if (highInStockCost == null || pb.price > highInStockCost) highInStockCost = pb.price;
                    if (lowInStockCost == null || pb.price < lowInStockCost) lowInStockCost = pb.price;
                }
            }
            if (v.cost != pbLow) hasPriceBreaks = true;
        }
    }

    const cost = stock && lowInStockCost && highInStockCost
        ? lowInStockCost === highInStockCost
            ? formatCurrency(highInStockCost)
            : <>
                {formatCurrency(lowInStockCost)} - {formatCurrency(highInStockCost)}
            </>
        : lowCost && highCost
            ? lowCost === highCost
                ? formatCurrency(highCost)
                : <>
                    {formatCurrency(lowCost)} - {formatCurrency(highCost)}
                </>
            : null;

    const inStock = stock;
    const inStockMessage = totalStock && totalStock <= 9999 ? `Stock: ${totalStock}` : 'In Stock';
    const enoughStock = desiredQuantity == null || totalStock && totalStock >= desiredQuantity;

    if (props.dense) {
        return <div
            id={id}
            className={`vendor-dense ${inStock ? 'in-stock-dense' : 'no-stock-dense'} ${props.refreshing && (inStock ? 'vendor-loading-in-stock-cell' : 'vendor-loading-no-stock-cell')}`}
            onClick={() => setOpen(!open)}
        >
            <div>
            {props.vendor[0] && <div key={props.vendor[0].vendorName + props.vendor[0].vendorPartNumber}>
                {props.vendor[0].priceBreaks && hasPriceBreaks && <>
                    <h5>
                        Price Breaks
                        <span style={{paddingLeft: '0.5em'}}>
                            <TooltipHost
                                content={'The price breaks may start at lower quantities. Please check with your distributor for exact price breaks.'}>
                                <Icon iconName={'alertSolid'}/>
                            </TooltipHost>
                        </span>
                    </h5>
                    <PriceBreaksTable baseCost={props.vendor[0].cost} priceBreaks={props.vendor[0].priceBreaks} list/>
                </>
                }
                </div>
            }
            {!hasPriceBreaks &&
                <div>
                {costMessage && <TooltipHost content={costMessage}>
                    <Icon iconName={'alertSolid'}
                      className={inStock ? 'cost-alert-stock' : 'cost-alert'}/>&nbsp;{cost}
                </TooltipHost>}
                {costMessage == null && <>{cost}</>}
                </div>
            }
            <div className={'stock-data'} style={!enoughStock ? {color: 'red'} : {}}>
                {inStock ? inStockMessage : "No Stock"}
            </div>
            </div>
            {(open) && (
            <Callout
                style={{
                    padding: '1em',
                }}
                directionalHint={DirectionalHint.leftCenter}
                target={`#${id}`}
                onDismiss={() => setOpen(false)}
                role='status'
            >
                {props.loadingWarehouses ? <p>Loading</p> :
                <WarehouseTable vendors={props.vendor} product={props.product}/>}
                <AddToWorkspaceButton
                product={props.product}
                source={vendorName}
                onChange={() => setOpen(false)}
                />
            </Callout>
            )}
        </div>
    }

    return <div>
        <DefaultButton
            id={id}
            onClick={() => setOpen(!open)}
            color={inStock ? "grey" : "red"}
            className={`text-center ${!inStock && 'no-stock-button'} ${props.refreshing && (inStock ? 'vendor-loading-in-stock-button' : 'vendor-loading-no-stock-button')}`}
            primary
            style={{
                width: '100%',
                height: '4em',
                padding: '1px'
            }}
        >
            <div>
                <div>
                    {costMessage && <TooltipHost content={costMessage}>
                        <Icon iconName={'alertSolid'}
                              className={inStock ? 'cost-alert-stock' : 'cost-alert'}/>&nbsp;{cost}
                    </TooltipHost>}
                    {costMessage == null && <>{cost}</>}
                </div>
                <div
                    className={cost ? 'stock-data' : 'stock-data-only'}
                    style={!enoughStock ? {color: 'red'} : {}}
                    >
                        {inStock ? inStockMessage : "No Stock"}
                </div>
            </div>
        </DefaultButton>
        {(open) && (
            <Callout
                style={{
                    padding: '1em',
                }}
                directionalHint={DirectionalHint.leftCenter}
                target={`#${id}`}
                onDismiss={() => setOpen(false)}
                role='status'
            >
                {props.vendor.map(v => v.priceBreaks ? <>
                    <h5>
                        Price Breaks - {v.vendorPartNumber}
                        <span style={{paddingLeft: '0.5em'}}>
                                <TooltipHost
                                    content={'The price breaks may start at lower quantities. Please check with your distributor for exact price breaks.'}>
                                <Icon iconName={'alertSolid'}/>
                            </TooltipHost>
                        </span>
                    </h5>
                    <PriceBreaksTable baseCost={v.cost} priceBreaks={v.priceBreaks}/>
                </> : null)}
                {props.loadingWarehouses ? <p>Loading</p> :
                    <WarehouseTable vendors={props.vendor} product={props.product}/>}
                <AddToWorkspaceButton
                    product={props.product}
                    source={vendorName}
                    onChange={() => setOpen(false)}
                />
            </Callout>
        )}
    </div>
}

export default VendorDisplay;
