import React from "react";
import {cloneDeep} from "lodash";
import {
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    IconButton,
    SelectionMode,
    TextField
} from "@fluentui/react";
import AttributeDivider from "../Common/AttributeDivider";

interface IAttributesTableProps {
    title?: string;
    attributes: IAttributeKV[]
    editMode?: boolean;
    onUpdate?: (attributes: IAttributeKV[]) => void;
}

export interface IAttributeKV {
    key: string;
    value: string | number | undefined;
}

export const AttributesTable: React.FC<IAttributesTableProps> = (props) => {
    const updateAttribute = (i: number, key: string, value: string | number | undefined) => {
        if (!props.onUpdate) return;
        const newAttributes = cloneDeep(props.attributes);
        newAttributes[i] = {key, value};
        props.onUpdate(newAttributes);
    }

    const addAttribute = () => {
        if (!props.onUpdate) return;
        const newAttributes = cloneDeep(props.attributes);
        newAttributes.push({key: '', value: undefined});
        props.onUpdate(newAttributes);
    }

    const deleteAttribute = (i: number | undefined) => {
        if (!props.onUpdate) return;
        if (i === undefined) return;
        const newAttributes = cloneDeep(props.attributes);
        newAttributes.splice(i, 1);
        props.onUpdate(newAttributes);
    }

    if (props.attributes.length === 0 && !props.editMode) return null;

    const columns: IColumn[] = [
        {
            key: 'keys',
            minWidth: 100,
            name: 'Key',
            flexGrow: 1,
            onRender: (item: IAttributeKV, i) => {
                return props.editMode ?
                    <TextField
                        value={item.key}
                        onChange={(e, d) => {
                            if (i !== undefined && d) updateAttribute(i, d, item.value);
                        }}
                    />
                    : <>{item.key}</>
            }
        },
        {
            key: 'value',
            minWidth: 100,
            flexGrow: 1,
            name: 'Value',
            onRender: (item: IAttributeKV, i) => props.editMode ?
                <TextField
                    value={item.value?.toString()}
                    onChange={(e, d) => {

                        if (i !== undefined) {
                            if (props.title === "Filters") {
                                const onlyNums = d?.replace(/[^0-9.]/g, '');
                                updateAttribute(i, item.key, onlyNums);
                            } else {
                                updateAttribute(i, item.key, d);
                            }
                        }
                    }}
                />
                : <>{item.value}</>
        },
        {
            key: 'actions',
            minWidth: 50,
            maxWidth: 50,
            name: 'Actions',
            onRender: (item: IAttributeKV, i) => props.editMode ? <IconButton
                iconProps={{iconName: 'Delete'}}
                onClick={() => deleteAttribute(i)}
            /> : <></>
        }
    ]

    return <div style={{paddingTop:"30px"}}>
        {props.title && <div className={"ac-attribute-segment"}><AttributeDivider title={props.title}></AttributeDivider>
        <DetailsList
            className={"ac-attribute-container"}
            selectionMode={SelectionMode.none}
            items={props.attributes}
            columns={columns}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            isHeaderVisible={false}
        />
        <div>
            {props.editMode && <IconButton
                iconProps={{iconName: 'Add'}}
                onClick={() => addAttribute()}
            />}
        </div></div>}
    </div>
};
