import React from 'react';

interface IDividerProps {
    title: string;
}
const AttributeDivider: React.FC<IDividerProps> = (props) => {
    return (      
        <div>
            <div className="ac-attribute-section">
                    {props.title}{props.children}
            </div>
        </div>
    );
}

export default AttributeDivider;