import {
    DefaultButton,
    Dialog, DialogFooter,
    DialogType
} from "@fluentui/react";

import React from "react";
import {useDeleteSavedSearchMutation} from "../../store/Api";
interface ISavedSearchDeleteModalProps {
    selectedSavedSearch?: string
    displayModal: boolean;
    onDeleteSavedSearch?: () => void;
    closer: () => void;
}
const SavedSearchDeleteModal: React.FC<ISavedSearchDeleteModalProps> = props => {
    const [removeSavedSearch] = useDeleteSavedSearchMutation();
    return (
        <Dialog
            hidden={!props.displayModal}
            onDismiss={props.closer}
            dialogContentProps={{
                type: DialogType.largeHeader,
                title: 'Delete Saved Search',
                subText: 'Are you sure you want to delete this saved search?',
            }}
            modalProps={{
                isBlocking: true,
            }}
        >
            <DialogFooter>
                <DefaultButton
                    primary
                    text='Delete'
                    onClick={() => {
                        if (props.selectedSavedSearch) {
                            removeSavedSearch(props.selectedSavedSearch).then(() => {
                                if(props.onDeleteSavedSearch) props.onDeleteSavedSearch()
                            });
                        }
                        props.closer()
                    }}
                />
                <DefaultButton
                    text='Cancel'
                    onClick={props.closer}
                />
            </DialogFooter>
        </Dialog>
    );
}
export default SavedSearchDeleteModal;