import React from 'react';
interface ISegmentProps {
    hasButton?: boolean
    buttonText?: string    
    onButtonClick?: () => void;

}
const Segment: React.FC<ISegmentProps> = (props) => {
    return (
        <section className={"ac-section"}>
            <div className={"ac-segment"}>
                {props.children}
                {props.hasButton && <div className={"ac-segment-button-bottom ac-button"} onClick={props.onButtonClick} role={"button"} tabIndex={0}>{props.buttonText}</div>}
            </div>
        </section>
    );
}

export default Segment;