import React from "react";
import FacetPanel from "./FacetPanel";
import PriceFilters from "./PriceFilters";
import ManufacturerFilters from "./ManufacturerFilters";
import CategorySelection from "./CategorySelection";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import ProductFilters from "./ProductFilters";
import {Checkbox, Stack} from "@fluentui/react";
import {IAttribute, IFilledFilters, ISearchQueryResponse} from "../../models/Search";
import Divider from "../Common/Divider";
import VendorSelection from "./VendorSelection";
import BestPriceExclusion from "./BestPriceExclusion";
import CAN, { STOREFRONT_READ } from "../../permissions/ability";
import StorefrontSearchSelection from "./StorefrontSearchSelection";

interface ISearchCriteriaProps {
    inStock: boolean;
    onInStockChange: (inStock: boolean) => void;
    checkRealtime?: boolean;
    onCheckRealtimeChange: (checkRealtime: boolean | undefined) => void;
    segment?: string;
    onSegmentChange?: (segment: string | undefined) => void;
    category?: string;
    onCategoryChange: (segment: string | undefined, category: string | undefined) => void;
    mustFilter?: IAttribute[];
    boostFilter?: IAttribute[];
    excludeFilter?: IAttribute[];
    onFacetsChange: (must: IAttribute[], boost: IAttribute[], exclude: IAttribute[]) => void;
    manufacturers: string[];
    onManufacturerChange: (manufacturers: string[]) => void;
    results?: ISearchQueryResponse;
    lowPrice?: number;
    highPrice?: number;
    onPriceChange: (lowPrice?: number, highPrice?: number) => void;
    rangeFilters: IFilledFilters;
    onRangeFilterChange: (filters: IFilledFilters) => void;
    inStockVendors?: string[];
    inCatalogVendors?: string[];
    onVendorChange?: (inStock: string[], inCatalog: string[]) => void;
    inStorefront?: boolean;
    onInStorefrontChange: (inStorefront: boolean) => void;
    storefrontFilter?: string[];
    onStorefrontFilterChange: (storefrontFilter: string[]) => void;
}

const SearchCriteria: React.FC<ISearchCriteriaProps> = (props) => {
    const selectedOrg = useSelector((state: RootState) => state.settings.selectedOrganization);
    const canStorefront = CAN(STOREFRONT_READ);

    let priceFilters: JSX.Element | null = null;
    if (selectedOrg?.configurations) {
        selectedOrg.configurations
            .forEach((configuration) => {
                if (configuration.name === "hidePriceFilter") {
                    if (configuration.defaultValue === "false") {
                        priceFilters =
                            <div>
                                <Divider title="Price"/>
                                <PriceFilters lowPrice={props.lowPrice} highPrice={props.highPrice} onChange={props.onPriceChange}/>
                            </div>;
                    }
                }
            });
    }

    return (
        <>
            <div>
                <Divider title="Filters"/>
                <Stack tokens={{childrenGap: '1em'}}>
                    <Checkbox
                        label='In Stock'
                        checked={props.inStock}
                        onChange={(e,d) => props.onInStockChange(d ?? false)}
                    />
                    <VendorSelection inStock={props.inStockVendors ?? []} inCatalog={props.inCatalogVendors ?? []} onChange={props.onVendorChange} />
                    <BestPriceExclusion />
                </Stack>
            </div>
            {canStorefront &&
                <StorefrontSearchSelection
                    inStorefront={props.inStorefront}
                    onInStorefrontChange={props.onInStorefrontChange}
                    storefrontFilter={props.storefrontFilter}
                    onStorefrontFilterChange={props.onStorefrontFilterChange}
                    />
            }
            {priceFilters}
            <CategorySelection
                category={props.category}
                onCategoryChange={props.onCategoryChange}
                segment={props.segment}
                onSegmentChange={props.onSegmentChange}
                results={props.results}
            />
            <ManufacturerFilters
                results={props.results}
                manufacturers={props.manufacturers}
                onChange={props.onManufacturerChange}
            />
            <FacetPanel
                attributeType="facet"
                mustFilter={props.mustFilter}
                boostFilter={props.boostFilter}
                excludeFilter={props.excludeFilter}
                onUpdate={props.onFacetsChange}
                results={props.results}
            />
            <ProductFilters
                filters={props.results?.metadata?.filters ?? {}}
                searchFilters={props.rangeFilters}
                onChange={props.onRangeFilterChange}
            />
        </>
    );
}

export default SearchCriteria;