import React from 'react';
import { useGetStorefrontOrdersQuery, useGetStorefrontsQuery } from '../../../store/Api';
import { IColumn, ShimmeredDetailsList } from '@fluentui/react';
import { ButtonColumnGenerator, ColumnGenerator, CurrencyColumnGenerator } from '../../../logic/Columns/ColumnGenerator';
import { IStorefrontOrder } from '../../../models/Storefront';
import StorefrontOrderPanel from './StorefrontOrderPanel';

const StorefrontOrdersTable: React.FC = () => {
    const orders = useGetStorefrontOrdersQuery();
    const storefronts = useGetStorefrontsQuery();
    const [selectedOrder, setSelectedOrder] = React.useState<IStorefrontOrder | undefined>(undefined);

    const columns: IColumn[] = [
        ColumnGenerator('Storefront', 'storefront', 100, undefined, (item:IStorefrontOrder) => <>{storefronts.data?.items?.find((s) => s.id === item.storefront)?.name ?? 'UNKOWN'}</>),
        ColumnGenerator('Email Address', 'emailAddress', 200, 200),
        ColumnGenerator('Order Date', 'createDate', 100, 150, (item:IStorefrontOrder) => <>{new Date(item.createDate).toLocaleString()}</>),
        ColumnGenerator('Order Number', 'orderNumber', 100, 100),
        CurrencyColumnGenerator('Total', 'total', 100, 100),
        ColumnGenerator('Products', 'productCount', 100, 100),
        ColumnGenerator('Status', 'status', 100, 100),
        ButtonColumnGenerator((item: IStorefrontOrder) => setSelectedOrder(item)),
    ]

    return (<>
        <ShimmeredDetailsList
            enableShimmer={orders.isLoading || storefronts.isLoading}
            items={orders.data?.items ?? []}
            columns={columns}
            />
        <StorefrontOrderPanel
            order={selectedOrder}
            onDismiss={() => setSelectedOrder(undefined)}
            />
    </>
    );
}

export default StorefrontOrdersTable;