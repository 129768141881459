import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import {setEffectivePermissions, setSelectedOrganization} from "../../store/settingsSlice";
import {SettingsDAL} from "../../dal/SettingsDAL";
import {useCreateSearchSessionMutation, useLazyGetOrganizationsQuery} from "../../store/Api";

const PermissionsHandler: React.FC = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const organization = useSelector((state: RootState) => state.settings.selectedOrganization?.id);
    const dispatch = useDispatch();
    const [searchSession] = useCreateSearchSessionMutation();
    const [getOrganizations] = useLazyGetOrganizationsQuery();
    
    React.useEffect(() => {
        const fetchPermissions = async () => {
            if (auth.isAuthed) {
                const dal = new SettingsDAL(auth, organization);
                const results = await dal.getPermissions();
                dispatch(setEffectivePermissions(results?.permissions));
                if(!auth.searchSession){
                    searchSession(undefined)
                }
            }
        }
        fetchPermissions()
            .catch(console.error)
    }, [auth]);

    React.useEffect(() => {
        console.log('PermissionsHandler', auth, organization)
        if (auth.isAuthed) {
            getOrganizations()
            .then((response) => {
                if (response.data) {
                    if (organization) {
                        const org = response.data.find(org => org.id === organization);
                        if (org) {
                            dispatch(setSelectedOrganization(org));
                        }
                    } else {
                        dispatch(setSelectedOrganization(response.data[0]));
                    }
                }
            })
        }
    }, [auth, organization])


    return <></>;
}

export default PermissionsHandler;