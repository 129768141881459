import React, {useEffect, useState} from 'react';
import {IWorkspaceItem} from "../../models/Workspace";
import {useGetWorkspaceQuery, usePutWorkspaceItemDetailsMutation} from "../../store/Api";
import {Checkbox} from "@fluentui/react";

interface ITaxableSelectionProps {
    item: IWorkspaceItem;
}

const TaxableSelection: React.FC<ITaxableSelectionProps> = (props) => {
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    const workspace = useGetWorkspaceQuery(props.item.workspace);
    const [defaultChecked, setDefaultChecked] = useState<boolean>();
    const [checked, setChecked] = useState<boolean>();

    useEffect(() => {
        setChecked(props.item.details?.isTaxable);
    }, [props.item.details.isTaxable])
    
    const getLabel = () => {
        if(checked == null && defaultChecked != null) {
            return '(From Default)';
        }
        return '';
    }
    
    useEffect(() => {
        setDefaultChecked(workspace.data?.defaultTaxable);
    }, [workspace.data?.defaultTaxable])
    return (<><Checkbox
        label={getLabel()}
        checked={(checked ?? defaultChecked) === true}
        onChange={(e,d) => {
        if (d != undefined) {
            detailsChange({
                workspace: props.item.workspace,
                product: props.item.id,
                details: {
                    ...props.item.details,
                    isTaxable: d
                }
            })
        }
    }}
    /></>)
}

export default TaxableSelection;