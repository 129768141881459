import React, {useEffect, useState} from "react";
import ResultsDisplay from "../components/Search/ResultsDisplay";
import {RootState} from "../store/rootReducer";
import {useDispatch, useSelector} from "react-redux";
import SearchCriteria from "../components/Search/SearchCriteria";
import SelectedFacetDisplay from "../components/Search/SelectedFacetDisplay";
import {
    clearSearchCriteria,
    updateDesiredQuantity,
    updateSearchCriteria,
    updateSelectedSaveSearchId
} from "../store/searchSlice";
import Paginator from "../components/Common/Paginator";
import {
    Callout,
    CommandBar, ContextualMenuItemType,
    ICommandBarItemProps,
    Icon, IconButton,
    mergeStyleSets,
    Panel,
    PrimaryButton,
    Spinner,
    Text, TooltipHost
} from '@fluentui/react'
import CustomizationModal from "../components/ColumnCustomization/CustomizationModal";
import {
    useGetCatalogsQuery,
    useGetInstalledExtensionsQuery,
    useGetOrganizationsQuery,
    useGetRecentWorkspacesQuery, 
    useGetSavedSearchQuery,
    useGetSearchResultsQuery,
    useGetTopSavedSearchesQuery,
    useGetUserSettingsQuery,
    useLazyGetWorkspaceQuery,
    usePutWorkspaceMutation,
    useRunRealtimeExtensionMutation,
    useUpdateProductVisitMutation
} from "../store/Api";
import {IAttribute, IFilledFilters, IFilter, ISavedSearch, ISearchRequest} from "../models/Search";
import * as _ from 'lodash';
import {cloneDeep} from 'lodash';
import DetailsModal from "../components/DetailsModal/DetailsModal";
import CAN, {EDIT_ITEM, SAVED_SEARCH_ADMIN} from "../permissions/ability";
import QuickSearch from "../components/Search/QuickSearch";
import SelectedDisplay from "../components/Search/SelectedDisplay";
import NewProductModal from "../components/DetailsModal/NewProductModal";
import ProductIdModal from "../components/DetailsModal/ProductIdModal";
import {IsMutationSuccess} from "../logic/MutationTypeChecker";
import {IRealTimeExtensionResponse} from "../models/Extension";
import {useId} from "@fluentui/react-hooks";
import SearchSettingsSelection from "../components/Search/SearchSettingsSelection";
import SplitKeywordDisplay from "../components/Search/SplitKeywordDisplay";
import WorkspaceContactModal from "../components/Workspace/WorkspaceContactModal";
import SavedSearchSelectorPanel from "../components/Search/SavedSearchSelectorPanel";
import SaveSearchPanel from "../components/Search/SaveSearchPanel";
import ClearSearchDialog from "../components/Search/ClearSearchDialog";

interface ISearchRequestOverride {
    newKeywords?: string;
    keywords?: string;
    page?: number;
    pageSize?: number;
    inStock?: boolean;
    lowPrice?: number;
    highPrice?: number;
    manufacturer?: string[];
    vendor?: string[];
    must?: IAttribute[];
    boost?: IAttribute[];
    exclude?: IAttribute[];
    subCategory?: string;
    category?: string;
    segment?: string;
    checkRealtimeSelection?: boolean;
    filters?: IFilter[];
    productLines?: string[];
    tags?: string[];
    userTags?: string[];
    inStockVendors?: string[];
    inCatalogVendors?: string[];
    name?: string;
    sortField?: string;
    inStorefront?: boolean;
    storefrontFilter?: string[];
}

const SearchPage: React.FC = () => {
    const dispatch = useDispatch();
    const [keywords, setKeywords] = useState<string | undefined>();
    const [page, setPage] = useState<number | undefined>();
    const [pageSize, setPageSize] = useState<number | undefined>();
    const [inStock, setInStock] = useState<boolean>(false);
    const [lowPrice, setLowPrice] = useState<number | undefined>();
    const [highPrice, setHighPrice] = useState<number | undefined>();
    const [manufacturer, setManufacturer] = useState<string[] | undefined>();
    const [tags, setTags] = useState<string[] | undefined>();
    const [userTags, setUserTags] = useState<string[] | undefined>();
    const [productLines, setProductLines] = useState<string[] | undefined>();
    const [vendor, setVendor] = useState<string[] | undefined>();
    const [mustFilter, setMustFilter] = useState<IAttribute[] | undefined>();
    const [boostFilter, setBoostFilter] = useState<IAttribute[] | undefined>();
    const [excludeFilter, setExcludeFilter] = useState<IAttribute[] | undefined>();
    const [categoryFilter, setCategoryFilter] = useState<string | undefined>();
    const [subCategoryFilter, setSubCategoryFilter] = useState<string | undefined>();
    const [segmentFilter, setSegmentFilter] = useState<string | undefined>();
    const [checkRealtimeSelection, setCheckRealtimeSelection] = useState<boolean | undefined>();
    const [filters, setFilters] = useState<IFilledFilters>({});
    const [hasRunRealtime, setHasRunRealtime] = useState(false);
    const [realtimeErrors, setRealtimeErrors] = useState<{[key:string]: string}>({});
    const [refreshingVendors, setRefreshingVendors] = useState<{[key:string]: string | undefined}>({});
    
    const criteria = useSelector((state: RootState) => state.search.criteria);
    const catalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const desiredQuantity = useSelector((state: RootState) => state.search.desiredQuantity);
    const selectedSavedSearchId = useSelector((state: RootState) => state.search.selectedSaveSearchId);
    const catalogs = useGetCatalogsQuery();
    const results = useGetSearchResultsQuery({catalog: catalog?.name, criteria});
    const extensions = useGetInstalledExtensionsQuery();
    const [runRealtime] = useRunRealtimeExtensionMutation();
    const [updateProductVisit] = useUpdateProductVisitMutation();
    const [selectedProduct, setSelectedProduct] = useState<number | undefined>();
    const [quicksearchProduct, setQuicksearchProduct] = useState<string | undefined>();
    const [sortVendor, setSortVendor] = useState<string | undefined>();
    const [sortDirection, setSortDirection] = useState<string | undefined>();
    const [inStockVendors, setInStockVendors] = useState<string[] | undefined>();
    const [inCatalogVendors, setInCatalogVendors] = useState<string[] | undefined>();
    const [newProduct, setNewProduct] = useState(false);
    const [contactModalOpen, setContactModalOpen]= useState<boolean>(false);
    const globalWorkspace = useSelector((state: RootState) => state.settings.selectedWorkspaceId);
    const selectedOrganization = useSelector((state: RootState) => state.settings.selectedOrganization);
    const [updateWorkspace] = usePutWorkspaceMutation();
    const [selectedContact, setSelectedContact] = useState<string>()
    const [getWorkspace] = useLazyGetWorkspaceQuery();
    const [nameSearch, setNameSearch] = useState<string | undefined>();
    const getOrganizations = useGetOrganizationsQuery();
    const errorIconId = useId('error-icon');
    const [showErrorCallout, setShowErrorCallout] = useState(false);
    const recentWorkspaces = useGetRecentWorkspacesQuery()
    const [selectedWorkspace, setSelectedWorkspace] = useState<string>()
    const [allKeywords, setAllKeywords] = useState<boolean | undefined>(false);
    const [newKeywords, setNewKeywords] = useState<string | undefined>();
    const [splitKeywords, setSplitKeywords] = useState<string[] | undefined>();
    const [splitQuotedKeywords, setSplitQuotedKeywords] = useState<string[] | undefined>();
    const [inStorefront, setInStorefront] = useState<boolean | undefined>();
    const [storefrontFilter, setStorefrontFilter] = useState<string[] | undefined>();
    const [savedSearchesModalOpen, setSavedSearchesModalOpen] = useState(false);
    const [savedSearchDetailsModalOpen, setSavedSearchDetailsModalOpen] = useState(false);
    const [topSavedSearchEntries,setTopSavedSearchEntries] = useState<ISavedSearch[] | undefined>([]);
    const [selectedSavedSearch, setSelectedSavedSearch] = useState<ISavedSearch | undefined>();
    const [displayClearCriteriaModal, setDisplayClearCriteriaModal] = useState<boolean>(false);
    const getSavedSearch = useGetSavedSearchQuery({id: selectedSavedSearchId ?? ""}, {skip: (!selectedSavedSearchId || selectedSavedSearchId === '')})
    const userSettings = useGetUserSettingsQuery();
    const topSavedSearches = useGetTopSavedSearchesQuery();

    const setDesiredQuantity = (quantity?: number) => {
        dispatch(updateDesiredQuantity(quantity));
    }

    useEffect(() => {
        setTopSavedSearchEntries(topSavedSearches.data);
    }, [topSavedSearches]);

    useEffect(() => {
        if(getSavedSearch.data){
            console.log('settings saved search data!')
            setSelectedSavedSearch(getSavedSearch.data);
        }
    }, [getSavedSearch.data]);

    useEffect(() => {
        if(selectedSavedSearch?.criteria){
            dispatch(updateSearchCriteria({...selectedSavedSearch.criteria}));
        }
    }, [selectedSavedSearch]);

    useEffect(() => {
        if((!globalWorkspace || globalWorkspace == '' || globalWorkspace === 'last') && recentWorkspaces.data && recentWorkspaces.data.length > 0) {
            setSelectedWorkspace(recentWorkspaces.data?.[0]?.id)}
        }, [globalWorkspace, recentWorkspaces]);
    
    const runAllRealtime = async () => {
        if (!hasRunRealtime && !results.isFetching && !results.isLoading && extensions.data && results.data && results.data.products.length > 0) {
            const promises = [];
            for (const ext of extensions.data) {
                if (ext.extensionType === 'realtime' && ext.enabled) {
                    // new guid for runId
                    const runId = _.uniqueId('runId_')
                    // Add to refreshing vendors
                    setRefreshingVendors((v) => {
                        v[ext.vendor] = runId;
                        return v;
                    });
                    promises.push(
                        runRealtime({
                            extension: ext,
                            products: results.data.products.map(p => ({catalog: p.catalog, id: p.id, quantity: desiredQuantity}))
                        }).then((result) => {
                            if (refreshingVendors[ext.vendor] !== runId) return;
                            setRefreshingVendors((v) => {
                                v[ext.vendor] = undefined;
                                return v;
                            })
                            if (IsMutationSuccess<IRealTimeExtensionResponse>(result)) {
                                if (result.data?.success) {
                                    setRealtimeErrors((e) => {
                                        const newErrors = {...e};
                                        delete newErrors[ext.nickname ?? ext.name];
                                        return newErrors;
                                    });
                                    return;
                                }
                                setRealtimeErrors({...realtimeErrors, [ext.nickname ?? ext.name]: result.data?.error ?? 'Unknown Error'});
                                setShowErrorCallout(true);
                                return;
                            }
                            setRealtimeErrors({...realtimeErrors, [ext.nickname ?? ext.name]: 'API Call Failed'});
                            setShowErrorCallout(true);
                        })
                    );
                }
            }
            await Promise.all(promises);
        } else {
            setSelectedWorkspace(globalWorkspace)
        }
    }

    useEffect(() => {
        if(selectedWorkspace){
            getWorkspace(selectedWorkspace).then((w) => {
                if(w.data?.contactId) {
                    setSelectedContact(w.data.contactId)
                }
            })
        }
    }, [selectedWorkspace])

    useEffect(() => {
        if (!hasRunRealtime && !results.isFetching && !results.isLoading && extensions.data && results.data) {
            runAllRealtime().then(() => {
                setHasRunRealtime(true);
            });
        }
    }, [hasRunRealtime, extensions.data, results.isFetching, results.isLoading])

    // When desired quantity changes, run realtime again
    useEffect(() => {
        setHasRunRealtime(false);
    }, [desiredQuantity])

    useEffect(() => {
        setKeywords(criteria.keywords);
        setNewKeywords('');
        if (criteria.keywords) {
            const quotedKeywords = criteria.keywords.match(/"([^"]*)"/g);
            const splitKeywords = criteria.keywords.replace(/"([^"]*)"/g, '').split(' ');
            setSplitKeywords(splitKeywords.filter((k) => k !== ''));
            setSplitQuotedKeywords(quotedKeywords || []); // Add null check and provide a default value
        }
        setPage(criteria.page);
        setPageSize(criteria.pageSize);
        setInStock(criteria.inStock);
        setLowPrice(criteria.lowPrice);
        setHighPrice(criteria.highPrice);
        setManufacturer(criteria.manufacturer);
        setVendor(criteria.vendor);
        setMustFilter(criteria.must);
        setBoostFilter(criteria.boost);
        setExcludeFilter(criteria.exclude);
        setCategoryFilter(criteria.category);
        setSegmentFilter(criteria.segment);
        setCheckRealtimeSelection(criteria.checkRealtimeSelection);
        setTags(criteria.tags);
        setUserTags(criteria.userTags);
        setProductLines(criteria.productLines);
        setSortVendor(criteria.sortVendor);
        setSortDirection(criteria.sortDirection);
        setInStockVendors(criteria.inStockVendors);
        setInCatalogVendors(criteria.inCatalogVendors);
        setNameSearch(criteria.name);
        setAllKeywords(criteria.allKeywords);
        const filledFilters: IFilledFilters = {};
        if(criteria.filters) {
            for (const filter of criteria.filters) {
                if ((filter.max != undefined && filter.max !== 0) || (filter.min != undefined && filter.min !== 0))
                    filledFilters[filter.name] = {min: filter.min, max: filter.max};
            }
        }
        setFilters(filledFilters);
        setHasRunRealtime(false);
        setInStorefront(criteria.inStorefront);
        setStorefrontFilter(criteria.storefrontFilter);
    }, [criteria]);
    
    const updateCompany = (companyName: string, companyId: string, contactId: string) => {
        if(selectedWorkspace) {
            updateWorkspace({companyName: companyName, contactId: contactId, companyId: companyId, id: selectedWorkspace, documentIsCurrent: false})
            setSelectedContact(contactId)
        }
    }
    const canUseSavedSearch = CAN(SAVED_SEARCH_ADMIN);
    const search = (criteriaOverride?: ISearchRequestOverride | undefined) => {
        const kw = userSettings.data?.keywordToTags
            ? (criteriaOverride?.keywords ?? keywords) + ' ' + (criteriaOverride?.newKeywords ?? newKeywords)
            : criteriaOverride?.keywords ?? keywords;
        console.log('keywords', kw, criteriaOverride?.keywords, criteriaOverride?.newKeywords, keywords, newKeywords);
        const newCriteria: ISearchRequest = {
            keywords: kw,
            page: criteriaOverride?.page ?? page,
            pageSize: criteriaOverride?.pageSize ?? pageSize,
            inStock: criteriaOverride?.inStock ?? inStock,
            lowPrice: criteriaOverride?.lowPrice ?? lowPrice,
            highPrice: criteriaOverride?.highPrice ?? highPrice,
            manufacturer: criteriaOverride?.manufacturer ?? manufacturer ?? [],
            vendor: criteriaOverride?.vendor ?? vendor ?? [],
            must: criteriaOverride?.must ?? mustFilter ?? [],
            boost: criteriaOverride?.boost ?? boostFilter ?? [],
            exclude: criteriaOverride?.exclude ?? excludeFilter ?? [],
            subCategory: criteriaOverride?.subCategory ?? subCategoryFilter,
            category: criteriaOverride?.category ?? categoryFilter,
            segment: criteriaOverride?.segment ?? segmentFilter,
            userTags: criteriaOverride?.userTags ?? userTags,
            tags: criteriaOverride?.tags ?? tags,
            productLines: criteriaOverride?.productLines ?? productLines,
            sortVendor: sortVendor,
            sortDirection: sortDirection,
            checkRealtimeSelection,
            inCatalogVendors: criteriaOverride?.inCatalogVendors ?? inCatalogVendors,
            inStockVendors: criteriaOverride?.inStockVendors ?? inStockVendors,
            filters: Object.entries(filters).map(([key, value]) => ({
                name: key,
                min: value.min,
                max: value.max,
            })),
            name: criteriaOverride?.name ?? nameSearch,
            allKeywords: allKeywords,
            inStorefront: criteriaOverride?.inStorefront ?? inStorefront,
            storefrontFilter: criteriaOverride?.storefrontFilter ?? storefrontFilter,
        }
        // Split the keywords into quoted strings, and space seperated strings
        if (newCriteria.keywords) {
            const quotedKeywords = newCriteria.keywords.match(/"([^"]*)"/g);
            const splitKeywords = newCriteria.keywords.replace(/"([^"]*)"/g, '').split(' ');
            setSplitKeywords(splitKeywords.filter((k) => k !== ''));
            setSplitQuotedKeywords(quotedKeywords || []); // Add null check and provide a default value
            setNewKeywords('');
        }
        if (_.isEqual(newCriteria, criteria)) results.refetch();
        else dispatch(updateSearchCriteria(newCriteria));
        setHasRunRealtime(false);
    }

    const startCount = (((page ?? 1) - 1) * (pageSize ?? 50)) + 1;
    const endCount = (startCount + (results.data?.products.length ?? 0) - 1);

    const [menuOpen, setMenuOpen] = useState(true);
    const [editColumnsModalOpen, setEditColumnsModalOpen] = useState(false);
    const getSavedSearchmenuItems = () => {
        if (!topSavedSearchEntries || topSavedSearchEntries?.length === 0){
            return [{key: 'empty-saved-search-result', text: 'No Saved Searches', disabled: true}]
        }
         return (topSavedSearchEntries?.map((s) => ({
        key: s.id ?? "",
        text: s.name,
        onClick: () => {
            if(s.id) {
                applySavedSearch(s.id)
            }
        }})))
    };
    
    const getSavedSearchCommandBarItem = () => {
        if(!canUseSavedSearch){
            return {}
        }

        return         {
            key:'savedSearches',
            subMenuProps:{
                items: [
                    {
                        key: "sectionTitle",
                        text: "Top Saved Searches",
                        itemType: ContextualMenuItemType.Header
                    },
                    ...getSavedSearchmenuItems(),
                    {
                        key: "dividerSavedSearch",
                        itemType: ContextualMenuItemType.Divider
                    },
                    {
                        key: 'more',
                        text: 'More...',
                        onClick: () => {
                            setSavedSearchesModalOpen(true);
                        }
                    },
                    {
                        key: "divider2SavedSearch",
                        itemType: ContextualMenuItemType.Divider
                    },
                    {
                        key: 'save',
                        text: 'Save',
                        iconProps: {iconName: 'Save'},
                        onClick: () => {
                            setSavedSearchDetailsModalOpen(true);
                        }
                    },

                ]
            },
            iconProps: {iconName: 'SearchBookmark'},
            text: 'Saved Searches',
            onClick: () => setSavedSearchesModalOpen(true)
        }
    }
    
    const getCommands = () => {
        if(canUseSavedSearch){
            return ([criteriaCommandBarItem, savedSearchCommandBarItem, customizeCommandBarItem, contactCommandBarItem])
        }
        else{
            return ([criteriaCommandBarItem, customizeCommandBarItem, contactCommandBarItem])
        }
    }
    
    const criteriaCommandBarItem: ICommandBarItemProps =
    {
        key: 'criteria',
            text: 'Search Criteria',
        subMenuProps:{
        items:[
            {
                key: 'clearCriteria',
                text: 'Clear Search Criteria',
                iconProps: { iconName: 'ClearFilter' },
                onClick: () => {
                    setDisplayClearCriteriaModal(true);
                }
            }
        ]
    },
        split: true,
        iconProps: {iconName: 'Filter'},
        onClick: () => setMenuOpen(!menuOpen),

    }
    
    const savedSearchCommandBarItem: ICommandBarItemProps =         
    {
            key:'savedSearches',
            subMenuProps:{
                items: [
                    {
                        key: "sectionTitle",
                        text: "Top Saved Searches",
                        itemType: ContextualMenuItemType.Header
                    },
                    ...getSavedSearchmenuItems(),
                    {
                        key: "divider3SavedSearch",
                        itemType: ContextualMenuItemType.Divider
                    },
                    {
                        key: 'more',
                        text: 'More...',
                        onClick: () => {
                            setSavedSearchesModalOpen(true);
                        }
                    },
                    {
                        key: "divider4SavedSearch",
                        itemType: ContextualMenuItemType.Divider
                    },
                    {
                        key: 'saveEdit',
                        text: (selectedSavedSearchId ? 'Edit' : 'Save'),
                        iconProps: {iconName: (selectedSavedSearchId ? 'edit': 'Save')},
                        onClick: () => {
                            setSavedSearchDetailsModalOpen(true);
                        }
                    },

                ]
            },
            iconProps: {iconName: 'SearchBookmark'},
            text: 'Saved Searches',
            onClick: () => setSavedSearchesModalOpen(true)
    }
    
    const customizeCommandBarItem: ICommandBarItemProps =         
        {
            key: 'customize',
            text: 'Customize Columns',
            iconProps: {iconName: 'edit'},
            onClick: () => setEditColumnsModalOpen(true),
        }
    const contactCommandBarItem: ICommandBarItemProps =         
    {
        key: 'contact',
        text: `${!selectedContact ? 'Attach Contact' : 'Edit Contact'}`,
        iconProps:{ iconName: 'ContactLink'},
        onClick: () => {
            setContactModalOpen(true)
        }
    }
    

    const rightCommands: ICommandBarItemProps[] = [
        {
            key: 'status',
            text: hasRunRealtime ? '' : 'Updating Pricing',
            onRender: () => hasRunRealtime ? <></> : <Spinner label='Updating Pricing' labelPosition='right'/>
        }
    ];
    if (Object.keys(realtimeErrors).length > 0) {
        rightCommands.push({
            key: 'errors',
            id: errorIconId,
            onRenderIcon: () => <Icon
                iconName={'Warning'}
                style={{color: '#ff0000'}}
            />,
            iconOnly: true,
            onClick: () => setShowErrorCallout(true)
        });
    }
    rightCommands.push({
        key: 'page',
        text: results.data?.total ? `${startCount}-${endCount} of ${results.data.total}` : '',
    });
    const canEditItem = CAN(EDIT_ITEM);

    const getRightCommands = () => {
        const commands = rightCommands
        const org = selectedOrganization ?? (getOrganizations?.data?.length === 1 ? getOrganizations.data[0] : undefined)
        if (canEditItem && org?.configurations?.filter((a) => a.name === "canCreateNewItems" && a.defaultValue === "true")) {
            commands.push({
                key: 'more',
                text: '',
                iconProps: {iconName: 'MoreVertical'},
                iconOnly: true,
                onRenderIcon: () => null,
                subMenuProps: {
                    items: [
                        {
                            key: 'create',
                            text: 'Create New Item',
                            iconProps: {iconName: 'Add'},
                            onClick: () => {
                                setNewProduct(true);
                            }
                        }
                    ]
                }
            })
        }
        return commands;
    }

    const contentStyles = mergeStyleSets({
        root: {
            zIndex: '10 !important',
            marginTop: '48px',
        },
        main: {
            marginTop: '48px',
        },
        scrollableContent: {
            backgroundColor: '#e2e9cb',
        },
        navigation: {
            backgroundColor: '#e2e9cb',
        },
        footerInner: {
            backgroundColor: '#e2e9cb',
        },
    });
    
    const applySavedSearch = (savedSearchId: string) => {
        console.log('settings saved search Id: ', savedSearchId)
        dispatch(updateSelectedSaveSearchId(savedSearchId));
    }
    

    const header = () => (
        <div style={{flexGrow: 1, paddingLeft: '25px', background: 'rgb(226, 233, 203)'}}>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                {selectedSavedSearch?.name && 
                <TooltipHost content={`Saved search ${selectedSavedSearch.name} applied`}>
                    <IconButton iconProps={{iconName: 'SingleBookmarkSolid'}} style={{paddingRight: '15px'}} onClick={() => setSavedSearchDetailsModalOpen(true)}/>
                </TooltipHost>
                }
                <h2>Search Criteria</h2>

            </div>
        </div>
    )
    
    const clearEverything = () => {
        dispatch(clearSearchCriteria());
        dispatch(updateSelectedSaveSearchId(''));
        setSelectedSavedSearch({})
    }
    
    return (
        <div className='main-content' key={'search-main-content'}>
                <Panel
                    onRenderHeader={header}
                    headerText='Search Criteria'
                    isOpen={menuOpen}
                    onDismiss={() => setMenuOpen(false)}
                    closeButtonAriaLabel='Close'
                    isBlocking={false}
                    isHiddenOnDismiss={true}
                    isFooterAtBottom={true}
                    layerProps={{ className: 'search-panel' }}
                    styles={{
                        root: {
                            zIndex: 10,
                            marginTop: '48px',
                        },
                        commands:{
                            backgroundColor: 'rgb(226, 233, 203)'
                        },
                        scrollableContent: contentStyles.scrollableContent,
                        navigation: contentStyles.navigation,
                        footerInner: contentStyles.footerInner,
                    }}
                    onRenderFooterContent={() => {
                        return <div>
                            <PrimaryButton style={{ width: '100%' }} onClick={() => {setPage(1); search({page: 1})}} >
                                Apply
                            </PrimaryButton>
                        </div>
                    }}
                // focusTrapZoneProps={focusTrapZoneProps}
                >
                    <SearchSettingsSelection
                        onDesiredQuantityChange={setDesiredQuantity}
                        desiredQuantity={desiredQuantity}
                        allKeywords={allKeywords ?? false}
                        onAllKeywords={setAllKeywords}
                    />
                    <SearchCriteria
                        inStock={inStock}
                        onInStockChange={setInStock}
                        checkRealtime={checkRealtimeSelection}
                        onCheckRealtimeChange={setCheckRealtimeSelection}
                        category={categoryFilter}
                        onCategoryChange={(s,c) => {
                            setSegmentFilter(s);
                            setCategoryFilter(c);
                        }}
                        segment={segmentFilter}
                        onSegmentChange={setSegmentFilter}
                        mustFilter={mustFilter}
                        boostFilter={boostFilter}
                        excludeFilter={excludeFilter}
                        onFacetsChange={(m,b,e) => {
                            setMustFilter(m);
                            setBoostFilter(b);
                            setExcludeFilter(e);
                        }}
                        manufacturers={manufacturer ?? []}
                        onManufacturerChange={setManufacturer}
                        results={results.data}
                        lowPrice={lowPrice}
                        highPrice={highPrice}
                        onPriceChange={(l,h) => {
                            setLowPrice(l);
                            setHighPrice(h);
                        }}
                        rangeFilters={filters ?? {}}
                        onRangeFilterChange={setFilters}
                        inStockVendors={inStockVendors}
                        inCatalogVendors={inCatalogVendors}
                        onVendorChange={(inStock, inCatalog) => {
                            setInStockVendors(inStock);
                            setInCatalogVendors(inCatalog);
                        }}
                        storefrontFilter={storefrontFilter}
                        onStorefrontFilterChange={setStorefrontFilter}
                        inStorefront={inStorefront}
                        onInStorefrontChange={setInStorefront}
                    />
                </Panel>
                <div style={{ marginRight: menuOpen ? '340px' : '0' }} key={'searchbox-container'}>
                    <QuickSearch
                        catalogName={catalog?.name}
                        criteriaKeywords={userSettings.data?.keywordToTags ? newKeywords : criteria.keywords}
                        onKeywordChange={(k, searchNow) => {
                            if (userSettings.data?.keywordToTags) {
                                setNewKeywords(k);
                                if (searchNow) search({newKeywords: k, page:1});
                            } else {
                                setKeywords(k)
                                if (searchNow) search({keywords: k, page:1});
                            }
                        }}
                        onSearch={() => search({page:1})}
                        onCategorySelected={(segment, category) => {
                            setSegmentFilter(segment);
                            setCategoryFilter(category);
                            search({keywords: '', segment, category, page:1});
                        }}
                        onSubCategorySelected={(segment, category, subCategory) => {
                            setSegmentFilter(segment);
                            setCategoryFilter(category);
                            setSubCategoryFilter(subCategory);
                            search({keywords: '', segment, category, subCategory, page:1});
                        }}
                        onManufacturerSelected={(manufacturer) => {
                            setManufacturer([manufacturer]);
                            search({keywords: '', manufacturer: [manufacturer], page:1});
                        }}
                        onUserTagSelected={(userTag) => {
                            const currentUserTags = cloneDeep(userTags) ?? []
                            currentUserTags.push(userTag)
                            setUserTags(currentUserTags);
                            search({keywords: '', userTags: currentUserTags, page:1});
                        }}
                        onTagSelected={(tag) => {
                            const currentTags = cloneDeep(tags) ?? []
                            currentTags.push(tag)
                            setTags(currentTags);
                            search({keywords: '', tags: currentTags, page:1});
                        }}
                        onProductLineSelected={(productLine) => {
                            const currentProductLines = cloneDeep(productLines) ?? []
                            currentProductLines.push(productLine)
                            setProductLines(currentProductLines);
                            search({keywords: '', productLines: currentProductLines, page:1});
                        }}
                        onProductSelected={setQuicksearchProduct}
                    />
                    <CommandBar
                        items={getCommands()}
                        farItems={getRightCommands()}
                    />
                    <div key={'selectedFacet-container'}>
                        <SelectedFacetDisplay
                            mustFilter={mustFilter}
                            boostFilter={boostFilter}
                            excludeFilter={excludeFilter}
                            onFacetChange={(m,b,e) => {
                                setMustFilter(m);
                                setBoostFilter(b);
                                setExcludeFilter(e);
                                search({must:m, boost:b, exclude:e, page:1});
                            }}
                        />
                        <SelectedDisplay
                            prefix="Manufacturer"
                            selections={manufacturer ?? []}
                            onChange={(mf: React.SetStateAction<string[] | undefined>) => setManufacturer(mf)}
                        />
                        <SelectedDisplay
                            prefix='Name'
                            selections={nameSearch ? [nameSearch] : []}
                            onChange={(name: React.SetStateAction<string[] | undefined>) => {
                                setNameSearch(undefined);
                                search({name: ''});
                            }}
                        />
                        <SelectedDisplay
                            prefix="Product Lines"
                            selections={productLines ?? []}
                            onChange={(pl: React.SetStateAction<string[] | undefined>) => setProductLines(pl)}
                        />
                        <SelectedDisplay
                            prefix="Tags"
                            selections={tags ?? []}
                            onChange={(tags: React.SetStateAction<string[] | undefined>) => setTags(tags)}
                        />
                        <SelectedDisplay
                            prefix="My Tags"
                            selections={userTags ?? []}
                            onChange={(tags: React.SetStateAction<string[] | undefined>) => setUserTags(tags)}
                        />
                        {userSettings.data?.keywordToTags && <SplitKeywordDisplay
                            keywords={splitKeywords ?? []}
                            onChange={(nk) => {
                                const newKeywordsString = nk.join(' ') + ' ' + (splitQuotedKeywords ?? []).join(' ');
                                setKeywords(newKeywordsString);
                                search({keywords: newKeywordsString, page:1});
                            }}
                        />}
                        {userSettings.data?.keywordToTags && <SplitKeywordDisplay
                            keywords={splitQuotedKeywords ?? []}
                            quoted
                            onChange={(nk) => {
                                const newKeywordsString = (splitKeywords ?? []).join(' ') + ' ' + nk.join(' ');
                                setKeywords(newKeywordsString);
                                search({keywords: newKeywordsString, page:1});
                            }}
                        />}
                    </div>
                    <div className="search-results" style={{overflow: 'auto'}}>
                        <ResultsDisplay
                            // runningRealtime={!hasRunRealtime}
                            refreshingVendors={Object.entries(refreshingVendors).filter(([k,v]) => v != undefined).map(([k,v]) => k)}
                            onDetailsSelection={(p) => {
                                updateProductVisit({catalog: p.catalog, id: p.id})
                                setSelectedProduct(results.data?.products.findIndex(rp => rp.id == p.id))}}
                            onFilterChange={setFilters}
                        />
                    </div>
                    <Paginator
                        page={page ?? 1}
                        resultCount={results.data?.total ?? 0}
                        onChange={(p) => {
                            setPage(p);
                            search({page: p});
                        }}
                        pageSize={pageSize ?? 50}
                    />
                    {(selectedProduct != null && results.data?.products[selectedProduct] != null) && 
                        <DetailsModal 
                            product={results.data.products[selectedProduct]} 
                           refreshingVendors={!hasRunRealtime} open={true} 
                           onClose={() => setSelectedProduct(undefined)} 
                           preventEdit={!canEditItem}
                    />}
                    <NewProductModal open={newProduct} onClose={() => setNewProduct(false)}/>
                </div>
                <CustomizationModal
                    page='search'
                    isOpen={editColumnsModalOpen}
                    onClose={() => setEditColumnsModalOpen(false)}
                />
                <ClearSearchDialog 
                    displayModal={displayClearCriteriaModal} 
                    onClear={clearEverything} 
                    closer={() => {setDisplayClearCriteriaModal(false)}}
                />
                {savedSearchDetailsModalOpen && 
                <SaveSearchPanel 
                    closer={() => {
                        setSavedSearchDetailsModalOpen(false)}
                    }
                    onUpdate={(id) => {
                        dispatch(updateSelectedSaveSearchId(id))}
                    }
                    criteria={criteria}
                    savedSearch={selectedSavedSearch}
                />
                }
                <SavedSearchSelectorPanel 
                    displayModal={savedSearchesModalOpen} 
                    onSelect={applySavedSearch} 
                    closer={() => setSavedSearchesModalOpen(false)}
                    onClear={clearEverything}
                />
                {selectedWorkspace && <WorkspaceContactModal
                    selectedContact={selectedContact}
                    displayModal={contactModalOpen}
                    closer={() =>{ setContactModalOpen(false)}}
                    onChange={(name, contactId, companyId) => {updateCompany(name, companyId, contactId)}}
                />}
                {quicksearchProduct && (catalog || catalogs.data) &&
                    <ProductIdModal
                        catalog={catalog?.name ?? catalogs.data?.[0].name ?? ''}
                        productId={quicksearchProduct}
                        onDismiss={setQuicksearchProduct}
                    />
                }
                {showErrorCallout && <Callout
                    target={`#${errorIconId}`}
                    onDismiss={() => setShowErrorCallout(false)}
                    setInitialFocus
                    style={{padding: '1em', backgroundColor: '#f8d1d1'}}
                    styles={{
                        beak: { backgroundColor: '#f8d1d1' },
                    }}
                >
                    <Text as='h1' block variant={'xLarge'}>There was an error</Text>
                    {Object.entries(realtimeErrors).map(([key, value]) => {
                        return <Text key={key} as='p' block variant={'mediumPlus'}>The extension {key} returned: {value}</Text>
                    })}
                </Callout>}
            </div>
        );
}

export default SearchPage;