import React from "react";
import "./Cards.css";
import {Link, useHistory} from "react-router-dom";
import {DefaultButton, IColumn, SelectionMode, ShimmeredDetailsList} from "@fluentui/react";
import {IWorkspace} from "../../models/Workspace";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import {useGetRecentWorkspacesQuery, useSetSelectedWorkspaceMutation} from "../../store/Api";
import Card from "./Card";


const WorkspaceCard: React.FC = () => {
    const history = useHistory()
    const workspaceQuery = useGetRecentWorkspacesQuery();
    const [setSelectedWorkspace] = useSetSelectedWorkspaceMutation();
    const columns: IColumn[] = [
        ColumnGenerator('Workspace', 'name'),
        {
            key: 'Open',
            name: '',
            fieldName: 'Open',
            onRender: (ws: IWorkspace) => (
                <Link to='/workspace'><DefaultButton primary onClick={() => 
                {
                    setSelectedWorkspace(ws.id).then( ()=> {
                    history.push(`/workspace/${ws.id}`)}
                    )}
                }>Open</DefaultButton></Link>
            ),
            minWidth: 100,
            maxWidth: 100,
        }
    ]

    return (
        <Card title='Workspaces'>
            <ShimmeredDetailsList items={workspaceQuery.data ?? []} columns={columns} selectionMode={SelectionMode.none}
                         enableShimmer={workspaceQuery.isLoading} isHeaderVisible={false}/>
        </Card>
    )
}


export default WorkspaceCard;
