import React, {useEffect, useState} from 'react';
import {IWorkspaceItem} from "../../models/Workspace";
import {useGetTaxCodesQuery, useGetWorkspaceQuery, usePutWorkspaceItemDetailsMutation} from "../../store/Api";
import {Dropdown} from "@fluentui/react/lib/Dropdown";

interface ITaxCodeSelectionProps {
    item: IWorkspaceItem;
}

const TaxCodeSelection: React.FC<ITaxCodeSelectionProps> = (props) => {
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    const workspace = useGetWorkspaceQuery(props.item.workspace);
    const taxCodes = useGetTaxCodesQuery();
    const getTaxRateFromCode = (taxCode: string) => {
        return taxCodes.data?.filter(f => f.name == taxCode)?.[0]?.rate
    }
    const [selectedKey, setSelectedKey] = useState<string | undefined>();
    useEffect(() => {
        if(props.item.details?.taxCode){
            setSelectedKey(props.item.details?.taxCode)
        }
        else if(workspace.data?.defaultTaxCode){
            setSelectedKey(workspace.data?.defaultTaxCode)
        }
    }, [props.item.details?.taxCode, workspace.data?.defaultTaxCode])
    return (<Dropdown
        selectedKey={selectedKey}
    options={[...(taxCodes?.data?.map(taxCode => (
        {
            key: taxCode.name ?? '',
            text: `${taxCode.name ?? ''} (${taxCode.rate}%)`
        }
    )) ?? [])
]}
    onChange={(e,d) => {
        if (d) {
            detailsChange({
                workspace: props.item.workspace,
                product: props.item.id,
                details: {
                    ...props.item.details,
                    taxCode: (d.key ?? '') as string,
                    taxRate: getTaxRateFromCode(d.key.toString())
                }
            })
        }
    }}
    />)
}

export default TaxCodeSelection;