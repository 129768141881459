import React from 'react';
import {Separator} from '@fluentui/react';

interface IDividerProps {
    title: string;
}
const styles = {
    content: [{
          background: 'rgb(226, 233, 203)'
    }]
  };
const Divider: React.FC<IDividerProps> = (props) => {
    return (                
    <Separator className="separator" styles={styles}>
        <h4 className='ui header'>
            {props.title}
        </h4>
    </Separator>
    );
}

export default Divider;