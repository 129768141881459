import React, {useEffect} from "react";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    IStackTokens, 
    Label, 
    Spinner,
    Stack
} from "@fluentui/react";

import {
    useGetColumnSetQuery, 
    usePutCustomColumnSetMutation,
} from "../../store/Api";
import {MODAL_STYLE} from "../../Constants";
import {Dropdown, IDropdownOption, IDropdownStyles} from "@fluentui/react/lib/Dropdown";
import {DetailsList, IColumnDragDropDetails} from "@fluentui/react/lib/DetailsList";
import {PrimaryButton} from "@fluentui/react/lib/Button";
import {IProductColumnBase} from "../../logic/Columns/ProductColumns";


interface ICustomColumManagementProps {
    columnSetName: string;
    defaultColumns: string[];
    dropdownOptions: IDropdownOption[];
    testItems: object[];
    onSave?: (columns: string[]) => void;
    columnMetadata?: {[columnName: string]: IProductColumnBase}
}
const CustomColumnEdit: React.FC<ICustomColumManagementProps> = (props) => {
    const getColumns = useGetColumnSetQuery({columnType: props.columnSetName}, {skip: props.columnSetName == undefined || props.columnSetName == ''});
    const [updateColumns] = usePutCustomColumnSetMutation();
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
    const [testColumns, setTestColumns] = React.useState<IColumn[]>([]);
    
    useEffect(() => {
        if(getColumns.isLoading){
            return
        }
        
        if(!getColumns.data?.columns || getColumns.data.columns.length == 0){
            setSelectedKeys(props.defaultColumns)

            return
        }
        setSelectedKeys(getColumns.data.columns)

    }, [getColumns]);

    useEffect(() => {
        setTestColumns(selectedKeys.map(a => props?.columnMetadata?.[a]?.basicColumn ??
            {
                key: a.toLowerCase(),
                name: a,
                minWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                isPadded: true,
                isMultiline: true
            }
        ))
    }, [selectedKeys]);

    const onColumnDrop = (details: IColumnDragDropDetails) => {
        const draggedItems: IColumn = testColumns[details.draggedIndex];
        const newColumns: IColumn[] = [...testColumns];
        newColumns.splice(details.draggedIndex, 1);
        newColumns.splice(details.targetIndex, 0, draggedItems);
        setTestColumns(newColumns);
        setSelectedKeys(newColumns.map(newColumn => newColumn.fieldName ?? newColumn.key));
    }
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {width: 300,},
    };
    const onChange = (item: IDropdownOption | undefined): void => {
        if (item) {
            setSelectedKeys(
                item.selected
                    ? [...selectedKeys, item.key as string]
                    : selectedKeys.filter(key => key !== item.key)
            );
        } else {
            setSelectedKeys(props.defaultColumns);
        }
    }
    const onResetColumns = () => {

        setTestColumns(props.defaultColumns.map(a => props?.columnMetadata?.[a]?.basicColumn ?? 
                {
                    key: a.toLowerCase(),
                    name: a,
                    minWidth: 100,
                    isRowHeader: true,
                    isResizable: true,
                    isSorted: false,
                    isSortedDescending: false,
                    isPadded: true,
                    isMultiline: true
                }
        ))

        setSelectedKeys(props.defaultColumns);
    }
    const commands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Reset Columns',
            iconProps: { iconName: 'Refresh' },
            onClick: () => {onResetColumns();}
        }
    ];
    function save() {
        updateColumns({columnSet: {
            columnType: props.columnSetName,
            columns: selectedKeys,
            id: getColumns?.data?.id ?? ""
        }})
        if(props.onSave) {
            props.onSave(selectedKeys)
        }
    }
    const stackTokens: IStackTokens = {childrenGap: 10};
    return (
        <div className="main-content">
            <div className={MODAL_STYLE.header}>
                <span>Customize {props.columnSetName} Columns</span>
            </div>
            {getColumns.isLoading &&
                <div className={MODAL_STYLE.body}>
                    <Spinner label="Loading..." ariaLive="assertive" style={{margin: '5em'}}/>
                </div>
            }

            {!getColumns.isLoading &&
                <div className={MODAL_STYLE.body}>
                    <div>
                        <CommandBar items={commands} />
                        <Dropdown
                            placeholder="Select options"
                            label="Select Columns"
                            selectedKeys={selectedKeys}
                            multiSelect
                            options={props.dropdownOptions}
                            styles={dropdownStyles}
                            onChange={(_e, selectedItem) => onChange(selectedItem)}
                        />
                        <Label style={{marginTop: '1em'}}>Arrange Columns</Label>
                        <DetailsList
                            setKey="items"
                            items={props.testItems}
                            columns={testColumns}
                            columnReorderOptions={{
                                frozenColumnCountFromStart: 0,
                                frozenColumnCountFromEnd: 0,
                                onColumnDrop
                            }}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />
                    </div>
                    <div className={MODAL_STYLE.body}>
                        <Stack horizontal tokens={stackTokens} style={{paddingTop: '10px'}} horizontalAlign='end'>
                            <PrimaryButton text="Save" onClick={() => save()}/>
                        </Stack>
                    </div>
                </div>
            }
        </div>
    );
}
export default CustomColumnEdit;
