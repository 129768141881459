import React from 'react';
import { Dropdown, IDropdownOption, Icon, Label, TooltipHost } from '@fluentui/react';
import { useGetStorefrontsQuery } from '../../store/Api';

interface IStorefrontSelectionProps {
    storefrontFilter?: string[];
    onStorefrontFilterChange: (storefrontFilter: string[]) => void;
    tooltipText?: string
}

const StorefrontSelection: React.FC<IStorefrontSelectionProps> = (props) => {
    const storefronts = useGetStorefrontsQuery();
    
    const onStorefrontFilterChange = (e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption | undefined) => {
        if (!item) return;
        if (item.selected) {
            props.onStorefrontFilterChange([...(props.storefrontFilter ?? []), item.key as string]);
        } else {
            props.onStorefrontFilterChange((props.storefrontFilter ?? []).filter((value) => value !== item.key));
        }
    }

    const preventClick = (e: React.MouseEvent) => {
        e.preventDefault();
    }

    const renderDropdownLabel = () => {
        return <Label>
            Storefront
            <TooltipHost content={props.tooltipText ?? 'Filters to products listed on any of the selected storefronts.'}>
                <Icon iconName='info' style={{ paddingLeft: '5px', fontSize: '80%' }} />
            </TooltipHost>
        </Label>
    }

    return (
        <div>
            <Dropdown
                placeholder='Select Storefront'
                multiSelect
                styles={{ root: { marginBottom: '1em' }}}
                options={storefronts.data?.items?.map((storefront) => ({ key: storefront.id ?? '', text: storefront.name ?? '' })) ?? []}
                selectedKeys={props.storefrontFilter}
                onRenderLabel={renderDropdownLabel}
                onChange={onStorefrontFilterChange}
            />
        </div>
    );
}

export default StorefrontSelection;