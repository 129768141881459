import React from 'react';
import { useGetUserChatQuery } from '../../store/Api';
import { Stack } from '@fluentui/react';
import ChatMessage from './ChatMessage';

interface IChatMessagesProps {
    chatId: string;
}

const ChatMessages: React.FC<IChatMessagesProps> = (props) => {
    const chat = useGetUserChatQuery(props.chatId, { skip: !props.chatId });

    React.useEffect(() => {
        const interval = setInterval(() => {
            chat.refetch();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Stack tokens={{childrenGap: '1em'}}>
            {chat.data?.messages.map((m,i) => (
                <ChatMessage
                    key={i.toString()}
                    message={m.message}
                    messageSource={m.source.toUpperCase() === 'USER' ? 'USER' : 'SYSTEM'}
                    timestamp={m.timestamp}
                />
            ))}
            {chat.data?.status === 'Processing' && <ChatMessage messageSource='SYSTEM' replying />}
        </Stack>
    )
}

export default ChatMessages;