import {IApiKey} from "../../models/ApiKey";
import React from "react";
import {DefaultButton, Label, Panel, PrimaryButton, Stack} from "@fluentui/react";
import CAN, {API_KEYS_SELF} from "../../permissions/ability";
import {
    useDeleteApiKeyMutation,
    useDisableApiKeyMutation,
    useEnableApiKeyMutation
} from "../../store/Api";

interface IApiKeyDetailsDialogProps {
    apiKey?: IApiKey;
    onClose: () => void;
}

const ApiKeyDetailsDialog: React.FC<IApiKeyDetailsDialogProps> = (props) => {
    const canAccess = CAN(API_KEYS_SELF);
    const [disableApiKey] = useDisableApiKeyMutation();
    const [enableApiKey] = useEnableApiKeyMutation();
    const [deleteApiKey] = useDeleteApiKeyMutation();

    return (
        <Panel
            onDismiss={() => props.onClose()}
            isOpen={props.apiKey != null}
            headerText={'API Key'}
            onRenderFooterContent={() => (
                <>                
                { canAccess && (
                    <Stack horizontal>
                        {props.apiKey?.enabled ? (
                            <DefaultButton text='Disable' style={{margin: '0 1em', width: '100%'}} onClick={() => {
                                if (props.apiKey?.id) {
                                    disableApiKey(props.apiKey?.id)
                                        .then(() => {
                                            props.onClose();
                                        });
                                }
                            }}/>
                        ) : (
                            <PrimaryButton text='Enable' style={{margin: '0 1em', width: '100%'}} onClick={() => {
                                if (props.apiKey?.id) {
                                    enableApiKey(props.apiKey?.id)
                                        .then(() => {
                                            props.onClose();
                                        });
                                }
                            }}/>
                        )}
                        <DefaultButton text='Delete' className='btn-warning' style={{margin: '0 1em', width: '100%'}} onClick={() => {
                            if (props.apiKey?.id)
                                deleteApiKey(props.apiKey.id)
                                    .then(() => {
                                        props.onClose();
                                    });
                        }}/>
                    </Stack>
                )}
                </>
            )}
            isFooterAtBottom={true}
        >
            <Label>Key Name</Label>
            {props.apiKey?.name}
            <Label>Key Type</Label>
            {props.apiKey?.keyType}
            <Label>Enabled</Label>
            {props.apiKey?.enabled ? 'Enabled' : 'Disabled'}
            <Label>Create Date</Label>
            {props.apiKey?.createDate}
            <Label>Created By</Label>
            {props.apiKey?.username}
        </Panel>
    )
}

export default ApiKeyDetailsDialog;