import React, {useState} from "react";
import {
    DefaultButton, DetailsList, DetailsListLayoutMode,
    IColumn,
    Panel,
    PanelType,
    SelectionMode,
    TextField
} from "@fluentui/react";
import {IAdHocWorkspaceItem, IWorkspaceItem} from "../../models/Workspace";
import {ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import AttributeDivider from "../Common/AttributeDivider";
import {useAddAdhocProductToWorkspaceMutation, usePutWorkspaceAdhocItemMutation} from "../../store/Api";

interface IAdHocItemModalProps {
    open: boolean;
    onClose: () => void;
    workspace: string;
    product?: IWorkspaceItem;
}
const AdHocItemModel: React.FC<IAdHocItemModalProps> = (props) => {
    const [name, setName] = useState<string | undefined>(props.product?.adhoc?.name);
    const [manufacturer, setManufacturer] = useState<string | undefined>(props.product?.adhoc?.manufacturer);
    const [mfp, setMfp] = useState<string | undefined>(props.product?.adhoc?.manufacturerPartNumber);
    const [source, setSource] = useState<string | undefined>(props.product?.adhoc?.source);
    const [cost, setCost] = useState<number | undefined>(props.product?.adhoc?.cost);
    const [tempCost, setTempCost] = useState<string | undefined>();
    const [editMode, setEditMode] = useState<boolean>(!props.product);
    const [saveProduct] = useAddAdhocProductToWorkspaceMutation();
    const [updateProduct] = usePutWorkspaceAdhocItemMutation();
    const [saving, setSaving] = useState(false);

    React.useEffect(() => {
        setTempCost(props.product?.adhoc?.cost?.toString());
    }, [props.product?.adhoc?.cost])

    const adhocDetails = [
        {
            key: 'Name',
            value: name,
            edit: <TextField
                value={name ?? ""}
                onChange={(e, d) => {
                    setName(d);
                }}
            />,
        },
        {
            key: 'Manufacturer',
            value: manufacturer,
            edit: <TextField
                value={manufacturer ?? ""}
                onChange={(e, d) => {
                    setManufacturer(d);
                }}
            />,
        },
        {
            key: 'Manufacturer Part Number',
            value: mfp,
            edit: <TextField
                value={mfp ?? ""}
                onChange={(e, d) => {
                    setMfp(d);
                }}
            />,
        },
        {
            key: 'Source',
            value: source,
            edit: <TextField
                value={source ?? ""}
                onChange={(e, d) => {
                    setSource(d);
                }}
            />,
        },
        {
            key: 'Cost',
            value: cost,
            edit: <TextField
                value={tempCost ?? ""}
                type={'number'}
                onBlur={() => {
                    setCost(parseFloat(tempCost ?? '0'));
                    setTempCost(parseFloat(tempCost ?? '0').toString());
                }}
                onChange={(e, d) => {
                    setTempCost(d);
                }}
            />,
        }
    ];

    const columns: IColumn[] = [
        {...ColumnGenerator('Data', 'key', 200), flexGrow: 1},
        {
            ...ColumnGenerator('Value', 'value', 200, undefined, (item) => editMode ? item.edit : item.value),
            flexGrow: 1
        }
    ]
    
    const save = () => {
        setSaving(true);
        const adHoc: IAdHocWorkspaceItem = {
            name: name,
            manufacturer: manufacturer,
            manufacturerPartNumber: mfp,
            source: source,
            cost: cost
        }
        if(props.product) {
            updateProduct({workspace: props.workspace, adhocItem: props.product.id, details: adHoc}).
            then(() => {
                setSaving(false);
                close();
            });
        }
        else {
            saveProduct({workspace: props.workspace, req: adHoc}).
            then(() => {
                setSaving(false);
                close();
            });
        }
        setEditMode(false);
    }
    
    const close = () => {
        setName(undefined);
        setManufacturer(undefined);
        setMfp(undefined);
        setSource(undefined);
        setCost(undefined);
        props.onClose();
    }
    
    return (
        <div>
            <Panel
                className={"detail-panel"}
                isOpen={props.open}
                onDismiss={() => props.onClose()}
                type={PanelType.large}
                isFooterAtBottom={true}
                styles={{
                    commands: {
                        background: "white"
                    },
                    footer: {
                        background: "white"
                    },
                    navigation: {
                        flex: '1 1 auto',
                    },
                }}
                onRenderFooterContent={() => (
                    <div style={{background: "white"}}>
                        {editMode
                            ? <><DefaultButton
                                text={saving ? "Saving" : "Save"}
                                iconProps={{iconName: 'Save'}}
                                disabled={saving}
                                primary
                                onClick={() => {
                                    save();
                                }}
                            />
                                <DefaultButton
                                    text={"Cancel"}
                                    iconProps={{iconName: 'cancel'}}
                                    disabled={saving}
                                    onClick={() => {
                                        if(props.product) {
                                            setName(props.product?.adhoc?.name);
                                            setManufacturer(props.product?.adhoc?.manufacturer);
                                            setMfp(props.product?.adhoc?.manufacturerPartNumber);
                                            setSource(props.product?.adhoc?.source);
                                            setCost(props.product?.adhoc?.cost);
                                            setEditMode(false);
                                        }
                                        else{
                                            close();
                                        }
                                    }}
                                    style={{marginLeft: '1em'}}
                                />
                            </>
                            : <DefaultButton
                                text="Edit"
                                iconProps={{iconName: 'Edit'}}
                                primary
                                onClick={() => {
                                    if (props.product) {
                                        setEditMode(true);
                                    }
                                }}
                            />
                        }
                    </div>
                )}
            >
                <div className={"ac-attribute-segment"}>
                    <AttributeDivider title={"Details"}></AttributeDivider>
                    <DetailsList
                        className={"ac-attribute-container"}
                        items={adhocDetails}
                        columns={columns}
                        isHeaderVisible={false}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.fixedColumns}
                    />
                </div>
            </Panel>
        </div>
    )
}

export default AdHocItemModel;