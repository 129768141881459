import React from 'react';
import {IExtensionResponse} from "../../models/Extension";
import {FontIcon, Stack} from "@fluentui/react";
import {mergeStyles} from "@fluentui/react/lib/Styling";

interface IExtensionModalMessageProps {
    response: IExtensionResponse;
}

const ExtensionModalMessage: React.FC<IExtensionModalMessageProps> = (props) => {
    const iconClass = mergeStyles({
        fontSize: 50,
        margin: '0 auto',
        marginTop: '1em',
    });
    return <div>
        <h2 style={{textAlign: 'center'}}>
            <Stack tokens={{childrenGap: '1em'}}>
                <FontIcon iconName={props.response.success ? 'Accept' : 'Warning'} className={iconClass}/>
                <span>{props.response.success ? 'Extension ran successfully' : 'Extension did not run successfully'}</span>
            </Stack>
        </h2>
        { props.response.message?.split('\n').map(m => <p key={m}>{m}</p>)}
        {props.response.error?.split('\n').map(m => <p key={m}>{m}</p>)}
        {props.response.redirectUrl &&
            (<a href={props.response.redirectUrl} target='_blank' rel='noopener noreferrer'>
                    {props.response.redirectUrl}
                </a>
            )}
    </div>
}

export default ExtensionModalMessage;