import React from 'react';
import Divider from '../Common/Divider';
import { Checkbox, Icon, TooltipHost } from '@fluentui/react';
import StorefrontSelection from "./StorefrontSelection";

interface IStorefrontSearchSelectionProps {
    inStorefront?: boolean;
    onInStorefrontChange: (inStorefront: boolean) => void;
    storefrontFilter?: string[];
    onStorefrontFilterChange: (storefrontFilter: string[]) => void;
}

const StorefrontSearchSelection: React.FC<IStorefrontSearchSelectionProps> = (props) => {
    const onInStorefrontChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
        props.onInStorefrontChange(checked ?? false);
    }

    const preventClick = (e: React.MouseEvent) => {
        e.preventDefault();
    }

    const renderCheckboxLabel = () => {
        return <span>
            Listed on Storefront
            <TooltipHost content={'Filters to products listed on any storefront. Overriden by specific storefront selection'}>
                <Icon iconName='info' style={{ paddingLeft: '5px', fontSize: '80%' }} onClick={preventClick} />
            </TooltipHost>
        </span>
    }
    return (
        <div>
            <Divider title='Storefront' />
            <Checkbox
                onRenderLabel={renderCheckboxLabel}
                checked={props.inStorefront}
                onChange={onInStorefrontChange}
                styles={{ root: { marginBottom: '1em' }}}
            />
            <StorefrontSelection onStorefrontFilterChange={props.onStorefrontFilterChange} storefrontFilter={props.storefrontFilter}/>
        </div>
    );
}

export default StorefrontSearchSelection;