import React from "react";
import {cloneDeep} from "lodash";
import {
    DocumentCard,
    DocumentCardActions,
    DocumentCardDetails,
    Dropdown, Icon,
    IDocumentCardStyles,
    IDropdownOption,
    Separator,
    TextField
} from "@fluentui/react";
import EditableImage from "./EditableImage";
import {IProductImage} from "../../models/Search";
import {PLACEHOLDER_IMAGE} from "../../Constants";
import Header from "../Common/Header";

interface IProductImagesProps {
    productId?: string;
    title?: string;
    images: IProductImage[]
    editMode?: boolean;
    onUpdate?: (images: IProductImage[]) => void;
    onSetExternalProduct: (image: IProductImage) => void;
    onDelete?: (image: IProductImage) => void;
}
export const ProductImages: React.FC<IProductImagesProps> = (props) => {

    const updateImageName = (value: string, image: IProductImage) => {
        if (!props.onUpdate) return
        const newImages = cloneDeep(props.images);
        const i = newImages.findIndex(a => a.id === image.id)
        newImages[i] = {name: value, type: image.type, url: image.url, id: image.id, isCustom: image.isCustom}
        props.onUpdate(newImages);

    }

    const updateImageType = (value: IDropdownOption, image: IProductImage) => {
        if (!props.onUpdate) return
        const newImages = cloneDeep(props.images);
        const i = newImages.findIndex(a => a.id === image.id)
        newImages[i] = {name: image.name, type: value.key.toString(), url: image.url, id: image.id, isCustom: image.isCustom}
        props.onUpdate(newImages);

    }

    const updateImageUrl = (id: string, url: string) => {
        if (!props.onUpdate) return
        const newImages = cloneDeep(props.images);
        const i = newImages.findIndex(a => a.id === id)
        const original = newImages[i];
        newImages[i] = {name: original.name, type: original.type, url: url, id: id, isCustom: true}
        props.onUpdate(newImages);

    }
    
    const deleteImage = (image: IProductImage) => {
        if (!props.onUpdate) return
        const newImages = cloneDeep(props.images);
        const i = newImages.findIndex(a => a.id === image.id);
        newImages.splice(i, 1);
        props.onUpdate(newImages)
    }

    if (!props.images && !props.editMode) return null;
    const cardStyles: IDocumentCardStyles = {
        root: { display: 'inline-block', marginRight: 20, width: 250, height: 400, padding: 10 },
        
    };
    const typeOptions: IDropdownOption[] = [
        { key: 'product', text: 'Product Image' },
        { key: 'marketing', text: 'Marketing'},
        { key: 'internal', text: 'Internal' },
        { key: 'technical', text: 'Technical' }

    ];
    const getTypeLabel = (value : string) =>{
        const selection = typeOptions.filter(a => a.key == value)
        if(selection[0]){
            return selection[0].text
        }
        return value ?? "";
    }

    const getDocumentCardActions = (image : IProductImage) => {
        if(image.type != 'product' || image.url === PLACEHOLDER_IMAGE || image.name == '') return [deleteAction(image)]
        return [deleteAction(image), setAsCurrentAction(image)]
    };
    
    const deleteAction : any = (image : IProductImage) => {
        return {
            iconProps: {iconName: 'Delete'},
            onClick: onDeleteActionClick.bind(this, image),
            ariaLabel: 'delete product image',
        };
    }

    const setAsCurrentAction : any = (image : IProductImage) => {
        return {
            iconProps: {iconName: 'Pin'},
            onClick: onSetAsCurrentActionClick.bind(this, image),
            ariaLabel: 'set as primary image',
        };
    }

    const onSetAsCurrentActionClick = (image: IProductImage, ev: React.SyntheticEvent<HTMLElement>): void => {
        props.onSetExternalProduct(image);
        ev.stopPropagation();
        ev.preventDefault();
    };
    const onDeleteActionClick = (image: IProductImage, ev: React.SyntheticEvent<HTMLElement>): void => {
        deleteImage(image);
        if(props.onDelete && image.isCustom) {
            props.onDelete(image);
        }
        ev.stopPropagation();
        ev.preventDefault();
    };
    
    const setupNewCard = () => {
        if (!props.onUpdate) return
        const newId = crypto.randomUUID();
        const newImages = cloneDeep(props.images) ?? [];
        
        newImages.push({name: "", type: "product", url:PLACEHOLDER_IMAGE , id: newId, isCustom:true})

        props.onUpdate(newImages);
    }

    const onCreateNewActionClick = (ev: React.SyntheticEvent<HTMLElement>): void => {
        setupNewCard();
        ev.stopPropagation();
        ev.preventDefault();
    };

    const getProductImageCard:any = (productImage: IProductImage) => {
        return( props.editMode ?         
                <DocumentCard
                    key={`document-card-edit-${productImage.id}`}
                    aria-label={'Product Image Card'}
                    styles={cardStyles}
                >
                    <EditableImage image={productImage} sizeSquare={240} editMode={true} 
                                   onChange={(a, b) => updateImageUrl(a, b)}></EditableImage>
                    <DocumentCardDetails>
                        <TextField
                            label='Image Name'
                            placeholder={'Please Enter an Image Name'}
                            value={productImage.name}
                            onChange={(e, value) => 
                                {if (value != undefined) updateImageName(value, productImage)}}
                        />
                        <Dropdown
                            options={typeOptions}
                            selectedKey={ productImage.type }
                            label='Image Type'
                            onChange={(e, d) => 
                                {if(d)updateImageType(d, productImage)}}
                            placeholder= 'Select an Image Type'
                        />
                    </DocumentCardDetails>
                    <DocumentCardActions actions={getDocumentCardActions(productImage)} 
                                         styles={{root:{padding: 2, alignItems: "left"}}}/>

                </DocumentCard>
                :
                <DocumentCard
                    key={`document-card-${productImage.id}`}
                    aria-label={
                        'Product Image Card'
                    }
                    styles={cardStyles}
                    onClickHref={productImage.url}
                    onClickTarget={"new"}
                >
                    <EditableImage image={productImage} sizeSquare={220} editMode={false}/>
                    <div className={'labeled-content-container'}>
                        <div className={'label'}>Image Name:</div>
                        <div className={'labeled-content'}>{productImage.name}</div>
                        <div className={'label'}>Image Type:</div>
                        <div className={'labeled-content'}>{getTypeLabel(productImage.type)}</div>
                    </div>
                </DocumentCard>
        )
    }
    
    return <div>
        {props.title && <Separator>{props.title}</Separator>}
        {props.images && props.images.length > 0 ? props.images.map((productImage) => (getProductImageCard(productImage))) :                                         
            props.editMode ? <></> : <Header icon={"FabricFolderFill"}>
                No Product Images Found
            </Header>}
        {props.editMode ? <div className={"add-product-image-card"}>
            <p className={"ac-product-image-add-icon"}>
                <Icon iconName={'Add'} onClick={onCreateNewActionClick} />
            </p>
        </div>: <></>}
    </div>
};
