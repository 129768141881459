import React from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';

interface IBooleanDropdownProps {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

const BooleanDropdown: React.FC<IBooleanDropdownProps> = (props) => {
    const options = [
        { key: 'true', text: 'Yes' },
        { key: 'false', text: 'No' },
    ]

    const onChange = (e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        props.onChange(option?.key === 'true');
    }

    return <Dropdown
        label={props.label}
        options={options}
        
        selectedKey={props.value ? 'true' : 'false'}
        onChange={onChange}
    />
}

export default BooleanDropdown;