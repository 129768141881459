import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Header from "../components/Common/Header";

interface EmbeddedProductLinkPageParams {
	extensionId: string;
	id: string;
}

const EmbeddedProductLinkPage: React.FC<RouteComponentProps<EmbeddedProductLinkPageParams>> = () => {
	return (
		<div className='mainContent'>
			<Header icon={"Error"}>
				Could not find linked product.
			</Header>
		</div>
	)
}

export default EmbeddedProductLinkPage;