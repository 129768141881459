import React from "react";
import CustomColumnEdit from "./CustomColumnEdit";
import {MODAL_STYLE} from "../../Constants";
import {getTheme, IButtonStyles, IconButton, Modal} from "@fluentui/react";
import {IDropdownOption} from "@fluentui/react/lib/Dropdown";
import {IProductColumnBase} from "../../logic/Columns/ProductColumns";

interface ICustomColumnEditModalProps {
    setName: string;
    isOpen: boolean;
    defaultColumns: string[];
    onClose: () => void;
    onSave: (columns: string[]) => void;
    testItems: any[]
    options: IDropdownOption[]
    columnMetadata?: {[columnName: string]: IProductColumnBase}
}
const theme = getTheme();
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const CustomColumnEditModal: React.FC<ICustomColumnEditModalProps> = (props) => {
    return(
        <Modal

            isOpen={props.isOpen}
            onDismiss={() => props.onClose}
            containerClassName={MODAL_STYLE.container}
            styles={{
                main: {
                    width: '80%',
                    minWidth: '40em',
                }
            }}
        >
            <div className={MODAL_STYLE.header}>

                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={props.onClose}
                />
            </div>
            <CustomColumnEdit
                columnSetName={props.setName}
                defaultColumns={props.defaultColumns}
                dropdownOptions={props.options}
                testItems={props.testItems}
                columnMetadata={props.columnMetadata}
                onSave={(columns) => {
                    props.onSave(columns)
                    props.onClose()
                }}
            />

        </Modal>);
}

export default CustomColumnEditModal;