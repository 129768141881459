import React, {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";
import Autosuggest from 'react-autosuggest';
import {useGetProductTagsQuery} from "../../store/Api";
import SearchFilter from "../Common/SearchFilter";

interface IProductTagsAutoSuggestProps {
    onCreate: (tagValue: string) => void;
    editable: boolean;
}

interface ISuggestionSection {
    title: string;
    suggestions: ISuggestion[];
}

interface ISuggestion {
    value: string;
    label: string;
    color?: string;
}

const ProductTagsAutoSuggest: React.FC<IProductTagsAutoSuggestProps> = (props) => {

    const [searchedKeywords, setSearchedKeywords] = useState<string | undefined>();
    const [keywords, setKeywords] = useState<string | undefined>();
    const {data: searchData, isLoading: searchIsLoading} = useGetProductTagsQuery({searchTerm: searchedKeywords ?? ""}, {skip: !searchedKeywords});
    const [suggestionsNormalized, setSuggestionsNormalized] = useState<ISuggestionSection[]>([]);
    const debouncedSetSearchedKeywords = useCallback(
        debounce((val:string | undefined) => {
            setSearchedKeywords(val);
        }, 200, {leading: false}),
        []
    );

    useEffect(() => {
        if (searchData) {
            const userTags = searchData.userTags.map((t) => ({value: t, label: t, color: "#F9D585"}));
            const tags = searchData.tags.map((t) => ({value: t, label: t, color: "#9BD3AB"}))
            
            const normalized: ISuggestionSection[] = [];
            if (tags && tags.length > 0) normalized.push({title: 'Existing Global Tags', suggestions: tags});
            if (userTags && userTags.length > 0) normalized.push({title: 'My Tags', suggestions: userTags});

            setSuggestionsNormalized(normalized);
        }
    }, [searchData])

    const onChange = (e:any, { newValue }:any) => {
        setKeywords(newValue);
    }

    const onSelect = (e:React.FormEvent<any>, suggestion: Autosuggest.SuggestionSelectedEventData<ISuggestion>) => {
        if(suggestion.sectionIndex == null) return;
        props.onCreate(suggestion.suggestion.value)
        setKeywords("");
    }
    const editable = props.editable;

    return (editable? 
        <Autosuggest
            suggestions={searchIsLoading ? [] : suggestionsNormalized}
            multiSection={true}
            onSuggestionsFetchRequested={({value}) => debouncedSetSearchedKeywords(value)}
            onSuggestionsClearRequested={() => setSuggestionsNormalized([])}
            getSuggestionValue={(suggestion: ISuggestion) => suggestion.value}
            getSectionSuggestions={(section: ISuggestionSection) => section.suggestions}
            renderSuggestion={(suggestion: ISuggestion) => <div className={"autosuggest-tag"}><SearchFilter protected={true} color={suggestion.color}>{suggestion.label}</SearchFilter></div>}
            renderSectionTitle={(section: ISuggestionSection) => <strong>{section.title}</strong>}
            onSuggestionSelected={onSelect}
            inputProps={{
                placeholder: 'Search Existing / Create Tag (Press Enter)',
                value: keywords ?? '',
                onChange,
                style: {
                    width: '50%',
                    boxSizing: 'border-box',
                    height: 'auto'
                },
                onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        if(searchedKeywords && searchedKeywords != "") {
                            props.onCreate(searchedKeywords);
                        }
                    }
                }
            }}
        /> : <></>
    )
}
export default ProductTagsAutoSuggest;
