import React from "react";
import {IAttribute} from "../../models/Search";
import {Dropdown, IDropdownOption} from "@fluentui/react/lib/Dropdown";
import {Icon, mergeStyleSets} from "@fluentui/react";

interface IFacetDropdown {
    facet: IAttribute;
    selectedKey: string;
    onFacetChange: (facet: IAttribute, option?: IDropdownOption) => void;
}

const FacetDropdown: React.FC<IFacetDropdown> = (props) => {

    const options : IDropdownOption[] = [
        {
            key: "MUST",
            text: "Must Have",
            data: {icon: 'HeartFill', iconColor: 'red'},
        },
        {
            key: "BOOST",
            text: "Nice to Have",
            data: {icon: 'Like', iconColor: 'blue'},
        },
        {
            key: "EXCLUDE",
            text: "Must Not Have",
            data: {icon: 'StatusCircleBlock', iconColor: 'red'},
        },
        {
            key: "NONE",
            text: "Clear Selection",
            data: {icon: 'CircleRing'},
        },
    ];
    const contentStyles = mergeStyleSets({
        title: {
            borderWidth: 'unset',
            borderStyle: 'unset',
            borderColor: 'unset',
            borderRadius: 'unset',
            backgroundColor: 'unset',
            width: 'fit-content',
        },
        dropDownItem: {
            backgroundColor: 'red'
        }
    });
    const caretDown = () => {
        return <></>
    }

    const iconStyles = (color? : string) => {
        return {
            marginRight: '8px',
            color: color ?? 'black'
        }
    };
    const onRenderOption = (option?: IDropdownOption): JSX.Element => {
        return (
            <div style={{width:'120px'}}>
                {option?.data?.icon && (
                    <div style={{display:'inline-flex'}}><Icon style={iconStyles(option.data.iconColor)} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />{option.text}</div>
                )}
            </div>
        );
    };

    const optionElement = (option: string) => {
        let trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"CircleRing"}/></span>;
        switch (option) {
            case 'BOOST':
                trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"Like"} style={{color:'blue'}}/></span>
                break;
            case 'EXCLUDE':
                trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"StatusCircleBlock"} style={{color:'red'}}/></span>
                break;
            case 'MUST':
                trigger = <span className='ac-attribute-dropdown-item'><Icon iconName={"HeartFill"} style={{color:'red'}}/></span>;
                break;
        }
        return trigger;
    }
    
    
    return (
        <Dropdown
            styles={contentStyles}
            selectedKey={props.selectedKey}
            id={props.facet?.value}
            className='dropdown'
            options={options}
            onRenderTitle={() => optionElement(props.selectedKey)}
            onRenderCaretDown={() => caretDown()}
            onRenderOption={onRenderOption}
            onChange={(a, b) => props.onFacetChange(props.facet, b)}
        />
    )
}

export default FacetDropdown;