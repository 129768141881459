import React from 'react';
import {IWorkspaceItem} from "../../models/Workspace";
import {TextField} from "@fluentui/react";
import {useGetWorkspaceQuery, usePutWorkspaceItemDetailsMutation} from "../../store/Api";

interface IMarkupEntryProps {
    item: IWorkspaceItem;
}

const MarkupEntry: React.FC<IMarkupEntryProps> = (props) => {
    const workspace = useGetWorkspaceQuery(props.item.workspace);
    const defaultMarkup = workspace.data?.defaultMarkup;
    const [detailsChange] = usePutWorkspaceItemDetailsMutation();
    const [val, setVal] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if (props.item.details?.markup !== 0) {
            setVal(props.item.details?.markup?.toString());
        }
    }, [props.item.details?.markup])

    const update = (v: string | undefined) => {
        detailsChange({
            workspace: props.item.workspace,
            product: props.item.id,
            details: {
                ...props.item.details,
                markup: v ? parseFloat(v) : undefined,
            }
        })

    }

    return (
        <TextField
            placeholder={defaultMarkup?.toString() ?? 'Not Set'}
            suffix={'%'}
            value={val}
            type={'number'}
            className={'italic-placeholder'}
            onChange={(e, v) => {
                setVal(v);
            }}
            onBlur={(e) => update(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    update(e.currentTarget.value);
                }
            }}
            />
    )
}

export default MarkupEntry;