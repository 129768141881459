import React from 'react';
import { IStorefrontOrderProduct } from '../../../models/Storefront';
import { DetailsList, IColumn, SelectionMode } from '@fluentui/react';
import { ColumnGenerator, CurrencyColumnGenerator } from '../../../logic/Columns/ColumnGenerator';

interface IStorefrontOrderProductsTableProps {
    products: IStorefrontOrderProduct[];
}

const StorefrontOrderProductsTable: React.FC<IStorefrontOrderProductsTableProps> = (props) => {
    const columns: IColumn[] = [
        ColumnGenerator('Product', 'name', 100),
        CurrencyColumnGenerator('Price', 'price', 100, 100),
        ColumnGenerator('Quantity', 'quantity', 100, 100),
    ]
    return <DetailsList
        selectionMode={SelectionMode.none}
        items={props.products}
        columns={columns}
    />
}

export default StorefrontOrderProductsTable;