import React, {useEffect, useState} from "react";
import { Checkbox, Stack } from "@fluentui/react";

interface ICheckboxGroupProps {
    options: string[];
    onChange: (selected: string[]) => void;
    selected: string[];
}

export const CheckboxGroup: React.FC<ICheckboxGroupProps> = (props) => {
    if (!props.options) {
        return null;
    }
    function handleCheck(option: string) {
        const selected: string[] = [...props.selected];
        const index = selected.indexOf(option);
        if (index === -1) {
            selected.push(option);
        } else {
            selected.splice(index, 1);
        }
        props.onChange(selected);
    }
    return <div>
        <Stack tokens={{childrenGap: '1em'}}>
        {props.options.map((option) =>
            <Checkbox
                // style={{display:"block", marginTop: "0.5em"}}
                key={option}
                label={option}
                checked={props.selected != null && props.selected.indexOf(option) !== -1}
                onChange={() => { handleCheck(option); }}
            />
        )}
        </Stack>
    </div>;
};

interface ILabeledCheckboxGroupProps {
    options: {[key:string]: string};
    descriptions?: {[key:string]: string};
    onChange: (selected: string[]) => void;
    selected: string[];
}

export const LabeledCheckboxGroup: React.FC<ILabeledCheckboxGroupProps> = (props) => {

    const [keys, setKeys] = useState<string[]>([]);
    useEffect(() => {
        setKeys(Object.keys(props.options))
        console.log('keys', Object.keys(props.options))
        console.log('selected', props.selected)
    }, [props.options]);
    function handleCheck(option: string) {
        const selected: string[] = [...props.selected];
        const index = selected.indexOf(option);
        if (index === -1) {
            selected.push(option);
        } else {
            selected.splice(index, 1);
        }
        props.onChange(selected);
    }
    if (!props.options) {
        return null;
    }
    console.log('CHECKBOXES', props.options, props.descriptions, keys)
    return <div>
        <Stack tokens={{childrenGap: '2em'}}>
            {keys?.map((key) => <div key={props.options[key]}>
                <Checkbox
                    styles={{label: {fontWeight: 'bold'}}}
                    label={key}
                    checked={props.options[key] != undefined && props.selected != null && props.selected.indexOf(props.options[key] ?? "") !== -1}
                    onChange={() => { handleCheck(props.options[key] ?? ""); }}
                />
                {props.descriptions && props.descriptions[props.options[key]] && <div style={{marginLeft: '2em', marginTop: '0.5em'}}>{props.descriptions[props.options[key]]}</div>}
            </div>)}
        </Stack>
    </div>;
};
