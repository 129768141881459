import {DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack} from '@fluentui/react';
import React from 'react';
import { IGlobalStorefrontSettings, IStorefrontSettings } from '../../models/Storefront';
import StorefrontSettingsForm from './StorefrontSettingsForm';
import { useCreateStorefrontMutation } from '../../store/Api';
import StorefrontPreview from './StorefrontPreview';

interface INewStorefrontPanelProps {
    isOpen: boolean;
    onAddStorefront: () => void;
    onClose: () => void;
    globalSettings?: IGlobalStorefrontSettings;
}

export const NEW_STOREFRONT: IStorefrontSettings = {
        name: '',
        logo: '',
        title: '',
        bannerText: '',
        menuBarColor: '',
        menuBarTextColor: '',
        backgroundColor: '',
        textColor: '',
        headerFont: '',
        defaultFont: '',
        termsAndConditions: '',
        orderEmail: '',
        customerEmailText: '',
        customerEmailSubject: '',
        checkoutEnabled: false,
        checkoutType: '',
        defaultMarkup: 0,
        defaultMarkupType: '',
        subUrl: '',
        enabled: true,
        fullCatalogs: [],
        overrideTermsAndConditions: false,
    }

const NewStorefrontPanel: React.FC<INewStorefrontPanelProps> = (props) => {
    const [createStorefront] = useCreateStorefrontMutation();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>()
    const [saving, setSaving] = React.useState(false);
    const [newStorefront, setNewStorefront] = React.useState<IStorefrontSettings>(NEW_STOREFRONT);
    const save = () => {
        setSaving(true);
        createStorefront(newStorefront).unwrap().then((r) => {
            setSaving(false);
            if(r.success) {
                props.onAddStorefront();
            }
            else{
                setErrorMessage(r.message ?? 'an error occurred while creating new storefront')
            }
        });
    }
    const footer = () => (
        <Stack horizontal tokens={{childrenGap: '1em'}}>
            <PrimaryButton disabled={saving} onClick={save}>Add Storefront</PrimaryButton>
            <DefaultButton disabled={saving} onClick={props.onClose}>Cancel</DefaultButton>
        </Stack>
    )
    const header = () => (
        <div style={{flexGrow: 1, paddingLeft: '25px', background: "white"}}>
            <h1>New Storefront</h1>
            {errorMessage &&
                <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
            }
        </div>
    )
    return (
        <Panel
            type={PanelType.large}
            isOpen={props.isOpen}
            onDismiss={props.onClose}
            headerText="New Storefront"
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            onRenderHeader={header}
            onRenderFooterContent={footer}
        >
            <Stack tokens={{childrenGap: '1em'}} horizontal>
                <div
                    style={{
                        width: '50%',
                        padding: '1em',
                        borderRight: '1px solid #f3f2f1',
                    }}
                >
                    <StorefrontSettingsForm
                        globalSettings={props.globalSettings}
                        settings={newStorefront}
                        onChange={setNewStorefront}
                    />
                </div>
                <StorefrontPreview 
                    globalSettings={props.globalSettings} 
                    storefrontSettings={newStorefront}
                />
            </Stack>
        </Panel>
    );
}

export default NewStorefrontPanel;