import {IProductImage} from "../../models/Search";
import React, {useEffect, useRef} from "react";
import {Image, PrimaryButton} from "@fluentui/react";
import {cloneDeep} from "lodash";
import {useUploadImageMutation} from "../../store/Api";

interface IStorefrontBundleImagesProps {
    selected: string;
    images: IProductImage[]
    onSelect: (imageUrl: string) => void;
}
export const StorefrontBundleImages: React.FC<IStorefrontBundleImagesProps> = (props) => {
    const [images, setImages] = React.useState<IProductImage[]>([]);
    const [uploadImage] = useUploadImageMutation();
    
    useEffect(() => {
        const completeImageList: IProductImage[] = props.images;
        if(props.selected !== "" && !props.images.some(i => i.url === props.selected)) {
            const bundleImage: IProductImage = {
                name: "Bundle Image",
                url: props.selected,
                type: "Custom",
                id: '',
                isCustom: true
            };
            completeImageList.push(bundleImage);
        }
        setImages(completeImageList);
    }, [props.selected, props.images])
    
    const inputFile = useRef<HTMLInputElement | null>(null);
    const onButtonClick = () => {
        inputFile?.current?.click();
    };

    const onChangeFile = (event : any) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
                const file = reader.result;
                if (file && typeof file !== "string" && file.byteLength < 20971520) {
                    const newId = crypto.randomUUID();
                    const newImages = cloneDeep(props.images) ?? [];
                    
                    uploadImage({imageId: newId, file: file, imageType: 'Custom'})
                        .unwrap()
                        .then((result) => {
                            newImages.push({name: "Bundle Image", type: "Custom", url:result.url , id: newId, isCustom:true})
                            setImages(newImages);
                            props.onSelect(result.url);
                        });
                }
            };
            reader.readAsArrayBuffer(file);
        }
    }
    
    const imageDisplay = (image: IProductImage) => {
        if(props.selected === image.url){
            return (
                 <div className={'bundle-image-selected'}>
                    <Image
                        src={image.url}
                        style={{margin: '0 auto', width: '100%'}}
                        onClick={() => props.onSelect(image.url)}
                    />
                </div>
            )
        }
        else {
            return (
                <div className={'bundle-image'}>
                    <Image
                        src={image.url}
                        style={{margin: '0 auto', width: '100%'}}
                        onClick={() => props.onSelect(image.url)}
                    />
                </div>
            )
        }
    }


    return (
        <div style={{paddingTop: '1em'}}>
            <h4>Image</h4>
            <div className={'bundle-image-pod'}>

                <div>
                    {images && images.length > 0 &&
                    <div>
                        {images.map((image) => (imageDisplay(image)))}
                    </div>
                    }
                </div>
            </div>
            <div>
                <input type='file' id='file' ref={inputFile} accept="image/*" style={{display: 'none'}}
                       onChange={onChangeFile}/>
                <PrimaryButton style={{margin: '1em'}} text={'Upload New'} onClick={onButtonClick}/>
            </div>
        </div>
    );
}