import React, {useEffect, useLayoutEffect} from 'react';
import {Checkbox, Icon, Label, TooltipHost} from '@fluentui/react';

interface IToggleFieldProps {
    readonly?: boolean;
    disabled?: boolean;
    label?: string;
    checked?: boolean;
    onChange?: (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => void;
    toolTip?: string | JSX.Element | JSX.Element[] | undefined;
}



const ToggleField: React.FC<IToggleFieldProps> = (props) => {
    const [isChecked, setIsChecked] = React.useState<boolean>(props.checked ?? false);
    useLayoutEffect(() => {
        setIsChecked(props.checked?? false);
    }, [props.checked]);
    const label = () => {
        if(props.toolTip){
            return(<TooltipHost content={props.toolTip}>
                <div style={{display: "inline-flex"}}><Label><Icon iconName={'Info'} style={{paddingRight: '5px'}}/>{props.label}</Label> </div>
            </TooltipHost>)
        }
        return(<Label>{props.label}</Label>)
    }
    if(props.readonly || props.disabled){
        return( 
            <div className={'ac-details-field'}>
                {label()}
                {}
                <Checkbox
                    styles={{text: {color: 'black', display: 'inline-flex'}}}
                    disabled={true}
                    checked={isChecked}
                />
            </div>
        )
    }

    return (
        <React.Fragment>
            {label()}
            <Checkbox
                checked={isChecked}
                disabled={false}
                onChange={props.onChange}
            />
        </React.Fragment>
    );
}

export default ToggleField;