import React from 'react';
import {IInstalledExtension} from "../../models/Extension";
import {useGetExtensionLibraryQuery} from "../../store/Api";
import {Dropdown} from "@fluentui/react/lib/Dropdown";

interface ILibrarySelectionProps {
    installedExtension: IInstalledExtension;
    value: string | undefined;
    onChange: (fileId: string | undefined) => void;
    label?: string;
}

const LibrarySelection: React.FC<ILibrarySelectionProps> = (props) => {
    const library = useGetExtensionLibraryQuery({extensionId: props.installedExtension.id, enabledOnly: true});
    return <>
        <Dropdown
            label={props.label}
            options={library.data?.items.map((doc) => ({key: doc.id, text: doc.name})) ?? []}
            disabled={library.isLoading}
            placeholder={library.isLoading ? 'Loading...' : 'Select a file'}
            selectedKey={props.value}
            onChange={(e, o) => props.onChange(o?.key as string | undefined)}
        />
    </>
}

export default LibrarySelection;