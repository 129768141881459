import React, {useEffect, useState} from 'react';
import {DefaultButton, Panel, PanelType, Pivot, PivotItem, Stack} from "@fluentui/react";
import {INewProduct, IProductImage} from "../../models/Search";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import BasicDetails from "./BasicDetails";
import BasicDetailsTable from "./BasicDetailsTable";
import {AttributesTable, IAttributeKV} from "./AttributesTable";
import {ProductLinesTable} from "./ProductLinesTable";
import EditorVendorTable from "./EditableVendorsTable";
import {usePostProductMutation} from "../../store/Api";
import EditableImage from "./EditableImage";

interface INewProductModalProps {
    open: boolean;
    onClose: () => void;
}

const defaultProduct: INewProduct = {
    manufacturer: '',
    manufacturerPartNumber: '',
    name: '',
    description: '',
    vendors: [],
    tags: [],
    links: [],
    images: [],
    filters: {},
    facets: {},
    attributes: {},
    priceLevels: [],
    productLines: [],
    favorite: {company: false, user: false},
}

const NewProductModal: React.FC<INewProductModalProps> = (props) => {
    const [saving, setSaving] = React.useState(false);
    const catalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const [product, setProduct] = React.useState<INewProduct>(defaultProduct);
    const [attrArray, setAttrArray] = useState<IAttributeKV[]>([]);
    const [facetArray, setFacetArray] = useState<IAttributeKV[]>([]);
    const [filterArray, setFilterArray] = useState<IAttributeKV[]>([]);
    const [productLines, setProductLines] = useState<string[]>([])
    const [updatedProductImageUrl, setProductImageUrl] = useState<string>();
    const [postProduct] = usePostProductMutation();

    useEffect(() => {
        setProduct(defaultProduct);
    }, [props.open])

    const save = () => {
        setSaving(true);
        postProduct({
            catalog: catalog?.name ?? 'technology',
            product: {
                ...product,
                image: updatedProductImageUrl,
                attributes: Object.assign({}, ...attrArray.map((x) => ({[x.key]: x.value}))),
                facets: Object.assign({}, ...facetArray.map((x) => ({[x.key]: x.value}))),
                filters: Object.assign({}, ...filterArray.map((x) => ({[x.key]: x.value}))),
                productLines,
            }
        }).then(() => {
            setSaving(false);
            reset();
            props.onClose();
        });
    }

    const productImage: IProductImage = {
        name: "Product Image",
        url: updatedProductImageUrl ?? '',
        type: "Product",
        id: 'NEW',
        isCustom: true
    };
    
    const reset = () => {
        setProduct(defaultProduct);
        setAttrArray([]);
        setFacetArray([])
        setFilterArray([])
        setProductLines([])
        setProductImageUrl(undefined)
    }

    return (
        <Panel
            className={"detail-panel"}
            headerText="New Product"
            isOpen={props.open}
            onDismiss={() => {props.onClose(); reset();}}
            type={PanelType.large}
            styles={{
                commands: {
                    background: "white"
                },
                footer: {
                    background: "white"
                },
                navigation: {
                    flex: '1 1 auto',
                },
            }}
            onRenderHeader={() => {
                return (
                    <div style={{flexGrow: 1, paddingLeft: '25px', background: "white"}}>
                        <Stack horizontal disableShrink>
                            <Stack.Item align='auto'>
                                <EditableImage image={productImage} editMode={true}
                                               onChange={(a, b) => setProductImageUrl(b)}/>
                            </Stack.Item>
                        </Stack>
                    </div>
                )
            }}
            isFooterAtBottom={true}
            onRenderFooterContent={() => (
                <div style={{background: "white"}}>
                    <DefaultButton
                        text={saving ? "Saving" : "Save"}
                        iconProps={{iconName: 'Save'}}
                        disabled={saving}
                        primary
                        onClick={save}
                    />
                    <DefaultButton
                        text={"Cancel"}
                        iconProps={{iconName: 'cancel'}}
                        disabled={saving}
                        onClick={() => {
                            props.onClose(); 
                            reset()}
                        }
                        style={{marginLeft: '1em'}}
                    />
                </div>
            )}
        >
            <Pivot
                overflowBehavior='menu'
                styles={{itemContainer: {marginTop: '1em'}}}
            >
                <PivotItem headerText='Details'>
                    <h3>Description</h3>
                    <BasicDetails
                        id={'NEW'}
                        product={product}
                        onChange={setProduct}
                        editMode={true}
                    />
                </PivotItem>
                <PivotItem headerText='Attributes'>
                    <BasicDetailsTable
                        product={product}
                        editMode={true}
                        onChange={setProduct}
                        catalog={catalog?.name ?? ''}
                    />
                    <AttributesTable
                        title='Attributes'
                        attributes={attrArray}
                        onUpdate={setAttrArray}
                        editMode={true}
                    />
                    <AttributesTable
                        title='Facets'
                        attributes={facetArray}
                        onUpdate={setFacetArray}
                        editMode={true}
                    />
                    <AttributesTable
                        title='Filters'
                        attributes={filterArray}
                        onUpdate={setFilterArray}
                        editMode={true}
                    />
                    <ProductLinesTable
                        title='Product Lines'
                        productLines={productLines}
                        editMode={true}
                        onUpdate={setProductLines}
                    />
                </PivotItem>
                <PivotItem headerText='Vendor'>
                    <EditorVendorTable product={product} onChange={setProduct}/>
                </PivotItem>
            </Pivot>
        </Panel>
    )
}

export default NewProductModal;
