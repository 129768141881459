import React, {useState} from "react";
import {CostDisplay} from "../Products/CostDisplay";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import DetailsModal from "../DetailsModal/DetailsModal";
import {
    IColumn,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {ButtonColumnGenerator, ColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import Card from "./Card";
import CAN, {EDIT_ITEM} from "../../permissions/ability";
import {useGetFavoritesProductsQuery} from "../../store/Api";

const FavoritesCard: React.FC = () => {
    const favoritesApi = useGetFavoritesProductsQuery();
    const [selectedProduct, setSelectedProduct] = useState<INormalizedProduct | undefined>();

    const columns: IColumn[] = [
        {
            ...ColumnGenerator('Name', 'name', 100),
            isRowHeader: true,
            isMultiline: true,
        },
        {
            key: 'cost',
            name: 'Cost',
            fieldName: 'costDisplay',
            onRender: (item: INormalizedProduct) => <CostDisplay product={item}/>,
            minWidth: 100,
            maxWidth: 100,
            isMultiline: true,
        },
        ButtonColumnGenerator((item: INormalizedProduct) => {
            setSelectedProduct(item)
        }),
    ]
    const canEditItem = CAN(EDIT_ITEM);
    return <Card title='Favorites' loading>
        <ShimmeredDetailsList
            enableShimmer={favoritesApi.isLoading}
            items={favoritesApi?.data?.products ?? []}
            columns={columns}
            selectionMode={SelectionMode.none}
            isHeaderVisible={false}
        />
        {selectedProduct &&
            <DetailsModal product={selectedProduct} onClose={() => setSelectedProduct(undefined)} open={true} preventEdit={!canEditItem}/>}
    </Card>
}

export default FavoritesCard;
