import React from 'react';
import {
    ActivityItem,
    Icon,
    IconButton,
    mergeStyleSets,
    Panel,
    ProgressIndicator,
    Spinner,
    Stack, StackItem
} from "@fluentui/react";
import {useDismissTaskMutation, useGetTasksQuery} from "../../store/Api";
import moment from "moment";
import {IAdaptiveCatalogTask} from "../../models/Tasks";

interface INotificationsPanelProps {
    isOpen: boolean;
    onclose: () => void;
}

const NotificationsPanel: React.FC<INotificationsPanelProps> = (props) => {
    const getTasks = useGetTasksQuery({includeDismissed: false}, {refetchOnMountOrArgChange: 30});

    const classNames = mergeStyleSets({
        header: {
            paddingLeft: '24px',
            paddingRight: '24px',
            alignSelf: 'flex-start',
            flexGrow: 1,
        },
        headerText: {
            fontSize: '20px',
            fontWeight: '600',
            color: 'rgb(50,49,48)',
            lineHeight: '27px',
        }
    });

    return (
        <Panel
            isOpen={props.isOpen}
            onDismiss={props.onclose}
            // headerText={'Notifications'}
            onRenderHeader={() => <div className={classNames.header}>
                <div className={classNames.headerText}>
                    <Stack tokens={{childrenGap: '1em'}} horizontal verticalAlign='center'>
                        <span>Notifications</span>
                        {getTasks.isFetching && <Spinner/>}
                    </Stack>
                </div>
            </div>}
        >
            <Stack tokens={{childrenGap: '1em', padding: '1em 0'}}>
                {getTasks.data?.map(task => <NotificationDisplay task={task} key={task.id}/>)}
            </Stack>
        </Panel>
    );
}

interface INotificationDisplayProps {
    task: IAdaptiveCatalogTask;
    showDismissed?: boolean;
}

export const NotificationDisplay: React.FC<INotificationDisplayProps> = ({task, showDismissed}) => {
    const [dismissTask] = useDismissTaskMutation();
    const classNames = mergeStyleSets({
        statusText: {
            fontWeight: 'bold',
        },
    });

    if (task.dismissed && !showDismissed) return null;

    let icon = 'Refresh';

    if (task.completed) {
        if (task.success) icon = 'Checkmark'
        else icon = 'Error'
    }

    return <>
        <Stack horizontal>
            <StackItem grow={1}>
                <ActivityItem
                    activityDescription={[
                        <span key={0}><span className={classNames.statusText}>{task.status}</span> - {task.message}</span>,
                        <ProgressIndicator key={1} percentComplete={task.percentCompleted / 100}
                                           progressHidden={task.completed}/>,
                    ]}
                    activityIcon={<Icon iconName={icon}/>}
                    timeStamp={moment(task.createDate).fromNow()}
                />
            </StackItem>
            {(task.completed && !task.dismissed) && <IconButton
                key={2}
                iconProps={{iconName: 'Cancel'}}
                onClick={() => dismissTask(task)}
            />}
        </Stack>
    </>
}

export default NotificationsPanel;