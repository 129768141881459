import React, {useState} from 'react';
import {
    Checkbox, DefaultButton, Dropdown,
    IconButton, MessageBar, MessageBarType,
    Modal,
    PrimaryButton,
    ProgressIndicator,
    Stack,
    TextField
} from "@fluentui/react";
import {MODAL_ICON_BUTTON_STYLE, MODAL_STYLE} from "../../Constants";
import {
    useAddProductReferenceToWorkspaceMutation,
    useGetCatalogsQuery,
    useUploadWorkspaceImportFileMutation
} from "../../store/Api";
import {ArrayBufferToBase64} from "../../logic/ArrayBufferConverter";
import {
    IWorkspaceFileImportResponse,
    IWorkspaceItemDetails
} from "../../models/Workspace";
import {IsMutationSuccess} from "../../logic/MutationTypeChecker";
import ImportTable from "./ImportTable";
import {IProductReference} from "../../models/Extension";

interface WorkspaceImportModalProps {
    isOpen: boolean;
    onClose: () => void;
    workspaceId: string;
}


const WorkspaceImportModal: React.FC<WorkspaceImportModalProps> = (props) => {
    const [currentStep, setCurrentStep] = React.useState<number>(0);
    const [importFile, setImportFile] = useState<ArrayBuffer | undefined>(undefined);
    const [importFileName, setImportFileName] = useState<string | undefined>(undefined);
    const [firstRowHeader, setFirstRowHeader] = useState<boolean>(true);
    const [delimiter, setDelimiter] = useState<string>(',');
    const [quote, setQuote] = useState<string>('"');
    const [uploadFile] = useUploadWorkspaceImportFileMutation();
    const [rows, setRows] = useState<IWorkspaceFileImportResponse | undefined>();
    const [error, setError] = useState<string | undefined>(undefined);
    const [selected, setSelected] = useState<IProductReference[]>([]);
    const [addProduct] = useAddProductReferenceToWorkspaceMutation();
    const [selectedCatalog, setSelectedCatalog] = useState<string | undefined>(undefined);
    const catalogs = useGetCatalogsQuery();

    const Steps = [
        {
            name: 'Upload File',
            progress: 0,
            nextButton: 'Import',
            nextButtonDisabled: importFile == null || selectedCatalog == null
        },
        {name: 'Importing', progress: 0.25, nextButton: 'Importing', nextButtonDisabled: true},
        {
            name: 'Product Selection',
            progress: 0.5,
            nextButton: `Add ${selected.length} Products`,
            nextButtonDisabled: selected.length === 0
        },
        {
            name: 'Adding to Workspace',
            progress: 0.75,
            nextButton: `Adding ${selected.length} Products`,
            nextButtonDisabled: true
        },
        {name: 'Import Complete', progress: 1, nextButton: 'Completed', nextButtonDisabled: false},
    ]

    const upload = async () => {
        if (importFile == null) {
            setCurrentStep(0);
            return;
        }
        const result = await uploadFile({
            workspace: props.workspaceId,
            file: {
                fileName: importFileName || '',
                file: ArrayBufferToBase64(importFile),
                delimiter: delimiter,
                quote: quote,
                hasHeader: firstRowHeader,
            }
        });
        setCurrentStep(2);
        if (IsMutationSuccess<IWorkspaceFileImportResponse>(result)) {
            setRows(result?.data);
        } else {
            setCurrentStep(0);
            setError('Failed to upload file');
        }
    }

    const addToWorkspace = async () => {
        // first - deduplicate
        const addProdReqs: {[key: string]: {workspace: string, product: IProductReference, req: IWorkspaceItemDetails}} = {}
        for (const reference of selected) {
            if (addProdReqs[reference.id]) {
                addProdReqs[reference.id].req.quantity = (addProdReqs[reference.id].req.quantity ?? 1) + 1;
            } else {
                addProdReqs[reference.id] = {
                    workspace: props.workspaceId,
                    product: reference,
                    req: {
                        quantity: 1,
                    }
                }
            }
        }
        // second - add
        for (const req of Object.values(addProdReqs)) {
            const result = await addProduct(req);
            if (!IsMutationSuccess<boolean>(result)) {
                setError('Failed to add product');
                return;
            }
        }
    }

    const nextStep = () => {
        switch (currentStep) {
            case 0: // Import
                setCurrentStep(1);
                upload();
                break;
            case 1: // Importing (not clickable)
                break;
            case 2: // Product Selection
                setCurrentStep(3);
                addToWorkspace().then(() => {
                    setCurrentStep(4);
                });
                break;
            case 3: // Adding to workspace (not clickable)
                break;
            case 4:
                close();
                break;
        }
    }

    const close = () => {
        setCurrentStep(0);
        props.onClose();
    }

    return (
        <Modal isOpen={props.isOpen} styles={{main: {width: '100%', maxWidth: '1200px'}}}>
            <div className={MODAL_STYLE.header}>
                <span>Workspace File Import</span>
                <IconButton
                    styles={MODAL_ICON_BUTTON_STYLE}
                    iconProps={{iconName: 'Cancel'}}
                    onClick={close}
                />
            </div>
            <div className={MODAL_STYLE.body}>
                <Stack tokens={{childrenGap: '1em'}}>
                    <ProgressIndicator description={Steps[currentStep].name}
                                       percentComplete={Steps[currentStep].progress}/>
                    {error && <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar> }
                    <div style={{height: '60vh', maxHeight: '500px', overflowY: 'auto'}}>
                        {currentStep === 0 && <div>
                            <Stack tokens={{childrenGap: '1em'}}>
                                <input
                                    type='file'
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                                const result = e.target?.result;
                                                if (result) {
                                                    setImportFile(result as ArrayBuffer);
                                                    setImportFileName(file.name);
                                                }
                                            }
                                            reader.readAsArrayBuffer(file);
                                        }
                                    }}
                                />
                                <Checkbox
                                    label='First row is header'
                                    checked={firstRowHeader}
                                    onChange={(e, d) => {
                                        setFirstRowHeader(d ?? false)
                                    }}
                                />
                                <TextField
                                    label='Delimiter'
                                    value={delimiter}
                                    onChange={(e, d) => {
                                        setDelimiter(d ?? ',')
                                    }}
                                />
                                <TextField
                                    label='Quote Character'
                                    value={quote}
                                    onChange={(e, d) => {
                                        setQuote(d ?? '"')
                                    }}
                                />
                                <Dropdown
                                    label='Catalog'
                                    selectedKey={selectedCatalog}
                                    onChange={(e,d) => setSelectedCatalog(d?.key as string)}
                                    options={catalogs.data?.map(c => ({
                                        key: c.name,
                                        text: c.description ?? c.name,
                                    })) ?? []}
                                />
                            </Stack>

                        </div>}
                        {currentStep === 1 && <div>
                            <span>Uploading {importFileName}</span>
                        </div>}
                        {currentStep === 2 && <div>
                            <ImportTable data={rows} onSelectedChange={setSelected} sideBySide={true} catalog={selectedCatalog}/>
                        </div>}
                    </div>
                </Stack>
            </div>
            <div className={MODAL_STYLE.footer}>
                <Stack horizontal tokens={{childrenGap: '1em'}} horizontalAlign={'end'}>
                    <DefaultButton text='Cancel' onClick={close}/>
                    <PrimaryButton
                        text={Steps[currentStep].nextButton}
                        disabled={Steps[currentStep].nextButtonDisabled}
                        onClick={nextStep}
                    />
                </Stack>
            </div>
        </Modal>
    )
}

export default WorkspaceImportModal;