import React, {useState} from 'react';
import {
    ContextualMenu,
    Icon,
    IconButton,
    IContextualMenuItem,
    MessageBar,
    MessageBarType,
    TextField
} from "@fluentui/react";

interface ISortableDirectImportPreviewColumnHeaderProps {
    label: string;
    field: string;
    filterValue?: string;
    sortedDescending?: boolean;
    sortable?: boolean;
    onSort?: (column? :string) => void;
    onFilterApply?: (filter?: string) => void;
    filterable?: boolean;
    isCwPsa?: boolean
}

const SortableDirectImportPreviewColumnHeader: React.FC<ISortableDirectImportPreviewColumnHeaderProps> = (props) => {
    const divRef = React.useRef<HTMLDivElement>(null);
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const [filterWarningMessage, setFilterWarningMessage] = useState<string>()

    function isFirstCharacterNumeric(input: string): boolean {
        return /^[0-9]/.test(input);
    }
    const menuItems: IContextualMenuItem[] = [
        {
            key: 'filter',
            onRender: () => (
                <div style={{padding: '1em'}}>
                    {filterWarningMessage &&
                        <MessageBar messageBarType={MessageBarType.blocked}>{filterWarningMessage}</MessageBar>}
                        <TextField
                            placeholder={'filter'}
                            defaultValue={props.filterValue}
                            onBlur={(ev) => {if(ev.target.value && props.onFilterApply){
                                if(isFirstCharacterNumeric(ev.currentTarget.value) && props.isCwPsa){
                                    setFilterWarningMessage('ConnectWise PSA API does not allow filters where the first character is a number')
                                    return
                                }
                                props.onFilterApply(ev.target.value)
                                setFilterWarningMessage('')
                            }}}
                            onKeyDown={(ev) =>{

                                if (ev.key === 'Enter' && props.onFilterApply) {
                                    if(isFirstCharacterNumeric(ev.currentTarget.value) && props.isCwPsa){
                                        setFilterWarningMessage('ConnectWise PSA API does not allow filters where the first character is a number')
                                        return
                                    }
                                    props.onFilterApply(ev.currentTarget.value)
                                    setFilterWarningMessage('')
                                }
                            }}/>
                </div>
            )
        }
    ];

    return (<>{props.sortable &&
        <div className={"ac-sortable-column-header"} onClick={() => {if(props.onSort)props.onSort(props.field)}} ref={divRef}>
            <Icon
                iconName={ (props.sortedDescending == undefined ? 'Sort': (props.sortedDescending  ? 'SortDown': 'SortUp'))}
                className={"ac-sortable-vendor-header-icon"}
                style={{paddingRight: '1px'}}
            />
            {props.label}
        </div>}
        {props.filterable && 
        <div>
            <div style={{right: '30px', position: 'absolute'}}>
            </div>
            <div style={{right: '3px', position: 'absolute'}}>
                <IconButton
                    iconProps={{ iconName: (props.filterValue && props.filterValue != '' ? 'FilterSolid' : 'Filter') }}
                    onClick={() => setShowMenu(true)}
                />
            </div>
        </div>
        }
        <ContextualMenu items={menuItems} hidden={!showMenu} target={divRef} onDismiss={() => setShowMenu(false)}/>
    </>);
}

export default SortableDirectImportPreviewColumnHeader;