import React, {useState} from 'react';
import {
    CommandBar,
    DefaultButton,
    ICommandBarItemProps, IconButton,
    PrimaryButton,
    Separator,
    Stack, StackItem,
    Text,
    TextField
} from "@fluentui/react";
import {useAnalyzeCustomerRequestMutation, useUploadCustomerRequestDocumentMutation} from "../../store/Api";
import {IsMutationSuccess} from "../../logic/MutationTypeChecker";
import {IActionResponse} from "../../dal/BaseDAL";

interface INewCustomerRequestProps {
    workspaceId: string;
    onClose: () => void;
    onSubmit: (id: string) => void;
}

const NewCustomerRequest: React.FC<INewCustomerRequestProps> = (props) => {
    const [analyze] = useAnalyzeCustomerRequestMutation();
    const [analyzeFile] = useUploadCustomerRequestDocumentMutation();
    const [request, setRequest] = React.useState<string | undefined>(undefined);
    const [importFile, setImportFile] = useState<ArrayBuffer | undefined>(undefined);
    const [importFileName, setImportFileName] = useState<string | undefined>(undefined);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [running, setRunning] = React.useState(false);

    const run = () => {
        if (request) {
            runRequest();
        } else if (importFile && importFileName) {
            runFile();
        }
    }

    const runRequest = () => {
        if (!props.workspaceId || !request) return;
        setRunning(true);
        analyze({
            workspace: props.workspaceId,
            req: {
                request: request
            }
        }).then((response) => {
            if (IsMutationSuccess<IActionResponse>(response)) {
                props.onSubmit(response.data.id);
            }
            setRunning(false);
        });
    }

    const runFile = () => {
        if (!props.workspaceId || !importFile || !importFileName) return;
        setRunning(true);
        analyzeFile({
            file: importFile,
            fileName: importFileName,
            workspace: props.workspaceId,
        }).then((response) => {
            if (IsMutationSuccess<IActionResponse>(response)) {
                props.onSubmit(response.data.id);
            }
            setRunning(false);
        })
    }

    const commands: ICommandBarItemProps[] = [
        {
            key: 'back',
            text: 'Back',
            iconProps: {iconName: 'Back'},
            onClick: props.onClose,
            iconOnly: true
        },
        {
            key: 'title',
            text: 'New Customer Request',
        },
    ]

    const farCommands: ICommandBarItemProps[] = []

    return <>
        <CommandBar items={commands} farItems={farCommands}/>
        <Stack tokens={{childrenGap: '1em'}}>
            <Text>You can manually enter or paste in the customer's request, or upload a file to be analyzed.</Text>
            <TextField multiline value={request} onChange={(e, v) => setRequest(v)} label={'Customer Request'}/>
            <Separator>OR</Separator>
            <Stack horizontal tokens={{childrenGap: '1em'}}>
                <StackItem grow={1}>
                    <DefaultButton
                        style={{width: '100%'}}
                        text={importFileName ?? 'Upload File'}
                        onClick={() => {
                            if (fileInputRef.current) {
                                fileInputRef.current.click();
                            }
                        }}
                    />
                </StackItem>
                <IconButton
                    iconProps={{iconName: 'Clear'}}
                    onClick={() => {
                        setImportFile(undefined);
                        setImportFileName(undefined);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                    }}
                    />
            </Stack>
            <input
                style={{display: 'none'}}
                ref={fileInputRef}
                type='file'
                onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            const result = e.target?.result;
                            if (result) {
                                setImportFile(result as ArrayBuffer);
                                setImportFileName(file.name);
                            }
                        }
                        reader.readAsArrayBuffer(file);
                    }
                }}
            />
            <PrimaryButton
                text={'Analyze'}
                disabled={(request == null || request === '') && (importFile == null || importFileName == null) || running}
                onClick={run}
            />
        </Stack>
    </>
}

export default NewCustomerRequest;