import { Checkbox, Dropdown, PrimaryButton, Spinner } from '@fluentui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetMetadataQuery, useGetUserSettingsQuery, usePatchUserSettingsMutation, useUpdateOrganizationMutation } from '../../store/Api';
import { RootState } from '../../store/rootReducer';
import CAN, { ORG_MODIFY } from '../../permissions/ability';

const SearchSettingsPage: React.FC = () => {
    const selectedCatalog = useSelector((state: RootState) => state.search.selectedCatalog);
    const selectedOrg = useSelector((state: RootState) => state.settings.selectedOrganization);
    const metadata = useGetMetadataQuery(selectedCatalog?.name ?? '');
    const userSettings = useGetUserSettingsQuery();
    const [saveUserSettings] = usePatchUserSettingsMutation();
    const [excludeFromBestPrice, setExcludeFromBestPrice] = React.useState<string[]>([]);
    const [keywordToTag, setKeywordToTag] = React.useState<boolean | undefined>(undefined);
    const [updateOrg] = useUpdateOrganizationMutation();
    const [saving, setSaving] = React.useState(false);
    const canOrgModify = CAN(ORG_MODIFY);

    React.useEffect(() => {
        if (selectedOrg) {
            setExcludeFromBestPrice(selectedOrg.excludeFromBestPrice ?? []);
        }
    }, [selectedOrg]);

    React.useEffect(() => {
        setKeywordToTag(userSettings.data?.keywordToTags);
    }, [userSettings.data]);

    const save = async () => {
        setSaving(true);
        await saveUserSettings({
            keywordToTags: keywordToTag,
        });
        if (canOrgModify && selectedOrg && (
            selectedOrg.excludeFromBestPrice !== excludeFromBestPrice
        )) {
            await updateOrg({
                excludeFromBestPrice,
            });
        }
        setSaving(false);
    }

    if (userSettings.isLoading || metadata.isLoading) return <div>
        <h1>Search Settings</h1>
        <Spinner label="Loading..." ariaLive="assertive" style={{margin: '5em'}}/>
    </div>;


    return <>
        <h1>Search Settings</h1>
        <h3>Advanced Search Settings</h3>
        <Checkbox
            label='Convert Keywords to Quick Tags'
            checked={keywordToTag ?? userSettings.data?.keywordToTags}
            onChange={(e, checked) => setKeywordToTag(!!checked)}
        />
        <h3>Vendor Settings</h3>
        <Dropdown
            label='Exclude from Best Price'
            multiSelect
            selectedKeys={excludeFromBestPrice}
            options={metadata.data?.vendors?.map(v => ({key: v, text: v})) ?? []}
            disabled={!canOrgModify}
            onChange={(e,item) => {
                if (!item) return;
                setExcludeFromBestPrice(
                    item.selected ? [...excludeFromBestPrice, item.key as string] : excludeFromBestPrice.filter(key => key !== item.key),
                );
            }}
            />
        <div style={{marginTop: '1em'}}>
            <PrimaryButton
                text={saving ? 'Saving' : 'Save'}
                onClick={save}
                disabled={saving}
            />
        </div>
    </>;
}

export default SearchSettingsPage;