import React, {useState} from "react";
import {IUploadFileResponse} from "../../dal/BaseDAL";
import Dropzone from "react-dropzone";
import {useUploadRecommendationFileMutation} from "../../store/Api";
import {
    IsFetchBaseQueryError,
    IsMutationError,
    IsMutationSuccess,
    IsSerializedError
} from "../../logic/MutationTypeChecker";
import * as path from 'path-browserify';
import * as url from "url";
import Header from "../Common/Header";
import Segment from "../Common/Segment";

interface IFileUploadProps {
    existingFile?: string;
    extensionId: string
    onSuccess: (url: string) => void;
}

const FileUpload: React.FC<IFileUploadProps> = (props) => {
    const [uploadFile] = useUploadRecommendationFileMutation();
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [uploadingFile, setUploadingFile] = useState<boolean>(false)
    const [error, setError] = useState<string | undefined>();

    const handleFiles = async (files: any[]) => {
        console.log(['UPLOAD', files]);
        if (files && files.length > 0) {
            const reader = new FileReader();
            reader.onload = async () => {
                const file = reader.result;
                handleFile(file, files[0].name);
            };
            reader.readAsArrayBuffer(files[0]);
        }
    }

    const handleFile = async (file: string | ArrayBuffer | null, fileName: string) => {
        console.log(['UPLOAD', file]);
        if (file && typeof file !== "string") {
            setUploadingFile(true);
            setError(undefined);
            const result = await uploadFile({extension: props.extensionId, file, fileName: fileName ?? 'document'});
            // if ((result as {data: IUploadFileResponse})?.data) {
            if (IsMutationSuccess<IUploadFileResponse>(result)) {
                props.onSuccess(result.data.url);
            } else if (IsMutationError(result)) {
                if (IsFetchBaseQueryError(result.error)) {
                    setError(`Error uploading file: ${result.error.status}`)
                } else if (IsSerializedError(result.error)) {
                    setError(`Serializing error uploading file: ${result.error.name}`)
                }
            }
            setUploadingFile(false);
            setFileName(fileName);
        }
    }

    return (
        <div
        style={{marginBottom: '3em'}}
        >
            <Dropzone
                disabled={uploadingFile}
                onDrop={handleFiles}>
                {({getRootProps, getInputProps}) => {
                    const pathname = props.existingFile ? url.parse(props.existingFile).pathname : null;
                    const basename = pathname ? path.basename(pathname) : null;

                    let message = 'Drag and drop your file or click to select one';
                    if (uploadingFile) message = 'Uploading File';
                    else if (error) message = error;
                    else if (fileName) message = fileName;
                    else if (basename) message = basename;

                    return <section>
                        <div {...getRootProps()}>
                            <Segment hasButton={true} buttonText={"Clear"} onButtonClick={() => {
                                props.onSuccess('');
                            }}>
                                <Header icon={"OpenFile"} showIconCircle={false}>
                                    {message}
                                    <input
                                        {...getInputProps()}
                                        onChange={(e) => {
                                            const file = e.target.files?.[0];
                                            console.log(['FILE', file]);
                                            if (file) {
                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                    const result = e.target?.result;
                                                    if (result) {
                                                        handleFile(result, file.name);
                                                    }
                                                }
                                                reader.readAsArrayBuffer(file);
                                            }
                                        }}
                                    />
                                </Header>
                            </Segment>
                        </div>
                    </section>;
                }}
            </Dropzone>
        </div>
    );
}

export default FileUpload;