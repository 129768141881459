import React from 'react';
import {Icon, Text} from "@fluentui/react";
import {ISearchQueryResponse} from "../../models/Search";
import Divider from "../Common/Divider";
import {useGetMetadataQuery} from "../../store/Api";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";

interface ICategorySelectionProps {
    category?: string;
    onSegmentChange?: (segment: string | undefined) => void;
    results?: ISearchQueryResponse;
    onCategoryChange: (segment: string | undefined, category: string | undefined) => void;
    segment?: string;
}

const CategorySelection: React.FC<ICategorySelectionProps> = (props) => {
    const catalog = useSelector((state: RootState) => state.search.selectedCatalog?.name ?? '')
    const metadata = useGetMetadataQuery(catalog);
    const categoryTree = props.results?.metadata?.segments ?? metadata.data?.segments ?? {};
    if (props.segment && Object.keys(categoryTree).length > 0 && Object.keys(categoryTree).indexOf(props.segment) === -1) {
        categoryTree[props.segment] = props.category ? [props.category] : [];
    }

    if (Object.keys(categoryTree).length > 0) {
        if (props.category) {
            return <>
                <Divider title="Category"/>
                <Text as="h5">
                    <Icon
                        iconName="Clear"
                        style={{paddingRight: '1em', cursor: 'Pointer', fontSize:'10px'}}
                        onClick={() => {
                            props.onCategoryChange(undefined,undefined);
                        }}
                    />
                    {props.segment} &gt; {props.category}
                </Text>
            </>
        } else {
            return (<>
                <Divider title="Category"/>
                <div>
                    {Object.keys(categoryTree).map((segment) => (
                        <div key={segment} style={{margin: '0 auto'}}>
                            <div
                                style={{ cursor: "Pointer" }}
                                onClick={() => {
                                    if (props.segment === segment) {
                                        props.onCategoryChange(undefined,undefined);
                                    } else {
                                        props.onCategoryChange(segment,undefined);
                                    }
                                }}
                            >
                                <div className={'accordion-title'}>                                            
                                    {props.segment === segment
                                    ? <><b>{segment}</b><Icon style={{marginLeft: '5px', fontSize: '10px'}}
                                        iconName="Clear"
                                    /> </>
                                    :   <>{segment}</>
                                }

                                </div>

                            </div>
                            <div style={{marginLeft: '25px'}}>
                                {categoryTree[segment].map((category) => (
                                    <div key={category}>
                                        <span
                                            style={{ cursor: "Pointer" }}
                                            onClick={() => {
                                                props.onCategoryChange(segment, category);
                                            }}
                                        >
                                            {category}
                                        </span>

                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </>);
        }
    } else {
        return null;
    }
}

export default CategorySelection;