import React from 'react';
import {IInstalledExtension} from "../../models/Extension";
import {Callout, DefaultButton, Text} from "@fluentui/react";
import {IActionResponse} from "../../dal/BaseDAL";
import {IsMutationSuccess} from "../../logic/MutationTypeChecker";
import {useId} from "@fluentui/react-hooks";
import {
    useSimpleValidateExtensionFolioMutation,
    useSimpleValidateInstalledExtensionMutation
} from "../../store/Api";

interface IInstalledExtensionsTableValidationButtonProps {
    extension?: IInstalledExtension;
    folio?: string
}

const InstalledExtensionsTableValidationButton: React.FC<IInstalledExtensionsTableValidationButtonProps> = (props) => {
    const [validate] = useSimpleValidateInstalledExtensionMutation();
    const [validateFolio] = useSimpleValidateExtensionFolioMutation()
    const [validating, setValidating] = React.useState<boolean>(false);
    const [validationResult, setValidationResult] = React.useState<IActionResponse | undefined>(undefined);
    const [calloutOpen, setCalloutOpen] = React.useState<boolean>(false);
    const buttonId = useId('validation-button');

    const run = async () => {
        if (validationResult != null) {
            setCalloutOpen(true);
            return;
        }
        setValidating(true);
        if(props?.extension?.id) {
            const result = await validate({id: props.extension.id});
            if (IsMutationSuccess<IActionResponse>(result)) {
                setValidationResult(result.data);
            } else {
                setValidationResult({success: false, message: 'Unknown error', id: ''})
            }
        }
        else if(props.folio) {
            const result = await validateFolio({id: props.folio})
            if (IsMutationSuccess<IActionResponse>(result)) {
                setValidationResult(result.data);
            } else {
                setValidationResult({success: false, message: 'Unknown error', id: ''})
            }
        }
        setValidating(false);
        
    }


    return <>
        <DefaultButton
            text={!validating ? 'Validate' : 'Validating'}
            disabled={validating}
            onClick={run}
            iconProps={validationResult ? {iconName: validationResult.success ? 'Checkmark' : 'Warning'} : undefined}
            id={buttonId}
        />
        {calloutOpen && <Callout
            target={`#${buttonId}`}
            onDismiss={() => setCalloutOpen(false)}
            setInitialFocus
            styles={validationResult?.success == false ? {
                calloutMain: { padding: '1em', backgroundColor: '#f8d1d1' },
                beak: { backgroundColor: '#f8d1d1' },
            } : {
                calloutMain: { padding: '1em' }
            }}
            >
            <Text as={'h1'} block variant={'xLarge'}>{validationResult?.success == false ? 'Validation Failed' : 'Validation Succeeded' }</Text>
            <Text variant={'mediumPlus'}>{validationResult?.success == false ? `Error: ${validationResult?.message}` : 'Extension settings were successfully validated'}</Text>
        </Callout>}
        </>
}

export default InstalledExtensionsTableValidationButton;