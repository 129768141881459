import React, { useState } from "react";
import { LinkedItemsTable } from "./LinkedItemsTable";
import {INormalizedProduct} from "../../models/NormalizedProduct";
import {Checkbox} from "@fluentui/react";

interface ILinkedItemsDetailsProps {
    product: INormalizedProduct;
    editMode: boolean;
}

const LinkedItemsDetails: React.FC<ILinkedItemsDetailsProps> = (props) => {
    const accessories = props.product.links.filter(p => p.type === 'accessories');
    const alternatives = props.product.links.filter(p => p.type === 'alternatives');
    const replacements = props.product.links.filter(p => p.type === 'replacements');
    const services = props.product.links.filter(p => p.type === 'services');

    const [filterStock, setFilterStock] = useState<boolean>(false);

    return (
        <>
            <Checkbox
                label="In Stock Only"
                checked={filterStock}
                onChange={(e, d) => {
                    setFilterStock(d ?? false)
                }}
            />
            {services.length > 0 && <LinkedItemsTable
                catalog={props.product.catalog}
                header="Services"
                items={services}
                filterStock={filterStock}
                editMode={props.editMode}
            />}
            {accessories.length > 0 && <LinkedItemsTable
                catalog={props.product.catalog}
                header="Accessories"
                items={accessories}
                filterStock={filterStock}
                editMode={props.editMode}
            />}
            {replacements.length > 0 && <LinkedItemsTable
                catalog={props.product.catalog}
                header="Replacements"
                items={replacements}
                filterStock={filterStock}
                editMode={props.editMode}
            />}
            {alternatives.length > 0 && <LinkedItemsTable
                catalog={props.product.catalog}
                header="Alternatives"
                items={alternatives}
                filterStock={filterStock}
                editMode={props.editMode}
            />}
        </>
    );
}

export default LinkedItemsDetails;