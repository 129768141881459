import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {setAuth} from '../../store/authSlice';
import { RootState } from '../../store/rootReducer';
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {InteractionRequiredAuthError, InteractionStatus, ServerError} from "@azure/msal-browser";
import Authenticating from "./Authenticating";
import {loginRequest, msalConfig} from "../../authConfig";

const AuthHandler: React.FC = (props) => {
    const dispatch = useDispatch();
    const {instance, accounts, inProgress} = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const auth = useSelector((state: RootState) => state.auth);
    const queryString = window.location.search;
    const path = window.location.pathname;
    const urlParams = new URLSearchParams(queryString);
    const apiKey = urlParams.get('apiKey');
    const sessionKey = urlParams.get('sessionKey');
    
    const needCredentials = () => {
        if(sessionKey || auth.sessionKey || path == '/embedded/login') { return false;}
        return !(auth.token && isAuthenticated);
    }
    
    useEffect(() => {
        console.log(['INTERACTION STATUS', inProgress]);
        if (!instance || inProgress === InteractionStatus.Startup) {
            console.log('MSAL is not ready')
            return;
        }
        if (inProgress === InteractionStatus.HandleRedirect || inProgress === InteractionStatus.Login){
            console.log('MSAL is busy')
            return;
        }
        const account = instance.getActiveAccount() ?? accounts[0]
        if (needCredentials() && account) {
            console.log('trying to get access token ')
            const accessTokenRequest = {
                scopes: ['openid', 'profile', 'email', 'offline_access', msalConfig.auth.clientId],
                account: account,
            };
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    const accessToken = accessTokenResponse.accessToken;
                    const idToken = accessTokenResponse.idToken

                    if(idToken && account && account.idTokenClaims && account.idTokenClaims['name']){
                        console.log('got access token. Setting it.')
                        dispatch(setAuth({
                            token: idToken,
                            user: account.idTokenClaims['name'],
                            accessToken: accessToken
                        }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error instanceof InteractionRequiredAuthError) {
                        console.log('interaction required. Redirecting')
                        instance.acquireTokenRedirect(accessTokenRequest);
                    }
                    
                    else if (error instanceof ServerError) {
                        console.log('Unknown error. Redirecting to attempt login again')
                        instance.acquireTokenRedirect(accessTokenRequest);
                    }

                });
        }
        else if(needCredentials()) {
            console.log('not authed. Redirecting to login flow.')
            instance.loginRedirect(loginRequest);
        }
    }, [instance, accounts, inProgress, auth.token]);



    if (inProgress == InteractionStatus.Logout) {
        return <Authenticating message={'Logging Out'}></Authenticating>
    }
    else if(inProgress == InteractionStatus.HandleRedirect) {
        return <Authenticating message={'Redirecting'}></Authenticating>

    }
    else if (inProgress != InteractionStatus.None) {
        return <Authenticating />;
    }

    else {
        return <>{props.children}</>
    }
}

export default AuthHandler;