import React from 'react';
import {INavLink, INavLinkGroup, INavStyles, Nav, Stack, StackItem} from "@fluentui/react";
import {useHistory} from "react-router-dom";

const ReportingLayout: React.FC = (props) => {
    const history = useHistory();
    const navStyles: Partial<INavStyles> = { root: { width: 300 }};
    const searchNavLinks: INavLink[] = [
        {
            key: 'Search Usage',
            name: 'Search Usage',
            icon: 'Search',
            url: '/reporting/search/usage'
        }
    ]

    const navLinkGroups: INavLinkGroup[] = [
        {
            name: 'Search',
            links: searchNavLinks
        },
    ]

    return <>
        <Stack horizontal tokens={{childrenGap: '1em', padding: '1em'}}>
            <StackItem>
                <Nav
                    groups={navLinkGroups}
                    styles={navStyles}
                    onLinkClick={(e, l) => {
                        e?.preventDefault();
                        if (l?.url)
                            history.push(l?.url);
                    }}
                    />
            </StackItem>
            <StackItem grow>
                {props.children}
            </StackItem>
        </Stack>
    </>
}

export default ReportingLayout;