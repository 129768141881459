import React from "react";
import NavMenu from "./NavMenu";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";

const Layout: React.FC = (props) => {
    const auth = useSelector((state: RootState) => state.auth);

    return (
        <>
        {auth.isAuthed && <>
            <NavMenu />
            <div className="all-content">
                {props.children}
            </div></>}
        </>
    );
};

export default Layout;
