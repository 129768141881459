import React from 'react';

interface ICurrencyDisplayProps {
    value?: number;
}

const CurrencyDisplay: React.FC<ICurrencyDisplayProps> = (props) => {
    if (props.value == null) return null;
    return <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'}).format(props.value)}</>
}

export default CurrencyDisplay;