import React, {useEffect, useState} from "react";
import {IAdditionalData, IInstalledExtension} from "../../models/Extension";
import {IWorkspaceItem} from "../../models/Workspace";
import {Dropdown, IDropdownOption} from "@fluentui/react";
import {
    useGetExportExtensionOptionsQuery,
    useGetItemExtensionFieldsQuery,
    usePutItemExtensionFieldMutation
} from "../../store/Api";
import {cloneDeep} from "lodash";

interface IExtensionItemDropdownProps {
    extension: IInstalledExtension;
    item: IWorkspaceItem;
    field: IAdditionalData;
    additionalData?: {[key:string]:string}
    onChange?:(value: string) => void;
}

const ExtensionItemDropdown: React.FC<IExtensionItemDropdownProps> = (props) => {
    const [updateValue] = usePutItemExtensionFieldMutation();
    const [additionalData, setAdditionalData] = useState<{[key:string]:string}>({});
    const getValue = useGetItemExtensionFieldsQuery({
        catalog: props.item.catalog,
        id: props.item.productId,
        extension: props.extension.id
    });

    const setData = () => {
        if (props.field.optionsFields) {
            const newData = cloneDeep(additionalData);
            for(const field of props.field.optionsFields) {
                const value = getValue.data?.find(v => v.key === field.field)?.value;
                if (value) newData[field.field] = value;
            }
            setAdditionalData(newData);
        }
    }
    useEffect(() => {
        setData();
    }, [getValue.status])

    const getOptions = useGetExportExtensionOptionsQuery({
        extensionId: props.extension.id,
        field: props.field.field,
        additionalData: additionalData,
    });
    const options: IDropdownOption[] = getOptions.data?.options?.map((option) => ({
        key: option.value,
        text: option.display,
    })) ?? [];

    const value = getValue.data?.find(v => v.key === props.field.field)?.value
    return <>
        <Dropdown
            options={options}
            disabled={getOptions.isFetching || getValue.isFetching}
            placeholder={getOptions.isFetching || getValue.isFetching ? 'Loading' : ''}
            selectedKey={value}
            onChange={(e,d) => {
                updateValue({
                    extension: props.extension.id,
                    catalog: props.item.catalog,
                    id: props.item.productId,
                    field: props.field.field,
                    value: d?.key.toString() ?? ''
                })
                if (props.onChange) props.onChange(d?.key.toString() ?? '')
            }}
        />
    </>
}

export default ExtensionItemDropdown;