import React from 'react';
import { useGetCompaniesQuery } from '../../store/Api';
import {CommandBar, DetailsList, IColumn, ICommandBarItemProps} from '@fluentui/react';
import { ButtonColumnGenerator, ColumnGenerator } from '../../logic/Columns/ColumnGenerator';
import { ICompany } from '../../models/Company';
import CompanyDetailsModal from './CompanyDetailsModal';

const CompanyManagementDisplay: React.FC = () => {
    const [query, setQuery] = React.useState('');
    const companies = useGetCompaniesQuery(query);
    const [selectedCompany, setSelectedCompany] = React.useState<ICompany | undefined>(undefined);
    const [newCompany, setNewCompany] = React.useState(false);
    
    const columns: IColumn[] = [
        ColumnGenerator('Company Name', 'companyName', 100, 200),
        ColumnGenerator('Company ID', 'companyIdentifier', 100, 200),
        ButtonColumnGenerator((item: ICompany) => setSelectedCompany(item), 'Details'),
    ]

    const newCommand: ICommandBarItemProps[] = [{
        key: 'new',
        text: 'Add New Company',
        iconProps: { iconName: 'Add' },
        onClick: () => {setNewCompany(true);},
    }]

    return (
        <div>
            <CommandBar
                items={newCommand}
            />
            <DetailsList
                items={companies.data ?? []}
                columns={columns}
                />
            <CompanyDetailsModal
                company={selectedCompany}
                isOpen={selectedCompany !== undefined || newCompany}
                onClose={() => {
                    setSelectedCompany(undefined);
                    setNewCompany(false);
                }}
                />
        </div>
    );
}

export default CompanyManagementDisplay;