import { DefaultButton, Dialog, DialogFooter, PrimaryButton, TextField } from '@fluentui/react';
import React from 'react';
import { useSetupStripeMutation } from '../../../store/Api';

interface IStripeSetupDialogProps {
    isOpen: boolean;
    onDismiss: () => void;
}

const StripeSetupDialog: React.FC<IStripeSetupDialogProps> = (props) => {
    const [key, setKey] = React.useState<string>('');
    const [saving, setSaving] = React.useState<boolean>(false);
    const [setupStripe] = useSetupStripeMutation();

    const close = () => {
        if (saving) return;
        setKey('');
        props.onDismiss();
    }

    const save = async () => {
        if (saving) return;
        setSaving(true);
        await setupStripe({key: key});
        setSaving(false);
        close();
    }

    return (
        <Dialog
            hidden={!props.isOpen}
            onDismiss={close}
            dialogContentProps={{
                title: 'Setup Stripe',
                showCloseButton: true,
            }}
            >
                <TextField
                    label='Stripe Key'
                    value={key}
                    onChange={(e, value) => setKey(value ?? '')}
                    type='password'
                    disabled={saving}
                    />
                <DialogFooter>
                    <PrimaryButton text='Save' onClick={save} disabled={saving} />
                    <DefaultButton text='Cancel' onClick={close} disabled={saving} />
                </DialogFooter>
        </Dialog>
    );
}

export default StripeSetupDialog;