import React, {useEffect, useState} from 'react';

import {
    useGetCatalogsQuery,
    useGetOrganizationsQuery,
    useSetSelectedOrganizationMutation,
    useUpdateOrganizationMutation
} from "../../store/Api";
import {CommandBar, Dropdown, ICommandBarItemProps, Label, PrimaryButton, Stack, TextField} from "@fluentui/react";
import EditableImage from "../../components/DetailsModal/EditableImage";
import CountrySelection from "../../components/CountrySelection/CountrySelection";
import {IOrganization} from "../../models/Settings";
import DataField from "../../components/Common/DataField";

const OrganizationManagement: React.FC = () => {
    const [selectedOrg, setSelectedOrg] = useState<IOrganization>();
    const organizations = useGetOrganizationsQuery();
    const [setGlobalOrg] = useSetSelectedOrganizationMutation();
    const catalogs = useGetCatalogsQuery();
    const [updateOrg] = useUpdateOrganizationMutation();

    const [name, setName] = useState<string | undefined>('');
    const [contact1, setContact1] = useState<string | undefined>();
    const [contact2, setContact2] = useState<string | undefined>();
    const [contactEmail, setContactEmail] = useState<string | undefined>();
    const [contactPhone, setContactPhone] = useState<string | undefined>();
    const [address1, setAddress1] = useState<string | undefined>();
    const [address2, setAddress2] = useState<string | undefined>();
    const [city, setCity] = useState<string | undefined>();
    const [stateProvince, setStateProvince] = useState<string | undefined>();
    const [postalCode, setPostalCode] = useState<string | undefined>();
    
    const [logo, setLogo] = useState<string | undefined>();
    const [defaultCatalog, setDefaultCatalog] = useState<string | undefined>();
    const [country, setCountry] = useState<string | undefined>();
    const [saving, setSaving] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [procurementEnabled, setProcurementEnabled] = useState<boolean>();
    const [punchoutCurrency, setPunchoutCurrency] = useState<string | undefined>();
    
    useEffect(() => {
        if(organizations.data?.[0]){
            setSelectedOrg(organizations.data?.[0])
        }
    }, [organizations.data]);
    
    useEffect(() => {
        if (selectedOrg) {
            setName(selectedOrg.name);
            setLogo(selectedOrg.logo);
            setDefaultCatalog(selectedOrg.defaultCatalog);
            setCountry(selectedOrg.country);
            setContact1(selectedOrg.contact1);
            setContact2(selectedOrg.contact2);
            setContactEmail(selectedOrg.contactEmail);
            setContactPhone(selectedOrg.contactPhone);
            setAddress1(selectedOrg.address1);
            setAddress2(selectedOrg.address2);
            setCity(selectedOrg.city)
            setStateProvince(selectedOrg.stateProvince);
            setPostalCode(selectedOrg.postalCode);
            setPunchoutCurrency(selectedOrg.punchoutCurrency);
            const procurementConfig = selectedOrg.procurementEnabled
            setProcurementEnabled(procurementConfig)
        }
    }, [selectedOrg]);

    useEffect(() => {
        if (selectedOrg) {
            if (selectedOrg.name !== name ||
                selectedOrg.logo !== logo ||
                selectedOrg.defaultCatalog !== defaultCatalog ||
                selectedOrg.country !== country ||
                selectedOrg.punchoutCurrency !== punchoutCurrency) {
                setUpdated(true);
            } else {
                setUpdated(false);
            }
        }
    }, [name, logo, defaultCatalog, country]);

    const changeComparer = (changer: (value: (((prevState: string | undefined) => string) | string | undefined)) => void, 
                            currentValue?: string | undefined, originalValue?: string | undefined) => {
        if(currentValue != originalValue){
            changer(currentValue)
            setUpdated(true)
        }
    }
    const save = () => {
        setSaving(true);
        updateOrg({
            name,
            logo,
            defaultCatalog,
            country,
            contact1,
            contact2,
            contactEmail,
            contactPhone,
            address1,
            address2,
            city,
            stateProvince,
            postalCode,
            punchoutCurrency,
        })
            .then((result) => {
                setSaving(false);
                setUpdated(false);
                
            })
            .catch((err) => {
                setSaving(false);
            })

        organizations.refetch().then(() => {
            setGlobalOrg(organizations.data?.[0])
        })
    }

    const farCommands: ICommandBarItemProps[] = [
        {
            key: 'refresh',
            text: 'Refresh',
            iconOnly: true,
            iconProps: {iconName: 'Refresh'},
            onClick: () => {
                organizations.refetch().then(() => {
                    setGlobalOrg(organizations.data?.[0])
                });
                catalogs.refetch();
            }
        }
    ]

    return (
        <div>
            <h1>Organization Management</h1>
            <CommandBar items={[]} farItems={farCommands} />
            <Stack tokens={{childrenGap: '1em'}}>
                <TextField
                    label='Organization Name'
                    value={name}
                    onChange={(e, v) => changeComparer(setName, v, name)}
                />
                <div>
                    <Label>Organization Logo</Label>
                    <EditableImage
                        image={{
                            url: logo ?? '',
                            type: 'Custom',
                            name: name ?? '',
                            id: 'logo',
                            isCustom: true,
                        }}
                        editMode={true}
                        onChange={(id, url) => setLogo(url)}
                    />
                </div>
                {procurementEnabled && <div className={"filtered-field-container"}>
                    <h3>Procurement Defaults</h3>
                    <h4>Address</h4>
                    <DataField
                        placeholder='Contact 1'
                        value={contact1}
                        onChange={(e, v) => changeComparer(setContact1, v, contact1 ?? "")}
                    />
                    <DataField
                        placeholder='Contact 2'
                        value={contact2}
                        onChange={(e, v) => changeComparer(setContact2, v, contact2)}
                    />
                    <DataField
                        placeholder='Address 1'
                        value={address1}
                        onChange={(e, v) => changeComparer(setAddress1,v, address1)}
                    />
                    <DataField
                        placeholder='Address 2'
                        value={address2}
                        onChange={(e, v) => changeComparer(setAddress2,v, address2)}
                    />
                    <DataField
                        placeholder='City'
                        value={city}
                        onChange={(e, v) => changeComparer(setCity,v, city)}
                    />
                    <DataField
                        placeholder='State / Province'
                        value={stateProvince}
                        onChange={(e, v) => changeComparer(setStateProvince,v, stateProvince)}
                    />
                    <DataField
                        placeholder='Zip / Postal Code'
                        value={postalCode}
                        onChange={(e, v) => changeComparer(setPostalCode,v, postalCode)}
                    />
                    <DataField
                        label='Contact Email'
                        value={contactEmail}
                        onChange={(e, v) => changeComparer(setContactEmail, v, contactEmail)}
                    />
                    <DataField
                        mask={"(999) 999 - 9999"}
                        label='Contact Phone Number'
                        value={contactPhone ?? ''}
                        onChange={(e, v) => changeComparer(setContactPhone, v, contactPhone)}
                    />
                </div>}
                <CountrySelection value={country} onChange={setCountry}/>
                <Dropdown
                    label={'Default Catalog'}
                    options={catalogs.data?.map((c) => ({key: c.name, text: c.description})) ?? []}
                    selectedKey={defaultCatalog}
                    onChange={(e, o) => setDefaultCatalog(o?.key as string)}
                />
                <TextField
                    label='Punchout Currency'
                    value={punchoutCurrency}
                    onChange={(e, v) => changeComparer(setPunchoutCurrency, v, punchoutCurrency)}
                />
                <div>
                    <PrimaryButton
                        text={saving ? 'Saving' : 'Save'}
                        onClick={save}
                        disabled={saving || !updated}
                    />
                </div>
            </Stack>
        </div>
    );
}

export default OrganizationManagement;