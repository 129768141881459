import React, {useState} from "react";
import {
    Panel,
    PanelType, Pivot, PivotItem,
} from "@fluentui/react";
import {IExtensionInstallRequest, IInstalledExtension} from "../../models/Extension";
import {useGetAvailableExtensionQuery} from "../../store/Api";
import ExtensionLibraryTable from "./ExtensionLibraryTable";
import UnmatchedProductsTable from "./UnmatchedProductsTable";
import ExtensionLogDisplay from "../InstalledExtensions/ExtensionLogDisplay";
import ExtensionInstallForm, {ExtensionInstallButtons} from "./ExtensionDataForm";
import {IActionResponse} from "../../dal/BaseDAL";

interface IExtensionEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    installedExtension?: IInstalledExtension;
    extensionId: string;
    isNew: boolean;
}


const ExtensionDetailsPanel: React.FC<IExtensionEditModalProps> = (props) => {
    const extension = useGetAvailableExtensionQuery(props.installedExtension?.source ?? props.extensionId, 
        {skip: !(props.installedExtension?.source ?? props.extensionId) || props.installedExtension?.extensionType == 'Folio'});
    const [updateRequest, setUpdateRequest] = useState<IExtensionInstallRequest | undefined>(undefined);
    const [selectedPivot, setSelectedPivot] = useState<string>('edit');
    const [validationResponse, setValidationResponse] = useState<IActionResponse | undefined>(undefined);
    const [saveError, setSaveError] = useState<string | undefined>(undefined);

    const getStaticData = () => {
        if(!props?.installedExtension?.staticData){
            const staticData: { [key: string]: string } = {};
            for (const field of extension.data?.additionalData ?? []) {
                if (field.fieldType !== 'static') continue;
                staticData[field.field] = field.dataType == 'boolean' ? //If boolean type, set to false if no well-defined default
                    (field.defaultValue?.toLowerCase() != 'true' ? 'false' : 'true') :
                    field.defaultValue ?? '';
            }
            return staticData
        }
        return props.installedExtension?.staticData
    }
    React.useEffect(() => {
        if(extension.data) {
            const staticData = getStaticData();
            if (props.installedExtension == null) {
                setUpdateRequest({
                    additionalData: staticData,
                    extensionLevel: 'organization',
                    extensionNickname: extension.data?.name ?? '',
                    vendor: extension.data?.defaultVendor ?? '',
                    catalog: '',
                    mappings: [],
                    schedule: undefined,
                    extensionType: extension.data?.category,
                });
                return;
            }
            setUpdateRequest({
                additionalData: staticData,
                extensionLevel: props.installedExtension.user ? 'user' : 'organization',
                extensionNickname: props.installedExtension.nickname,
                vendor: props.installedExtension.vendor,
                catalog: props.installedExtension.targetCatalog,
                mappings: props.installedExtension.mappings,
                schedule: props.installedExtension.schedule
            })
        }
    }, [props.isOpen, extension.data]);

    const close = () => {
        setUpdateRequest(undefined);
        setValidationResponse(undefined);
        props.onClose();
    }

    return (
        <Panel
            onOuterClick={() => {console.log("swallowing modal close event")}}
            isOpen={props.isOpen}
            headerText={props.isNew ? 'Install Extension' : 'Extension Details'}
            isFooterAtBottom={true}
            type={PanelType.medium}
            onDismiss={close}
            onRenderFooter={() => (<>
                {selectedPivot === 'edit' && <ExtensionInstallButtons
                        request={updateRequest}
                        extension={extension.data}
                        installed={props.installedExtension?.id}
                        onSaved={close}
                        onValidate={setValidationResponse}
                        onClose={close}
                        onSaveError={setSaveError}
                        isNew={props.isNew}
                        />
                }
            </>)}
        >
            {extension.data === undefined
                ? extension.isFetching ? <>Loading</> :
                    <p>An error occurred loading the extension. Please try again.</p>
                : (
                    <Pivot
                        selectedKey={selectedPivot}
                        onLinkClick={(item) => {
                            if (item?.props?.itemKey) setSelectedPivot(item.props.itemKey)
                        }}
                        overflowBehavior='menu'
                        styles={{itemContainer: '1em'}}
                    >
                        <PivotItem headerText='Extension Settings' itemKey={'edit'}>
                            {updateRequest && <ExtensionInstallForm
                                extension={extension.data}
                                request={updateRequest}
                                onChange={setUpdateRequest}
                                validationResponse={validationResponse}
                                saveError={saveError}
                                isNew={props.isNew}
                                installedId={props.installedExtension?.id}
                                />}
                        </PivotItem>
                        {props.installedExtension?.extensionType === 'import' && <PivotItem headerText='Unmatched Products' itemKey={'unmatched'}>
                            <UnmatchedProductsTable extensionId={props.installedExtension.id} />
                        </PivotItem>}
                        {props.installedExtension?.allowFileLibrary && <PivotItem headerText='File Library' itemKey={'files'}>
                            <ExtensionLibraryTable installedExtension={props.installedExtension} />
                        </PivotItem>}
                        {props.installedExtension && <PivotItem headerText='Logs' itemKey={'logs'}>
                            <ExtensionLogDisplay extension={props.installedExtension} />
                        </PivotItem>}
                    </Pivot>
                )}
        </Panel>
    );
}

export default ExtensionDetailsPanel;
