import React, {useEffect, useState} from 'react';
import { CheckboxGroup } from '../CheckboxGroup';
import {ISearchQueryResponse} from "../../models/Search";
import {cloneDeep} from "lodash";
import Divider from "../Common/Divider"

interface IManufacturerFilters {
    manufacturers?: string[];
    onChange: (manufacturers: string[]) => void;
    results?: ISearchQueryResponse;
}

const ManufacturerFilters: React.FC<IManufacturerFilters> = (props) => {
    const [merged, setMerged] = useState<string[]>([]);
    useEffect(() => {
        const manufacturers = cloneDeep(props.manufacturers ?? []);
        if (props.results?.metadata?.manufacturers)
            for(const mf of props.results.metadata.manufacturers)
                if (manufacturers.indexOf(mf) === -1)
                    manufacturers.push(mf);
        setMerged(manufacturers);
    }, [props.manufacturers, props.results?.metadata?.manufacturers]);

    if (merged.length === 0) return null;

    return (<>
        <Divider title="Manufacturer"/>
            <CheckboxGroup
                options={merged}
                onChange={(selected: string[]) => {
                    props.onChange(selected);
                }}
                selected={props.manufacturers ?? []}
            />
    </>
    );
}

export default ManufacturerFilters;