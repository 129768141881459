import React, {useCallback, useState} from 'react';
import Autosuggest from "react-autosuggest";
import {debounce} from "lodash";
import {useLazyGetImportCompanyExtensionOptionsQuery} from "../../store/Api";
interface IExtensionCompanyFieldSearchProps {
	extensionId: string;
	onChange: (value: string) => void;
	isDisabled?: boolean;
}

const ExtensionCompanyFieldSearch: React.FC<IExtensionCompanyFieldSearchProps> = (props) => {
	const [lazyCompanySearch] = useLazyGetImportCompanyExtensionOptionsQuery();
	const [searchText, setSearchText] = useState<string>();
	const [options, setOptions] = useState<{key: string, text: string, value: string}[]>([]);
	
	const debouncedSetSearchedKeywords = useCallback(
		debounce(async (val:string | undefined) => {
			if(val &&  val.length > 0) {
				load(val)
			}
		}, 500, {leading: false}),
		[]
	);
	
	const load = (value: string) => {
			lazyCompanySearch({extensionId: props.extensionId, search: value}).then((result) => {
			if(result.data?.options){
				setOptions(result.data.options?.map(o => ({
					key: o.value,
					text: o.display,
					value: o.value
				})))
			}})
	}
	return (
		<Autosuggest
			alwaysRenderSuggestions={false}
			suggestions={options ?? []}
			multiSection={false}
			onSuggestionsFetchRequested={({value}) => {if(value.length > (searchText?.length ?? 0)) {debouncedSetSearchedKeywords(value)}}}
			onSuggestionsClearRequested={() => setOptions([])}
			getSuggestionValue={(suggestion) => suggestion.value}
			renderSuggestion={(suggestion) => {
				return (<div>{suggestion.text}</div>)
			}}
			onSuggestionSelected={(event, d) => {setSearchText(undefined); setOptions([]);props.onChange(d?.suggestion.value?.toString())}}
			inputProps={{
				disabled: props.isDisabled,
				placeholder: 'Search Company',
				value: searchText ?? '',
				onChange: (e, v) => {
					setSearchText(v.newValue)
				},
				style: {
					width: '100%',
					boxSizing: 'border-box',
					height: 'auto',
				},
				onKeyDown: (async (e) => {
					if (e.key === 'Enter') {
						load(searchText ?? "");
					}
				})
			}}
		/>

	);
}

export default ExtensionCompanyFieldSearch;