import React from 'react';
import {
    DefaultButton,
    getTheme,
    IColumn,
    SpinButton,
    Stack,
    PrimaryButton
} from "@fluentui/react";
import {generateShortDescription} from ".././Product";
import { INormalizedProduct } from "../../models/NormalizedProduct";
import { FavoritesDisplay } from "../../components/Products/FavoritesDisplay";
import { InStockDisplay } from "../../components/Products/InStockDisplay";
import { CostDisplay } from "../../components/Products/CostDisplay";
import {IWorkspaceItem, IWorkspaceItemDetails} from "../../models/Workspace";
import AddToWorkspaceButton from '../../components/ProductDetails/AddToWorkspaceButton';
import VendorSourceSelection from "../../components/ProductTable/VendorSourceSelection";
import PriceEntry from "../../components/ProductTable/PriceEntry";
import MarkupEntry from "../../components/ProductTable/MarkupEntry";
import WorkspaceMarkupHeader from "../../components/ProductTable/WorkspaceMarkupHeader";
import VendorDisplay from "../../components/Products/VendorDisplay";
import {IVendor} from "../../models/Search";
import TaxCodeSelection from "../../components/ProductTable/TaxCodeSelection";
import TaxCodeHeader from "../../components/ProductTable/TaxCodeHeader";
import TaxableSelection from "../../components/ProductTable/TaxableSelection";
import TaxableHeader from "../../components/ProductTable/TaxableHeader";
import PriceLevelSelection from "../../components/ProductTable/PriceLevelSelection";
import SortableColumnHeader from "../../components/ResultTable/SortableColumnHeader";
import QuantityEntry from "../../components/ProductTable/QuantityEntry";
import BestPriceDisplay from '../../components/Products/BestPriceDisplay';
import BestPriceColumnHeader from '../../components/ResultTable/BestPriceColumnHeader';
import CurrencyDisplay from '../../components/Currency/CurrencyDisplay';

export interface IProductColumnBase {
    basicColumn: IColumn;
}
export interface IProductColumn extends IProductColumnBase{
    onSearchTableRender?: (product: INormalizedProduct) => React.ReactElement;
    onSearchTableHeaderRender?: () => React.ReactElement;
    onSearchTableExampleRender?: (product: INormalizedProduct) => React.ReactElement;
    onWorkspaceTableRender?: (product: IWorkspaceItem) => React.ReactElement;
    onWorkspaceTableExampleRender?: (product: IWorkspaceItem) => React.ReactElement;
    refreshingVendors?: boolean;
}

export const COLUMN_PROPERTIES: {[key: string]: IProductColumn } = {
    //WS & Search
    'VENDOR_COLUMN': {
        basicColumn: {
            key: 'vendor',
            name: 'Vendor',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            isMultiline: true,
        },
    },
    'DENSE_VENDOR_COLUMN': {
        basicColumn: {
            key: 'dense_vendor',
            name: 'Vendor',
            minWidth: 115,
            maxWidth: 115,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            isMultiline: true,
        },
    },
    'name': {
        basicColumn: {
            key: 'name',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            isMultiline: true
        },
        onSearchTableHeaderRender: () => <SortableColumnHeader
            label='Name'
            field='name'
            criteriaFilter='name'
        />,
        onSearchTableRender: (product: INormalizedProduct) => {
            const shortDescription = generateShortDescription(product);
            return <div>
                {product.name}
                {shortDescription && <p style={{color: 'grey'}}>
                    {shortDescription}
                </p>}
            </div>
        },
        onWorkspaceTableRender: (product: IWorkspaceItem) => <div>{product.product ? product.product.name : product.adhoc?.name}</div>,
    },
    'msrp': {
        basicColumn: {
            key: 'msrp',
            name: 'MSRP',
            fieldName: 'msrp',
            minWidth: 100,
            maxWidth: 100,
        },
        onSearchTableRender: (product: INormalizedProduct) => <CurrencyDisplay value={product.msrp} />,
        onWorkspaceTableRender: (product: IWorkspaceItem) => <CurrencyDisplay value={product.product?.msrp ?? product.normalized?.msrp} />,
    },
    //WS &Search
    'image': {
        basicColumn: {
            key: 'image',
            name: '',
            fieldName: 'imageDisplay',
            minWidth: 50,
            maxWidth: 50,
        },
        onSearchTableRender: (product: INormalizedProduct) => (product.image
            ? <img src={product.image} style={{ maxHeight: '50px', maxWidth: '50px', margin: '0 auto' }} alt={product.name} />
            : <div></div>),
        onWorkspaceTableRender: (product: IWorkspaceItem) => (product.product?.image
            ? <img src={product.product.image} style={{ maxHeight: '50px', maxWidth: '50px', margin: '0 auto' }} alt={product.product.name} />
            : <div></div>)
    },
    //WS & Search
    'availability': {
        basicColumn: {
            key: 'availability',
            name: 'Availability',
            fieldName: 'stockLabel',
            minWidth: 150,
            maxWidth: 150,
            isRowHeader: true,
        }
    },
    //WS & Search
    'sortOrder': {
        basicColumn: {
            key: 'sortOrder',
            name: 'Item Number',
            fieldName: 'sortOrder',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
        },
        onWorkspaceTableRender: (product: IWorkspaceItem) => ( <div>{product.sortOrder}</div>)
    },
    //WS & Search
    'cost': {
        basicColumn: {
            key: 'cost',
            name: 'Cost',
            fieldName: 'cost',
            minWidth: 100,
            maxWidth: 150,
            isMultiline: true,
        },
        onSearchTableRender: (product: INormalizedProduct) => <CostDisplay product={product} />,
        onWorkspaceTableRender: (product: IWorkspaceItem) => {
            if (product.normalized) {
                return <CostDisplay product={product.normalized} source={product.details.source} wsItem={product}/>
            }
            else if (product.adhoc?.cost) { return <CostDisplay wsItem={product}/>}
            else return <div></div>
        },
    },
    'internalPartNumber': {
        basicColumn: {
            key: 'internalPartNumber',
            name: 'Internal PN',
            fieldName: 'internalPartNumber',
            minWidth: 100,
            maxWidth: 150,
        }
    },
    //Search
    'favorite': {
        basicColumn: {
            key: 'favorite',
            name: '',
            fieldName: 'favorite',
            minWidth: 25,
            maxWidth: 25,
            isIconOnly: true,
        },
        onSearchTableRender: (product: INormalizedProduct) => <FavoritesDisplay product={product} />,
    },
    //Search
    'manufacturer': {
        basicColumn: {
            key: 'manufacturer',
            name: 'Manufacturer',
            fieldName: 'manufacturer',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        onWorkspaceTableRender: (product: IWorkspaceItem) => <div>{product.product?.manufacturer}</div>,
        onSearchTableHeaderRender: () => <SortableColumnHeader
            label='Manufacturer'
            field='manufacturer'
            />
    },
    //Search
    'mfp': {
        basicColumn: {
            key: 'mfp',
            name: 'MFP',
            fieldName: 'manufacturerPartNumber',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
        },
        onWorkspaceTableRender: (product: IWorkspaceItem) => <div>{product.product?.manufacturerPartNumber}</div>,
    },
    //Search
    'details': {
        basicColumn: {
            key: 'details',
            name: '',
            fieldName: 'details',
            minWidth: 100,
            maxWidth: 100,
        },
    },
    //Search
    'addWs': {
        basicColumn: {
            key: 'addWs',
            name: '',
            fieldName: 'addWs',
            minWidth: 100,
            maxWidth: 100,
        },
        onSearchTableRender: (product: INormalizedProduct) => <AddToWorkspaceButton product={product}/>,
    },
    'best_price_base': {
        basicColumn: {
            key: 'best_price',
            name: 'Best Price',
            fieldName: 'best_price',
            minWidth: 100,
            maxWidth: 100,
        },
    },
    //WS
    'quantity': {
        basicColumn: {
            key: 'quantity',
            name: 'Quantity',
            fieldName: 'quantity',
            minWidth: 100,
            maxWidth: 100,
        }
    },
    //WS
    'actions': {
        basicColumn: {
            key: 'actions',
            name: '',
            fieldName: 'actions',
            minWidth: 200,
        },
    },
    //WS
    'price': {
        basicColumn: {
            key: 'price',
            name: 'Price',
            fieldName: 'price',
            minWidth: 125
        },
        onWorkspaceTableRender: (item) => <PriceEntry item={item} />
    },
    //WS
    'markup': {
        basicColumn: {
            key: 'markup',
            name: 'Markup',
            fieldName: 'markup',
            minWidth: 125,
            onRenderHeader: () => <WorkspaceMarkupHeader />,
        },
        onWorkspaceTableRender: (item) => <MarkupEntry item={item} />
    },
    //WS
    'listDiscount': {
        basicColumn: {
            key: 'listDiscount',
            name: 'List Price Discount',
            fieldName: 'listDiscount',
            minWidth: 100
        },
        onWorkspaceTableRender: () => <SpinButton step={1} defaultValue="0"/>
    },
    //WS
    'source': {
        basicColumn: {
            key: 'source',
            name: 'Source',
            fieldName: 'source',
            minWidth: 200
        },
        onWorkspaceTableRender: (item) => {
            if (item.normalized) { return <VendorSourceSelection item={item}/>}
            else if (item.adhoc?.source) { return <div>{item.adhoc?.source}</div>}
            else { return <div></div>}
        },
    },
    //WS
    'taxRate': {
        basicColumn: {
            key: 'taxRate',
            name: 'Tax Code',
            fieldName: 'taxRate',
            minWidth: 300,
            onRenderHeader: () => <TaxCodeHeader></TaxCodeHeader>
        },
        onWorkspaceTableRender: (item) => <TaxCodeSelection item={item} />
    },
    //WS
    'isTaxable': {
        basicColumn: {
            key: 'isTaxable',
            name: 'Taxable',
            fieldName: 'isTaxable',
            minWidth: 125,
            onRenderHeader: () => <TaxableHeader/>
        },
        onWorkspaceTableRender: (item) => <TaxableSelection item={item} />
    },
    'priceLevel': {
        basicColumn: {
            key: 'priceLevel',
            name: 'Price Level',
            fieldName: 'priceLevelPartNumber',
            minWidth: 200
        },
        onWorkspaceTableRender: (item) => <PriceLevelSelection item={item} />
    }
}

const EXAMPLE_VENDOR: IVendor = {
    name: 'Example Product',
    onHand: 10,
    inStock: true,
    cost: 134.99,
    vendorPartNumber: 'abc123',
    vendorName: 'Example Vendor',
    priceBreaks: [
        {
            quantity: 1,
            price: 134.99
        },
        {
            quantity: 5,
            price: 129.99
        },
        {
            quantity: 10,
            price: 124.99
        },
    ]
}

export const renderSearchColumns = (selectedColumns: string[], refreshing: boolean, isExample = false, refreshingVendors: string[] = []) : IColumn[] => {
    const columns: IColumn[] = [];
    for (const field of selectedColumns) {
        if(COLUMN_PROPERTIES[field]) {
            const tempCol: IColumn = COLUMN_PROPERTIES[field].basicColumn;

            if(field === "availability"){
                tempCol.onRender = (product: INormalizedProduct) => <InStockDisplay refreshing={refreshing} product={product} multiLine loadingWarehouses={refreshing} />;
            } else if (field === 'details') {
                continue;
            } else if(COLUMN_PROPERTIES[field].onSearchTableRender){
                tempCol.onRender = COLUMN_PROPERTIES[field].onSearchTableRender;
            }

            if (COLUMN_PROPERTIES[field].onSearchTableHeaderRender) {
                tempCol.onRenderHeader = COLUMN_PROPERTIES[field].onSearchTableHeaderRender;
            }

            columns.push(tempCol);

        } else if (field.startsWith('VENDOR:')) {
            const tempCol: IColumn = {
                ...COLUMN_PROPERTIES['VENDOR_COLUMN'].basicColumn,
                key: field,
                name: field.split(':')[1],
                onRenderHeader: () => <SortableColumnHeader
                    label={field.split(':')[1]}
                    vendor={field.split(':')[1]}
                    field='vendor'
                />,
            };
            tempCol.onRender = (product: INormalizedProduct) => {
                const vendors = product.vendors?.filter(v => v.vendorName == field.split(':')[1]);
                const vendorName = (vendors != null && vendors?.length > 0) ? vendors[0].vendorName : null;
                const exampleVendor: IVendor[] = [EXAMPLE_VENDOR];
                return <VendorDisplay
                    product={product}
                    refreshing={refreshing || (vendorName != null && refreshingVendors.includes(vendorName))}
                    loadingWarehouses={false}
                    vendor={isExample ? exampleVendor : vendors}
                />;
            }
            columns.push(tempCol);
        } else if (field.startsWith('DENSE_VENDOR:')) {
            const tempCol: IColumn = {
                ...COLUMN_PROPERTIES['DENSE_VENDOR_COLUMN'].basicColumn,
                key: field,
                name: field.split(':')[1],
                onRenderHeader: () => <SortableColumnHeader
                    label={field.split(':')[1]}
                    vendor={field.split(':')[1]}
                    field='vendor'
                />,
            };
            tempCol.onRender = (product: INormalizedProduct) => {
                const vendors = product.vendors?.filter(v => v.vendorName == field.split(':')[1]);
                const vendorName = (vendors != null && vendors?.length > 0) ? vendors[0].vendorName : null;
                const exampleVendor: IVendor[] = [EXAMPLE_VENDOR];
                return <VendorDisplay
                    product={product}
                    refreshing={refreshing || (vendorName != null && refreshingVendors.includes(vendorName))}
                    loadingWarehouses={false}
                    vendor={isExample ? exampleVendor : vendors}
                    dense
                />;
            }
            columns.push(tempCol);
        } else if (field === 'best_price') {
            columns.push({
                ...COLUMN_PROPERTIES['best_price_base'].basicColumn,
                onRender: (product) => <BestPriceDisplay product={product} refreshingVendors={refreshingVendors} />,
                onRenderHeader: () => <BestPriceColumnHeader />
            });
        }
    }
    return columns
}

export const renderWorkspaceColumns = (
    selectedColumns: string[],
    refreshingVendors: boolean,
    isExample: boolean,
    onDetailsChange: (product: IWorkspaceItem, details: IWorkspaceItemDetails) => void,
    onDelete: (workspaceProductId: string) => void,
    onDetailsClick: (product: IWorkspaceItem) => void,
    onAdhocClick: (product: IWorkspaceItem) => void, 
    sorter?: (column: IColumn) => void
): IColumn[] => {
    const columns: IColumn[] = [];
    for (const field of selectedColumns) {
        if(COLUMN_PROPERTIES[field]) {
            const tempCol: IColumn = COLUMN_PROPERTIES[field].basicColumn;
            tempCol.onColumnClick = (ev, column) =>  {if(sorter){sorter(column)}}
            if(field === "quantity"){
                tempCol.onRender = (product: IWorkspaceItem) => product.normalized
                    ? <QuantityEntry
                        item={product}
                        onChange={(v) => onDetailsChange(product, { ...product.details, quantity: v })}
                    />
                    : null;
                
            }
            else if(field === "actions"){
                const theme = getTheme();
                tempCol.onColumnClick = undefined
                tempCol.onRender = (product: IWorkspaceItem) => {
                    if (product.product) {
                        return <Stack horizontal tokens={{childrenGap: 5}}>
                            <DefaultButton
                                styles={{
                                    root: {backgroundColor: theme.palette.red, color: theme.palette.white},
                                    rootFocused: {backgroundColor: theme.palette.redDark, color: theme.palette.white},
                                    rootHovered: {backgroundColor: theme.palette.redDark, color: theme.palette.white},
                                }}
                                iconProps={{iconName: 'Delete'}}
                                onClick={isExample ? undefined : () => {
                                    if (onDelete)
                                        onDelete(product.id);
                                }}
                            />
                            <PrimaryButton text='Details' onClick={() => onDetailsClick(product)}/>
                        </Stack>
                    }
                    else if (product.adhoc) {
                         return <Stack horizontal tokens={{childrenGap: 5}}>
                            <DefaultButton
                                styles={{
                                    root: {backgroundColor: theme.palette.red, color: theme.palette.white},
                                    rootFocused: {backgroundColor: theme.palette.redDark, color: theme.palette.white},
                                    rootHovered: {backgroundColor: theme.palette.redDark, color: theme.palette.white},
                                }}
                                iconProps={{iconName: 'Delete'}}
                                onClick={isExample ? undefined : () => {
                                    if (onDelete)
                                        onDelete(product.id);
                                }}
                            />
                            <PrimaryButton text='Details' onClick={() => onAdhocClick(product)}/>
                        </Stack>
                    }
                    else return null;
                }
            }
            else if(field === "availability") {
                tempCol.onColumnClick = undefined
                tempCol.onRender = (product: IWorkspaceItem) => product.normalized ? <InStockDisplay hideWarehouses refreshing={refreshingVendors} product={product.normalized} multiLine loadingWarehouses={refreshingVendors} /> : null;
            }
            else if(COLUMN_PROPERTIES[field].onWorkspaceTableRender){
                tempCol.onRender = COLUMN_PROPERTIES[field].onWorkspaceTableRender;
            }
            columns.push(tempCol);

        }
    }
    return columns
}