import React from 'react';
import { IStorefrontOrderBundle } from '../../../models/Storefront';
import { DetailsList, IColumn, SelectionMode } from '@fluentui/react';
import { ColumnGenerator, CurrencyColumnGenerator } from '../../../logic/Columns/ColumnGenerator';

interface IStorefrontOrderBundlesTableProps {
    bundles: IStorefrontOrderBundle[];
}

const StorefrontOrderBundlesTable: React.FC<IStorefrontOrderBundlesTableProps> = (props) => {
    const columns: IColumn[] = [
        ColumnGenerator('Bundle', 'name', 100, undefined, (item:IStorefrontOrderBundle) => <>
            <p style={{fontWeight: 'bold'}}>{item.name}</p>
            {item.products.map((p) => <p key={p.productId}>{p.quantity}x {p.name}</p>)}
        </>),
        CurrencyColumnGenerator('Price', 'price', 100, 100),
        ColumnGenerator('Quantity', 'quantity', 100, 100),
    ]
    return (
        <DetailsList
            selectionMode={SelectionMode.none}
            items={props.bundles}
            columns={columns}
            />
    )
}

export default StorefrontOrderBundlesTable;