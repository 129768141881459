import React, {useState} from "react";
import EditCatalogModal from "../../components/EditCatalogModal/EditCatalogModal";
import {
    CommandBar,
    IColumn,
    ICommandBarItemProps,
    Icon,
    SelectionMode,
    ShimmeredDetailsList
} from "@fluentui/react";
import {ICatalog} from "../../models/Catalog";
import {useGetCatalogsQuery} from "../../store/Api";
import {ColumnGenerator, ConditionalButtonColumnGenerator} from "../../logic/Columns/ColumnGenerator";
import CAN, {CATALOG_MODIFY} from "../../permissions/ability"


const CatalogManagement: React.FC = () => {
    const getCatalogs = useGetCatalogsQuery();
    const [selectedCatalog, setSelectedCatalog] = useState<ICatalog | undefined>();
    const [modalOpen, setModalOpen] = useState(false);

    const commands: ICommandBarItemProps[] = [
        {
            key: 'Add',
            text: 'Add',
            iconProps: { iconName: 'Add' },
            onClick: () => {
                setModalOpen(true);
            }
        }
    ]

    const columns: IColumn[] = [
        ColumnGenerator('Name','name', 100, 300),
        ColumnGenerator('Description', 'description', 100),
        ColumnGenerator('Active', 'active', 50, 50, (c: ICatalog) => c.active
             ? <span style={{fontSize:'2em', fontWeight: 'bold'}}><Icon iconName='CheckMark' /></span> : <></>
        ),
        ConditionalButtonColumnGenerator((c: ICatalog) => {setSelectedCatalog(c); setModalOpen(true);}, 'Edit', 'Edit', CATALOG_MODIFY)
    ]

    return (
        <div className="main-content">
            <h1>Catalog Management</h1>
            {CAN(CATALOG_MODIFY) && (
            <CommandBar items={commands} />
            )}
            <ShimmeredDetailsList items={getCatalogs.data ?? []} enableShimmer={getCatalogs.isFetching} columns={columns} selectionMode={SelectionMode.none} />
            <EditCatalogModal
                catalog={selectedCatalog}
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedCatalog(undefined);
                }}
            />
        </div>
    );
}

export default CatalogManagement;
